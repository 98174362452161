import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { getCustomers } from "store/actions/Customers";
import { RootState } from 'store/store';
import { addInvoice } from "store/actions/Billing";
import { getTenants } from "store/actions/Tenants";
//@ts-ignore
import Select from 'react-select'
import { Invoice } from 'models/Invoice';
import { CustomerState } from 'store/reducers/Customers';
import { TenantState } from 'store/reducers/Tenants';

interface Props {
    customerState: CustomerState;
    tenantState: TenantState;
}

interface Actions {
    getCustomers: () => void;
    addInvoice: (i: Invoice) => void;
    getTenants: () => void;
}

const InvoiceAddButton: React.FC<Props & Actions> = ({ customerState, tenantState, getCustomers, addInvoice, getTenants }) => {

    const navigate = useNavigate();

    const [customerId, setTenantId] = useState<string>("")
    const [addAction, setAddAction] = useState<boolean>(false)
    const [error, setError] = useState("")

    const createNew = (isInvoice: boolean) => {
        const customer = customerState.getCustomerById(customerId)


        if (customer !== null) {
            const invoice = new Invoice()
            invoice.draft = true;
            invoice.quote = !isInvoice;
            invoice.created = new Date();
            const deadline = new Date();
            deadline.setDate(deadline.getDate() + customer.billing.default_deadline);
            invoice.deadline = deadline;
            invoice.tenant = customer.tenant;
            invoice.customer = customer.id;

            addInvoice(invoice)
                //@ts-ignore
                .then((response) => {
                    navigate("/invoice/" + response.id)
                })
        }

    }

    useEffect(() => {
        getCustomers()
    }, [getCustomers])

    return (<>
        {
            error &&
            <Alert color="danger">{error}</Alert>
        }
        {

            addAction ?

                <> <div className="form-floating mb-3" style={{ marginLeft: "5px", marginRight: "5px" }}>
                    <Select
                        options={customerState.getCustomers().map((customer) => ({ value: customer.id, label: customer.name + " - " + tenantState.getTenantById(customer.tenant)?.name }))}
                        onChange={(object: any) => {
                            setTenantId(object.value);
                        }}
                    />

                    <button type="button" style={{ marginRight: "3px", width: "100px", marginTop: "3px" }} className="btn btn-success waves-effect waves-light"
                        onClick={() => {
                            if (customerId !== "") {
                                createNew(true)
                                setAddAction(false)
                            } else {
                                setError("Choose customer");
                            }
                        }}>
                        Invoice
                    </button>

                    <button type="button" style={{ marginRight: "3px", width: "100px", marginTop: "3px" }} className="btn btn-primary waves-effect waves-light"
                        onClick={() => {
                            if (customerId !== "") {
                                createNew(false)
                                setAddAction(false)
                            } else {
                                setError("Choose customer");
                            }
                        }}>
                        Quote
                    </button>


                    <button type="button" style={{ width: "100px", marginTop: "3px" }} className="btn btn-danger waves-effect waves-light"
                        onClick={() => {
                            setAddAction(false)
                        }}>
                        Cancel
                    </button>
                </div>
                </>
                :
                <>
                    <button type="button" className="btn btn-success waves-effect waves-light md-3"
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                            setAddAction(true)
                        }}
                    >
                        Add invoice / quote
                    </button>
                </>
        }

    </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        customerState: state.Customers,
        tenantState: state.Tenants,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getCustomers, addInvoice, getTenants }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceAddButton);