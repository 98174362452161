import React, { useEffect, useState } from "react";
import { Person } from "models/Person";
import { Role } from "models/Role";
import { Customer } from "models/Customer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deletePerson, updatePerson } from 'store/actions/Customers';
import { Alert } from "reactstrap";
import LongClickButton from "components/LongClickButton";


interface Props {
    person: Person;
    customer: Customer;
    index: number;
    onCancel: () => void;
    save: () => void;
}

interface Actions {
    updatePerson: typeof updatePerson;
    deletePerson: typeof deletePerson;
}

const CustomerPersonAdd: React.FC<Props & Actions> = ({ customer, person, onCancel, save, updatePerson, deletePerson, index }) => {

    const [error, setError] = useState<string | null>(null)

    const roleTechnical: Role = "technical"
    const roleBilling: Role = "billing"

    const savePerson = () => {

        if (person.name === "" || person.name === undefined) {
            setError("Person needs a name")
        } else {
            updatePerson(person)
                //@ts-ignore
                .then(() => {
                    save();
                })
        }
    }

    const DeletePerson = () => {
        // customer.persons.splice(index, 1);
        deletePerson(person, customer.id)
            //@ts-ignore
            .then(() => {
                save();
            })
    }

    useEffect(() => {
    }, [])

    return (
        <>
            {
                error &&
                <Alert color="danger">{error}</Alert>
            }

            <br />
            <div className="form-floating mb-3">
                <button type="button"
                    onClick={() => {
                        onCancel();
                    }}
                    className="btn btn-danger waves-effect waves-light">
                    Cancel
                </button>
            </div>

            <div className="form-floating mb-3">
                <input type="text" name="nameperson" className="form-control" defaultValue={person.name} onChange={e => {
                    person.name = e.target.value

                }} placeholder="customer" />
                <label className="">First name</label>
            </div>

            <div className="form-floating mb-3">
                <input type="text" name="lastnameperson" className="form-control" defaultValue={person.last_name} onChange={e => {
                    person.last_name = e.target.value

                }} placeholder="customer" />
                <label className="">Last name</label>
            </div>

            <div className="form-floating mb-3">
                <input type="text" name="roleperson" className="form-control" defaultValue={person.title} onChange={e => {
                    person.title = e.target.value

                }} placeholder="name@example.com" />
                <label className="">Role</label>
            </div>
            <div className="form-floating mb-3">
                <input type="text" name="phoneperson" className="form-control" defaultValue={person.phone} onChange={e => {
                    person.phone = e.target.value

                }} placeholder="name@example.com" />
                <label className="">Phone</label>
            </div>
            <div className="form-floating mb-3">
                <input type="email" name="emailperson" defaultValue={person.email} onChange={e => {
                    person.email = e.target.value
                }} className="form-control" id="emailPerson" placeholder="name@example.com" />
                <label className="">Email</label>
            </div>
            <div className="form-floating mb-3">

                <p className="text-muted text-uppercase fw-semibold mb-0" style={{ fontSize: "13px" }}>Type</p>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" key="billing" type="checkbox" name="billing" id="billing"
                        defaultChecked={(person.roles ?? []).filter(item => item === "billing").length > 0} onChange={e => {
                            if (e.target.checked) {
                                person.roles = person.roles.filter(item => item !== "billing")
                                person.roles.push(roleBilling)
                            } else {
                                person.roles = person.roles.filter(item => item !== "billing")
                            }

                        }} />
                    <label className="form-check-label">Billing</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" key="technical" type="checkbox" name="technical" id="technical" defaultChecked={(person.roles ?? []).filter(item => item === "technical").length > 0} onChange={e => {
                        if (e.target.checked) {
                            person.roles = person.roles.filter(item => item !== "technical");
                            person.roles.push(roleTechnical)
                        } else {
                            person.roles = person.roles.filter(item => item !== "technical");
                        }
                    }} />
                    <label className="form-check-label">Technical</label></div><div className="form-check form-check-inline">
                </div>

                <div className="mt-3">
                    <button
                        id="buttonPerson"
                        name="buttonPerson"
                        type="button"
                        onClick={() => {
                            savePerson()
                        }}
                        className="btn btn-primary w-100 waves-effect waves-light"
                    >
                        <i className="mdi mdi-account-plus"></i> Save
                    </button>
                </div>

                <LongClickButton color="danger" style={{ marginTop: '5px', width: "100%" }}
                    onClick={() => {
                        DeletePerson()
                    }}>
                    Delete person</LongClickButton>

            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ deletePerson, updatePerson }, dispatch);
};

export default connect(null, mapDispatchToProps)(CustomerPersonAdd);
