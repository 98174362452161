import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getInvoices } from "store/actions/Billing";
import { bindActionCreators } from "redux";
import { Customer } from "models/Customer";
import { RootState } from "store/store";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getEmails, sendEmail } from "store/actions/Emails";
import { Email } from "models/Email";
// import { File } from "models/File";
import { getCustomers, getPersons } from "store/actions/Customers";
import { useNavigate } from "react-router-dom";
import TenantName from "components/TenantName";
//@ts-ignore
import Select from 'react-select'
import { Invoice } from "models/Invoice";
import EmailCard from "./EmailCard";
import { EmailState } from 'store/reducers/Emails';
import { CustomerState } from 'store/reducers/Customers';
import { InvoiceState } from 'store/reducers/Invoices';
import DropzoneCustom, { DroppedFile } from "components/DropzoneCustom";
import { getInvoiceExport } from "../../services/Api";
import { UuidNil } from "../../common/uuid";
import { PersonsState } from "../../store/reducers/Persons";

interface Props {
    customerState: CustomerState;
    personsState: PersonsState;
    invoiceState: InvoiceState;
    emailState: EmailState;
}

interface Actions {
    getInvoices: () => void;
    sendEmail: (email: Email) => Promise<Email>;
    getCustomers: typeof getCustomers;
    getPersons: typeof getPersons;
}

const EmailCompose: React.FC<Props & Actions> = ({
    personsState,
    getPersons,
    invoiceState,
    emailState,
    customerState,
    getInvoices,
    sendEmail,
    getCustomers
}) => {
    const [selectedFiles, setselectedFiles] = useState<DroppedFile[]>([]);
    const [email, setEmail] = useState(new Email())
    const [customer, setCustomer] = useState<Customer>()
    const [, setInvoice] = useState<Invoice | null>()
    const [cc, setCc] = useState<string[]>([])

    const [attachments, setAttachments] = useState<string[]>([])

    const [prefillTo, setPrefillTo] = useState<{ value: string, label: string } | null>(null);

    const navigate = useNavigate();

    const reply_id = new URLSearchParams(window.location.search).get("reply");
    const customer_id = new URLSearchParams(window.location.search).get("customer");
    const person_id = new URLSearchParams(window.location.search).get("person");

    const emailReply = emailState.getEmailById(reply_id || "");

    const setReply = React.useCallback(() => {

        if (reply_id && emailReply) {
            const mail = new Email();

            mail.reply_to = reply_id;
            mail.subject = emailReply.subject;
            if (!emailReply.subject.startsWith("RE: ")) {
                mail.subject = "RE: " + emailReply.subject;
            }
            mail.tenant = emailReply.tenant;
            mail.reference = emailReply.reference;
            mail.recipient = emailReply.sender_name + " <" + emailReply.sender + ">";
            mail.carbon_copy = emailReply.carbon_copy;

            if (mail.carbon_copy && mail.carbon_copy !== "") {
                setCc([...mail.carbon_copy.split(", ")])
            }

            var customer = customerState.getCustomerById(emailReply.customer);

            if (!customer) {
                // customer = customerState.getCustomers().find(customer => customer.persons.some(item => item.email === mail.recipient)) || null;

            }

            if (!customer) {
                customer = customerState.getCustomers().find(x => x.email === emailReply.recipient) || null;

            }

            if (!customer) {
                mail.customer = UuidNil;
            }

            if (customer) {
                mail.customer = customer.id
                setCustomer(customer)
            }

            const invoice = invoiceState.getInvoiceById(emailReply.reference.replace('invoice_', ''));

            //set base64 om naar files
            // mail.attachments.forEach((file, i) => {

            // })


            setInvoice(invoice);
            setEmail(mail);
        }

    }, [customerState, emailReply, invoiceState, reply_id, setEmail, setCc]);


    const setEmailTo = React.useCallback(() => {

        if (customer_id) {

            const mail = new Email();
            var customer = customerState.getCustomerById(customer_id);

            if (customer) {
                mail.customer = customer.id;
                mail.tenant = customer.tenant;
                const person = personsState.getPersonById(person_id ?? "")
                if (person) {
                    mail.recipient = person.name + " | " + customer.name + " <" + person.email + ">";
                } else {
                    mail.recipient = customer.name + " <" + customer.email + ">";
                }
            }

            setEmail(mail);
        }
        // eslint-disable-next-line
    }, [customerState, customer_id, person_id, setEmail]);

    useEffect(() => {
        getCustomers()
        getInvoices()
        getPersons()
        getEmails()
    }, [getCustomers, getInvoices, getPersons])

    useEffect(() => {
        setReply()
        setEmailTo()
    }, [setReply, setEmailTo])


    const nl2br = (str: string, replaceMode: boolean = true, isXhtml: boolean = true) => {
        var breakTag = (isXhtml) ? '<br />' : '<br>';
        var replaceStr = (replaceMode) ? '$1' + breakTag : '$1' + breakTag + '$2';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
    }


    const contacts: object[] = [];
    if (emailReply) {
        contacts.push({
            value: `${UuidNil}%${emailReply.sender}`,
            label: `${emailReply.sender_name} <${emailReply.sender}>`
        })
    }
    customerState.getCustomers().map(customer => {
        if (customer.email !== "") {
            contacts.push({ value: `${customer.id}%${customer.email}`, label: `${customer.name} <${customer.email}>` })
        }
        return true
    })

    personsState.getPersons().map(person => {
        if (person.email !== "") {
            // contacts.push({value: `${person.id}%${person.email}`, label: `${person.name} <${person.email}>`})
        }
        person.customers.map(customerId => {
            const customer = customerState.getCustomerById(customerId)
            if (customer != null) {
                contacts.push({
                    value: `${customerId}%${person.email}`,
                    label: person.name + " | " + customer.name + " <" + person.email + ">"
                })
            }
            return true
        })
        return true
    })


    // To-header pre-filling
    /* eslint-disable @typescript-eslint/no-unused-vars */
    let toPrefilled: { value: string, label: string } | undefined = undefined;

    const prefillPerson = personsState.getPersonById(person_id ?? "");
    const prefillCustomer = customerState.getCustomerById(customer_id ?? "");

    useEffect(() => {
        if (emailReply !== null) {
            const customer = customerState.getCustomerById(emailReply.customer);
            if (customer === null) {
                setPrefillTo({
                    value: `${UuidNil}%${emailReply.sender}`,
                    label: `${emailReply.sender_name} <${emailReply.sender}>`
                });
                return;
            }

            const person = personsState.getPersonsByCustomerId(customer.id).find(person => person.email === email.sender)
            if (person === undefined) {
                setPrefillTo({
                    value: `${customer.id}%${emailReply.sender}`,
                    label: `${emailReply.sender_name} | ${customer.name} <${emailReply.sender}>`
                });
            } else {
                setPrefillTo({
                    value: `${customer.id}%${person.email}`,
                    label: `${person.name} | ${customer.name} <${person.email}>`
                });
            }
            const replyToCustomer = customerState.getCustomers().find(customer => {
                const persons = personsState.getPersonsByCustomerId(customer.id);
                return persons.find(person => person.email === emailReply.sender) !== undefined
            })
            if (replyToCustomer === undefined) {
                // eslint-disable-next-line
                toPrefilled = {
                    value: `${UuidNil}%${emailReply.sender}`,
                    label: `${emailReply.sender_name} <${emailReply.sender}>`
                }
            } else {
                const replyToPerson = personsState.getPersonsByCustomerId(replyToCustomer.id).find(person => person.email === emailReply.sender)
                if (replyToPerson === undefined) {
                    toPrefilled = {
                        value: `${replyToCustomer.id}%${emailReply.sender}`,
                        label: `${emailReply.sender_name} | ${replyToCustomer.name} <${emailReply.sender}>`
                    }
                } else {
                    // eslint-disable-next-line
                    toPrefilled = {
                        value: `${replyToCustomer.id}%${replyToPerson.email}`,
                        label: `${replyToPerson.name} | ${replyToCustomer.name} <${replyToPerson.email}>`
                    }
                }
            }
        } else {

            if (prefillCustomer !== null) {
                if (prefillPerson !== null) {
                    setPrefillTo({
                        value: `${prefillCustomer.id}%${prefillPerson.email}`,
                        label: `${prefillPerson.name} | ${prefillCustomer.name} <${prefillPerson.email}>`
                    });
                } else {
                    setPrefillTo({
                        value: `${prefillCustomer.id}%${prefillCustomer.email}`,
                        label: `${prefillCustomer.name} | ${prefillCustomer.name} <${prefillCustomer.email}>`
                    });
                }
            }
        }

    }, [prefillPerson, prefillCustomer, emailReply])


    return (
        <>
            <Card>
                <CardBody>
                    <div>
                        <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>Compose</p>
                    </div>
                    <div>
                        {emailReply?.type !== "whatsapp" &&
                            <Row className="mb-3">
                                <Col lg={10}>
                                    <Select
                                        placeholder={'To'}
                                        //@ts-ignore
                                        options={contacts}
                                        value={prefillTo}
                                        onChange={(object: any) => {
                                            const target = object.value
                                            const customer = customerState.getCustomerById(target.split("%")[0]);
                                            if (customer) {
                                                email.customer = customer.id
                                                email.tenant = customer.tenant;
                                                email.recipient = object.label;
                                                setEmail(email)
                                                setCustomer(customer)
                                            } else {
                                                if (emailReply !== null) {
                                                    email.customer = emailReply.customer;
                                                    email.tenant = emailReply.tenant;
                                                } else {
                                                    email.customer = UuidNil;
                                                    email.tenant = UuidNil;
                                                }
                                                email.recipient = target.split("%")[1]
                                                setEmail(email)
                                            }
                                        }}
                                    />
                                </Col>

                                <Col lg={2}>
                                    {email.tenant !== undefined &&
                                        <>
                                            <small>Tenant:</small>
                                            <br />
                                            <TenantName id={email.tenant} />
                                        </>
                                    }
                                </Col>

                            </Row>
                        }
                        {(customer !== undefined && invoiceState.getInvoicesByCustomerId(customer.id).length > 0 && emailReply?.type !== "whatsapp") &&
                            <Row>
                                <Col lg={12} className="mb-3">
                                    <label className="form-label">Choose invoice</label>
                                    <Select
                                        options={
                                            invoiceState
                                                .getInvoicesByCustomerId(customer.id)
                                                .map((invoice) => ({
                                                    value: invoice.id,
                                                    label: invoice.name + " - " + invoice.GetCreatedDate().toDateString()
                                                }))}
                                        onChange={(object: any) => {
                                            getInvoiceExport(object.value).then(pdf => {
                                                const file = new DroppedFile();
                                                file.name = object.value + ".pdf";
                                                file.content = "data:application/pdf;base64," + pdf
                                                setselectedFiles([...selectedFiles, file]);
                                            })
                                            email.reference = "invoice_" + object.value
                                            setEmail(email)
                                        }}
                                    />
                                </Col>
                            </Row>
                        }


                        {(email.recipient && emailReply?.type !== "whatsapp") &&
                            <Row>
                                <Col lg={12} className="mb-3">
                                    <label className="form-label">Choose CC</label>
                                    <Select
                                        placeholder={'CC'}
                                        options={contacts}
                                        onChange={(object: any) => {
                                            const target = object.label
                                            cc.push(target)
                                            setCc([...cc])
                                        }}
                                    />
                                    {cc
                                        .map((customer, key) => (
                                            <span onClick={() => {
                                                cc.splice(key, 1);
                                                setCc([...cc])
                                            }} className="badge bg-primary me-1 font-size-12 me-2 mb-3">
                                                {customer} x
                                            </span>
                                        ))}
                                </Col>

                            </Row>
                        }


                        {emailReply?.type !== "whatsapp" && <div className="form-floating mb-3">
                            <input type="text" className="form-control" defaultValue={email.subject} onChange={e => {
                                email.subject = e.target.value
                                setEmail(email)
                            }} />
                            <label className="">Subject</label>
                        </div>
                        }
                        <div className="form-floating mb-3">
                            <textarea rows={4} className="form-control" style={{ height: "100px" }}
                                defaultValue={email.body} onChange={e => {
                                    email.body = e.target.value
                                    setEmail(email)
                                }} />
                            <label className="">Body</label>
                        </div>

                        {(email.recipient && emailReply?.type !== "whatsapp") &&
                            <Row>
                                <Col lg={12} className="mb-3">
                                    <DropzoneCustom selectedFiles={selectedFiles}
                                        setselectedFiles={(files) => setselectedFiles([...selectedFiles, ...files])} />
                                </Col>
                            </Row>
                        }


                        <button
                            type="button"
                            disabled={email.customer === undefined || email.tenant === undefined}
                            onClick={() => {
                                var ccString = "";
                                cc.forEach((email) => {
                                    ccString += (email + ", ");
                                });
                                var finalCC = ccString.substring(0, ccString.length - 2);
                                email.carbon_copy = finalCC;

                                if (selectedFiles.length > 0) {
                                    setAttachments([...[]])
                                    selectedFiles.forEach((file) => {
                                        attachments.push(file.name + "*" + file.content);
                                    })
                                    setAttachments([...attachments])
                                    email.attachments = attachments;
                                    setEmail(email)
                                }

                                if (emailReply != null && emailReply.type === "whatsapp") {
                                    email.type = "whatsapp"
                                }

                                const newEmail = JSON.parse(JSON.stringify(email))
                                newEmail.body = nl2br(email.body)

                                sendEmail(newEmail).then(sentEmail => navigate(`/conversation/${sentEmail.id}`))
                            }}
                            className="btn btn-primary w-100 waves-effect waves-light"
                        >
                            <i className="mdi mdi-account-plus"></i> Send
                        </button>
                    </div>
                </CardBody>
            </Card>
            {emailReply !== null &&
                <EmailCard id={emailReply.id} />
            }
        </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        personsState: state.Persons,
        emailState: state.Emails,
        customerState: state.Customers,
        invoiceState: state.Invoices
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ sendEmail, getPersons, getCustomers, getInvoices, getEmails }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailCompose);
