import { RequestTypes, generateAction, EventType } from "../../storeAccount/actions/Util";
import * as AccountApi from "../../services/AccountApi";
import { RegisterWsHandler } from "./Index";

export const GET_SERVICES = new RequestTypes("GET_SERVICES");
export const SERVICE_LAST_CHECK_UPDATED = new EventType("SERVICE_LAST_CHECK_UPDATED");

export const getServices = () => {
    return generateAction(GET_SERVICES, () =>
        AccountApi.getServices());
};


export const serviceLastCheckUpdated = (payload: any) =>
    (dispatch: any) =>
        dispatch(SERVICE_LAST_CHECK_UPDATED.payload(payload))
RegisterWsHandler("last_check_update", serviceLastCheckUpdated)