import React from "react";
import {Tenant} from "models/Tenant";
import {RootState} from "store/store";
import {connect} from "react-redux";
import {InvoiceState} from 'store/reducers/Invoices';

interface Props {
    tenant: Tenant;
    invoiceState: InvoiceState;
}

const InvoiceTabButtonMobile: React.FC<Props> = ({ tenant, invoiceState }) => {

    const total = invoiceState.getInvoicesFromTenant(tenant.id);
    const unpaid = total.filter(invoice => !invoice.IsPaid()).length;

    return <span className="d-block d-sm-none">
        <span className="badge bg-success ms-1">{total.length - unpaid}</span>
        <span className="badge bg-danger ms-1">{unpaid}</span>
    </span>;
};

const mapStateToProps = (state: RootState) => {
    return {
        invoiceState: state.Invoices,
    };
};

export default connect(mapStateToProps)(InvoiceTabButtonMobile);