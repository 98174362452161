import WaitingDots from 'components/WaitingDots';
import {Customer} from 'models/Customer';
import React from 'react';
import {connect} from 'react-redux';
import {RootState} from 'store/store';
import {InvoiceState} from 'store/reducers/Invoices';

interface Props {
    customer: Customer;
    invoiceState: InvoiceState;
}

const CustomerOpenInvoicesButton: React.FC<Props> = ({ customer, invoiceState }) => {
    if (customer === undefined) {
        return <WaitingDots />
    }

    const openInvoices = invoiceState.getInvoices().filter(invoice => invoice.customer === customer.id).filter(invoice => !invoice.IsPaid()).length

    return (
        <button type="button" disabled className={`btn btn-sm btn-outline-${openInvoices > 0 ? "danger" : "success"}`}>
            Invoices
            <span className={`badge bg-${openInvoices > 0 ? "danger" : "success"} ms-1`}>{openInvoices}</span>
        </button>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        invoiceState: state.Invoices,
    };
};

export default connect(mapStateToProps)(CustomerOpenInvoicesButton);