import { ApexOptions } from 'apexcharts';
import { Expense } from 'models/Expense';
import { Invoice } from 'models/Invoice';
import { AccountableContainer, FilterExternalInvoices, FilterFinalInvoices } from 'models/InvoiceContainer';
import React, { useEffect } from 'react';
import ReactApexChart from "react-apexcharts"
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { getInvoices } from 'store/actions/Billing';
import { getExpenses } from 'store/actions/Expenses';
import { InvoiceState } from 'store/reducers/Invoices';
import { ExpenseState } from 'store/reducers/Expenses';
import { RootState } from 'store/store';
import { CustomerState } from "../../store/reducers/Customers";
import { getCustomers } from "../../store/actions/Customers";
import { UuidNil } from "../../common/uuid";

interface Props {
    invoiceState: InvoiceState;
    expenseState: ExpenseState;
    customerState: CustomerState;
}

interface Actions {
    getCustomers: () => void;
    getInvoices: () => void;
    getExpenses: () => void;
}

const SalesAnalytics: React.FC<Props & Actions> = ({ getCustomers, getInvoices, getExpenses, customerState, invoiceState, expenseState }) => {

    useEffect(() => { getCustomers() }, [getCustomers])
    useEffect(() => { getInvoices() }, [getInvoices])
    useEffect(() => { getExpenses() }, [getExpenses])

    const ic = (new AccountableContainer<Invoice>(invoiceState.getInvoices()))
        .Filter(FilterExternalInvoices)
        .Filter(FilterFinalInvoices)

    const ec = (new AccountableContainer<Expense>(expenseState.getExpenses().filter(exp => {
        if (exp.name.length === 36) {
            const inv = invoiceState.getInvoiceById(exp.name)
            if (inv !== null) {
                const customer = customerState.getCustomerById(inv.customer)
                return customer !== null && customer.is_tenant === UuidNil
            }
        }
        return true;
    })))

    var series: ApexAxisChartSeries = [
        {
            name: 'Revenue',
            type: 'column',
            data: ic
                .EveryMonth(12)
                .CalcTotal(invoice => invoice.CalcTotalContents())
                .map((rev) => Math.floor(rev))
        },
        {
            name: 'Expenses',
            type: 'column',
            data: ec
                .EveryMonth(12)
                .CalcTotal(expense => expense.total - expense.tax)
                .map((rev) => Math.floor(rev))
        },
        // {
        //     name: 'Conversation Ratio',
        //     type: 'area',
        //     data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
        // },
        // {
        //     name: 'Invoices',
        //     type: 'line',
        //     data: ic.EveryMonth(12).Count()
        // }
    ];
    var options: ApexOptions = {
        chart: {
            height: 338,
            type: 'line',
            stacked: !1,
            offsetY: -5,
            toolbar: {
                show: !1
            }
        },
        stroke: {
            width: 1,
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '40%'
            }
        },
        colors: ['#2cb57e', '#0576b9', '#dfe2e6', '#f1b44c'],
        fill: {
            opacity: [0.85, 1, 0.25, 1],
            gradient: {
                inverseColors: !1,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
            }
        },
        labels: ic.EveryMonth(12).GetMonths(),
        markers: {
            size: 0
        },
        yaxis: {
            decimalsInFloat: 0,
        },
        tooltip: {
            shared: !0,
            intersect: !1
        },
        grid: {
            borderColor: '#f1f1f1',
            padding: {
                bottom: 15
            }
        }
    }

    return (
        <Card>
            <CardBody>
                <div>
                    <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>Analytics</p>
                </div>
                <div className="mt-3">
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="bar"
                        height="340"
                        dir="ltr"
                        className="apex-charts"
                    />
                </div>
            </CardBody>
        </Card>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        invoiceState: state.Invoices,
        expenseState: state.Expenses,
        customerState: state.Customers
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getInvoices, getExpenses, getCustomers }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesAnalytics);