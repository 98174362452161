import {generateAction, RequestTypes} from "./Util";
import * as api from "../../services/Api";
import {Expense} from "models/Expense";
import {DroppedFile} from "components/DropzoneCustom";

export const GET_EXPENSES = new RequestTypes("GET_EXPENSES");
export const GET_EXPENSE_ATTACHMENTS = new RequestTypes("GET_EXPENSE_ATTACHMENTS");
export const ADD_EXPENSE_ATTACHMENTS = new RequestTypes("ADD_EXPENSE_ATTACHMENTS");
export const DELETE_EXPENSE_ATTACHMENTS = new RequestTypes("DELETE_EXPENSE_ATTACHMENTS");
export const ADD_EXPENSE = new RequestTypes("ADD_EXPENSE");
export const EDIT_EXPENSE = new RequestTypes("EDIT_EXPENSE");

export const getExpenses = () => {
  return generateAction(GET_EXPENSES, () =>
    api.getExpenses()
  );
};

export const getExpenseAttachments = (id: string) => {
  return generateAction(GET_EXPENSE_ATTACHMENTS, () =>
    api.getExpenseAttachments(id)
  );
};

export const addExpenseAttachment = (id: string, file: DroppedFile) => {
  return generateAction(ADD_EXPENSE_ATTACHMENTS, () =>
    api.addExpenseAttachment(id, file.name, file.content)
  );
};

export const deleteExpenseAttachment = (id: string, aid: string) => {
  return generateAction(DELETE_EXPENSE_ATTACHMENTS, () =>
    api.deleteExpenseAttachment(id, aid)
  );
};

export const addExpense = (expense: Expense) => {
  return generateAction(ADD_EXPENSE, () =>
    api.addExpense(expense)
  );
};

export const editExpense = (expense: Expense) => {
  return generateAction(EDIT_EXPENSE, () =>
    api.editExpense(expense)
  );
};
