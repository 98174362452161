import TenantName from 'components/TenantName';
import WaitingDots from 'components/WaitingDots';
import {Customer} from 'models/Customer';
import {Invoice} from 'models/Invoice';
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {RootState} from 'store/store';

interface Props {
    invoice: Invoice;
    customers: Customer[]
}


const InvoiceRowIndex: React.FC<Props> = ({ invoice, customers }) => {

    if (invoice === undefined) {
        return <WaitingDots />
    }

    return (
        <tr>
            <td>
                <Link to={`/invoice/${invoice.id}`}>
                    {invoice.name !== "" ? invoice.name : <i>No name</i>}
                </Link>
            </td>
            <td>
                <>
                    <Link to={"/customers/" + customers.find(customer => customer.id === invoice.customer)?.id}>
                        <span className="badge bg-primary me-1 font-size-12 me-2 mb-3"> <i className="fas fa-user" /> {customers.find(customer => customer.id === invoice.customer)?.name}</span>
                    </Link>
                    <br></br>
                    <Link to={"/tenants/" + invoice.tenant}>
                        <span className="badge bg-primary me-1 font-size-12 me-2 mb-3"><i className="dripicons-store" />  <TenantName fetch={false} id={invoice.tenant} /></span>
                    </Link>
                </>
            </td>
            <td>{invoice.GetCreatedDate().toDateString()}</td>
            {!invoice.quote && !invoice.draft && <td><span className={invoice.IsPaid() ? "badge bg-success me-1 font-size-12 me-2 mb-3" : "badge bg-danger me-1 font-size-12 me-2 mb-3"}>{invoice.GetDeadlineDate().toDateString()}</span></td>}
            {!invoice.quote && invoice.draft && <td>  <span className="badge bg-primary me-1 font-size-12 me-2 mb-3">Invoice (draft)</span></td>}
            {invoice.quote && <td> <span className="badge bg-primary me-1 font-size-12 me-2 mb-3">Quote</span></td>}
            <td>&euro; {invoice.CalcTotalContents().toFixed(2)}</td>
        </tr>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
    };
};


export default connect(mapStateToProps)(InvoiceRowIndex);