import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getInvoices } from "store/actions/Billing";
import { bindActionCreators } from "redux";
import { RootState } from "../../store/store";
import { getCustomers } from "store/actions/Customers";
import WaitingDots from "components/WaitingDots";
import PageMeta from "components/PageMeta";
import { Card, CardBody, Col, Row } from "reactstrap";
import InvoiceRowIndex from "./InvoiceRowIndex";
import InvoiceAddButton from "./InvoiceAddButton";
import { InvoiceState } from 'store/reducers/Invoices';
import { CustomerState } from 'store/reducers/Customers';
import { getTenants } from "../../store/actions/Tenants";
import InvoiceFilter from "./InvoiceFilter";

interface Props {
    invoiceState: InvoiceState;
    customerState: CustomerState;
}


interface Actions {
    getTenants: () => void;
    getInvoices: () => void;
    getCustomers: () => void;
}

const Invoices: React.FC<Props & Actions> = ({ invoiceState, customerState, getTenants, getInvoices, getCustomers }) => {

    const [nameKeyword, setNameKeyword] = useState("")
    const [onlyUnpaid, setOnlyUnpaid] = useState(false)

    //@ts-ignore
    var url = new URL(window.location);
    const searchTerm = url.searchParams.get('q')

    useEffect(() => {
        getInvoices()
        getCustomers()
        getTenants()
        // eslint-disable-next-line
        if (searchTerm != undefined) {
            //@ts-ignore
            setNameKeyword(searchTerm);
        }
        // eslint-disable-next-line
    }, [getInvoices, getCustomers, getTenants])

    if (customerState.getCustomers() === undefined) {
        return <WaitingDots />
    }

    const refreshSearch = () => {
        const params = new URLSearchParams(window.location.search)
        if (params.get('q') != null) {
            // @ts-ignore
            setNameKeyword(params.get('q'))
        }
    }

    const filteredCustomer = customerState.getCustomers()
        .filter(customer => customer.name.toLowerCase().includes(nameKeyword.toLowerCase()))
        .map((customer) => { return (customer.id) })

    const filteredInvoices = invoiceState.getInvoices()
        .filter(invoice => invoice.name.toLowerCase().includes(nameKeyword.toLowerCase())
            || filteredCustomer.includes(invoice.customer))


    return (
        <div className="page-content">
            <PageMeta title={`Invoices`} />
            <Row>
                <Col md={12} xl={12}>
                    <Row>
                        <Col md={12}>
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <form className="app-search" style={{ marginLeft: "5px" }}>
                                    <div className="position-relative">
                                        <input type="text"
                                            name="search" id="search" key="search" className="form-control" value={nameKeyword}
                                            onChange={(e) => {
                                                //@ts-ignore
                                                var url = new URL(window.location);
                                                url.searchParams.set('q', e.target.value);
                                                //@ts-ignore
                                                window.history.pushState({}, '', url);
                                                refreshSearch()
                                            }}
                                            placeholder="Search..." />
                                        <span className="mdi mdi-magnify"></span>
                                    </div>
                                </form>

                                <InvoiceAddButton />

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <InvoiceFilter setOnlyUnpaid={setOnlyUnpaid} filteredInvoices={filteredInvoices} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table table-hover table-borderless align-middle table-centered table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Customer & Tenant</th>
                                                    <th>Created</th>
                                                    <th>Deadline</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoiceState.getInvoices().length > 0 && filteredInvoices
                                                    .sort((a, b) => (a.GetCreatedDate() < b.GetCreatedDate()) ? 1 : (a.GetCreatedDate() > b.GetCreatedDate()) ? -1 : 0)
                                                    .filter(invoice => !onlyUnpaid || (!invoice.draft && !invoice.IsPaid()))
                                                    .map((invoice, key) => {
                                                        return (
                                                            <InvoiceRowIndex key={key} invoice={invoice} customers={customerState.getCustomers()} />
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}




const mapStateToProps = (state: RootState) => {
    return {
        invoiceState: state.Invoices,
        customerState: state.Customers,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getInvoices, getCustomers, getTenants }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)