import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";

import { Alert, Card, CardBody, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTenants } from "store/actions/Tenants";
import { addCustomer } from "store/actions/Customers";
import { RootState } from "../../store/store";
import { Address } from "models/Address";
import { Customer } from "models/Customer";
import PageMeta from "components/PageMeta";
//@ts-ignore
import Select from 'react-select'
import { TenantState } from 'store/reducers/Tenants';

interface Props {
    tenantState: TenantState;
}

interface Actions {
    getTenants: () => void;
    addCustomer: (c: Customer) => void;
}

const CustomerAddForm: React.FC<Props & Actions> = ({ tenantState, getTenants, addCustomer }) => {
    const [customer, setCustomer] = useState(new Customer())
    const [address, setAddress] = useState(new Address())

    const [error, setError] = useState<string | null>(null)

    const navigate = useNavigate();

    const countries = [
        { value: "The Netherlands", label: "The Netherlands" },
        { value: "Belgium", label: "Belgium" },
        { value: "Germany", label: "Germany" },
        { value: "United Kingdom", label: "United Kingdom" },
        { value: "France", label: "France" },
        { value: "USA", label: "USA" }
    ]

    useEffect(() => {
        getTenants()
    }, [getTenants])


    const Save = () => {
        if (
            (customer.name !== "" && customer.name !== undefined)
            && (customer.email !== "" && customer.email !== undefined)
            && (customer.tenant !== "" && customer.tenant !== undefined)
        ) {

            customer.address = address;
            setCustomer(customer);

            addCustomer(customer)
                //@ts-ignore
                .then(() => {
                    navigate("/customers");
                })
                .catch(() => {
                    setError("Login has failed, check your credentials")
                });

        } else {
            setError("Name & email & tenant are mandatory")
        }


    }

    return (
        <>
            <PageMeta title={`Add Customer`} />
            <div className="page-content">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h2 className="mb-0 font-size-120">Add new customer</h2>
                </div>
                {
                    error &&
                    <Alert color="danger">{error}</Alert>
                }

                <Row>
                    <Col md={12} xl={12}>
                        <Card>
                            <CardBody>
                                <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "22px" }}>Details</p>
                                <div className="dropdown-divider"></div>



                                <div className="mb-3">
                                    <label className="form-label">Tenants:</label>
                                    <Select
                                        options={tenantState.getTenants().map((tenant) => ({ value: tenant.id, label: tenant.name }))}
                                        onChange={(object: any) => {
                                            customer.tenant = object.value
                                            setCustomer(customer)
                                        }}
                                    />
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={customer.name} placeholder="customer" onChange={e => {
                                        customer.name = e.target.value
                                        setCustomer(customer)
                                    }} />
                                    <label className="">Name</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="email" className="form-control" value={customer.email} onChange={e => {
                                        customer.email = e.target.value
                                        setCustomer(customer)
                                    }} placeholder="name@example.com" />
                                    <label className="">Email address</label>
                                </div>
                                <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "22px" }}>Address</p>
                                <div className="dropdown-divider"></div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={address.street} onChange={e => {
                                        address.street = e.target.value
                                        setAddress(address)
                                    }} placeholder="customer" />
                                    <label className="">Street</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={customer.address.number} onChange={e => {
                                        customer.address.number = e.target.value
                                        setCustomer(customer)
                                    }} placeholder="customer" />
                                    <label className="">Number</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={address.city} onChange={e => {
                                        address.city = e.target.value
                                        setAddress(address)
                                    }} placeholder="customer" />
                                    <label className="">City</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={address.zip_code} onChange={e => {
                                        address.zip_code = e.target.value
                                        setAddress(address)
                                    }} placeholder="customer" />
                                    <label className="">Zip</label>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Country:</label>
                                    <Select
                                        options={countries}
                                        onChange={(object: any) => {
                                            address.country = object.value
                                            setAddress(address)
                                        }}
                                    />
                                </div>



                                <div className="mt-3">
                                    <button
                                        id="buttonCustomer"
                                        name="buttonCustomer"
                                        className="btn btn-primary w-100 waves-effect waves-light"
                                        type="button"
                                        onClick={() => {
                                            Save()
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {/* <Col md={6} xl={6}>
                        <Card>
                            <CardBody> */}
                    {/* <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "22px" }}>Persons</p>
                    <div className="dropdown-divider"></div> */}
                    {/*{*/}
                    {/*    customer.persons.map((person, i) => {*/}
                    {/*        return <PersonAddForm person={person}*/}
                    {/*            onDelete={() => {*/}
                    {/*                customer.persons.splice(i, 1);*/}
                    {/*                setCustomer({ ...customer })*/}
                    {/*            }*/}
                    {/*            } onUpdate={(person: Person) => {*/}
                    {/*                if (person.name === "") {*/}
                    {/*                    setError("Person needs a name")*/}
                    {/*                } else {*/}
                    {/*                    customer.persons[i] = person*/}
                    {/*                    setCustomer(customer)*/}
                    {/*                }*/}

                    {/*            }} />*/}
                    {/*    })*/}
                    {/*}*/}
                    {/*<div className="mt-3">*/}
                    {/*    <button*/}
                    {/*        id="buttonPerson"*/}
                    {/*        name="buttonPerson"*/}
                    {/*        type="button"*/}
                    {/*        onClick={() => {*/}
                    {/*            customer.persons.push(new Person())*/}
                    {/*            setCustomer({ ...customer })*/}
                    {/*        }}*/}
                    {/*        className="btn btn-primary w-100 waves-effect waves-light"*/}
                    {/*    >*/}
                    {/*        <i className="mdi mdi-account-plus"></i> Add another person*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    {/*</CardBody>*/}
                    {/* </Card >
                    </Col> */}
                </Row>

            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        tenantState: state.Tenants
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getTenants, addCustomer }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAddForm);
