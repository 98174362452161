import React from "react";
import Dropzone from "react-dropzone";
import {Card, Col, Row} from "reactstrap";
import {File} from "models/File";
import {b64toBlob} from 'common/base64toblob';
import prettyBytes from 'pretty-bytes';

export class DroppedFile {
    name!: string;
    content!: string;
}

interface Props {
    mini?: boolean;
    selectedFiles?: DroppedFile[]
}

interface Actions {
    setselectedFiles?: (f: DroppedFile[]) => void;
    onDrop?: (file: DroppedFile) => void;
    onDelete?: (file: DroppedFile) => void;
}

const DropzoneCustom: React.FC<Props & Actions> = ({ onDrop, mini, selectedFiles, setselectedFiles }) => {

    function handleAcceptedFiles(files: any[]) {

        files.map((file: File) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                let result = reader.result
                if (typeof result !== "string") {
                    result = "";
                }
                if (setselectedFiles !== undefined) {
                    setselectedFiles([{
                        name: file.name,
                        content: result,
                    }])
                }
                if (onDrop !== undefined) {
                    onDrop({
                        name: file.name,
                        content: result,
                    })
                }
            };

            return true;
        });
    }

    return (
        <>
            <Dropzone
                onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                }}
            >
                {({ getRootProps, getInputProps }) => (
                    <div className="dropzone" style={mini ? { height: "80px", minHeight: "0px" } : {}}>
                        <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                            style={mini ? { padding: '0' } : {}}
                        >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            {!mini &&
                                <h4>Drop file(s) here or click to upload.</h4>
                            }
                        </div>
                    </div>
                )}
            </Dropzone>
            <div className="dropzone-previews mt-3" id="file-previews">
                {selectedFiles !== undefined && selectedFiles.map((f, i) => {
                    console.log(f)
                    const blob = b64toBlob(f.content.split(";base64,")[1], f.content.split(";")[0].split(":")[1]);
                    const blobUrl = URL.createObjectURL(blob);
                    return (
                        <>
                            <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                            >
                                <div className="p-2">
                                    <Row className="align-items-center">
                                        <Col className="col-auto">
                                            {
                                                (f.name.includes("png") || f.name.includes("svg") || f.name.includes("jpg") || f.name.includes("jpeg")) ?
                                                    < img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"

                                                        alt={f.name}
                                                        src={blobUrl}
                                                    />
                                                    :
                                                    <>
                                                        <p className="mb-0">
                                                            Preview unavailable
                                                        </p>
                                                    </>
                                            }
                                        </Col>
                                        <Col>
                                            <a target={"_blank"} rel="noreferrer" href={blobUrl}>
                                                {
                                                    f.name
                                                }
                                            </a>

                                            <p className="mb-0">
                                                <strong>{prettyBytes(blob.size)}</strong>
                                            </p>
                                        </Col>

                                        <Col className="col-auto">
                                            <button className="btn btn-danger waves-effect waves-light fw-semibold btn btn-danger"
                                                onClick={() => {
                                                    // files.splice(i, 1)
                                                    // setFiles([...files])

                                                }}>
                                                Delete
                                            </button>


                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                            <br />
                        </>
                    );
                })}
            </div>
        </>
    );
}

export default DropzoneCustom;
