import React, { useEffect } from 'react';
import { GetRouterQuery } from 'components/Utils/GetRouterQuery';
import { ServicesState } from 'store/reducers/Services';
import WaitingDots from 'components/WaitingDots';
import { RootState } from 'store/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getService } from "store/actions/Services";
import ServiceDetailPage from './ServiceDetailPage';

interface Props {
    servicesState: ServicesState;
}

interface Actions {
    getService: (id: string) => void;
}

const ServiceDetail: React.FC<Props & Actions> = ({ servicesState, getService }) => {

    const { id } = GetRouterQuery<{ id: string }>()
    const service = servicesState.getServiceById(id);

    useEffect(() => {
        if (service === null) {
            getService(id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (service === null) {
        return <WaitingDots />
    }

    return (
        <>
            <div className="page-content">
                <ServiceDetailPage service={service} />
            </div>
        </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        servicesState: state.Services
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getService }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetail);