import WaitingDots from 'components/WaitingDots';
import { Invoice } from 'models/Invoice';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootStateAccount } from "storeAccount/accountStore";
import { CustomerState } from 'storeAccount/reducers/Customers';
import { getInvoiceExport } from 'services/AccountApi';
import { Spinner } from 'reactstrap';

interface Props {
    invoice: Invoice;
    customerState: CustomerState;
}

const InvoiceRowAccount: React.FC<Props> = ({ invoice, customerState }) => {

    const [fileURL, setFileURL] = useState("-1")

    const getInvoiceData = () => {
        setFileURL("0")
        getInvoiceExport(invoice.id).then(pdf => {

                    var byteCharacters = atob(pdf);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
                    var fileURL = URL.createObjectURL(file);
                    setFileURL(fileURL)
                });
    }

    if (invoice === undefined) {
        return <WaitingDots />
    }

    return (
        <tr key={invoice.id}>
            <td>
                <span style={{ width: '100px', whiteSpace: "nowrap", textOverflow: 'ellipsis' }}>
                    {invoice.name !== "" ? invoice.name : <i>No name</i>}
                </span>
            </td>
            <td>
                <span style={{ whiteSpace: "nowrap" }}>
                    {invoice.GetCreatedDate().toDateString()}
                </span>
            </td>
            <td>
                <span style={{ whiteSpace: "nowrap" }}>
                    &euro;{invoice.CalcGrandTotal().toFixed(2)}
                </span>
            </td>
            <td>
                <span style={{ whiteSpace: "nowrap" }}>
                    {invoice.quote ?
                        <button type="button" disabled className="btn btn-sm btn-outline-primary">
                            Quote
                        </button>
                        :
                        <>
                            {invoice.draft ?
                                <button type="button" disabled className="btn btn-sm btn-outline-primary">
                                    Draft
                                </button>
                                :
                                <>
                                    {invoice.IsPaid() ?
                                        <button type="button" disabled className="btn btn-sm btn-outline-success">
                                            Paid
                                        </button>
                                        :

                                        <button type="button" disabled className="btn btn-sm btn-outline-danger">
                                            Open
                                        </button>}
                                </>
                            }
                        </>
                    }
                </span>
            </td>
            <td>
                <span style={{ whiteSpace: "nowrap" }}>
                    {
                        (fileURL === "-1") &&
                            <button onClick={getInvoiceData } type="button" className="btn btn-sm w-xs btn-primary" >
                                <i className="mdi mdi-download" /> Download
                            </button>
                    }
                    {
                        (fileURL === "0") &&
                        <Spinner />
                    }
                    {
                        (fileURL.length > 2) &&
                        <a target={"_blank"} rel="noreferrer" href={fileURL} download={invoice.name}>
                            <button type="button" className="btn btn-sm w-xs btn-success" >
                                <i className="mdi mdi-download" /> Open
                            </button>
                        </a>
                    }
                </span>
            </td>

            {
                !invoice.draft && !invoice.IsPaid() ?
                    <td>
                        <span style={{ whiteSpace: "nowrap" }}>
                            <button type="button" className="btn btn-sm btn-success waves-effect waves-light"
                                onClick={() => {
                                    window.open(`https://pay.galactic-group.nl/?${btoa(`${invoice.id}*${invoice.token}`)}`)

                                }}
                            >
                                Click to pay
                            </button>
                        </span>
                    </td>
                    :
                    <>
                    </>
            }

        </tr>
    );
}

const mapStateToProps = (state: RootStateAccount) => {
    return {
        customerState: state.Customers,
    };
};


export default connect(mapStateToProps)(InvoiceRowAccount);