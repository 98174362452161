import { Customer } from 'models/Customer';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { vatCheck } from 'services/AccountApi';
import { editCustomerVat } from 'storeAccount/actions/Customers';

interface Props {
    customer: Customer;
}

interface Actions {
    editCustomerVat: typeof editCustomerVat
}

const CustomerVatCheckCard: React.FC<Props & Actions> = ({ customer, editCustomerVat }) => {
    const [vat, setVat] = useState<string>("")
    const [editVatState, setVatState] = useState<boolean>(false)
    const [vatInfo, setVatInfo] = useState<any>("info not available")
    const [validVat, setValidVat] = useState<boolean>(false)
    const resultBox = useRef<HTMLElement>(null);

    useEffect(() => {
        setVat(customer.vat)
    }, [customer.vat])

    const matchAddress = (data: any) => {

        if (resultBox.current === null) {
            return;
        }

        let prefix = " ";
        for (let i = 0; i < 5 - (customer.address.number.replace(/\D/g, '')).length; i++) {
            prefix += "0";
        }

        const streetNotation = (customer.address.street.toUpperCase() + prefix + customer.address.number.replace(/\D/g, '') + " " + customer.address.number.replaceAll("" + parseInt(customer.address.number), "")).trim()

        if (data.address.split("\n")[1].toUpperCase() !== streetNotation) {
            resultBox.current.style.color = "red";
            return;
        }
        if (data.address.split("\n")[2].split(" ")[0] !== customer.address.zip_code) {
            resultBox.current.style.color = "red";
            return;
        }
        if (data.address.split("\n")[2].split(" ")[1] !== customer.address.city.toUpperCase()) {
            resultBox.current.style.color = "red";
            return;
        }


        resultBox.current.style.color = "green";
    }

    useEffect(() => {
        vatCheck(customer.vat)
            .then((r) => {
                if (r.isValid) {
                    setVatInfo(objToString(r))
                    setValidVat(true)
                    matchAddress(r)
                } else {
                    setValidVat(false)
                    setVatInfo("the vat-number is not valid/correct")
                }
            })
            .catch(() => {
                setValidVat(false)
                setVatInfo("the vat-number is not valid/correct")
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer.vat]);

    const setVatInfoCorrect = React.useCallback((vatNumber: string) => {
        vatCheck(vatNumber)
            .then((r) => {
                if (r.isValid) {
                    setVatInfo(objToString(r))
                    setValidVat(true)
                    matchAddress(r)
                } else {
                    setValidVat(false)
                    setVatInfo("the vat-number is not valid/correct")
                }
            })
            .catch(() => {
                setValidVat(false)
                setVatInfo("the vat-number is not valid/correct")
            })
        // eslint-disable-next-line
    }, [vat]);


    useEffect(() => {
        setVatInfoCorrect(vat)
        // eslint-disable-next-line
    }, [vat])

    const editVat = () => {
        editCustomerVat(customer.id, vat)
            //@ts-ignore
            .then(() => {
                setVatState(false)
                if (vat === "") {
                    setVatInfo("the vat-number is not valid/correct")
                } else {
                    setVatInfoCorrect(vat)
                }
            });
    }

    function objToString(obj: any) {
        var str = '';
        for (var p in obj) {

            if (Object.prototype.hasOwnProperty.call(obj, p) && (p === "name" || p === "address")) {
                str += p + ': ' + obj[p] + '\n';
            }
        }
        return str;
    }

    return (
        <>
            <hr />
            <table className="table table-borderless align-middle table-centered table mb-0">
                <tbody>
                    <tr>
                        <td>Vat number</td>


                        {
                            editVatState
                                ?
                                <>
                                    <td>
                                        <input
                                            type="text"
                                            defaultValue={vat}
                                            className="form-control"
                                            placeholder="Vat number"
                                            onInput={e => {
                                                setVat(e.currentTarget.value)
                                            }} />
                                    </td>
                                    <td>
                                        <button type="button" onClick={() => {
                                            editVat()
                                        }} className="btn btn-primary waves-effect waves-light">
                                            Confirm
                                        </button>
                                    </td>
                                </>
                                :
                                <td style={validVat ? { color: "green" } : { color: "red" }}>
                                    {vat}
                                </td>
                        }

                        {
                            !editVatState
                            &&
                            <td>
                                <button type="button" onClick={() => { setVatState(true) }} className="btn btn-primary waves-effect waves-light">
                                    Edit
                                </button>
                            </td>
                        }
                    </tr>
                </tbody>
            </table>
            <hr />
            <code ref={resultBox} style={{ whiteSpace: 'pre-wrap' }}>{vatInfo}</code>
        </>
    );
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ editCustomerVat }, dispatch);
};

export default connect(null, mapDispatchToProps)(CustomerVatCheckCard);
