import { Customer } from 'models/Customer';
import { Recurring } from 'models/Recurring';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { getRecurrings } from 'store/actions/Customers';
import { RootState } from 'store/store';
import { RecurringsState } from "../../store/reducers/Recurring";
import CustomerRecurringRow from './CustomerRecurringRow';
import CustomerRecurringAdd from './CustomerRecurringAdd';


interface Props {
    customer: Customer;
    recurringState: RecurringsState;
}

interface Actions {
    getRecurrings: typeof getRecurrings
}

const RecurringManagementCard: React.FC<Props & Actions> = ({ recurringState, customer, getRecurrings }) => {
    const [customerInfo] = useState(customer)
    const [add, setAdd] = useState<boolean>(false)

    useEffect(() => {
        getRecurrings();
    }, [getRecurrings])

    return (
        <Card>
            <CardBody>
                <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>Recurrings</p>
                <hr />
                {
                    !add ?
                        recurringState.getRecurringsByCustomerId(customer.id).map((recurring, i) => {
                            return <CustomerRecurringRow customer={customerInfo} recurring={recurring} index={i} />
                        })
                        :
                        <CustomerRecurringAdd customer={customerInfo} recurringState={recurringState} recurring={new Recurring()}
                            onCancel={() => {
                                setAdd(false);
                            }
                            }
                            onSave={() => {
                                setAdd(false);
                            }} />
                }


                {!add && <div className="mt-3">
                    <button
                        id="buttonPerson"
                        name="buttonPerson"
                        type="button"
                        onClick={() => {
                            setAdd(true);
                        }}
                        className="btn btn-primary w-100 waves-effect waves-light"
                    >
                        <i className="mdi mdi-account-plus"></i> Add another recurring
                    </button>
                </div>}

            </CardBody>
        </Card>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        recurringState: state.Recurring

    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getRecurrings }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RecurringManagementCard);
