import {Address} from "./Address";
import {CustomerBilling} from "./CustomerBilling";

export class Customer {

    constructor() {
        this.address = new Address();
        this.billing = new CustomerBilling();
    }

    id!: string;
    name!: string;
    billing!: CustomerBilling;
    address!: Address;
    email!: string;
    tenant!: string;
    is_tenant!: string;
    vat!:string;
    computers!: Computer[];
}


export class ComputerUpdates {
    updates_available!: number;
}
export class Computer {
    id!: string;
    name!: string;
    address!: string;
    hostname!: string;
    os!: string;
    os_kernel!:string;
    cpu_model_name!: string;
    connection_state!: string;
    updates_status!: ComputerUpdates;
    vnc_token!: string;
}