import React from "react";
import {User} from "models/User";

interface Props {
    users: User[];
}

interface Actions {

}

const UserProfilesTable: React.FC<Actions & Props> = ({ users }) => {
    return (
        <>
            <div className="float-end">
            </div>
            <h4 className="card-title mb-4">Users</h4>
            <div className="table-responsive">
                <table className="table table-hover table-borderless align-middle table-centered table- mb-0">
                    <thead className="table-light">
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Activated</th>
                            <th>Region</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, key) => (
                            <tr key={key}>
                                <td>
                                    <span>{user.name}</span>
                                </td>
                                <td>{user.email}</td>
                                <td>
                                    {user.activated ?
                                        <div>
                                            <i className="mdi mdi-checkbox-blank-circle text-success" style={{ marginRight: "5px" }} />
                                            Active
                                        </div>
                                        :

                                        <div>
                                            <i className="mdi mdi-checkbox-blank-circle text-danger" style={{ marginRight: "5px" }} />
                                            Inactive
                                        </div>
                                    }
                                </td>
                                <td>{user.region}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default UserProfilesTable;