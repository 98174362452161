import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../store/store";
import { getExpenses } from "store/actions/Expenses";
import WaitingDots from "components/WaitingDots";
import ExpenseRow from "./ExpenseRow";
import { useNavigate } from "react-router";
import { ExpenseState } from 'store/reducers/Expenses';

interface Props {
    expenseState: ExpenseState;
    tenantId: string | null;
}


interface Actions {
    getExpenses: () => void;
}

const ExpensesList: React.FC<Props & Actions> = ({ getExpenses, expenseState, tenantId }) => {

    const navigate = useNavigate();

    useEffect(() => {
        getExpenses()
    }, [getExpenses])

    if (expenseState.getExpenses() === undefined) {
        return <WaitingDots />
    }

    return (
        <>
            <button
                id="buttonPerson"
                name="buttonPerson"
                type="button"
                onClick={() => {
                    navigate('/tenants/' + tenantId + '/expenses/add');
                }}
                className="btn btn-primary w-25 waves-effect waves-light button-width-mobile"
            >
                Add expense
            </button>
            <hr />
            <table className="table table table-borderless align-middle table-centered table mb-0">
                <tbody>
                    {expenseState.getExpenses().length > 0 && expenseState.getExpenses()
                        .sort((a, b) => b.GetDate().getTime() - a.GetDate().getTime())
                        .filter(expenses => !tenantId || expenses.tenant === tenantId)
                        .map((expense, key) => {
                            return <ExpenseRow key={key} expense={expense} />
                        })}
                </tbody>
            </table>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        expenseState: state.Expenses
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getExpenses }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpensesList);