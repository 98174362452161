import React from "react";
import {Link} from "react-router-dom";
import {Tenant} from "models/Tenant";
import {Card, CardBody} from 'reactstrap';
import {AccountableContainer, FilterFinalInvoices} from "models/InvoiceContainer";
import {Invoice} from "models/Invoice";
import {RootState} from "store/store";
import {connect} from "react-redux";
import {InvoiceState} from 'store/reducers/Invoices';

interface Props {
    tenant: Tenant;
    invoiceState: InvoiceState;
}

const TenantCard: React.FC<Props> = ({ tenant, invoiceState }) => {
    return (
        <>
            <Card>
                <Link to={"/tenants/" + tenant.id}>
                    <CardBody>
                        <div className="float-end">
                            {tenant.layout.logo !== "" ?
                                <div className="rounded-circle bg-light avatar-sm mx-auto mb-4" dangerouslySetInnerHTML={{ __html: tenant.layout.logo }} />
                                :
                                <div className="avatar-sm mx-auto mb-4">
                                    <span className="avatar-title rounded-circle bg-light font-size-24">
                                        <i className={`rounded-circle mdi mdi-alpha-${tenant.name.charAt(0).toLowerCase()}-circle text-primary`} style={{ fontSize: "55px" }} />
                                    </span>
                                </div>
                            }

                        </div>
                        <div>
                            <p className="text-muted text-uppercase fw-semibold">{tenant.name}</p>

                            <h4 className="mt-1">
                                <span className="counter-value" data-target="58425">
                                    &euro; {
                                        (new AccountableContainer<Invoice>(invoiceState.getInvoicesFromTenant(tenant.id)))
                                            .RangeThisQuarter()
                                            .Filter(FilterFinalInvoices)
                                            .CalcTotal(invoice => invoice.CalcTotalContents())
                                            .toFixed(2)
                                    }
                                </span>
                            </h4>
                            <p className="text-muted mb-0">
                                revenue this quarter
                            </p>
                        </div>
                    </CardBody>
                </Link>
            </Card>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        invoiceState: state.Invoices,
    };
};

export default connect(mapStateToProps)(TenantCard);