import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { getCustomer } from "store/actions/Customers";
import { Alert, Card, CardBody, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTenants } from "store/actions/Tenants";
import { editCustomer } from "store/actions/Customers";
import { RootState } from "../../store/store";
import { Customer } from "models/Customer";
import PageMeta from "components/PageMeta";
//@ts-ignore
import Select from 'react-select'
import { GetRouterQuery } from "components/Utils/GetRouterQuery";
import { CustomerState } from "store/reducers/Customers";
import WaitingDots from "components/WaitingDots";

interface Props {
    customerState: CustomerState;
}

interface Actions {
    getTenants: () => void;
    editCustomer: (c: Customer) => void;
    getCustomer: (id: string) => void;
}

const CustomerEditForm: React.FC<Props & Actions> = ({ customerState, getCustomer, editCustomer }) => {


    const { id } = GetRouterQuery<{ id: string }>()
    const customer = customerState.getCustomerById(id)

    useEffect(() => {

        getCustomer(id)
        // setCustomer(customerState.getCustomerById(id))
    }, [getCustomer, id])

    const [error, setError] = useState<string | null>(null)

    const navigate = useNavigate();

    const countries = [
        { value: "The Netherlands", label: "The Netherlands" },
        { value: "Belgium", label: "Belgium" },
        { value: "Germany", label: "Germany" },
        { value: "United Kingdom", label: "United Kingdom" },
        { value: "France", label: "France" },
        { value: "USA", label: "USA" }
    ]


    if (customer === null) {
        return <WaitingDots />
    }


    const Save = () => {
        if (
            (customer.name !== "" && customer.name !== undefined)
        ) {
            editCustomer(customer)
                //@ts-ignore
                .then(() => {
                    navigate("/customers/" + customer.id);
                })

        } else {
            setError("Name is mandatory")
        }


    }


    return (
        <>
            <PageMeta title={`Edit Customer`} />
            <div className="page-content">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h2 className="mb-0 font-size-120">Edit customer</h2>
                </div>
                {
                    error &&
                    <Alert color="danger">{error}</Alert>
                }

                <Row>
                    <Col md={12} xl={12}>
                        <Card>
                            <CardBody>
                                <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "22px" }}>Details</p>
                                <div className="dropdown-divider"></div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={customer.name} placeholder="customer" onChange={e => {
                                        customer.name = e.target.value
                                        //   setCustomer(customer)
                                    }} />
                                    <label className="">Name</label>
                                </div>
                                <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "22px" }}>Address</p>
                                <div className="dropdown-divider"></div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={customer.address.street} onChange={e => {
                                        customer.address.street = e.target.value
                                        //   setCustomer(customer)
                                    }} placeholder="customer" />
                                    <label className="">Street</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={customer.address.number} onChange={e => {
                                        customer.address.number = e.target.value
                                        //   setCustomer(customer)
                                    }} placeholder="customer" />
                                    <label className="">Number</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={customer.address.city} onChange={e => {
                                        customer.address.city = e.target.value
                                        //   setCustomer(customer)
                                    }} placeholder="customer" />
                                    <label className="">City</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={customer.address.zip_code} onChange={e => {
                                        customer.address.zip_code = e.target.value
                                        //   setCustomer(customer)
                                    }} placeholder="customer" />
                                    <label className="">Zip</label>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Country:</label>
                                    <Select
                                        options={countries}
                                        defaultValue={customer.address.country === undefined ? { value: undefined, label: "Select country" } : { value: customer.address.country, label: customer.address.country }}
                                        onChange={(object: any) => {
                                            customer.address.country = object.value
                                        }}
                                    />
                                </div>



                                <div className="mt-3">
                                    <button
                                        id="buttonCustomer"
                                        name="buttonCustomer"
                                        className="btn btn-primary w-100 waves-effect waves-light"
                                        type="button"
                                        onClick={() => {
                                            Save()
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        tenantState: state.Tenants,
        customerState: state.Customers
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getTenants, getCustomer, editCustomer }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEditForm);
