import React from "react";

interface Props {
    date: Date;
}

const months: { [index: number]: string } = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
}

const Date: React.FC<Props> = ({ date }) => {

    const month = months[date.getMonth() + 1]

    return (
        <span title={date.toLocaleString()}>
            {date.getDate()} {month}
        </span>
    );
};

export default Date;
