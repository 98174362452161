import axios from 'axios';
import { plainToClass } from 'class-transformer';
import "reflect-metadata";
import { Challenge } from "common/Challenge";
import { Customer } from 'models/Customer';
import { Person } from 'models/Person';
import { Invoice } from 'models/Invoice';
import { Service } from 'models/Service';


const accountApi = axios.create();

const authBaseUrl = `https://api.auth.galactic-group.nl/v1`;
const servicesUrl = `https://api.status.galactic-group.nl/v1`;

export function getCustAuthTotpActive(email: string, ): Promise<boolean> {
    return accountApi.post(`${authBaseUrl}/mfa`, { email })
        .then(response => response.data.totp);
}

export function loginCustAuth(email: string, password: string, extra: {} = {}, mfa: {} = {}): Promise<boolean> {
    return accountApi.post(`${authBaseUrl}/authenticate`, { email, password, extra, mfa })
        .then(response => response.data.token);
}

export function checkMFA(): Promise<string> {
    return accountApi.post(`${authBaseUrl}/mfa/setup`)
        .then(response => response.data);
}

export function checkEmail(email: string): Promise<{email: string, error:number}> {
    return accountApi.post(`${authBaseUrl}/register/email`, {email}).then(resp => resp.data)
}

export function activateMFA(mfa: {} = {}): Promise<boolean> {
    return accountApi.post(`${authBaseUrl}/mfa/activate`, { mfa })
        .then(response => response.data);
}

export function deactivateMFA(password: string, mfa: {} = {}): Promise<boolean> {
    return accountApi.post(`${authBaseUrl}/mfa/deactivate`, { password, mfa })
        .then(response => response.data);
}

export function registerCustAuth(
    email: string,
    password: string,
    challenge: Challenge,
    first_name: string,
    last_name: string,
    address: {
        street:string,
        number:string,
        zip_code:string,
        city:string,
        country:string,
    },
    is_business: boolean,
    business_name: string,
    vat_number: string
): Promise<boolean> {
    return accountApi.post(`${authBaseUrl}/register`, {
        email, password, challenge, first_name, last_name, address, is_business, business_name, vat_number })
        .then(response => response.data.success);
}

export function getChallenge(): Promise<{ challenge: number, signature: string }> {
    return accountApi.get(`${authBaseUrl}/challenge`)
        .then(response => response.data);
}

export function getAuthCustomers(): Promise<Customer[]> {
    return accountApi.get(`${authBaseUrl}/customers`)
        .then(response => response.data.map((customer: object): Customer => plainToClass(Customer, customer)));
}

export function editCustomerVat(id: string, vat: string): Promise<Customer> {
    return accountApi.post(`${authBaseUrl}/customers/` + id + `/vat`, { vat_number: vat })
        .then(response => plainToClass(Customer, response.data));
}

export function vatCheck(vatNumber: string): Promise<any> {
    return accountApi.get(`${authBaseUrl}/customers/vat_check/${vatNumber}`)
        .then(response => response.data);
}

export function getPersonLoggedIn(): Promise<Person> {
    return accountApi.get(`${authBaseUrl}/person`)
        .then(response => plainToClass(Person, response.data));
}

export function getInvoiceExport(id: string): Promise<string> {
    return accountApi.get(`${authBaseUrl}/invoices/${id}/export/pdf`)
        .then(response => response.data.pdf);
}

export function getCustomerPersons(id: string): Promise<Person[]> {
    return accountApi.get(`${authBaseUrl}/customers/${id}/persons`)
        .then(response => response.data.map((person: object): Person => plainToClass(Person, person)));
}

export function updatePerson(person: Person): Promise<Person> {
    return accountApi.put(`${authBaseUrl}/persons/${person.id}`, person)
        .then((response): Person => plainToClass(Person, response.data));
}

export function getInvoices(): Promise<Invoice[]> {
    return accountApi.get(`${authBaseUrl}/invoices`)
        .then(response => response.data.map((invoice: object): Invoice => plainToClass(Invoice, invoice)));
}

export function getServices(): Promise<Service[]> {
    return accountApi.get(`${servicesUrl}/services`)
        .then(response => response.data.map((service: object): Service => plainToClass(Service, service)));
}

export default accountApi;