import { Customer } from "models/Customer";
import { Person } from "models/Person";
import InvoiceRowAccount from "pages/Account/InvoiceQuoteViews/InvoiceRowAccount";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootStateAccount } from "storeAccount/accountStore";
import { getInvoices } from "storeAccount/actions/Billing";
import { InvoiceState } from 'storeAccount/reducers/Invoices';
import NotFoundImageQuote from "../../../assets/images/404-error.png";
import NotFoundImageInvoice from "../../../assets/images/peace.png";
import InvoicesOutstanding from "./InvoicesOutstanding";

interface Props {
    person: Person;
    invoiceState: InvoiceState;
    quotes: boolean;
    customer: Customer;
}

interface Actions {
    getInvoices: () => void;
}

const InvoiceListOnlyFinals: React.FC<Props & Actions> = ( {
                                                               customer,
                                                               quotes,
                                                               person,
                                                               invoiceState,
                                                               getInvoices
                                                           } ) => {

    useEffect( () => {
        document.addEventListener( "visibilitychange", function () {
            if ( document.visibilityState === 'visible' ) {
                getInvoices()
            }
        } );
        getInvoices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] )

    if ( quotes && invoiceState.getInvoicesByCustomerId( customer.id )
        .filter( invoice => invoice.quote === true )
        .filter( invoice => invoice.customer === customer.id ).length <= 0 ) {
        return ( <div>
                <img src={ NotFoundImageQuote } alt="quotes not found"
                     className="img-fluid mx-auto d-block image-not-found"></img>
                <br/>
                <p className="text-uppercase text-center" style={ { fontWeight: "lighter" } }>no quotes found</p>
            </div> );
    }

    const results = invoiceState.getInvoicesByCustomerId( customer.id )
        .sort( ( a, b ) => ( a.IsPaid() === b.IsPaid() ) ? 0 : a.IsPaid() ? 1 : -1 )
        .sort( ( a, b ) => a.GetCreatedDate() < b.GetCreatedDate() ? 1 : -1 )
        .filter( invoice => !quotes || invoice.quote )
        .filter( invoice => quotes || !invoice.quote )
        .filter( invoice => invoice.customer === customer.id );

    return ( <div>
            { !quotes && <InvoicesOutstanding invoices={ invoiceState.getInvoicesByCustomerId( customer.id ) }/> }
            <div className="table-responsive">
                <table className="table table table-borderless align-middle table-centered table mb-0">
                    <tbody>
                    { results.map((invoice, key) => { return <InvoiceRowAccount key={ key } invoice={ invoice } /> }) }
                    </tbody>
                </table>
            </div>
        </div> );

};

const mapStateToProps = ( state: RootStateAccount ) => {
    return {
        invoiceState: state.Invoices
    };
};

const mapDispatchToProps = ( dispatch: any ) => {
    return bindActionCreators( { getInvoices }, dispatch );
};

export default connect( mapStateToProps, mapDispatchToProps )( InvoiceListOnlyFinals );
