import PageMeta from 'components/PageMeta';
import React, { useEffect, useState } from 'react';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Card, Col, Container, Row } from "reactstrap";
import WaitingDots from 'components/WaitingDots';
import ideal from "../assets/images/ideal.png";
import { ReactComponent as LogoDark } from "../assets/images/logo-dark.svg";
import maintenance from '../assets/images/widget-img.png';
import { InvoicePaymentIdealIssuer, InvoicePaymentPortalInfo } from 'models/Invoice';
import { createIdealPayment, getInvoicePaymentPortalInfo, getPaymentIdealIssuers, trackPayment } from 'services/Api';

const idLength = 36;

const PaymentScreen: React.FC = () => {
    const [payload, setPayload] = useState("")
    const [paymentStatus, setPaymentStatus] = useState("")
    const [invoice, setInvoice] = useState<InvoicePaymentPortalInfo | undefined>(undefined)
    const [idealIssuers, setIdealIssuers] = useState<InvoicePaymentIdealIssuer[]>([])

    const rawPayload = useLocation().search
    const pathname = useLocation().pathname

    useEffect(() => {
        console.log(rawPayload)
        if (rawPayload !== "") {
            window.localStorage["pparams"] = rawPayload.substring(1)
        }
        if (window.localStorage["pparams"] !== undefined && window.localStorage["pparams"] !== "") {
            setPayload(atob(window.localStorage["pparams"]))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (pathname === "/poll") {
            const tracker = localStorage.getItem("tracker_id")
            if (tracker === null) {
                setTimeout(() => {
                    const payloadTemp = atob(rawPayload.substring(1))
                    if (payloadTemp !== "") {
                        const poller = setInterval(async () => {
                            const status = await trackPayment(payloadTemp.split("*")[0], "none")
                            setPaymentStatus(status)
                            if (status === "paid") {
                                clearInterval(poller)
                            }
                        }, 2000)
                    } else {
                        setPaymentStatus("failed")
                    }
                }, 1000)
            } else {
                const trackerDeco = atob(tracker)
                const poller = setInterval(() => {
                    trackPayment(trackerDeco.split("*")[0], trackerDeco.split("*")[1]).then(status => {
                        setPaymentStatus(status)
                        if (status === "canceled" || status === "paid") {
                            clearInterval(poller)
                        }
                    })
                }, 2000)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (payload !== "" && invoice === undefined) {
            const parts = payload.split("*");
            getInvoicePaymentPortalInfo(parts[0], parts[1]).then(info => {
                setInvoice(info)
            })
        }
        document.body.setAttribute("data-layout-mode", "light");
        document.body.setAttribute("data-topbar", "light");
    }, [payload, invoice])

    useEffect(() => {
        if (invoice !== undefined && invoice.tenant !== undefined && invoice.tenant.id !== "") {
            getPaymentIdealIssuers(invoice.tenant.id).then(issuers => {
                setIdealIssuers(issuers)
            })
        }
    }, [invoice])

    const payingGuy = () => {
        return <Col lg={{ size: 6, order: 2 }}>
            <div className="p-lg-5 p-4 bg h-100">
                <div className="maintenance-img">
                    <img src={maintenance} alt="" className="img-fluid mx-auto d-block" />
                </div>
            </div>
        </Col>;
    }

    const printStep = () => {
        if (payload.split("*")[0].length !== idLength) {
            return <>
                <Col lg={{ size: 6, order: 1 }}>
                    <div className="p-lg-5 p-4">
                        <div className="maintenance-box">
                            <div className="d-flex">
                                <div className="avatar-xs me-3">
                                    <span className="avatar-title bg-primary bg-soft rounded-circle">
                                        <i className="mdi mdi-access-point-network text-primary"></i>
                                    </span>
                                </div>
                                <div className="flex-1">
                                    <h5 className="font-size-15 text-uppercase">Invalid session</h5>
                                    <p className="text-muted">Something is wrong. Please try opening the link again or contact us.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                {payingGuy()}
            </>
        }

        if (invoice === undefined) {
            return <>
                <Col lg={{ size: 6, order: 1 }}>
                    <div className="p-lg-5 p-4">
                        <div className="spinner-border text-primary m-1" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </Col>
                {payingGuy()}
            </>
        }
        if (invoice.paid && pathname !== "/poll") {
            return <>
                <Col lg={{ size: 6, order: 1 }}>
                    <div className="p-lg-5 p-4">
                        <div className="maintenance-box" style={{ marginTop: "30px" }}>
                            <div className="d-flex">
                                <div className="avatar-md me-3">
                                    <span className="avatar-title bg-primary bg-soft rounded-circle">
                                        <i className="fas fa-check" style={{ color: "green", fontSize: '30px' }}></i>
                                    </span>
                                </div>

                                <div className="flex-1" style={{ marginTop: '10px' }}>
                                    <h5 className="font-size-15 text-uppercase">Already paid</h5>
                                    <p className="text-muted">Nothing to do over here. Have a great day.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                {payingGuy()}
            </>
        }

        return <>
            <Routes>
                <Route
                    path={`/method`}
                    element={
                        <>
                            <Col lg={{ size: 6, order: 1 }}>
                                <div className="p-lg-5 p-4">
                                    <div className="maintenance-box">
                                        <Link to={`/ideal`}>
                                            <div className="d-flex payment-method">
                                                <div className="avatar-md me-3">
                                                    <span className="avatar-title bg-primary bg-soft rounded-circle">
                                                        <img src={ideal} alt="" style={{ height: "45px" }} />
                                                    </span>
                                                </div>

                                                <div className="flex-1" style={{ marginTop: '10px' }}>
                                                    <h5 className="font-size-15 text-uppercase">iDeal</h5>
                                                    <p className="text-muted">Processed within one hour</p>
                                                </div>
                                            </div>
                                        </Link>
                                        <br />
                                        <br />
                                        <Link to={`/bank`}>
                                            <div className="d-flex payment-method">
                                                <div className="avatar-md me-3">
                                                    <span className="avatar-title bg-primary bg-soft rounded-circle">
                                                        <i className="fas fa-money-check text-primary" style={{ fontSize: '30px' }}></i>
                                                    </span>
                                                </div>

                                                <div className="flex-1" style={{ marginTop: '10px' }}>
                                                    <h5 className="font-size-15 text-uppercase">Bank transfer</h5>
                                                    <p className="text-muted">Processed within 3 working days</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                            {payingGuy()}
                        </>
                    }
                />
                <Route
                    path={`/ideal`}
                    element={
                        <>
                            <Col lg={{ size: 6, order: 1 }}>
                                <Row style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}>
                                    {idealIssuers.map(issuer =>
                                        <Col key={issuer.id} lg={6}>
                                            <div onClick={() => {
                                                createIdealPayment(invoice.id, payload.split("*")[1], issuer.id, `https://${window.location.hostname}/poll`).then(payment => {
                                                    localStorage.setItem("tracker_id", btoa(`${invoice.id}*${payment.tracker}`))
                                                    // @ts-ignore
                                                    window.location = payment.url
                                                })
                                            }
                                            } className="maintenance-box" style={{ cursor: "pointer", paddingLeft: "1.5rem", paddingRight: "1.5rem" }}>
                                                <div className="d-flex payment-method">
                                                    <div className="me-3">
                                                        <span className="">
                                                            <img src={issuer.image} alt="" style={{ height: "45px", width: "60px" }} />
                                                        </span>
                                                    </div>

                                                    <div className="flex-1" style={{ marginTop: '10px' }}>
                                                        <h5 className="font-size-15 text-uppercase">{issuer.name}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                            {payingGuy()}
                        </>
                    }
                />
                <Route
                    path={`/poll`}
                    element={
                        <>
                            <Col lg={{ size: 6, order: 1 }}>
                                <div className="p-lg-5 p-4">
                                </div>
                                <div className="p-lg-5 p-4">
                                    <div className="maintenance-box" style={{ marginTop: "0px" }}>
                                        {paymentStatus === "canceled" &&
                                            <div className="d-flex">
                                                <div className="avatar-md me-3">
                                                    <span className="avatar-title bg-primary bg-soft rounded-circle">
                                                        <i className="fas fa-times" style={{ color: "red", fontSize: '30px' }}></i>
                                                    </span>
                                                </div>
                                                <div className="flex-1" style={{ marginTop: '10px' }}>
                                                    <h5 className="font-size-15 text-uppercase">Payment cancelled</h5>
                                                    <p className="text-muted">Please <Link to={`/method`}>try again</Link></p>
                                                </div>
                                            </div>
                                        }
                                        {paymentStatus === "failed" &&
                                            <div className="d-flex">
                                                <div className="avatar-md me-3">
                                                    <span className="avatar-title bg-primary bg-soft rounded-circle">
                                                        <i className="fas fa-times" style={{ color: "red", fontSize: '30px' }}></i>
                                                    </span>
                                                </div>
                                                <div className="flex-1" style={{ marginTop: '10px' }}>
                                                    <h5 className="font-size-15 text-uppercase">Payment failed</h5>
                                                    <p className="text-muted">Please <Link to={`/method`}>try again</Link></p>
                                                </div>
                                            </div>
                                        }
                                        {(paymentStatus === "open" || paymentStatus === "") &&
                                            <div className="d-flex">
                                                <div className="avatar-md me-3">
                                                    <div className="spinner-border text-primary" role="status" style={{ marginTop: "15px", marginLeft: "10px" }}>
                                                        <span className="sr-only">loading</span>
                                                    </div>
                                                </div>

                                                <div className="flex-1" style={{ marginTop: '10px' }}>
                                                    <h5 className="font-size-15 text-uppercase">Waiting for confirmation</h5>
                                                    <p className="text-muted">Please wait<WaitingDots /></p>
                                                </div>
                                            </div>
                                        }
                                        {(paymentStatus === "paid") &&
                                            <div className="d-flex">
                                                <div className="avatar-md me-3">
                                                    <span className="avatar-title bg-primary bg-soft rounded-circle">
                                                        <i className="fas fa-check" style={{ color: "green", fontSize: '30px' }}></i>
                                                    </span>
                                                </div>

                                                <div className="flex-1" style={{ marginTop: '10px' }}>
                                                    <h5 className="font-size-15 text-uppercase">Payment received</h5>
                                                    <p className="text-muted">Thank you!</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                            {payingGuy()}
                        </>
                    }
                />
            </Routes>
        </>

    }

    return (
        <div className="my-10 pt-sm-5">
            <Container>
                <PageMeta title={`Pay | ${invoice?.tenant.name !== "" ? invoice?.tenant.name : "Galactic Group"}`} hideTitle={true} />
                <Row>
                    <Col md={12}>
                        <div className="text-center mb-5">
                            {
                                invoice?.tenant.logo !== "" ?
                                    <div className="rounded-circle bg-light avatar-md mx-auto mb-4" dangerouslySetInnerHTML={{ __html: invoice?.tenant.logo ?? "" }} />
                                    :
                                    <LogoDark />
                            }
                            {payload.split("*")[0].length === idLength ?
                                <>
                                    <Routes>
                                        <Route
                                            path={`/method`}
                                            element={<h3 className="mt-5" onClick={() => console.log(window.localStorage["pparams"])}>Payment method</h3>}
                                        />
                                        <Route
                                            path={`/ideal`}
                                            element={<h3 className="mt-5">Bank selection</h3>}
                                        />
                                        <Route
                                            path={`/poll`}
                                            element={<h3 className="mt-5">Payment verification</h3>}
                                        />
                                        <Route path="*" element={<Navigate replace to={`/method`} />} />
                                    </Routes>
                                    <p className="font-size-15 text-muted">Invoice #{payload.split("*")[0]} | &euro; {invoice === undefined ? <WaitingDots /> : invoice.total.toFixed(2)}</p>
                                </>
                                :
                                <h3 className="mt-5">Error</h3>
                            }
                        </div>

                        <Card className="overflow-hidden">
                            <Row className="g-0">
                                {printStep()}
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );

}

export default PaymentScreen;