import React from "react";
import { Service } from "models/Service";
import LongClickButton from "components/LongClickButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeService } from 'store/actions/Services';
import { useNavigate } from "react-router-dom";
import { RootState } from "store/store";
import { ServicesState } from "store/reducers/Services";


interface Props {
    service: Service;
    servicesState: ServicesState;
}

interface Actions {
    removeService: (s: Service) => void;
}

const RemoveServiceButton: React.FC<Props & Actions> = ({ service, removeService }) => {

    const navigate = useNavigate();

    const deleteService = () => {
        removeService(service)
            //@ts-ignore
            .then(() => {
                navigate("/services")
            })
    }

    return (
        <>
            <LongClickButton color="danger" style={{ marginTop: '5px', width: "100%" }}
                onClick={() => {
                    deleteService()
                }}>
                Delete service</LongClickButton>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        servicesState: state.Services
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ removeService }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveServiceButton);
