import {generateAction, RequestTypes} from "./Util";
import * as api from "../../services/Api";

export const GET_USER = new RequestTypes("USER");




export const getUser = () => {
  return generateAction(GET_USER, () =>
    api.getUser()
  );
};

