import {Computer, Customer} from 'models/Customer';
import React, {useEffect, useState} from 'react';
import {Col, Row} from "reactstrap";
import {getCustomerComputer} from "services/Api";

let NoVNC: any = undefined;

interface Props {
    id: string;
    name: string;
    customer: Customer;
}

const ComputerRow: React.FC<Props> = ({
                                          id,
                                          customer,
                                          name,
                                      }) => {
    const [computer, setComputer] = useState<Computer | null>(null)
    const [active, setActive] = useState(false);

    useEffect(() => {
        getCustomerComputer(customer.id, id).then(computer => {
            setComputer(computer)
        })
    }, [id])


    useEffect(() => {
        const func = (event: any) => {
            if (event.key === 'Escape') {
                setActive(false)
            }
        };
        if (active) {
            document.addEventListener('keydown', func);
        } else {
            document.removeEventListener('keydown', func);
        }
    }, [active])
    return <>

        <Row
            style={computer?.connection_state === "connected" ? {cursor: "pointer"} : {}}
            onClick={async () => {
                if (computer?.connection_state !== "connected") {
                    return false;
                }
                // @ts-ignore
                NoVNC = (await import('react-novnc')).default
                setActive(true);
            }}>

            <Col xs={1} style={{paddingTop: "10px"}}>

                {
                    computer?.connection_state === "connected" ?
                        <span className="badge bg-success me-1 font-size-12 me-2"> <i className="fas fa-check" style={{
                            color: "white",
                            fontSize: '10px'
                        }}></i></span>
                        :
                        <span className="badge bg-danger me-1 font-size-12 me-2"><i className="fas fa-times" style={{
                            color: "black",
                            fontSize: '10px'
                        }}></i></span>
                }
            </Col>
            <Col xs={10}>
                <h6 style={{marginTop: "4px"}}>{name} ({computer?.hostname})</h6>
                <small>{computer?.os}</small>
            </Col>

            <Col xs={1} style={{paddingTop: "10px"}}>

                {
                    (computer?.updates_status.updates_available ?? 0) > 0 &&
                    <span className="badge bg-danger me-1 font-size-12 me-2">
                            {computer?.updates_status.updates_available}
                            </span>
                }
            </Col>

        </Row>
        {active &&
                <NoVNC
                    connectionName={`api.office.galactic-group.nl/v1/remote-vnc/${computer?.id}/vnc?${computer?.vnc_token}`}
                    onDisconnected={() => setActive(false)}
                    isSecure={true}

                />
        }
    </>;

    // return ( <>
    //         { <>
    //             <div className="float-end ms-2 dropdown">
    //                 <button
    //                     type="button"
    //                     onClick={ () => {
    //                         setEditing( true )
    //                     } }
    //                     className="btn btn-primary waves-effect waves-light"
    //                 >
    //                     <i className=" bx bx-edit-alt"></i>
    //                 </button>
    //             </div>
    //             <div className="p-3 px-4">
    //                 <div className="d-flex align-items-start">
    //                     <div className="align-self-center me-3">
    //                         <div className="flex-1">
    //                             <h5 className="font-size-15 mt-0 mb-1">{ person.name }
    //                                 { person.auth === null && <span className="badge bg-danger me-1 font-size-12 me-2"
    //                                                                 style={ { marginLeft: "10px" } }>
    //                                 Cannot login
    //                             </span> }
    //                             </h5>
    //                             <p className="text-muted mb-0">{ person.title }</p>
    //                             <p className="text-muted mb-0"><Link
    //                                 to={ `/conversations/compose?person=${ person.id }&customer=${ customer.id }` }>{ person.email }</Link>
    //                                 { ( person.auth !== null && person.email !== person.auth.email ) && <><br/><span
    //                                     className="badge bg-warning me-1 font-size-12 me-2">
    //                                 Username: { person.auth.email }
    //                             </span></> }
    //                             </p>
    //                             <p className="text-muted mb-0"><span
    //                                 onClick={ () => callPerson( person, customer.tenant ) }>{ person.phone }</span></p>
    //                             { person.roles?.includes( "billing" ) &&
    //                                 <span className="badge bg-dark me-1 font-size-12 me-2 mb-3">
    //                                             billing
    //                                         </span> }
    //                             { person.roles?.includes( "technical" ) &&
    //                                 <span className="badge bg-success me-1 font-size-12 me-2 mb-3">
    //                                             technical
    //                                         </span> }
    //                         </div>
    //
    //                     </div>
    //                 </div>
    //             </div>
    //         </> }
    //     </>
    //
    // );
}
export default ComputerRow;
