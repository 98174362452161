import React, {useEffect, useState} from "react";
import {Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {connect} from "react-redux";
import {addUserToTenant, deleteUserFromTenant, getTenant} from "store/actions/Tenants";
import {bindActionCreators} from "redux";
import {RootState} from "../../store/store";
import {GetRouterQuery} from '../../components/Utils/GetRouterQuery'
import classnames from "classnames";
import {getCustomers} from "store/actions/Customers";
import InvoiceList from "pages/Invoice/InvoiceList";
import CustomerRow from "pages/Customer/CustomerRow";
import InvoiceTabButton from "./InvoiceTabButton";
import ExpensesList from "pages/Expenses/ExpensesList";
import InvoiceTabButtonMobile from "./InvoiceTabButtonMobile";
import {CustomerState} from 'store/reducers/Customers';
import {TenantState} from 'store/reducers/Tenants';

interface Props {
    tenantState: TenantState;
    customerState: CustomerState;
}

interface Actions {
    getTenant: (id: string) => void;
    getCustomers: () => void;
}

const TenantTab: React.FC<Props & Actions> = ({ tenantState, getTenant, customerState, getCustomers }) => {
    const [customActiveTab, setCustomActiveTab] = useState("1");

    const toggleCustom = (tab: any) => {
        if (customActiveTab !== tab) setCustomActiveTab(tab);
    };

    const chooseTab = () => {
        if (window.location.toString().includes("expenses")) toggleCustom("2")
        if (window.location.toString().includes("tickets")) toggleCustom("3")
        if (window.location.toString().includes("customers")) toggleCustom("4")
    };

    const { id } = GetRouterQuery<{ id: string }>()

    useEffect(() => {
        getTenant(id);
        getCustomers();
    }, [getTenant, getCustomers, id])

    useEffect(() => {
        chooseTab()
        //eslint-disable-next-line
    }, [])


    const tenant = tenantState.getTenantById(id);
    if (tenant === null) {
        return <></>
    }


    const tenantCustomers = customerState.getCustomersByTenantId(id)
    if (tenantCustomers === undefined) {
        return <></>
    }

    return (
        <>
            <Card>
                <CardBody>
                    <Nav
                        tabs
                        className="nav-tabs-custom nav-justified"
                    >
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                    toggleCustom("1");
                                }}
                            >
                                <InvoiceTabButtonMobile tenant={tenant} />
                                <InvoiceTabButton tenant={tenant} />
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                    toggleCustom("2");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-money-bill-alt"></i>
                                </span>
                                <span className="d-none d-sm-block">
                                    Expenses
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "3",
                                })}
                                onClick={() => {
                                    toggleCustom("3");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-window-close"></i>
                                </span>
                                <span className="d-none d-sm-block">
                                    Tickets
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "4",
                                })}
                                onClick={() => {
                                    toggleCustom("4");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-users"></i>
                                </span>
                                <span className="d-none d-sm-block">
                                    Customers
                                </span>
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent
                        className="p-3 mp-0 text-muted"
                        activeTab={customActiveTab}
                    >
                        <TabPane tabId="1">
                            <InvoiceList tenantId={tenant.id} customerId={null} />
                        </TabPane>

                        <TabPane tabId="2">
                            <p className="mb-0">
                                <ExpensesList tenantId={tenant.id} />
                            </p>
                        </TabPane>
                        <TabPane tabId="3">
                            <p className="mb-0">
                                Tickets
                            </p>
                        </TabPane>
                        <TabPane tabId="4">
                            <p className="mb-0">
                                <table className="table table table-borderless align-middle table-centered table mb-0">
                                    <tbody>
                                        {tenantCustomers
                                            .sort((b, a) => (a.name.toLowerCase() < b.name.toLowerCase()) ? 1 : (a.name.toLowerCase() > b.name.toLowerCase()) ? -1 : 0)
                                            .map((customer, key) => (
                                                <CustomerRow customer={customer} />
                                            ))}
                                    </tbody>
                                </table>
                            </p>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        tenantState: state.Tenants,
        customerState: state.Customers
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getTenant, addUserToTenant, deleteUserFromTenant, getCustomers }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantTab);

