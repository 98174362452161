import {User as UserModel} from "models/User";
import {LOGOUT_USER_ACCOUNT} from "store/actions/Auth";
import {GET_USER} from "store/actions/User";
import {createReducer} from "./Util";

export class UserState {

  private user: UserModel;

  constructor() {
    this.user = new UserModel();
  }

  public setUser(user: UserModel) {
    this.user = user
    return this
  }

  public getUser(): UserModel | null {
    return this.user;
  }

}

const User = createReducer<UserState>(new UserState())
  .handle<UserModel>([GET_USER.SUCCESS], (_state, payload) => _state.setUser(payload))
  .handle([LOGOUT_USER_ACCOUNT.SUCCESS], () => new UserState());

export default User.handler;

