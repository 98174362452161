import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import {
    addExpenseAttachment,
    deleteExpenseAttachment,
    getExpenseAttachments,
    getExpenses
} from "store/actions/Expenses";
import { RootState } from "../../store/store";
import PageMeta from "components/PageMeta";
import { GetRouterQuery } from "components/Utils/GetRouterQuery";
import { ExpenseState } from 'store/reducers/Expenses';
import TenantName from "components/TenantName";
import ExpenseEditButton from "./ExpenseEditButton";
import { Link } from "react-router-dom";
import ExpensesListSupplier from "./ExpensesListSupplier";
import DropzoneCustom, { DroppedFile } from "components/DropzoneCustom";
import { ExpenseAttachment } from "models/Expense";
import { b64toBlob } from 'common/base64toblob';
import prettyBytes from 'pretty-bytes';
import ExpenseSuggestedEmails from "./ExpenseSuggestedEmails";

interface Props {
    expenseState: ExpenseState;
}

interface Actions {
    getExpenses: () => void;
    getExpenseAttachments: (id: string) => Promise<ExpenseAttachment[]>
    addExpenseAttachment: (id: string, file: DroppedFile) => Promise<ExpenseAttachment[]>
    deleteExpenseAttachment: (id: string, aid: string) => Promise<ExpenseAttachment[]>
}

const ExpenseDetail: React.FC<Props & Actions> = ({ expenseState, getExpenses, getExpenseAttachments, addExpenseAttachment }) => {
    const [attachments, setAttachments] = useState<ExpenseAttachment[]>([]);

    const { id } = GetRouterQuery<{ id: string }>()
    const expense = expenseState.getExpenseById(id)

    useEffect(() => {
        getExpenses()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getExpenseAttachments(id).then(expenseAttachments => {
            setAttachments(expenseAttachments)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])



    if (expense === null) {
        return <></>
    }

    return (
        <>
            <PageMeta title={`Expense`} />
            <div className="page-content">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h2 className="mb-0 font-size-120">{expense.name}</h2>
                    <ExpenseEditButton expense={expense} />
                </div>
                <Container fluid>
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <div className="clearfix"></div>
                                        <p className="text-muted text-uppercase fw-semibold mb-0" style={{ fontSize: "13px" }}>Details</p>
                                        <hr />
                                        <table className="table table-borderless align-middle table-centered table mb-0">
                                            <tbody>
                                                <tr>
                                                    <td>Date</td>
                                                    <td>{expense.GetDate().toDateString()}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tax</td>
                                                    <td>&euro; {expense.tax.toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Supplier name</td>
                                                    <td>{expense.supplier.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tenant</td>
                                                    <td><Link to={`/tenants/${expense.tenant}`}><TenantName id={expense.tenant} /></Link></td>
                                                </tr>
                                                <tr>
                                                    <td>Total</td>
                                                    <td>&euro; {expense.total.toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Attachments</td>
                                                    <td>
                                                        {attachments !== undefined && attachments.map(attachment => {
                                                            const blob = b64toBlob(attachment.content.split(";base64,")[1], attachment.content.split(";")[0].split(":")[1]);
                                                            const blobUrl = URL.createObjectURL(blob);
                                                            return <a key={attachment.id} href={blobUrl} rel="noreferrer" target="_blank">{attachment.name} ({prettyBytes(blob.size)})</a>
                                                        })}
                                                        {(attachments === undefined || attachments.length === 0) &&
                                                            <>None</>
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </CardBody>
                            </Card>
                            <DropzoneCustom mini={true} onDrop={(file: DroppedFile) => {
                                addExpenseAttachment(id, file).then(exAttach => {
                                    setAttachments(exAttach);
                                })
                            }} />
                        </Col>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <div className="clearfix"></div>
                                        <p className="text-muted text-uppercase fw-semibold mb-0" style={{ fontSize: "13px" }}>All expenses to {expense.supplier.name}</p>
                                        <hr />
                                        <ExpensesListSupplier supplierId={expense.supplier.id} />
                                    </div>

                                </CardBody>
                            </Card>

                            <ExpenseSuggestedEmails expense={expense} callback={() => {
                                return getExpenseAttachments(id).then(expenseAttachments => {
                                    setAttachments(expenseAttachments)
                                })
                            }} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        expenseState: state.Expenses
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getExpenses, getExpenseAttachments, addExpenseAttachment, deleteExpenseAttachment }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseDetail);
