import React, {useEffect} from "react";
import {getEmails, getEmailsAfter} from "store/actions/Emails";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {RootState} from "store/store";
import {EmailState} from "store/reducers/Emails";

interface Actions {
    emails: EmailState;
    getEmails: () => void;
    getEmailsAfter: (date: number) => void;
}

const EmailFetcher: React.FC<Actions> = ({ emails, getEmails, getEmailsAfter }) => {

    useEffect(() => {
        const interval = setInterval(() => {
            //@ts-ignore
            const newest = emails.getNewestEmail();
            if (newest !== null) {
                getEmailsAfter(newest.date)
            } else {
                getEmails()
            }
        }, 20000);

        return () => {
            clearInterval(interval)
        }
    })

    return <></>;
};


const mapStateToProps = (state: RootState) => {
    return {
        emails: state.Emails,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getEmails, getEmailsAfter }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(EmailFetcher);

