import WaitingDots from 'components/WaitingDots';
import {Invoice} from 'models/Invoice';
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {RootState} from 'store/store';
import {CustomerState} from 'store/reducers/Customers';

interface Props {
    invoice: Invoice;
    customerState: CustomerState;
    hideCustomer: boolean;
}

const InvoiceRow: React.FC<Props> = ({ hideCustomer, invoice, customerState }) => {
    if (invoice === undefined) {
        return <WaitingDots />
    }

    return (
        <tr key={invoice.id}>
            <td><span style={{ display: 'block', width: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}><Link to={`/invoice/${invoice.id}`}>{invoice.name !== "" ? invoice.name : <i>No name</i>}</Link></span></td>
            {!hideCustomer &&
                <td>{customerState.getCustomers().find(customer => customer.id === invoice.customer)?.name}</td>
            }
            <td>{invoice.GetCreatedDate().toDateString()}</td>
            <td>&euro; {invoice.CalcGrandTotal().toFixed(2)}</td>
            <td>
                {invoice.quote ?
                    <button type="button" disabled className="btn btn-sm btn-outline-primary">
                        Quote
                    </button>
                    :
                    <>
                        {invoice.draft ?
                            <button type="button" disabled className="btn btn-sm btn-outline-primary">
                                Draft
                            </button>
                            :
                            <>
                                {invoice.IsPaid() ?
                                    <button type="button" disabled className="btn btn-sm btn-outline-success">
                                        Paid
                                    </button>
                                    :

                                    <button type="button" disabled className="btn btn-sm btn-outline-danger">
                                        Open
                                    </button>}
                            </>
                        }
                    </>
                }
            </td>
        </tr>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        customerState: state.Customers,
    };
};


export default connect(mapStateToProps)(InvoiceRow);