import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import MfaQr from "./MfaQr";

interface Props {

}

const SecurityPage: React.FC<Props> = () => {

    const [mfaType] = useState("MFA-QR")

    return (
        <Card>
            <CardBody>
                <div>
                    <p className="text-muted text-uppercase fw-semibold">Security</p>
                </div>

                {
                    mfaType === "MFA-QR" &&
                    <MfaQr />


                }


            </CardBody>
        </Card>
    );
};

export default SecurityPage;
