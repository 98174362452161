import React, { useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
import EmailSideBar from "./EmailSideBar"
import { getEmails, getEmail } from "store/actions/Emails";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from "store/store"
import WaitingDots from "components/WaitingDots"
import { GetRouterQuery } from "components/Utils/GetRouterQuery";
import EmailCard from "./EmailCard";
import PageMeta from "components/PageMeta";
import { EmailState } from 'store/reducers/Emails';
import WhatsappCompose from "./WhatsappCompose";


interface Props {
  emailState: EmailState;
}

interface Actions {
  getEmails: () => void;
  getEmail: (id: string) => void;
}

const EmailView: React.FC<Props & Actions> = ({ emailState, getEmails, getEmail }) => {

  const { id } = GetRouterQuery<{ id: string }>()

  const emailsUnread = emailState.getEmails().filter(email => (email.reader === null || email.reader.length === 0) && email.direction === "in").length;

  useEffect(() => {
    getEmail(id)

    if (emailState.getEmails().length === 0
      || emailState.getEmails().length === 1
      || emailState.getEmails().length === emailsUnread) {
      getEmails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (emailState.getEmailById(id) === null) {
    return <WaitingDots />
  }

  const email = emailState.getEmailById(id)

  return (
    <>
      <div className="page-content">
        <PageMeta title={`${emailState.getEmailById(id)?.subject} | Emails`} />
        <Container fluid>
          <Row>
            <Col xs="12">
              <EmailSideBar box={""} unread={emailState.getEmails()
                .filter(email => email.direction === "in" && !email.IsRead()).length} />
              <div className="email-rightbar mb-3">
                {(email !== null && email.type === "whatsapp") && <WhatsappCompose reply_id={email.id} />}
                <EmailCard id={id} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    emailState: state.Emails
  };
};


const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ getEmails, getEmail }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(EmailView);
