import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { ButtonDropdown, Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { connect } from "react-redux";
import { addInvoice } from "store/actions/Billing";
import { RootState } from "../../store/store";
import { Customer } from "models/Customer";
import InvoiceList from "pages/Invoice/InvoiceList";
import { Invoice } from "models/Invoice";
import { useNavigate } from "react-router-dom";

interface Props {
    customer: Customer;
}

interface Actions {
    addInvoice: (i: Invoice) => void;
}

const CustomerInvoiceList: React.FC<Props & Actions> = ({ customer, addInvoice }) => {
    const [dropNew, setDropNew] = useState(false);

    const navigate = useNavigate();

    const createNew = (isInvoice: boolean) => {
        const invoice = new Invoice()
        invoice.draft = true;
        invoice.quote = !isInvoice;
        invoice.created = new Date();
        const deadline = new Date();
        deadline.setDate(deadline.getDate() + customer.billing.default_deadline);
        invoice.deadline = deadline;
        invoice.tenant = customer.tenant;
        invoice.customer = customer.id;

        addInvoice(invoice)
            //@ts-ignore
            .then((response) => {
                navigate("/invoice/" + response.id)
            })
    }

    return (
        <Card>
            <CardBody>
                <div>
                    <ButtonDropdown
                        style={{ float: "right" }}
                        isOpen={dropNew}
                        toggle={() =>
                            setDropNew(!dropNew)
                        }>
                        <DropdownToggle
                            caret
                            color="primary"
                            className="btn btn-primary">
                            New
                            <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                createNew(true)
                            }}>
                                Invoice
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                createNew(false)
                            }}>
                                Quote
                            </DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>

                    <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>Invoices</p>
                </div>

                <InvoiceList customerId={customer.id} tenantId={null} />
            </CardBody>
        </Card>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        tenants: state.Tenants
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ addInvoice }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInvoiceList);
