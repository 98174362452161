import WaitingDots from "components/WaitingDots";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addInvoice } from "store/actions/Billing";
import { getCustomer } from "store/actions/Customers";
import { CustomerState } from "store/reducers/Customers";
import { RootState } from "store/store";

interface Props {
    customerState: CustomerState;
    id: string;
}

const CustomerName: React.FC<Props> = ( {
                                            customerState,
                                            id
                                        } ) => {

    const customer = customerState.getCustomerById( id );

    if ( customer === null ) {
        return <WaitingDots/>
    }

    return ( <span>{ customer.name }</span> );
}

const mapStateToProps = ( state: RootState ) => {
    return {
        customerState: state.Customers
    };
};

const mapDispatchToProps = ( dispatch: any ) => {
    return bindActionCreators( {
        getCustomer,
        addInvoice
    }, dispatch );
};

export default connect( mapStateToProps, mapDispatchToProps )( CustomerName );
