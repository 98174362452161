import WaitingDots from 'components/WaitingDots';
import { Invoice } from 'models/Invoice';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { RootState } from 'store/store';
import { convertInvoice, deleteInvoice, finalizeInvoice } from "store/actions/Billing";
import { useNavigate } from 'react-router-dom';
import { Customer } from 'models/Customer';
import LongClickButton from 'components/LongClickButton';

interface Props {
    invoice: Invoice;
    customers: Customer[];
}

interface Actions {
    deleteInvoice: (i: Invoice) => Promise<any>;
    convertInvoice: (i: Invoice) => void;
    finalizeInvoice: (i: Invoice) => void;
}

const InvoiceStatusCard: React.FC<Props & Actions> = ({ invoice, customers, deleteInvoice, convertInvoice, finalizeInvoice }) => {

    const navigate = useNavigate();

    const convert = () => {
        convertInvoice(invoice)
    }

    const finalize = () => {
        finalizeInvoice(invoice)
    }

    if (invoice === undefined) {
        return <WaitingDots />
    }

    if (invoice.quote) {
        return <Card>
            <CardBody>
                <h5 className="my-0 text-warning text-truncate"><i className="mdi mdi-block-helper me-3"></i>Quote</h5>
                <br />
                <Button color="primary" style={{ width: "100%" }}
                    onClick={() => {
                        convert()
                    }}>
                    Convert to invoice</Button>
                <LongClickButton color="danger" style={{ marginTop: '5px', width: "100%" }}
                    onClick={() => {
                        deleteInvoice(invoice).then(() => {
                            navigate("/customers/" + invoice.customer);
                        })
                    }}>
                    Delete quote</LongClickButton>
            </CardBody>
        </Card>
    }

    if (invoice.draft) {
        return <Card>
            <CardBody>
                <h5 className="my-0 text-warning text-truncate"><i className="mdi mdi-block-helper me-3"></i>Draft</h5>
                <br />
                {invoice.CalcGrandTotal() > 0 &&
                    <Button color="primary" style={{ width: "100%" }}
                        onClick={() => {
                            finalize()
                        }}>Finalize invoice</Button>
                }
                <Button color="dark" style={{ marginTop: '5px', width: "100%" }}
                    onClick={() => {
                        convert()
                    }}>
                    Convert to quote</Button>
                <LongClickButton color="danger" style={{ marginTop: '5px', width: "100%" }}
                    onClick={() => {
                        deleteInvoice(invoice).then(() => {
                            navigate("/customers/" + invoice.customer);
                        })
                    }}>
                    Delete draft</LongClickButton>
            </CardBody>
        </Card>
    }

    return (
        <Card>
            <CardBody>
                {invoice.IsPaid() ?
                    <h5 className="my-0 text-success text-truncate"><i className="mdi mdi-check-all me-3"></i>Paid</h5>
                    :
                    <h5 className="my-0 text-danger text-truncate"><i className="mdi mdi-block-helper me-3"></i>Not paid</h5>
                }
                {invoice.payments.length > 0 &&
                    <>
                        <hr className="my-1"></hr>
                        <table className="table table-borderless align-middle table-centered table mb-0">
                            <tbody>
                                {invoice.payments
                                    .sort((a, b) => (new Date(a.date) < new Date(b.date)) ? 1 : (new Date(a.date) > new Date(b.date)) ? -1 : 0)
                                    .map(payment => {
                                        return <tr>
                                            <td>{payment.type.includes("ideal") ? <div><span className={`fab fa-ideal`} /> iDeal</div> : payment.type}</td>
                                            <td>&euro; {payment.amount.toFixed(2)}</td>
                                            <td>{payment.date && new Date(payment.date).toLocaleDateString()}</td>
                                        </tr>;
                                    })}
                            </tbody>
                        </table>
                    </>
                }
            </CardBody>
        </Card>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        invoices: state.Invoices,
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ deleteInvoice, convertInvoice, finalizeInvoice }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceStatusCard);