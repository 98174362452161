import {AxiosInstance} from "axios";
import {AnyAction, Dispatch, Middleware, Store} from "redux";
import {LOGIN_USER_ACCOUNT} from "../actions/Auth";

export const setAuth = (api: AxiosInstance): Middleware => {

    const setAuthHeader = (token: string) => {
        if (!token) return;
        api.defaults.headers.common['Authorization'] = token;
    }

    // @ts-ignore
    return (store: Store) => {
        setAuthHeader(store.getState().Authentication);

        return (next: Dispatch<AnyAction>) =>
            (action: any) => {
                if (action.type === LOGIN_USER_ACCOUNT.SUCCESS) {
                    setAuthHeader(action.payload);
                }

                return next(action);
            };
    };
}
