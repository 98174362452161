import { Challenge } from "common/Challenge";
import { IsValidEmail } from "common/EmailUtils";
import { GetFaviconUrl } from "common/Favicon";
import { countries } from "components/Common/countries";
import CountrySelector from "components/Common/CountrySelector";
import Input from "components/Common/Input";
import { GetPasswordStrength } from "components/Common/PasswordUtils";
import TypeButton from "components/Common/TypeButton";
import WaitingDots from "components/WaitingDots";
import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { checkEmail, vatCheck } from "services/AccountApi";
import { registerCustAuth } from "../services/AccountApi";
import PartyIcon from "../assets/images/party.gif";

interface Props {
    onStepChange: ( e: number ) => void;
    challenge: Challenge;
}


const RegistrationForm = ( props: Props ) => {
    const [ firstname, setFirstname ] = useState( "" );
    const [ lastname, setLastname ] = useState( "" );
    const [ email, setEmail ] = useState( "" );
    const [ emailAvailable, setEmailAvailable ] = useState( -1 );
    const [ password, setPassword ] = useState( "" );
    const [ vatNumber, setVatNumber ] = useState( "" );
    const [ vatName, setVatName ] = useState( "" );
    const [ businessName, setBusinessName ] = useState( "" );
    const [ street, setStreet ] = useState( "" );
    const [ number, setNumber ] = useState( "" );
    const [ zip, setZip ] = useState( "" );
    const [ city, setCity ] = useState( "" );

    const [ type, setType ] = useState( "individual" );
    const [ country, setCountry ] = useState<string>( "" );

    const location = useLocation();
    useEffect( () => {
        switch ( location.pathname ) {
            case "/register":
                props.onStepChange( 1 );
                break;
            case "/register/address":
                props.onStepChange( 2 );
                break;
            case "/register/password":
                props.onStepChange( 3 );
                break;
            case "/register/done":
                props.onStepChange( 4 );
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ location.pathname ] );

    useEffect( () => {
        setEmailAvailable( -1 );
        if ( !IsValidEmail( email ) ) {
            return;
        }

        checkEmail( email ).then( ( resp ) => {
            setEmail( email => {
                if ( email === resp.email ) {
                    setEmailAvailable( resp.error );
                }
                return email;
            } )
        } )
    }, [ email ] )

    useEffect( () => {
        // setEmailAvailable( -1 );
        // if ( !IsValidEmail( email ) ) {
        //     return;
        // }
        if ( vatNumber.length < 3 ) {
            return
        }
        setVatName( "-1" )
        vatCheck( vatNumber ).then( ( resp ) => {
            if ( resp.isValid ) {
                setVatNumber(vat => {
                    if (vatNumber === vat) {

                        if ( country === "" ) {
                            const found = countries.find( country => country.code === vatNumber.slice( 0, 2 ) )
                            if ( found ) {
                                setCountry( found.name )
                            }
                        }

                        if (resp.name === "---") {
                            setVatName("Unknown name" )
                            return vat
                        }
                        if ( businessName === "" ) {
                            setBusinessName( resp.name )
                        }
                        setVatName( resp.name )



                        const lines = resp.address.split( "\n" );

                        let linesOffset = 0;
                        if (lines[0] === "") {
                            linesOffset = 1
                        }
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const [ _, fstreet, fnumber, fnumberExt ] = lines[linesOffset ].match( /(\w+) (\d*) *(\w)/ )
                        const parts = lines[ linesOffset+1 ].split( " " )
                        if ( street === "" ) {
                            setStreet( fstreet ?? "" )
                        }
                        if ( number === "" && fnumber ) {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            const [ _, clean ] = fnumber.match( /0*([1-9]*)/ )
                            setNumber( clean + ( fnumberExt ?? "" ) )
                        }
                        if ( zip === "" ) {
                            setZip( parts[ 0 ] )
                        }
                        if ( city === "" ) {
                            setCity( parts.slice( 1, parts.length ).join( " " ) )
                        }
                    }
                    return vat
                })

            } else {
                setVatNumber(vat => {
                    if (vatNumber === vat) {
                        setVatName( "" )

                    }
                    return vat
                })
            }
        } )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ vatNumber ] )

    const passwordStrength = GetPasswordStrength( password );

    const checks = {
        step1: (): boolean => {
            return emailAvailable === 0 && firstname.length > 2 && lastname.length > 2 && IsValidEmail( email )
        },
        step2: (): boolean => {
            return country !== "" && ( type !== "business" || businessName.length > 3 ) && street.length > 2 && number.length > 0 && zip.length > 2 && city.length > 2
        },
        step3: (): boolean => {
            return passwordStrength >= 50 && props.challenge.solution !== ""
        }
    }

    const navigate = useNavigate();

    const register = () => {
        if (!checks.step1()) {
            navigate("/register");
            return;
        }
        if (!checks.step2()) {
            navigate("/register/address");
            return;
        }
        if (!checks.step3()) {
            return;
        }
        registerCustAuth(
            email,
            password,
            props.challenge,
            firstname,
            lastname,
            {
                city: city,
                street:street,
                number:number,
                country:country,
                zip_code: zip
            },
            type === "business",
            businessName,
            vatNumber
        ).then(result => {
            navigate("/register/done");
        }).catch(err => console.error(err))
    }


    return <Routes>
        <Route
            path={ `/` }
            element={ <div key={ "step1" }>
                <h6>Tell us a bit about yourself</h6>
                <Row>
                    <Col xs={ 6 }>
                        <Input
                            autoFocus={ true }
                            type={ "text" }
                            name={ "first-name" }
                            placeholder={ "First name" }
                            value={ firstname }
                            onChange={ fname => {
                                setFirstname( fname )
                            } }
                        />
                    </Col>
                    <Col xs={ 6 }>
                        <Input
                            type={ "text" }
                            name={ "last-name" }
                            placeholder={ "Last name" }
                            value={ lastname }
                            onChange={ lname => {
                                setLastname( lname )
                            } }
                        />
                    </Col>
                </Row>
                <br/>
                <br/>
                <h6>How can we reach you?</h6>
                <Row>
                    <Col lg={ 12 }>
                        <Input
                            type={ "email" }
                            name={ "email" }
                            placeholder={ "Email address" }
                            value={ email }
                            onChange={ setEmail }
                            onKeyDown={ ( e ) => {
                                if ( e.key === "Enter" && checks.step1() ) {
                                    navigate( "/register/address" );
                                }
                            } }
                            style={{
                                paddingLeft: IsValidEmail( email )  ? "35px": "12px"
                            }}
                        />
                        { ( IsValidEmail( email ) ) &&
                            <div style={{textAlign:"left"}}>
                                <img style={ {
                                    position : "absolute",
                                    marginTop: "-30px",
                                    marginLeft:"15px",
                                    borderRadius:"10px",
                                    height:"20px"
                                } }
                                     src={ GetFaviconUrl( email.split( "@" )[ 1 ] ) }
                                />
                            </div>
                        }
                        { emailAvailable > 0 && <div style={ {
                            width     : "96%",
                            marginLeft: "2%"
                        } }>
                            <small style={ {
                                textAlign  : "left",
                                paddingLeft: "20px",
                                width      : "100%",
                                marginTop  : "5px",
                                color      : "orange",
                                display    : "inline-block"
                            } }>
                                { emailAvailable === 1 && <>Invalid email-address</> }
                                { emailAvailable === 2 && <>Email already in use. <Link to={ `/` }>Go to
                                    login</Link></> }
                                { emailAvailable === 3 && <>Not an email-domain <small><i>(MX-lookup
                                    failed)</i></small></> }
                            </small>
                        </div>

                        }
                    </Col>
                </Row>
                <br/>
                <br/>
                <Row>
                    <Col xs={ 6 }/>
                    <Col xs={ 6 }>
                        <Button
                            disabled={ !checks.step1() }
                            style={ {
                                float: "right",
                                width: "90%"
                            } }
                            color={ "primary" }
                            onClick={ () => {
                                navigate( "/register/address" );
                            } }>Next</Button>
                    </Col>
                </Row>
            </div> }/>
        <Route
            path={ `/address` }
            element={ <div key={ "step2" }>
                <Row>
                    <Col xs={ 6 }>
                        <TypeButton
                            text={ "I'm an individual" }
                            selected={ type === "individual" }
                            onClick={ () => setType( "individual" ) }
                        />
                    </Col>
                    <Col xs={ 6 }>
                        <TypeButton
                            text={ "I'm a business" }
                            selected={ type === "business" }
                            onClick={ () => setType( "business" ) }
                        />
                    </Col>
                </Row>
                <br/>
                { type === "business" && <>
                    <Row>
                        <Col lg={ 12 }>
                            <Input
                                type={ "text" }
                                name={ "business-name" }
                                placeholder={ "Business name" }
                                value={ businessName }
                                onChange={ setBusinessName }
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col lg={ 12 }>
                            <Input
                                type={ "text" }
                                name={ "vat-number" }
                                placeholder={ "VAT-Number (optional)" }
                                value={ vatNumber }
                                onChange={ setVatNumber }
                            />
                            { vatName !== "" &&vatName !== "-1" && <div style={ {
                                width     : "96%",
                                marginLeft: "2%"
                            } }>
                                <small style={ {
                                    textAlign  : "left",
                                    paddingLeft: "20px",
                                    width      : "100%",
                                    marginTop  : "5px",
                                    color      : "green",
                                    display    : "inline-block"
                                } }>Valid: <b>{ vatName }</b>
                                </small>
                            </div>

                            }
                            { (vatName === "" && vatNumber !== "") && <div style={ {
                                width     : "96%",
                                marginLeft: "2%"
                            } }>
                                <small style={ {
                                    textAlign  : "left",
                                    paddingLeft: "20px",
                                    width      : "100%",
                                    marginTop  : "5px",
                                    color      : "red",
                                    display    : "inline-block"
                                } }>Invalid vat-number
                                </small>
                            </div>

                            }{ (vatName === "-1" && vatNumber !== "") && <div style={ {
                            width     : "96%",
                            marginLeft: "2%"
                        } }>
                            <small style={ {
                                textAlign  : "left",
                                paddingLeft: "20px",
                                width      : "100%",
                                marginTop  : "5px",
                                color      : "orange",
                                display    : "inline-block"
                            } }>Checking<WaitingDots />
                            </small>
                        </div>

                        }
                        </Col>
                    </Row>
                    <br/>
                </> }
                <br/>
                <h6>Please fill in your { type === "business" ? "business" : "personal" } address.</h6>
                <Row>
                    <Col xs={ 8 }>
                        <Input
                            autoFocus={ true }
                            type={ "text" }
                            name={ "street" }
                            placeholder={ "Street" }
                            value={ street }
                            onChange={ setStreet }
                        />
                    </Col>
                    <Col xs={ 4 }>
                        <Input
                            type={ "text" }
                            name={ "number" }
                            placeholder={ "Number" }
                            value={ number }
                            onChange={ setNumber }
                        />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={ 4 }>
                        <Input
                            type={ "text" }
                            name={ "zip-code" }
                            placeholder={ "ZIP Code" }
                            value={ zip }
                            onChange={ setZip }
                        />
                    </Col>
                    <Col xs={ 8 }>
                        <Input
                            type={ "text" }
                            name={ "city" }
                            placeholder={ "City" }
                            value={ city }
                            onChange={ setCity }
                        />
                    </Col>
                </Row>
                <br/>
                <CountrySelector
                    initial={ country }
                    onSelect={ setCountry }
                    onKeyDown={ ( e ) => {
                        if ( e.key === "Enter" && checks.step2() ) {
                            navigate( "/register/password" );
                        }
                    } }
                />

                <br/>
                <Row>
                    <Col xs={ 6 }>
                        <Button style={ {
                            width: "90%"
                        } } onClick={ () => {
                            navigate( "/register" );
                        } }>Previous</Button>
                    </Col>
                    <Col xs={ 6 }>
                        <Button
                            disabled={ !checks.step2() }
                            style={ {
                                float: "right",
                                width: "90%"
                            } } color={ "primary" } onClick={ () => {
                            navigate( "/register/password" );
                        } }>Next</Button>
                    </Col>
                </Row>
            </div> }/>
        <Route
            path={ `/password` }
            element={ <Col key={ "step3" }>
                <h6>Finally, let's set a password</h6>
                <Row>
                    <Col lg={ 12 }>
                        <Input
                            autoFocus={ true }
                            type={ "password" }
                            name={ "password" }
                            value={ password }
                            placeholder={ "Password" }
                            onChange={ setPassword }
                        />

                        <div style={ {
                            width     : "96%",
                            marginLeft: "2%"
                        } }>
                            <small style={ {
                                textAlign   : "right",
                                paddingRight: "20px",
                                width       : "100px",
                                marginTop   : "5px",
                                display     : "inline-block"
                            } }>Strength</small>
                            <div style={ {
                                float       : "right",
                                height      : "6px",
                                borderRadius: "3px",
                                padding     : 0,
                                marginTop   : "10px",
                                marginBottom: "20px",
                                width       : "calc(100% - 100px)",
                                background  : "lightgrey",
                                display     : "inline-block"

                            } }>
                                <div style={ {
                                    height      : "6px",
                                    borderRadius: "3px",
                                    padding     : 0,
                                    width       : ( passwordStrength > 100 ? 100 : ( passwordStrength < 0 ? 0 : passwordStrength ) ) + "%",
                                    background  : passwordStrength < 50 ? "red" : ( passwordStrength < 70 ? "orange" : "green" ),
                                    transition  : "width 0.5s"
                                } }></div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <br/>
                <br/>
                <Row>
                    <Col xs={ 3 }>
                        <Button style={ {
                            width: "90%"
                        } } onClick={ () => {
                            navigate( "/register/address" );
                        } }>Back</Button>
                    </Col>
                    <Col xs={ 6 }>
                        <Button
                            disabled={ !checks.step3() }
                            style={ {
                                float: "right",
                                width: "90%"
                            } } color={ "primary" } onClick={ () => {
                            register();
                        } }>Register</Button>
                    </Col>
                </Row>
            </Col> }/>
        <Route
            path={ `/done` }
            element={ <Col key={ "step4" } className={"text-center"}>
                <img alt="party" src={PartyIcon} style={{width:"60%", marginBottom:"50px"}} />
                <br />
                <h5>Your account has been created. We have sent you an email to confirm your email address.</h5>

                <br/>
                <br/>
                <Row>

                    <Col xs={ 12 }>
                        <Button
                            style={ {
                                width: "70%"
                            } } color={ "primary" } onClick={ () => {
                            navigate("/login")
                        } }>Go to login</Button>
                    </Col>
                </Row>
            </Col> }/>
    </Routes>;
}

export default RegistrationForm;