import WaitingDots from 'components/WaitingDots';
import { Check } from 'models/Check';
import { Service } from 'models/Service';
import React, { useState } from 'react';
import CheckServiceEdit from './CheckServiceEdit';


interface Props {
    service: Service;
    check: Check;
    index: number;
}

interface Actions {
    setChecks: (c: Check[]) => void;
}



const ServiceCheckRow: React.FC<Props & Actions> = ({ check, service, index, setChecks }) => {

    const [change, setChange] = useState<boolean>(false)

    if (check === undefined) {
        return <WaitingDots />
    }

    return (
        <>
            {
                change ?
                    <CheckServiceEdit service={service} check={check} index={index}
                        onCancel={() => {
                            setChange(false);
                        }
                        }
                        save={() => {
                            setChange(false);
                        }}
                        setChecks={setChecks}
                    />
                    :
                    <>
                        <div className="float-end ms-2 dropdown">
                            <button
                                type="button"
                                onClick={() => {
                                    setChange(true)
                                }}
                                className="btn btn-primary waves-effect waves-light"
                            >
                                <i className=" bx bx-edit-alt"></i>
                            </button>
                        </div>
                        <div className="p-3 px-4">
                            <div className="d-flex align-items-start">
                                <div className="align-self-center me-3">
                                    <div className="flex-1">
                                        <h5 className="font-size-15 mt-0 mb-1">{check.name}</h5>
                                        <p className="text-muted mb-0">{check.target}</p>
                                        <p className="text-muted mb-0">{check.type}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>

    );
}

export default ServiceCheckRow;