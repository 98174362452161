import React from "react";

const Copyright: React.FC = () => {

    return (
        <>
            © {new Date().getFullYear()} Galactic Group
        </>
    );
};

export default Copyright;

