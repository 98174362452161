import { IsValidEmail } from "common/EmailUtils";
import { GetFaviconUrl } from "common/Favicon";
import TenantDomain from "common/TenantDomain";
import Input from "components/Common/Input";
import Copyright from "components/Copyright";
import PageMeta from "components/PageMeta";
import { Session } from "pages/AuthEntry";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Card, Col, Container, Row, Spinner } from "reactstrap";
import { ReactComponent as LogoDark } from "../assets/images/logo-dark.svg";
import { getCustAuthTotpActive, loginCustAuth } from "../services/AccountApi";

const LoginCustomer: React.FC = () => {
    const [ state, setState ] = useState( 0 );
    const [ sessions, setSessions ] = useState<Session[]>( [] );
    const [ redirectUrl, setRedirectUrl ] = useState( "" );

    useEffect( () => {
        const entry = window.localStorage.getItem( "entry" )
        if ( entry === null || entry === "" ) {
            setState( 9 );
        } else {
            const payload = atob( entry );
            const parsed = JSON.parse( payload );
            setRedirectUrl( parsed.redirect_url )
            setState( 1 );
        }

        const sessionsString = window.localStorage.getItem( "sessions" )
        if ( sessionsString === null ) {
            return
        }
        const sessions = JSON.parse( sessionsString );
        if ( sessions.length === 0 ) {
            return
        }
        setSessions( sessions );
    }, [] )

    const [ error, setError ] = useState<string | null>( null );
    const [ useMfa, setUseMfa ] = useState( -1 );

    const [ email, setEmail ] = useState( "" );
    const [ password, setPassword ] = useState( "" );
    const [ mfa, setMfa ] = useState( "" );

    useEffect( () => {
        setUseMfa( -1 )
        if ( IsValidEmail( email ) ) {
            getCustAuthTotpActive( email ).then( ( active ) => {
                setEmail( cemail => {
                    if ( cemail === email ) {
                        if ( active ) {
                            setUseMfa( 1 )
                        } else {
                            setUseMfa( 0 )
                        }
                    }
                    return cemail;
                } )
            } ).catch( () => setUseMfa( 0 ) )
        }
    }, [ email ] )

    const submit = () => {

        loginCustAuth( email, password, {}, { "totp": mfa } ).then( token => {
            const sessions = window.localStorage.getItem( "sessions" );
            let parsedSessions = [];
            if ( sessions !== null ) {
                parsedSessions = JSON.parse( sessions )
            }
            parsedSessions.push( {
                email: email,
                token: token
            } )
            window.localStorage.setItem( "sessions", JSON.stringify( parsedSessions ) );
            setState( 3 )
            setTimeout( () => {
                window.location.assign( redirectUrl + "/" + token )
            }, 1000 );
        } ).catch( () => {
            setError( "Invalid credentials" )
        } )
    }

    const navigate = useNavigate();

    const duplicateSession = sessions.filter( session => session.email === email ).length > 0;
    const invalidForm = useMfa === -1 || ( useMfa === 1 && mfa.length !== 6 ) || !IsValidEmail( email ) || password.length < 4 || duplicateSession;

    //@ts-ignore
    const [_, domain, tld] = redirectUrl.match(/http(?:s)?:\/\/(?:[\w-]+\.)*([\w-]{1,63})(\.(?:\w{3}|\w{2}))(?:$|\/)/) ?? [];
    return ( <>
        <PageMeta title={ `Login | Galactic-Group` } hideTitle={ true }/>
        <div className="account-pages my-0 pt-sm-5">
            <Container>
                <Row className="justify-content-center">
                    <Col md={ 10 } className="text-center mt-3">
                        <LogoDark/>

                        <div className="text-center login-height"/>
                        <Row>
                            <Col lg={ 3 }/>
                            <Col lg={ 6 }>
                                <Card className="overflow-hidden">
                                    <div className={ "pt-4 text-center" }>
                                        <h5 className={"pb-2"}><span style={{marginRight: "10px"}}>Login at</span> <TenantDomain domain={(domain ?? "") + (tld ?? "")} /></h5>
                                        <Link to={ `/register` }>Or create an account</Link>

                                    </div>
                                    { state === 0 && <div style={ { margin: '50px 0' } }>
                                        <Spinner/>
                                    </div> }
                                    { state === 3 && <div style={ {
                                        margin   : '50px 0',
                                        textAlign: "center"
                                    } }>
                                        <h5>Login successful, redirecting...</h5>
                                        <Spinner/>
                                    </div> }
                                    { state === 1 && <div className="g-0">
                                        <div className="p-lg-5 p-4">
                                            <div className="mt-4 pt-0">


                                                <Input
                                                    autoFocus={ true }
                                                    name={ "email" }
                                                    type={ "email" }
                                                    placeholder={ "Enter email-address" }
                                                    onChange={ setEmail }
                                                    style={{
                                                        paddingLeft: IsValidEmail( email )  ? "35px": "12px"
                                                    }}
                                                />
                                                { ( IsValidEmail( email ) ) &&
                                                    <div style={{textAlign:"left"}}>
                                                        <img style={ {
                                                            position : "absolute",
                                                            marginTop: "-30px",
                                                            marginLeft:"15px",
                                                            borderRadius:"10px",
                                                            height:"20px"
                                                        } }
                                                             src={ GetFaviconUrl( email.split( "@" )[ 1 ] ) }
                                                        />
                                                    </div>
                                                }
                                                <br/>

                                                { duplicateSession ? <>
                                                    <h6><br/>An active session has been found for email <b>{ email }</b>
                                                    </h6>
                                                    <button
                                                        className="btn btn-primary w-100 mt-4 waves-effect waves-light"
                                                        onClick={ () => navigate( "/entry" ) }
                                                    >
                                                        Go to active sessions
                                                    </button>
                                                </> : <>
                                                    <Input
                                                        name={ "password" }
                                                        type={ "password" }
                                                        placeholder={ "Enter password" }
                                                        onChange={ setPassword }
                                                        onKeyDown={ e => {
                                                            if ( e.key === "Enter" && !invalidForm ) {
                                                                submit()
                                                            }
                                                        } }/>
                                                    <br/>
                                                    { useMfa === 1 && <Input
                                                        name={ "totp" }
                                                        type={ "text" }
                                                        placeholder={ "Enter TOTP" }
                                                        onChange={ setMfa }
                                                        onKeyDown={ e => {
                                                            if ( e.key === "Enter" && !invalidForm ) {
                                                                submit()
                                                            }
                                                        } }
                                                    /> }

                                                    <br/>
                                                    { error && <Alert color="danger">{ error }</Alert> }

                                                    <div className="mt-3">
                                                        <button
                                                            disabled={ invalidForm }
                                                            className="btn btn-primary w-100 waves-effect waves-light"
                                                            onClick={ submit }
                                                        >
                                                            Log In
                                                        </button>
                                                    </div>
                                                </> }
                                            </div>
                                        </div>
                                    </div>

                                    }
                                    { state === 9 && <div style={ { margin: '50px 0' } }>
                                        <h5>Something went wrong. Please go back and try again</h5>
                                        {/* eslint-disable-next-line */ }
                                        <a style={ {
                                            display  : "block",
                                            color    : "#0576b9",
                                            marginTop: '50px'
                                        } }
                                           onClick={ () => {
                                               window.history.back()
                                           } }>Go back</a>
                                    </div> }
                                </Card>
                                <div className={ "text-center" }>
                                    <Copyright/>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    </> );
};

export default connect( null, null )( LoginCustomer );
