import React, { useState } from "react";
import { Person } from "models/Person";
import { Customer } from "models/Customer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updatePerson } from 'storeAccount/actions/Customers';
import { Alert } from "reactstrap";
import { PersonsState } from 'storeAccount/reducers/Persons';
import { RootStateAccount } from "storeAccount/accountStore";


interface Props {
    person: Person;
    customer: Customer;
    onCancel: () => void;
    setPerson: (p: Person) => void;
    save: () => void;
    personsState: PersonsState;
}

interface Actions {
    updatePerson: typeof updatePerson;
}

const CustomerPersonAdd: React.FC<Props & Actions> = ({ personsState, customer, person, onCancel, save, updatePerson, setPerson }) => {

    const [error, setError] = useState<string | null>(null)
    const savePerson = () => {

        if (person.name === "" || person.name === undefined) {
            setError("Person needs a name")
        } else {
            updatePerson(person)
                //@ts-ignore
                .then((person) => {
                    setPerson(person);
                    save();
                })
        }
    }

    return (
        <>
            {
                error &&
                <Alert color="danger">{error}</Alert>
            }

            <br />
            <div className="form-floating mb-3">
                <button type="button"
                    onClick={() => {
                        onCancel();
                    }}
                    className="btn btn-danger waves-effect waves-light">
                    Cancel
                </button>
            </div>

            <div className="form-floating mb-3">
                <input type="text" name="nameperson" className="form-control" defaultValue={person.name} onChange={e => {
                    person.name = e.target.value

                }} placeholder="customer" />
                <label className="">First name</label>
            </div>
            <div className="form-floating mb-3">
                <input type="text" name="lastnameperson" className="form-control" defaultValue={person.last_name} onChange={e => {
                    person.last_name = e.target.value

                }} placeholder="customer" />
                <label className="">Last name</label>
            </div>


            <div className="form-floating mb-3">
                <input type="text" name="phoneperson" className="form-control" defaultValue={person.phone} onChange={e => {
                    person.phone = e.target.value

                }} placeholder="name@example.com" />
                <label className="">Phone</label>
            </div>

            <div className="mt-3">
                <button
                    id="buttonPerson"
                    name="buttonPerson"
                    type="button"
                    onClick={() => {
                        savePerson()
                    }}
                    className="btn btn-primary w-100 waves-effect waves-light"
                >
                    <i className="mdi mdi-account-plus"></i> Save
                </button>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootStateAccount) => {
    return {
        personsState: state.Persons
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ updatePerson }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPersonAdd);
