import { generateAction, RequestTypes } from "./Util";

export const LOGOUT_ACCOUNT = new RequestTypes("LOGOUT_ACCOUNT");
export const SET_TOKEN = new RequestTypes("SET_TOKEN");

export const logoutAccount = () => LOGOUT_ACCOUNT.success();

export const setToken = (token: string) => {
  return generateAction(SET_TOKEN, () =>
    Promise.resolve().then(() => {
      return token
    }
    )
  );
};
