import { combineReducers } from 'redux';

import Customers from './Customers';
import Authentication from './Authentication';
import Persons from './Persons';
import Invoices from './Invoices';
import Services from './Services';



export default combineReducers({
  Customers,
  Authentication,
  Persons,
  Invoices,
  Services
});
