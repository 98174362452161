import { generateAction, RequestTypes } from "./Util";
import * as api from "../../services/Api";
import { Customer } from "models/Customer";
import { Person } from "../../models/Person";
import { Recurring } from "models/Recurring";

export const GET_CUSTOMERS = new RequestTypes("GET_CUSTOMERS");
export const GET_PERSONS = new RequestTypes("GET_PERSONS");
export const GET_CUSTOMER_PERSONS = new RequestTypes("GET_CUSTOMER_PERSONS");
export const ADD_CUSTOMER = new RequestTypes("ADD_CUSTOMER");
export const LINK_PERSON_TO_CUSTOMER = new RequestTypes("LINK_PERSON_TO_CUSTOMER");
export const CREATE_PERSON = new RequestTypes("CREATE_PERSON");
export const UPDATE_PERSON = new RequestTypes("UPDATE_PERSON");
export const DELETE_PERSON = new RequestTypes("DELETE_PERSON");
export const GET_CUSTOMER = new RequestTypes("GET_CUSTOMER");
export const GET_AUTH_CUSTOMER = new RequestTypes("GET_AUTH_CUSTOMER");
export const EDIT_CUSTOMER = new RequestTypes("EDIT_CUSTOMER");

export const GET_COMPUTERS = new RequestTypes("GET_COMPUTERS");
export const ADD_RECURRING = new RequestTypes("ADD_RECURRING");
export const GET_RECURRINGS = new RequestTypes("GET_RECURRINGS");
export const DELETE_RECURRING = new RequestTypes("DELETE_RECURRING");


export const getCustomers = () => {
  return generateAction(GET_CUSTOMERS, () =>
    api.getCustomers()
  );
};


export const getPersons = () => {
  return generateAction(GET_PERSONS, () =>
    api.getPersons()
  );
};

export const getCustomerPersons = (id: string) => {
  return generateAction(GET_CUSTOMER_PERSONS, () =>
    api.getCustomerPersons(id)
  );
};

export const getCustomerComputer = (id: string, computer: string) => {
  return generateAction(GET_COMPUTERS, () =>
    api.getCustomerComputer(id, computer)
  );
};

export const createPerson = (person: Person) => {
  return generateAction(CREATE_PERSON, () =>
    api.createPerson(person)
  );
};


export const linkPersonToCustomer = (person: Person, customer: Customer) => {
  return generateAction(LINK_PERSON_TO_CUSTOMER, () =>
    api.linkPersonToCustomer(person.id, customer.id)
  );
};

export const updatePerson = (person: Person) => {
  return generateAction(UPDATE_PERSON, () =>
    api.updatePerson(person)
  );
};

export const deletePerson = (person: Person, customer: string) => {
  return generateAction(DELETE_PERSON, () =>
    api.deletePerson(person, customer)
  );
};

export const addCustomer = (customer: Customer) => {
  return generateAction(ADD_CUSTOMER, () =>
    api.addCustomer(customer)
  );
};


export const getCustomer = (id: string) => {
  return generateAction(GET_CUSTOMER, () =>
    api.getCustomer(id)
  );
};

export const editCustomer = (customer: Customer) => {
  return generateAction(EDIT_CUSTOMER, () =>
    api.editCustomer(customer)
  );
};

export const addRecurring = (recurring: Recurring) => {
  return generateAction(ADD_RECURRING, () =>
    api.addRecurring(recurring)
  );
};

export const deleteRecurring = (recurring: Recurring) => {
  return generateAction(DELETE_RECURRING, () =>
    api.deleteRecurring(recurring)
  );
};

export const getRecurrings = () => {
  return generateAction(GET_RECURRINGS, () =>
    api.getRecurrings()
  );
};