import axios from 'axios';
import { User } from 'models/User';
import { Tenant } from 'models/Tenant';
import {plainToClass, plainToInstance} from 'class-transformer';
import "reflect-metadata";
import {Computer, Customer} from 'models/Customer';
import { Invoice, InvoicePaymentIdealIssuer, InvoicePaymentPortalInfo } from 'models/Invoice';
import { Email } from 'models/Email';
import { Expense, ExpenseAttachment } from 'models/Expense';
import { InvoiceContent } from 'models/InvoiceContent';
import { Call } from "../models/Call";
import { Person } from "../models/Person";
import { InvoicePayment } from "../models/InvoicePayment";
import { Recurring } from 'models/Recurring';
import { Service, ServiceHistory } from 'models/Service';

const api = axios.create();

const baseUrl = `https://api.office.galactic-group.nl/v1`;
const servicesUrl = `https://api.status.galactic-group.nl/v1`;

export function loginUserAccount(email: string, password: string, mfa_token: string): Promise<string> {
    return api.post(`${baseUrl}/auth`, { email, password, mfa_token })
        .then(response => response.data.token);
}

export function getUser(): Promise<User> {
    return api.get(`${baseUrl}/user`)
        .then(response => plainToClass(User, response.data));
}

export function getTenants(): Promise<Tenant[]> {
    return api.get(`${baseUrl}/tenants`)
        .then(response => response.data.map((tenant: object): Tenant => plainToClass(Tenant, tenant)));
}

export function getUsers(): Promise<User[]> {
    return api.get(`${baseUrl}/users`)
        .then(response => response.data.map((user: object): User => plainToClass(User, user)));
}

export function getEmails(): Promise<Email[]> {
    return api.get(`${baseUrl}/mailer/emails`)
        .then(response => response.data.map((mail: object): Email => plainToClass(Email, mail)));
}

export function getEmail(id: string): Promise<Email> {
    return api.get(`${baseUrl}/mailer/emails/` + id)
        .then(response => plainToClass(Email, response.data));
}

export function getUnreadEmails(): Promise<Email[]> {
    return api.get(`${baseUrl}/mailer/unreads`)
        .then(response => response.data.map((mail: object): Email => plainToClass(Email, mail)));
}

export function getEmailsAfter(date: number): Promise<Email[]> {
    return api.get(`${baseUrl}/mailer/emails?after=${date}`)
        .then(response => response.data.map((mail: object): Email => plainToClass(Email, mail)));
}

export function fetchEmailAttachments(id: string): Promise<string[]> {
    return api.get(`${baseUrl}/mailer/emails/${id}/attachments`)
        .then(response => response.data);
}

export function starEmail(email: Email, body: any): Promise<Email> {
    return api.post(`${baseUrl}/mailer/emails/` + email.id + `/starred`, body)
        .then(response => plainToClass(Email, response.data));
}

export function setEmailCustomer(email: Email, customer: string): Promise<Email> {
    return api.post(`${baseUrl}/mailer/emails/` + email.id + `/customer`, { customer })
        .then(response => plainToClass(Email, response.data));
}

export function sendEmail(email: Email): Promise<Email> {
    return api.post(`${baseUrl}/mailer/emails`, email)
        .then(response => plainToClass(Email, response.data));
}

export function getCalls(): Promise<Call> {
    return api.get(`${baseUrl}/customers/calls`)
        .then(response => response.data.map((call: object) => plainToClass(Call, call)));
}

export function readEmail(email: Email): Promise<Email> {
    return api.post(`${baseUrl}/mailer/emails/` + email.id + `/read`)
        .then(response => plainToClass(Email, response.data));
}

export function addTenant(name: string): Promise<Tenant> {
    return api.post(`${baseUrl}/tenants`, { name })
        .then(response => plainToClass(Tenant, response.data));
}

export function getTenant(id: string): Promise<Tenant> {
    return api.get(`${baseUrl}/tenants/` + id)
        .then(response => plainToClass(Tenant, response.data));
}

export function addUserToTenant(user: string, tenantId: string): Promise<User> {
    return api.post(`${baseUrl}/tenants/` + tenantId + `/users`, { user })
        .then(response => plainToClass(User, response.data));
}

export function deleteUserFromTenant(user: string, tenantId: string): Promise<User> {
    return api.delete(`${baseUrl}/tenants/` + tenantId + `/users/` + user)
        .then(response => plainToClass(User, response.data));
}

export function getInvoicePaymentPortalInfo(id: string, token: string): Promise<InvoicePaymentPortalInfo> {
    return api.get(`${baseUrl}/payportal/` + id + `/` + token)
        .then(response => plainToClass(InvoicePaymentPortalInfo, response.data));
}

export function getPaymentIdealIssuers(id: string): Promise<InvoicePaymentIdealIssuer[]> {
    return api.get(`${baseUrl}/payportal/${id}/ideal-issuers`)
        .then(response => response.data.map((issuer: object): InvoicePaymentIdealIssuer => plainToClass(InvoicePaymentIdealIssuer, issuer)));
}

export function createIdealPayment(id: string, token: string, issuer: string, origin: string): Promise<{ url: string, tracker: string }> {
    return api.get(`${baseUrl}/payportal/${id}/${token}/ideal-payment/${issuer}?origin=${origin}`)
        .then(response => response.data);
}

export function trackPayment(id: string, tracker: string): Promise<string> {
    return api.get(`${baseUrl}/payportal/${id}/track/${tracker}`)
        .then(response => response.data.status);
}

export function getCustomers(): Promise<Customer[]> {
    return api.get(`${baseUrl}/customers`)
        .then(response => response.data.map((customer: object): Customer => plainToClass(Customer, customer)));
}

export function getPersons(): Promise<Person[]> {
    return api.get(`${baseUrl}/persons`)
        .then(response => response.data.map((person: object): Person => plainToClass(Person, person)));
}

export function getCustomerPersons(id: string): Promise<Person[]> {
    return api.get(`${baseUrl}/customers/${id}/persons`)
        .then(response => response.data.map((person: object): Person => plainToClass(Person, person)));
}
export function getCustomerComputer(id: string, cid: string): Promise<Computer> {
    return api.get(`${baseUrl}/customers/${id}/computers/${cid}`)
        .then(response => plainToInstance(Computer, response.data));
}


export function createPerson(person: Person): Promise<Person> {
    return api.post(`${baseUrl}/persons`, person)
        .then((response): Person => plainToClass(Person, response.data));
}

export function updatePerson(person: Person): Promise<Person> {
    return api.put(`${baseUrl}/persons/${person.id}`, person)
        .then((response): Person => plainToClass(Person, response.data));
}


export function linkPersonToCustomer(person_id: string, customer_id: string): Promise<Person> {
    return api.put(`${baseUrl}/customers/${customer_id}/persons/${person_id}`, {})
        .then((response): Person => plainToClass(Person, response.data));
}

export function deletePerson(person: Person, customer: string): Promise<string> {
    return api.delete(`${baseUrl}/persons/${person.id}?customer=${customer}`)
        .then((): string => person.id);
}

export function callPerson(person: Person, tenant: string): Promise<string> {
    return api.post(`${baseUrl}/persons/${person.id}/call?tenant=${tenant}`);
}

export function getExpenses(): Promise<Expense[]> {
    return api.get(`${baseUrl}/expenses`)
        .then(response => response.data.map((expense: object): Expense => plainToClass(Expense, expense)));
}

export function getExpenseAttachments(id: string): Promise<ExpenseAttachment[]> {
    return api.get(`${baseUrl}/expenses/${id}/attachments`)
        .then(response => response.data.map((attachment: object): ExpenseAttachment => plainToClass(ExpenseAttachment, attachment)));
}

export function addExpenseAttachment(id: string, name: string, content: string): Promise<ExpenseAttachment[]> {
    return api.post(`${baseUrl}/expenses/${id}/attachments`, { name, content })
        .then(response => response.data.map((attachment: object): ExpenseAttachment => plainToClass(ExpenseAttachment, attachment)));
}

export function deleteExpenseAttachment(id: string, aid: string): Promise<ExpenseAttachment[]> {
    return api.delete(`${baseUrl}/expenses/${id}/attachments/${aid}`)
        .then(response => response.data.map((attachment: object): ExpenseAttachment => plainToClass(ExpenseAttachment, attachment)));
}

export function addExpense(expense: Expense): Promise<Expense> {
    return api.post(`${baseUrl}/expenses`, expense)
        .then(response => plainToClass(Expense, response.data));
}

export function editExpense(expense: Expense): Promise<Expense> {
    return api.post(`${baseUrl}/expenses/` + expense.id, expense)
        .then(response => plainToClass(Expense, response.data));
}

export function getCustomer(id: string): Promise<Customer> {
    return api.get(`${baseUrl}/customers/` + id)
        .then(response => plainToClass(Customer, response.data));
}

export function addCustomer(customer: Customer): Promise<Customer> {
    return api.post(`${baseUrl}/customers`, customer)
        .then(response => plainToClass(Customer, response.data));
}

export function vatCheck(vatNumber: string): Promise<any> {
    return api.get(`${baseUrl}/customers/vat_check/${vatNumber}`)
        .then(response => response.data);
}

export function editCustomer(customer: Customer): Promise<Customer> {
    return api.put(`${baseUrl}/customers/` + customer.id, customer)
        .then(response => plainToClass(Customer, response.data));
}

export function getInvoices(): Promise<Invoice[]> {
    return api.get(`${baseUrl}/invoices`)
        .then(response => response.data.map((invoice: object): Invoice => plainToClass(Invoice, invoice)));
}

export function getOrphanedPayments(): Promise<InvoicePayment[]> {
    return api.get(`${baseUrl}/payments/orphaned`)
        .then(response => response.data.map((payment: object): InvoicePayment => plainToClass(InvoicePayment, payment)));
}

export function linkOrphanedPayment(payment: string, invoice: string): Promise<Invoice> {
    return api.post(`${baseUrl}/payments/orphaned/${payment}`, { invoice })
        .then(response => plainToClass(Invoice, response.data));
}

export function getInvoice(id: string): Promise<Invoice> {
    return api.get(`${baseUrl}/invoices/${id}`)
        .then(response => plainToClass(Invoice, response.data));
}

export function getInvoiceExport(id: string): Promise<string> {
    return api.get(`${baseUrl}/invoices/${id}/export/pdf`)
        .then(response => response.data.pdf);
}

export function addInvoice(i: Invoice): Promise<Invoice> {
    return api.post(`${baseUrl}/invoices`, i)
        .then(response => plainToClass(Invoice, response.data));
}

export function addInvoiceContent(c: InvoiceContent, invoiceId: string): Promise<Invoice> {
    return api.post(`${baseUrl}/invoices/` + invoiceId + `/content`, c)
        .then(response => plainToClass(Invoice, response.data));
}


export function deleteInvoiceContent(i: Invoice, contentId: string): Promise<Invoice> {
    return api.delete(`${baseUrl}/invoices/` + i.id + `/content/` + contentId)
        .then(response => plainToClass(Invoice, response.data));
}

export function deleteInvoice(i: Invoice): Promise<any> {
    return api.delete(`${baseUrl}/invoices/` + i.id)
        .then(response => i.id);
}

export function deleteEmail(email: Email): Promise<any> {
    return api.delete(`${baseUrl}/mailer/emails/` + email.id)
        .then(response => email.id);
}

export function convertInvoice(i: Invoice): Promise<Invoice> {
    return api.post(`${baseUrl}/invoices/` + i.id + `/convert`)
        .then(response => plainToClass(Invoice, response.data));
}

export function finalizeInvoice(i: Invoice): Promise<Invoice> {
    return api.post(`${baseUrl}/invoices/` + i.id + `/finalize`)
        .then(response => plainToClass(Invoice, response.data));
}


export function editBasicDataInvoice(d: any): Promise<Invoice> {
    return api.post(`${baseUrl}/invoices/` + d.id, d)
        .then(response => plainToClass(Invoice, response.data));
}


export function getRecurrings(): Promise<Recurring[]> {
    return api.get(`${baseUrl}/invoices/recurrings`)
        .then(response => response.data.map((recurring: object): Recurring => plainToClass(Recurring, recurring)));
}

export function addRecurring(recurring: Recurring): Promise<Recurring> {
    return api.post(`${baseUrl}/invoices/recurrings`, recurring)
        .then(response => plainToClass(Recurring, response.data));
}


export function deleteRecurring(recurring: Recurring): Promise<string> {
    return api.delete(`${baseUrl}/invoices/recurrings/${recurring.id}`)
        .then((): string => recurring.id);
}

export function getServices(): Promise<Service[]> {
    return api.get(`${servicesUrl}/services`)
        .then(response => response.data.map((service: object): Service => plainToClass(Service, service)));
}

export function addService(service: Service): Promise<Service> {
    return api.post(`${servicesUrl}/services`, service)
        .then(response => plainToClass(Service, response.data));
}

export function editService(service: Service): Promise<Service> {
    return api.put(`${servicesUrl}/services/` + service.id, service)
        .then(response => plainToClass(Service, response.data));
}

export function removeService(service: Service): Promise<Service> {
    return api.delete(`${servicesUrl}/services/` + service.id)
        .then(response => plainToClass(Service, response.data));
}

export function getService(id: string): Promise<Service> {
    return api.get(`${servicesUrl}/services/` + id)
        .then(response => plainToClass(Service, response.data));
}

export function getServiceHistory(id: string): Promise<ServiceHistory> {
    return api.get(`${servicesUrl}/services/${id}/history`)
        .then(response => response.data);
}

export default api;