import React from "react";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { EmailState } from "store/reducers/Emails";
import { Helmet } from "react-helmet";

interface Props {
  title: string;
  hideTitle?: boolean;
  emailState: EmailState;
}

const PageMeta: React.FC<Props> = ({ emailState, title, hideTitle }: Props) => {
  const unread = emailState.getEmails().filter(email => email.direction === "in" && !email.IsRead()).length;
  const currentIcon = document.querySelector("link[rel*='icon']");

  if (unread > 0) {
    //@ts-ignore
    currentIcon.href = "/mail.ico"
  } else {
    //@ts-ignore
    currentIcon.href = "/logo.ico"
  }

  if (unread > 0) {
    title = `(${unread}) ${title}`
  }

  if (hideTitle === undefined || !hideTitle) {
    title = `${title} | Office | Galactic Group`
  }


  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    emailState: state.Emails
  };
};

export default connect(mapStateToProps)(PageMeta);