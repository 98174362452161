import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getInvoices } from "store/actions/Billing";
import { bindActionCreators } from "redux";
import { Customer } from "models/Customer";
import { RootState } from "store/store";
import { Card, CardBody } from "reactstrap";
import { getEmails, sendEmail } from "store/actions/Emails";
import { Email } from "models/Email";
import { getCustomers, getPersons } from "store/actions/Customers";
import { Invoice } from "models/Invoice";
import { EmailState } from 'store/reducers/Emails';
import { CustomerState } from 'store/reducers/Customers';
import { InvoiceState } from 'store/reducers/Invoices';
import { DroppedFile } from "components/DropzoneCustom";
import { UuidNil } from "../../common/uuid";
import { PersonsState } from "../../store/reducers/Persons";

interface Props {
    reply_id: string;
    customerState: CustomerState;
    personsState: PersonsState;
    invoiceState: InvoiceState;
    emailState: EmailState;
}

interface Actions {
    getInvoices: () => void;
    sendEmail: (email: Email) => Promise<Email>;
    getCustomers: typeof getCustomers;
    getPersons: typeof getPersons;
}

const WhatsappCompose: React.FC<Props & Actions> = ({
    personsState,
    getPersons,
    invoiceState,
    emailState,
    customerState,
    getInvoices,
    sendEmail,
    getCustomers,
    reply_id
}) => {
    const [selectedFiles,] = useState<DroppedFile[]>([]);
    const [email, setEmail] = useState(new Email())
    const [, setCustomer] = useState<Customer>()
    const [, setInvoice] = useState<Invoice | null>()
    const [cc, setCc] = useState<string[]>([])

    const [attachments, setAttachments] = useState<string[]>([])

    const emailReply = emailState.getEmailById(reply_id || "");

    const setReply = React.useCallback(() => {

        if (reply_id && emailReply) {
            const mail = new Email();

            mail.reply_to = reply_id;
            mail.subject = emailReply.subject;
            if (!emailReply.subject.startsWith("RE: ")) {
                mail.subject = "RE: " + emailReply.subject;
            }
            mail.tenant = emailReply.tenant;
            mail.reference = emailReply.reference;
            mail.recipient = emailReply.sender_name + " <" + emailReply.sender + ">";
            mail.carbon_copy = emailReply.carbon_copy;

            if (mail.carbon_copy && mail.carbon_copy !== "") {
                setCc([...mail.carbon_copy.split(", ")])
            }

            var customer = customerState.getCustomerById(emailReply.customer);

            if (!customer) {
                // customer = customerState.getCustomers().find(customer => customer.persons.some(item => item.email === mail.recipient)) || null;

            }

            if (!customer) {
                customer = customerState.getCustomers().find(x => x.email === emailReply.recipient) || null;

            }

            if (!customer) {
                mail.customer = UuidNil;
            }

            if (customer) {
                mail.customer = customer.id
                setCustomer(customer)
            }

            const invoice = invoiceState.getInvoiceById(emailReply.reference.replace('invoice_', ''));


            setInvoice(invoice);
            setEmail(mail);
        }

    }, [customerState, emailReply, invoiceState, reply_id, setEmail, setCc]);



    useEffect(() => {
        getCustomers()
        getInvoices()
        getPersons()
    }, [getCustomers, getInvoices, getPersons])

    useEffect(() => {
        setReply()
    }, [setReply])


    const nl2br = (str: string, replaceMode: boolean = true, isXhtml: boolean = true) => {
        var breakTag = (isXhtml) ? '<br />' : '<br>';
        var replaceStr = (replaceMode) ? '$1' + breakTag : '$1' + breakTag + '$2';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
    }

    return (
        <>
            <Card>
                <CardBody>
                    <div>
                        <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px", display: "inline-block" }}>Message</p>
                        <span className="badge bg-success rounded-pill" style={{ display: "inline-block", marginLeft: "3px" }}>
                            <i className={`bx bxl-whatsapp`} />
                        </span>
                    </div>
                    <div>

                        <div className="form-floating mb-3">
                            <textarea rows={4} className="form-control" style={{ height: "100px" }}
                                defaultValue={email.body} onChange={e => {
                                    email.body = e.target.value
                                    setEmail(email)
                                }} />
                            <label className="">Body</label>
                        </div>

                        <button
                            type="button"
                            disabled={email.customer === undefined || email.tenant === undefined}
                            onClick={() => {
                                var ccString = "";
                                cc.forEach((email) => {
                                    ccString += (email + ", ");
                                });
                                var finalCC = ccString.substring(0, ccString.length - 2);
                                email.carbon_copy = finalCC;

                                if (selectedFiles.length > 0) {
                                    setAttachments([...[]])
                                    selectedFiles.forEach((file) => {
                                        attachments.push(file.name + "*" + file.content);
                                    })
                                    setAttachments([...attachments])
                                    email.attachments = attachments;
                                    setEmail(email)
                                }

                                if (emailReply != null && emailReply.type === "whatsapp") {
                                    email.type = "whatsapp"
                                }

                                const newEmail = JSON.parse(JSON.stringify(email))
                                newEmail.body = nl2br(email.body)
                                // console.log(newEmail)
                                sendEmail(newEmail)
                            }}
                            className="btn btn-primary w-100 waves-effect waves-light bg-green"
                        >
                            <div style={{ display: "inline-block", fontSize: "15px" }}>Send</div>
                        </button>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        personsState: state.Persons,
        emailState: state.Emails,
        customerState: state.Customers,
        invoiceState: state.Invoices
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ sendEmail, getPersons, getCustomers, getInvoices, getEmails }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(WhatsappCompose);
