import React, { useEffect, useState } from "react"
import { Button, ButtonDropdown, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import { Email } from 'models/Email';
import { Link, useNavigate } from "react-router-dom";
import { deleteEmail, readEmail, setEmailCustomer, starEmail } from "store/actions/Emails";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { GetRouterQuery } from "components/Utils/GetRouterQuery";
import { EmailState } from 'store/reducers/Emails';
import { b64toBlob } from 'common/base64toblob';
import prettyBytes from 'pretty-bytes';
import { fetchEmailAttachments } from "services/Api";
import LongClickButton from "../../components/LongClickButton";
import { Customer } from "../../models/Customer";
import { CustomerState } from "../../store/reducers/Customers";
import { getCustomers } from "../../store/actions/Customers";
import { UuidNil } from "../../common/uuid";

interface Props {
    id: string;
    emailState: EmailState;
    customers: CustomerState;
    withoutReplies?: boolean;
    withoutFollowUps?: boolean;
}

interface Actions {
    getCustomers: () => void;
    readEmail: (email: Email) => void;
    deleteEmail: (email: Email) => Promise<any>;
    starEmail: (e: Email, b: any) => void;
    setEmailCustomer: (e: Email, customer: string) => Promise<Email>;
}

const EmailCardBase: React.FC<Props & Actions> = ({
    setEmailCustomer,
    getCustomers,
    deleteEmail,
    emailState,
    customers,
    id,
    starEmail,
    readEmail,
    withoutReplies,
    withoutFollowUps
}) => {
    const [showQuote, setShowQuote] = useState(false)
    const [attachments, setAttachments] = useState<string[]>([])
    const [dropNew, setDropNew] = useState(false);
    const [suggestedCustomers, setSuggestedCustomers] = useState<Customer[]>([])

    const navigate = useNavigate();

    useEffect(() => {
        fetchEmailAttachments(id).then(attachments => setAttachments(attachments !== null ? attachments : []))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const email = emailState.getEmailById(id);

    useEffect(() => {
        if (email === null) {
            return
        }
        if (email.customer === UuidNil && email.direction === "in" && !customers.isFetched()) {
            getCustomers()
        }

        if (customers.isFetched()) {
            const suggested = customers.findByEmail(email.sender)
            if (suggested.length > 0) {
                setSuggestedCustomers(suggested);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customers, email])


    if (email === null) {
        return <></>;
    }

    const viewingId = GetRouterQuery<{ id: string }>().id

    const star = () => {
        if (email.starred) {
            starEmail(email, { "starred": false })
        } else {
            starEmail(email, { "starred": true })
        }
    }


    let cleanBody = ""
    let rawBody = email.body
        .replace(/(\r\n|\n|\r)/gm, "")
        .replaceAll(/<img(.*?)>/img, "")
        .replaceAll(/<style(.*?)>(.*?)<\/style>/img, "")
        .replaceAll(/style="(.*?)"/img, "")
    if (!showQuote) {
        cleanBody = rawBody.replaceAll(/<blockquote (.*?)>(.*?)<\/blockquote>/img, "")
    } else {
        cleanBody = rawBody
    }


    const followUpEmails = emailState.getEmails().filter(email => email.reply_to === id)
    return (
        <>
            {!withoutFollowUps && followUpEmails.length > 0 &&
                <>
                    {followUpEmails
                        .sort((a, b) => (a.date < b.date) ? 1 : (a.date > b.date) ? -1 : 0).map(followUp => {
                            return <EmailCard id={followUp.id} withoutReplies={true} />
                        })}
                </>
            }

            <Card style={viewingId === id ? { borderLeft: "1px solid blue" } : {}}>
                <CardBody>

                    <div style={{ float: "right", textAlign: "right" }} className="text-muted font-size-14">
                        {email.GetDate().toDateString()}{" "}
                        {email.GetDate().getHours() < 10 && "0"}
                        {email.GetDate().getHours()}:
                        {email.GetDate().getMinutes() < 10 && "0"}
                        {email.GetDate().getMinutes()}
                        <span className="badge bg-gray font-size-12  mb-1">
                            {email.status.replace(/[\d()]/g, "").toUpperCase()}
                        </span><br />

                        {suggestedCustomers.length > 0 &&
                            <ButtonDropdown
                                isOpen={dropNew}
                                toggle={() =>
                                    setDropNew(!dropNew)
                                }>
                                <DropdownToggle
                                    caret
                                    color="primary"
                                    className="btn btn-primary">
                                    <i className="mdi mdi-link" /> Link
                                    <i className="mdi mdi-chevron-down" />
                                </DropdownToggle>
                                <DropdownMenu>
                                    {suggestedCustomers.map(customer => <DropdownItem key={customer.id} onClick={() => {
                                        setEmailCustomer(email, customer.id).then(() => {
                                            setSuggestedCustomers([]);
                                        })
                                    }}>
                                        {customer.name}
                                    </DropdownItem>
                                    )}
                                </DropdownMenu>
                            </ButtonDropdown>
                        }

                        {email.direction === "in" &&
                            <Link to={"/conversations/compose?reply=" + email.id}>
                                <Button
                                    type="button"
                                    color="primary"
                                    className="waves-light waves-effect"
                                    style={{ marginLeft: "3px" }}
                                >
                                    <i className="mdi mdi-reply" /> Reply
                                </Button>
                            </Link>
                        }

                        {email.direction === "in" && (email.reader === null || email.reader.length === 0) &&
                            <Button
                                type="button"
                                color="primary"
                                className="waves-light waves-effect btn-success"
                                onClick={() => {
                                    readEmail(email)
                                }}
                                style={{ marginLeft: "3px" }}
                            >
                                <i className="mdi mdi-check" /> Mark as read
                            </Button>
                        }
                        <LongClickButton
                            color="danger"
                            style={{ marginLeft: "3px" }}
                            onClick={() => {
                                deleteEmail(email).then(() => {
                                    navigate(email.direction === "in" ? "/conversations/inbox" : "/conversations/sent")
                                })
                            }}>
                            Delete
                        </LongClickButton>
                    </div>

                    <div style={{ alignItems: "center", flexDirection: "row" }}>

                        {
                            email.starred ?
                                <span onClick={() => {
                                    star()
                                }} className={"star-toggle text-warning bx bxs-star"} />
                                :
                                <span onClick={() => {
                                    star()
                                }} className={"star-toggle bx bx-star"} />
                        }
                        <h4 style={{ marginLeft: "5px", justifyContent: "flex-start", display: "inline-block" }}>
                            {viewingId === id ?
                                <span style={{ marginRight: "5px" }}>{email.subject}</span>
                                :
                                <Link to={`/conversation/${email.id}`}>
                                    <span style={{ marginRight: "5px" }}>{email.subject}</span>
                                </Link>
                            }
                            {email.IsRead() ?
                                <span title={new Date(email.reader[0].date).toString()}
                                    className="badge bg-success me-1 font-size-12 me-2 mb-3">
                                    Read
                                </span>
                                : <span className="badge bg-warning me-1 font-size-12 me-2 mb-3">
                                    Unread
                                </span>
                            }
                            {email.reference.includes("invoice") &&
                                <Link to={"/invoice/" + email.reference.replace('invoice_', '')}>
                                    <span className="badge bg-success me-1 font-size-12 me-2 mb-3">
                                        Invoice
                                    </span>
                                </Link>
                            }
                            <br />
                            {email.reference.includes("ticket") &&
                                <span className="badge bg-danger me-1 font-size-12 me-2 mb-3">
                                    Ticket
                                </span>
                            }
                        </h4>
                    </div>

                    <div className="d-flex align-items-start mb-4">
                        {
                            email.customer !== UuidNil ?
                                <Link to={`/customers/${email.customer}`}>
                                    <div className="avatar-sm mx-auto mb-2">
                                        <span className="avatar-title rounded-circle bg-light font-size-24">
                                            <i className={`rounded-circle mdi mdi-alpha-${email.sender_name.charAt(0).toLowerCase()}-circle text-primary`}
                                                style={{ fontSize: "55px" }} />
                                        </span>
                                    </div>
                                </Link>
                                :
                                <div className="avatar-sm mx-auto mb-2">
                                    <span className="avatar-title rounded-circle bg-light font-size-24">
                                        <i className={`rounded-circle mdi mdi-alpha-${email.sender_name.charAt(0).toLowerCase()}-circle text-primary`}
                                            style={{ fontSize: "55px" }} />
                                    </span>
                                    {
                                        email.type !== "whatsapp" ? <span className="badge bg-purple rounded-pill email">
                                            <i className={`bx bx-mail-send`} />
                                        </span> :
                                            <span className="badge bg-success rounded-pill whatsapp">
                                                <i className={`bx bxl-whatsapp`} />
                                            </span>
                                    }

                                </div>
                        }
                        <div className="flex-1" style={{ marginLeft: "20px" }}>
                            <h5 className="font-size-14 my-1" title={`${email.sender_name} <${email.sender}>`}>
                                {email.direction === "in" ?
                                    <>From: {email.sender_name} {'<'}{email.sender}{'>'} </>
                                    :
                                    <>To: {email.recipient}</>
                                }

                            </h5>
                            <small className="text-muted">
                                {email.direction === "in" ?
                                    <>To: {email.recipient}</>
                                    :
                                    <>From: {email.sender_name} {'<'}{email.sender}{'>'} </>
                                }
                            </small>
                            <br />
                            <small className="text-muted">
                                {email.carbon_copy ?
                                    <>Cc: {email.carbon_copy}</>
                                    :
                                    <></>
                                }
                            </small>
                        </div>
                    </div>

                    <p className="email_body" dangerouslySetInnerHTML={{ __html: cleanBody }} />

                    {(cleanBody !== rawBody) &&
                        <>
                            {showQuote ?
                                <div className="text-muted" style={{ cursor: "pointer" }}
                                    onClick={() => setShowQuote(false)}>Hide quoted</div>
                                :
                                <div className="text-muted" style={{ cursor: "pointer" }}
                                    onClick={() => setShowQuote(true)}>Show quoted</div>
                            }
                        </>
                    }

                    <div className="dropzone-previews mt-3" id="file-previews">
                        {attachments.map((f, i) => {
                            const name = f.split("*")[0]
                            const content = f.split("*")[1]
                            if (f.split("*").length !== 2) {
                                return <></>
                            }
                            const blob = b64toBlob(content.split(";base64,")[1], content.split(";")[0].split(":")[1]);
                            const blobUrl = URL.createObjectURL(blob);
                            return (
                                <Col lg={3} key={name}>
                                    <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                    >
                                        <div className="p-2">
                                            <a target={"_blank"} rel="noreferrer" href={blobUrl}>
                                                {
                                                    name
                                                } ({prettyBytes(blob.size)})
                                            </a><br />
                                            {
                                                (name.includes("png") || name.includes("svg") || name.includes("jpg") || name.includes("jpeg")) ?
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"

                                                        alt={name}
                                                        src={content}
                                                    />
                                                    :
                                                    <>
                                                        <p className="mb-0">
                                                            Preview unavailable
                                                        </p>
                                                    </>
                                            }
                                        </div>
                                    </Card>
                                </Col>
                            );
                        })}
                    </div>
                </CardBody>
            </Card>
            {(!withoutReplies && email.reply_to !== UuidNil) &&
                <EmailCard id={email.reply_to} withoutFollowUps={true} />
            }
        </>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        customers: state.Customers,
        emailState: state.Emails,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ setEmailCustomer, getCustomers, deleteEmail, readEmail, starEmail }, dispatch);
};


const EmailCard = connect(mapStateToProps, mapDispatchToProps)(EmailCardBase);

export default EmailCard;