import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { Row, Col, Alert, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { getServices } from "../../store/actions/Services";
import { RootState } from "../../store/store";
import { addService } from "store/actions/Services";
import { useNavigate } from "react-router";
import { Service } from "models/Service";
import { ServicesState } from "store/reducers/Services";
import CheckManagementCardAdd from "./AddFormElements/CheckManagementCardAdd";


interface Props {
    serviceState: ServicesState;
}

interface Actions {
    getServices: () => void;
    addService: (service: Service) => void;
}

const AddServiceForm: React.FC<Props & Actions> = ({ serviceState, getServices, addService }) => {
    const [service, setService] = useState(new Service())
    const [error, setError] = useState<string | null>(null)


    const navigate = useNavigate();

    useEffect(() => {
        getServices()
    }, [getServices])


    const Save = () => {
        if (
            (service.name !== "" && service.name !== undefined)
            && (service.region !== "" && service.region !== undefined)
        ) {

            addService(service)
                //@ts-ignore
                .then((service) => {
                    navigate("/services/" + service.id);
                })
                .catch(() => {
                    setError("Adding service has failed")
                });

        } else {
            setError("Name & region are mandatory")
        }


    }



    return (
        <>
            <div className="page-content">

                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h2 className="mb-0 font-size-120">Add new service</h2>
                </div>

                {
                    error &&
                    <Alert color="danger">{error}</Alert>
                }

                <Row>
                    <Col md={6} xl={6}>
                        <Card>
                            <CardBody>
                                <div>
                                    <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>Details</p>
                                </div>
                                <div className="dropdown-divider"></div>


                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder="customer" onInput={e => {
                                        service.name = e.currentTarget.value
                                        setService(service)
                                    }} />
                                    <label className="">Name</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="email" className="form-control" onInput={e => {
                                        service.region = e.currentTarget.value
                                        setService(service)
                                    }} placeholder="Region" />
                                    <label className="">Region</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="email" className="form-control" onInput={e => {
                                        service.tenant = e.currentTarget.value
                                        setService(service)
                                    }} placeholder="Region" />
                                    <label className="">Tenant</label>
                                </div>

                                <div className="mt-3">
                                    <button
                                        id="buttonCustomer"
                                        name="buttonCustomer"
                                        className="btn btn-primary w-100 waves-effect waves-light"
                                        type="button"
                                        onClick={() => {
                                            Save()
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} xl={6}>
                        <CheckManagementCardAdd service={service} setService={setService} />
                    </Col>
                </Row>

            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        servicesState: state.Services
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getServices, addService }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddServiceForm);
