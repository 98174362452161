import { LOGOUT_ACCOUNT, SET_TOKEN } from "storeAccount/actions/Auth";
import { createReducer } from "./Util";

export type AuthenticationState = string;

const logOut = () => {
  return ""
}

const setToken = (payload: any) => {
  return payload
}

const Authentication = createReducer<AuthenticationState>("")
  .handle<string>([SET_TOKEN.SUCCESS], (state, payload) => setToken(payload))
  .handle([LOGOUT_ACCOUNT.SUCCESS], () => logOut());

export default Authentication.handler;
