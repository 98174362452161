import {Tenant, Tenant as TenantModel} from "models/Tenant";
import {GET_TENANT, GET_TENANTS} from "store/actions/Tenants";
import {LOGOUT_USER_ACCOUNT} from "store/actions/Auth";
import {createReducer} from "./Util";
import {ADD_TENANT} from "store/actions/Tenant";


export class TenantState {

  private tenants: { [index: string]: Tenant }

  constructor() {
    this.tenants = {}
  }

  public setTenants(tenants: Tenant[]) {
    this.tenants = {}
    tenants.forEach(tenant => {
      this.setTenant(tenant)
    })
    return this
  }

  public setTenant(tenant: Tenant) {
    this.tenants[tenant.id] = tenant
    return this
  }

  public getTenantById(id: string): Tenant | null {
    return this.tenants[id] ?? null
  }

  public getTenants(): Tenant[] {
    return Object.values(this.tenants)
  }

}

const Tenants = createReducer<TenantState>(new TenantState())
  .handle<TenantModel>([ADD_TENANT.SUCCESS], (state, payload) => state.setTenant(payload))
  .handle<TenantModel>([GET_TENANT.SUCCESS], (state, payload) => state.setTenant(payload))
  .handle<TenantModel[]>([GET_TENANTS.SUCCESS], (state, payload) => state.setTenants(payload))
  .handle([LOGOUT_USER_ACCOUNT.SUCCESS], () => new TenantState());

export default Tenants.handler;
