import CustomerName from "components/CustomerName";
import PageMeta from "components/PageMeta";
import WaitingDots from "components/WaitingDots";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { bindActionCreators } from "redux";
import { getCustomers, getPersons } from "store/actions/Customers";
import { PersonsState } from "store/reducers/Persons";
import { RootState } from "../../store/store";

interface Props {
    personState: PersonsState;
}

interface Actions {
    getPersons: () => void;
    getCustomers: () => void;
}

const Persons: React.FC<Props & Actions> = ( {
                                                 personState,
                                                 getPersons,
                                                 getCustomers
                                             } ) => {

    const [ nameKeyword, setNameKeyword ] = useState( "" )

    useEffect( () => {
        getPersons()
        getCustomers()
    }, [] )

    if ( personState.getPersons().length === 0 ) {
        return <WaitingDots/>
    }

    const filteredPersons = personState.getPersons().filter( person => person.name.toLowerCase().includes( nameKeyword.toLowerCase() ) || person.last_name.toLowerCase().includes( nameKeyword.toLowerCase() ) || person.email.toLowerCase().includes( nameKeyword.toLowerCase() ) || person.phone.toLowerCase().includes( nameKeyword.toLowerCase() ) )

    return ( <div className="page-content">
        <PageMeta title={ `Persons` }/>
        <Row>
            <Col md={ 12 } xl={ 12 }>
                <Row>
                    <Col md={ 12 }>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <form className="app-search" style={ { marginLeft: "5px" } }>
                                <div className="position-relative">
                                    <input type="text"
                                           name="search" id="search" key="search" className="form-control"
                                           value={ nameKeyword }
                                           onChange={ ( e ) => {
                                               setNameKeyword( e.target.value )
                                           } }
                                           placeholder="Search..."/>
                                    <span className="mdi mdi-magnify"></span>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 12 }>
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    <table
                                        className="table table-hover table-borderless align-middle table-centered table mb-0">
                                        <thead className="table-light">
                                        <tr>
                                            <th>ID</th>
                                            <th>First name</th>
                                            <th>Last name</th>
                                            <th>Customers</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Auth</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        { filteredPersons.length > 0 && filteredPersons
                                            .sort( ( a, b ) => a.id.localeCompare( b.id ) )
                                            .map( person => <tr key={ person.id }>
                                                <td>{ person.id }</td>
                                                <td>
                                                    { person.name }
                                                </td>
                                                <td>
                                                    { person.last_name }
                                                </td>
                                                <td>
                                                    { person.customers.map( account => <>
                                                        <Link to={ "/customers/" + account }>
                                                                <span
                                                                    className="badge bg-primary me-1 font-size-12 me-2 mb-3"><CustomerName id={ account }/></span>
                                                        </Link>
                                                        <br/>
                                                    </> ) }
                                                </td>
                                                <td>{ person.email }</td>
                                                <td>{ person.phone }</td>
                                                <td>
                                                    { ( person.auth !== null ) ? <>
                                                        { ( person.auth.mfa_active ) ?
                                                            <span className="badge bg-success me-1 font-size-12 me-2"
                                                                  style={ { marginLeft: "5px" } }>
                            MFA: Active
                        </span> : <span className="badge bg-warning me-1 font-size-12 me-2"
                                        style={ { marginLeft: "5px" } }>
                            MFA: Inactive
                        </span> }
                                                    </> : <span className="badge bg-danger me-1 font-size-12 me-2"
                                                                style={ { marginLeft: "5px" } }>
                            No auth login
                        </span> }
                                                </td>

                                            </tr> ) }
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div> )
}

const mapStateToProps = ( state: RootState ) => {
    return {
        personState: state.Persons,
    };
};

const mapDispatchToProps = ( dispatch: any ) => {
    return bindActionCreators( { getPersons, getCustomers }, dispatch );
};

export default connect( mapStateToProps, mapDispatchToProps )( Persons )