import WaitingDots from 'components/WaitingDots';
import {Customer} from 'models/Customer';
import React from 'react';
import {Link} from 'react-router-dom';
import CustomerOpenInvoicesButton from './CustomerOpenInvoicesButton';

interface Props {
    customer: Customer;
}

const CustomerRow: React.FC<Props> = ({ customer }) => {
    if (customer === undefined) {
        return <WaitingDots />
    }

    return (
        <tr key={customer.id}>
            <td><span style={{ display: 'block', width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}><Link to={`/customers/${customer.id}`}>{customer.name}</Link></span></td>
            <td><a href={`mailto:${customer.email}`}>{customer.email}</a></td>
            <td>
                <CustomerOpenInvoicesButton customer={customer} />
            </td>
            <td>
                <button type="button" disabled className="btn btn-sm btn-outline-danger">
                    Tickets
                    <span className="badge bg-danger ms-1">2</span>
                </button>
            </td>
        </tr>
    );
}

export default CustomerRow;