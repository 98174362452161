import { LOGOUT_ACCOUNT } from "storeAccount/actions/Auth";
import { createReducer } from "./Util";
import { Invoice } from "models/Invoice";
import {
    GET_INVOICES
} from "storeAccount/actions/Billing";
import { InvoicePayment } from "../../models/InvoicePayment";

export class InvoiceState {

    private invoices: { [index: string]: Invoice }
    private orphanedPayments: { [index: string]: InvoicePayment }

    constructor() {
        this.invoices = {}
        this.orphanedPayments = {}
    }

    public setInvoices(invoices: Invoice[]) {
        invoices.forEach(invoice => {
            this.setInvoice(invoice)
        })
        return this
    }

    public setInvoice(invoice: Invoice) {
        this.invoices[invoice.id] = invoice
        return this
    }

    public setPayment(payment: InvoicePayment) {
        this.orphanedPayments[payment.id] = payment
        return this
    }

    public deleteInvoice(id: string) {
        delete this.invoices[id]
        return this
    }

    public deletePayment(id: string) {
        delete this.orphanedPayments[id]
        return this
    }

    public getInvoiceById(id: string): Invoice | null {
        return this.invoices[id] ?? null
    }

    public getInvoices(): Invoice[] {
        return Object.values(this.invoices)
    }

    public getOrphanedPayments(): InvoicePayment[] {
        return Object.values(this.orphanedPayments)
    }

    public getInvoicesByCustomerId(customerId: string): Invoice[] {
        return Object.values(this.invoices).filter((x) => x.customer === customerId)
    }

}


const Invoices = createReducer<InvoiceState>(new InvoiceState())
    .handle<Invoice[]>([GET_INVOICES.SUCCESS], (state, payload) => state.setInvoices(payload))
    .handle([LOGOUT_ACCOUNT.SUCCESS], () => new InvoiceState());

export default Invoices.handler;