import React from "react";
import {Col, Container, Row} from "reactstrap";
import Copyright from 'components/Copyright';

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}><Copyright /></Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
