import PageMeta from 'components/PageMeta';
import TenantName from 'components/TenantName';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { getCustomers } from "store/actions/Customers";
import { getTenants } from "store/actions/Tenants";
import { CustomerState } from 'store/reducers/Customers';
import { TenantState } from 'store/reducers/Tenants';
import { RootState } from 'store/store';
import CustomerCard from './CustomerCard';

interface Props {
    customerState: CustomerState;
    tenantState: TenantState;
}

interface Actions {
    getCustomers: () => void;
    getTenants: () => void;
}

const Customers: React.FC<Props & Actions> = ({ tenantState, customerState, getCustomers, getTenants }) => {

    const [nameKeyword, setNameKeyword] = useState("")
    const [tenantFilter, setTenantFilter] = useState<string[] | null>(null)

    //@ts-ignore
    var url = new URL(window.location);
    const searchTerm = url.searchParams.get('q')


    useEffect(() => {
        getCustomers()
        getTenants()
        // eslint-disable-next-line
        if (searchTerm != undefined) {
            //@ts-ignore
            setNameKeyword(searchTerm);
        }
        // eslint-disable-next-line
    }, [getCustomers, getTenants])

    useEffect(() => {
        if (tenantState.getTenants().length > 0 && tenantFilter == null) {
            setTenantFilter(tenantState.getTenants().map(tenant => tenant.id))
        }
    }, [tenantState, tenantFilter, setTenantFilter])

    if (tenantFilter === null) {
        return <></>
    }

    const refreshSearch = () => {
        const params = new URLSearchParams(window.location.search)
        if (params.get('q') != null) {
            // @ts-ignore
            setNameKeyword(params.get('q'))
        }
    }

    const filteredCustomers = customerState
        .getCustomers()
        .filter(customer => customer.name.toLowerCase().includes(nameKeyword.toLowerCase()))
        .filter(customer => tenantFilter.includes(customer.tenant))

    return (<div className="page-content">
        <PageMeta title={`Customers`} />
        <Row>
            <Col md={12} xl={12}>
                <Row>
                    <Col md={12}>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <form className="app-search" style={{ marginLeft: "5px" }}>
                                <div className="position-relative">
                                    <input type="text"
                                        name="search" id="search" key="search" className="form-control" value={nameKeyword}
                                        onChange={(e) => {
                                            //@ts-ignore
                                            var url = new URL(window.location);
                                            url.searchParams.set('q', e.target.value);
                                            //@ts-ignore
                                            window.history.pushState({}, '', url);
                                            refreshSearch()
                                        }}
                                        placeholder="Search..." />
                                    <span className="mdi mdi-magnify"></span>
                                </div>
                            </form>
                            {tenantState.getTenants().map(tenant => {
                                return <div className="form-check form-check-inline not-mobile">
                                    <input className="form-check-input" type="checkbox" checked={tenantFilter.includes(tenant.id)} onChange={e => {
                                        if (tenantFilter.includes(tenant.id)) {
                                            setTenantFilter(tenantFilter.filter(tenantId => tenantId !== tenant.id))
                                        } else {
                                            setTenantFilter([...tenantFilter, tenant.id])
                                        }
                                    }} />
                                    <TenantName id={tenant.id} />
                                </div>
                            })}
                            <Link to="/customers/add" style={{ marginLeft: "10px", marginRight: "5px" }} className={`mobile`}>
                                <button type="button" className="btn btn-success waves-effect waves-light">
                                    Add new customer
                                </button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {filteredCustomers
                        .sort((b, a) => (a.name.toLowerCase() < b.name.toLowerCase()) ? 1 : (a.name.toLowerCase() > b.name.toLowerCase()) ? -1 : 0)
                        .map(customer => {
                            return <CustomerCard customer={customer} />
                        })}

                </Row>
            </Col>
        </Row>
    </div>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        customerState: state.Customers,
        tenantState: state.Tenants,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getCustomers, getTenants }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);