import { Type } from "class-transformer";
import { Role } from "./Role";

export class PersonAuth {
    email!: string;
    mfa_active!: boolean;
}

export class Person {

    constructor() {
        this.roles = [];
    }

    id!: string;
    name!: string;
    last_name!: string;
    title!: string;
    roles!: Role[];
    email!: string;
    phone!: string;
    customers!: string[];

    @Type(() => PersonAuth)
    auth!: PersonAuth | null;

    public getDefaultCustomerId(): string {
        return this.customers[0];
    }
}


