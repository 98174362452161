import React from "react";

const TypeButton = ( {
                         style = {},
                         showCheckbox = true,
                         text,
                         onClick,
                         selected
                     }: { style?: object, text: string, selected: boolean, showCheckbox?: boolean, onClick: () => void, } ) => {
    return <div style={ {
        width       : "calc(100% - 20px)",
        marginLeft  : "10px",
        border      : "1px solid lightblue",
        padding     : "0px",
        borderRadius: "20px",
        background  : selected ? "lightblue" : "none",
        fontSize    : "15px",
        height      : "40px",
        cursor      : "pointer",
        whiteSpace  : 'nowrap',
        overflow    : 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: showCheckbox ? "0px":"15px",
        paddingRight: "15px", ...style
    } } onClick={ onClick }>
        { showCheckbox && <div style={ {
            width       : "16px",
            height      : "16px",
            borderRadius: "8px",
            border      : selected ? "white" : "1px solid lightblue",
            background  : selected ? "orange" : "white",
            padding     : "-1px",
            paddingLeft : "4px",
            fontWeight  : "bold",
            float       : "left",
            marginTop   : "11px",
            marginLeft  : "11px",
            marginRight : "10px"
        } }/> }
        <span style={ {
            marginTop: "8px",
            display  : "inline-block"
        } }>
                            { text }
            </span>
    </div>;
}

export default TypeButton;