import React, { useState } from "react";
import { Alert } from "reactstrap";
import { deactivateMFA } from "../../../services/AccountApi";
import { checkMFA } from "../../../services/AccountApi";

interface Props {

}

interface Actions {
    setMfaSetUp: (n: number) => void;
    setMfaQr: (n: string) => void;
}


const DeleteMfa: React.FC<Props & Actions> = ({ setMfaSetUp, setMfaQr }) => {

    const [otp, setOtp] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [error, setError] = useState<string | null>(null)

    return (<>
        <>

            {
                error &&
                <div className="form-floating mb-3" style={{ width: "50%", display: "block", marginRight: "auto", marginLeft: "auto" }}>
                    <Alert color="danger">{error}</Alert>
                </div>
            }
            <p className="text-uppercase text-center" style={{ fontWeight: "lighter" }}>Deactivate MFA</p>

            <div className="form-floating mb-3" style={{ width: "50%", display: "block", marginRight: "auto", marginLeft: "auto" }}>
                <input type="text" name="otp" className="form-control" placeholder="otp" onChange={e => {
                    setOtp(e.target.value)
                }} />
                <label className="">OTP</label>
            </div>

            <div className="form-floating mb-3" style={{ width: "50%", display: "block", marginRight: "auto", marginLeft: "auto" }}>
                <input type="password" name="otp" className="form-control" placeholder="otp" onChange={e => {
                    setPassword(e.target.value)
                }} />
                <label className="">Password</label>
            </div>


            <div className="text-center">
                <button
                    id="buttonPerson"
                    name="buttonPerson"
                    type="button"
                    onClick={() => {
                        deactivateMFA(password, { "totp": otp }).then(() => {
                            checkMFA().then((code) => {
                                //@ts-ignore
                                setMfaQr(code.mfa_qr)
                                setMfaSetUp(1)
                            }).catch(() => {
                                setError("Something went wrong.... Try again. Make sure your OTP & password are correct.")
                            })
                        }).catch(() => {
                            setError("Something went wrong.... Try again. Make sure your OTP & password are correct.")
                        })
                    }}
                    className="btn btn-success w-20 waves-effect waves-light"
                >
                    CONFIRM
                </button>
                <button
                    style={{ marginLeft: "5px" }}
                    id="buttonPerson"
                    name="buttonPerson"
                    type="button"
                    onClick={() => {
                        setMfaSetUp(3)
                    }}
                    className="btn btn-danger w-20 waves-effect waves-light"
                >
                    CANCEL
                </button>
            </div>
        </>
    </>
    );
};

export default DeleteMfa;
