import WaitingDots from 'components/WaitingDots';
import React, { useEffect } from 'react';
import { RootStateAccount } from "storeAccount/accountStore";
import { getServices } from "storeAccount/actions/Services";
import { connect } from "react-redux";
import { ServicesState } from 'storeAccount/reducers/Services';
import { bindActionCreators } from 'redux';

interface Props {
    servicesState: ServicesState;
}

interface Actions {
    getServices: () => void;
}

const ServiceStatusBanner: React.FC<Props & Actions> = ({ servicesState, getServices }) => {


    useEffect(() => {
        getServices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getServices])


    if (servicesState.getServices() === undefined) {
        return <WaitingDots />
    }

    let errors = 0;

    servicesState.getServices().forEach(service => service.checks.forEach(check => {
        if (!check.last_result.ok) {
            errors = errors + 1;
        }
    }))

    return (<>
        {errors > 0 ?
            <div className="alert alert-warning fade show" role="alert" style={{ display: "flex" }}>
                <span className="bx bx-error-alt bx-sm" style={{ display: "flex" }} />
                <div style={{ display: "flex", marginLeft: '10px', fontSize: "16px", fontWeight: "lighter" }}>
                    We are experiencing minor service disruptions, see <a href={"https://status.galactic-group.nl/"} rel="noreferrer" style={{ display: "contents" }} target='_blank'>status.galactic-group.nl</a>
                </div>
            </div>
            :
            <>
                <div className="alert alert-success fade show" role="alert" style={{ display: "flex" }}>
                    <span className="bx bx-check-shield bx-sm" style={{ display: "flex" }} />
                    <div style={{ display: "flex", marginLeft: '10px', fontSize: "17px", fontWeight: "lighter" }}>
                        All systems operational, see <a href={"https://status.galactic-group.nl/"} rel="noreferrer" style={{ display: "contents" }} target='_blank'>status.galactic-group.nl</a>
                    </div>
                </div>
            </>
        }
    </>
    );
}

const mapStateToProps = (state: RootStateAccount) => {
    return {
        servicesState: state.Services
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getServices }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(ServiceStatusBanner);