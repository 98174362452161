import TenantName from 'components/TenantName';
import WaitingDots from 'components/WaitingDots';
import { Customer } from 'models/Customer';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import CustomerOpenInvoicesButton from './CustomerOpenInvoicesButton';

interface Props {
    customer: Customer;
}

const CustomerCard: React.FC<Props> = ({ customer }) => {

    const navigate = useNavigate();

    const goToDetail = (id: string) => {
        navigate("/customers/" + id);
    }

    if (customer === undefined) {
        return <WaitingDots />
    }

    return (<>
        <Col xs={12} sm={4} md={4} lg={3} xl={2}>
            <Card style={{ height: "250px" }}>
                <CardBody onClick={() => goToDetail(customer.id)} style={{ marginTop: "5%" }}>
                    <div>
                        <div className="clearfix"></div>
                        <div className="text-center">
                            <h5 className="text-primary mb-2" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{customer.name}</h5>
                            <span className="badge bg-primary me-1 font-size-12 me-2 mb-3">Tenant: <TenantName id={customer.tenant} /></span>
                        </div>
                        <hr className="my-2" />
                        <p style={{ fontSize: "10px", }}>
                            {customer.address.street} {customer.address.number}<br />
                            {customer.address.zip_code} {customer.address.city}<br />
                            {customer.address.country}
                        </p>
                        <hr className="my-3" />

                        <div className={`text-center`}>
                            <CustomerOpenInvoicesButton customer={customer} />
                            <span> </span>
                            <button type="button" disabled className="btn btn-sm btn-outline-danger">
                                Tickets
                                <span className="badge bg-danger ms-1">2</span>
                            </button>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    </>
    );
}

export default CustomerCard;