import React, { useEffect, useState } from "react";
import { Person } from "models/Person";
import { Role } from "models/Role";
import { Customer } from "models/Customer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createPerson, editCustomer, linkPersonToCustomer } from 'store/actions/Customers';
import { Alert } from "reactstrap";
import { PersonsState } from "../../store/reducers/Persons";
import { RootState } from "../../store/store";


interface Props {
    person: Person;
    personsState: PersonsState;
    customer: Customer;
    onCancel: () => void;
    onSave: () => void;
}

interface Actions {
    editCustomer: (c: Customer) => void;
    createPerson: typeof createPerson;
    linkPersonToCustomer: typeof linkPersonToCustomer;
}

const CustomerPersonAdd: React.FC<Props & Actions> = ({ customer, linkPersonToCustomer, personsState, person, onCancel, onSave, createPerson }) => {

    const [email, setEmail] = useState("");

    const [billing] = useState({ title: "billing", state: false })
    const [technical] = useState({ title: "technical", state: false })

    const [error, setError] = useState<string | null>(null)


    const roleTechnical: Role = "technical"
    const roleBilling: Role = "billing"

    const savePerson = () => {
        person.email = email;
        if (person.name === "" || person.name === undefined) {
            setError("Person needs a name")
        } else {
            person.customers = [customer.id];
            createPerson(person).then(() => onSave())
        }
    }

    useEffect(() => {
    }, [])

    const duplicate = personsState.getPersons().find(dupPerson => dupPerson.email === email)

    return (
        <>
            {
                error &&
                <Alert color="danger">{error}</Alert>
            }

            <div className="form-floating mb-3">
                <button type="button"
                    onClick={() => {
                        onCancel();
                    }}
                    className="btn btn-danger waves-effect waves-light">
                    Cancel
                </button>
            </div>

            <div className="form-floating mb-3">
                <input type="text" name="nameperson" className="form-control" defaultValue={person.name} onChange={e => {
                    person.name = e.target.value

                }} placeholder="customer" />
                <label className="">Name</label>
            </div>

            <div className="form-floating mb-3">
                <input type="text" name="roleperson" className="form-control" defaultValue={person.title} onChange={e => {
                    person.title = e.target.value

                }} placeholder="name@example.com" />
                <label className="">Role</label>
            </div>
            <div className="form-floating mb-3">
                <input type="text" name="phoneperson" className="form-control" defaultValue={person.phone} onChange={e => {
                    person.phone = e.target.value

                }} placeholder="name@example.com" />
                <label className="">Phone</label>
            </div>
            <div className="form-floating mb-3">
                <input type="email" name="emailperson" defaultValue={email} onChange={e => {
                    setEmail(e.target.value)
                }} className="form-control" id="emailPerson" placeholder="name@example.com" />
                <label className="">Email</label>
            </div>
            <div className="form-floating mb-3">

                <p className="text-muted text-uppercase fw-semibold mb-0" style={{ fontSize: "13px" }}>Type</p>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" key="billing" type="checkbox" name="billing" id="billing"
                        defaultValue={billing.title} onChange={e => {
                            if (e.target.checked) {
                                person.roles = person.roles.filter(item => item !== "billing")
                                person.roles.push(roleBilling)
                            } else {
                                person.roles = person.roles.filter(item => item !== "billing")
                            }

                        }} />
                    <label className="form-check-label">Billing</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" key="technical" type="checkbox" name="technical" id="technical" defaultValue={technical.title} onChange={e => {
                        if (e.target.checked) {
                            person.roles = person.roles.filter(item => item !== "technical");
                            person.roles.push(roleTechnical)
                        } else {
                            person.roles = person.roles.filter(item => item !== "technical");
                        }
                    }} />
                    <label className="form-check-label">Technical</label></div><div className="form-check form-check-inline">
                </div>

                {duplicate !== undefined &&
                    <button
                        id="buttonPerson"
                        name="buttonPerson"
                        type="button"
                        onClick={() => {
                            linkPersonToCustomer(duplicate, customer)
                        }}
                        className="btn btn-primary w-100 waves-effect waves-light"
                    >
                        <i className="mdi mdi-account-plus"></i> Instead link {duplicate.name}
                    </button>
                }
                <div className="mt-3">
                    <button
                        id="buttonPerson"
                        name="buttonPerson"
                        type="button"
                        onClick={() => {
                            savePerson()
                        }}
                        className="btn btn-primary w-100 waves-effect waves-light"
                    >
                        <i className="mdi mdi-account-plus"></i> Save
                    </button>
                </div>

            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        personsState: state.Persons,
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ editCustomer, createPerson, linkPersonToCustomer }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPersonAdd);
