import { Customer } from 'models/Customer';
import { Person } from 'models/Person';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { bindActionCreators } from 'redux';
import {editCustomer, getCustomerComputer, getCustomerPersons} from 'store/actions/Customers';
import { RootState } from 'store/store';
import CustomerPersonAdd from './CustomerPersonAdd';
import CustomerPersonRow from './CustomerPersonRow';
import { PersonsState } from "../../store/reducers/Persons";
import ComputerRow from "./ComputerRow";



interface Props {
    customer: Customer;
}

interface Actions {
}

const PersonManagementCard: React.FC<Props & Actions> = ({ customer }) => {

    if (!customer) {
        return <></>
    }

    return (
        <Card>
            <CardBody>
                <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>Computers</p>
                <hr />
                {customer.computers?.map(computer => {
                    return <ComputerRow customer={customer} name={computer.name} id={computer.id} />
                })}
            </CardBody>
        </Card>
    );
};


export default PersonManagementCard;
