import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { editExpense, getExpenses } from "store/actions/Expenses";
import { RootState } from "../../store/store";
import PageMeta from "components/PageMeta";
import { Expense } from "models/Expense";
import { GetRouterQuery } from "components/Utils/GetRouterQuery";
import { useNavigate } from "react-router";
import { ExpenseState } from 'store/reducers/Expenses';

interface Props {
    expenseState: ExpenseState;
}

interface Actions {
    getExpenses: () => void;
    editExpense: (e: Expense) => void;
}

const ExpenseEditForm: React.FC<Props & Actions> = ({ expenseState, getExpenses, editExpense }) => {

    const { id } = GetRouterQuery<{ id: string }>()
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        getExpenses()
    }, [getExpenses])


    const expense = expenseState.getExpenseById(id)

    if (expense === undefined && expense) {
        return <></>
    }

    const Save = () => {
        if (
            (expense && expense.name !== undefined
                && expense.total !== undefined
            )
        ) {
            editExpense(expense)
                //@ts-ignore
                .then(() => {
                    navigate("/expenses?q=" + expense.name);
                })
                .catch((e: any) => {
                    setError("Expenses has failed, check your credentials")
                });

        } else {
            setError("Name & total are mandatory")
        }


    }

    return (
        <>
            <PageMeta title={`Edit Expense`} />
            <div className="page-content">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h2 className="mb-0 font-size-120">Edit expense</h2>
                </div>
                {
                    error &&
                    <Alert color="danger">{error}</Alert>
                }
                <Container fluid>
                    <Row>
                        <Col >
                            <Card>
                                <CardBody>
                                    {
                                        expense &&
                                        <>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" defaultValue={expense.name} onInput={e => {
                                                    expense.name = e.currentTarget.value
                                                }} placeholder="Name" />
                                                <label className="Name">Name</label>
                                            </div>

                                            <div className="form-floating mb-3">
                                                <input type="number" className="form-control" onWheel={(e) => e.currentTarget.blur()} defaultValue={parseFloat(expense.tax.toString())} onInput={e => {
                                                    expense.tax = parseFloat(e.currentTarget.value) === 0 ? 0 : parseFloat(e.currentTarget.value)
                                                }} placeholder="Tax" />
                                                <label className="Tax">Tax</label>
                                            </div>

                                            <div className="form-floating mb-3">
                                                <input type="number" className="form-control" onWheel={(e) => e.currentTarget.blur()} defaultValue={expense.total} onInput={e => {
                                                    expense.total = parseFloat(e.currentTarget.value)
                                                }} placeholder="Total" />
                                                <label className="Total">Total</label>
                                            </div>

                                            <div className="mt-3">
                                                <button
                                                    id="buttonCustomer"
                                                    name="buttonCustomer"
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    type="button"
                                                    onClick={() => {
                                                        Save()
                                                    }}
                                                >
                                                    Save edit
                                                </button>
                                            </div>
                                        </>
                                    }

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        expenseState: state.Expenses
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getExpenses, editExpense }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseEditForm);
