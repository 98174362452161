import { Customer } from 'models/Customer';
import { Person } from 'models/Person';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { editCustomerVat, getCustomerPersons } from 'storeAccount/actions/Customers';
import { RootStateAccount } from "storeAccount/accountStore";
import CustomerPersonRow from './CustomerPersonRow';


interface Props {
    customer: Customer;
    person: Person;
    setPerson: (p: Person) => void;
}

interface Actions {
    getCustomerPersons: typeof getCustomerPersons
}

const AccountPersonManagementCard: React.FC<Props & Actions> = ({ person, customer, setPerson }) => {
    const [customerState] = useState(customer)

    return (<>
        <Card>
            <CardBody>
                <h4 className="mb-0 font-size-120">My details</h4>
                <div className="dropdown-divider"></div>
                {
                    <CustomerPersonRow setPerson={setPerson} customer={customerState} person={person} />
                }
            </CardBody>
        </Card>
    </>
    );
};

const mapStateToProps = (state: RootStateAccount) => {
    return {
        persons: state.Persons
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ editCustomerVat, getCustomerPersons }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPersonManagementCard);
