import {Type} from "class-transformer";

export class InvoicePayment {
    id!: string;
    type!: string;
    tenant!: string;
    amount!: number;

    @Type(() => Date)
    date!: Date;
    metadata!: {[name: string]: string}
}