import React from "react"
import ReactApexChart from "react-apexcharts"
import {connect} from "react-redux"
import {RootState} from "store/store"

interface Props {
    data: any[];
}

const DashedLine: React.FC<Props> = ({data}: Props) => {

    const options = {
        chart: {
            zoom: {enabled: !1},
            toolbar: {show: !1},
            animations: {
                enabled: false
            },
        },
        legend: {
            show: true,
            yaxis: "ms",
        },
        tooltip: {
            enabled: false
        },
        dataLabels: {enabled: !1},
        stroke: {width: [2, 2, 2], curve: "straight"},
        title: {text: "", align: "left"},
        markers: {size: 0, hover: {sizeOffset: 6}},
        xaxis: {
            labels: {
                show: false
            },
            axisTicks: {
                show: false,
            },
        },
    }

    return (

        <ReactApexChart
            //@ts-ignore
            options={options}
            series={data}
            type="line"
            height="380"
        />
    )
}

const mapStateToProps = (state: RootState) => {
    return {};
};

export default connect(mapStateToProps)(DashedLine);
