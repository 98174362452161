import WaitingDots from "components/WaitingDots";
import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import MfaAlreadySetup from "../../../assets/images/widget-img.png";
import { checkMFA, activateMFA } from "../../../services/AccountApi";
import DeleteMfa from "./DeleteMfa";

interface Props {
}

const MfaQr: React.FC<Props> = () => {

    const [mfaSetUp, setMfaSetUp] = useState<number>(1)
    const [mfaQr, setMfaQr] = useState<string | undefined>(undefined)
    const [load, setLoad] = useState(true)
    const [otp, setOtp] = useState<string>("")
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        checkMFA().then((code) => {
            //@ts-ignore
            setMfaQr(code.mfa_qr)
            setLoad(false)
        }).catch(() => {
            setMfaSetUp(3)
            setLoad(false)
        })
    }, [])

    if (load) {
        return <WaitingDots />
    }

    return (<>

        <>
            {(mfaSetUp !== 3 && mfaSetUp !== 5) &&
                <>
                    <p className="text-uppercase text-center" style={{ fontWeight: "lighter" }}>Set up MFA</p>
                    <ul className="list-unstyled plan-features mt-4">
                        <li><i className="mdi mdi-circle-medium text-primary me-2"></i> Step 1: Download a MFA app</li>
                        <li><i className="mdi mdi-circle-medium text-primary me-2"></i> Step 2: Determine whether the MFA app supports QR codes</li>
                        <li><i className="mdi mdi-circle-medium text-primary me-2"></i> Step 3: Scan the QR code within the app</li>
                        <li><i className="mdi mdi-circle-medium text-primary me-2"></i> Step 4: Confirm that you scanned the QR code, by clicking the confirm button under the QR code</li>
                        <li><i className="mdi mdi-circle-medium text-primary me-2"></i> Step 5: Enter the OTP that the app shows</li>
                        <li><i className="mdi mdi-circle-medium text-primary me-2"></i> Step 6: After you confirmed the OTP, MFA is active</li>
                        <li><i className="mdi mdi-circle-medium text-primary me-2"></i> Tip: Did the first try fail or did you accidentally refresh? Delete the old code in your app and start again by scanning the new QR</li>
                    </ul>
                </>
            }

            {
                (mfaQr && mfaSetUp === 1) && <>
                    <p className="text-uppercase text-center" style={{ fontWeight: "lighter" }}>QR CODE</p>
                    <img style={{
                        display: "block", marginRight: "auto", marginLeft: "auto"
                    }} src={"data:image/png;base64," + mfaQr} alt="QR" />
                    <div className="text-center">
                        <button
                            id="buttonPerson"
                            name="buttonPerson"
                            type="button"
                            onClick={() => {
                                setMfaSetUp(2)
                            }}
                            className="btn btn-success w-25 waves-effect waves-light"
                        >
                            SCANNED
                        </button>
                    </div>
                </>

            }
            {
                mfaSetUp === 2 &&
                <>

                    {
                        error &&
                        <div className="form-floating mb-3" style={{ width: "50%", display: "block", marginRight: "auto", marginLeft: "auto" }}>
                            <Alert color="danger">{error}</Alert>
                        </div>
                    }

                    <div className="form-floating mb-3" style={{ width: "50%", display: "block", marginRight: "auto", marginLeft: "auto" }}>
                        <input type="text" name="otp" className="form-control" placeholder="otp" onChange={e => {
                            setOtp(e.target.value)
                        }} />
                        <label className="">OTP</label>
                    </div>

                    <div className="text-center">
                        <button
                            id="buttonPerson"
                            name="buttonPerson"
                            type="button"
                            onClick={() => {
                                activateMFA({ "totp": otp }).then(() => {
                                    setMfaSetUp(4)
                                }).catch(() => {
                                    setError("Something went wrong.... Try again. Make sure your OTP is correct.")
                                })
                            }}
                            className="btn btn-success w-25 waves-effect waves-light"
                        >
                            CONFIRM
                        </button>
                    </div>
                </>

            }

            {
                mfaSetUp === 3 && <>
                    <div>
                        <img src={MfaAlreadySetup} alt="invoices not found" className="img-fluid mx-auto d-block image-not-found"></img>
                        <br />
                        {
                            <>
                                <p className="text-uppercase text-center" style={{ fontWeight: "lighter" }}>MFA is already in use</p>
                                <button style={{ width: "50%", display: "block", marginRight: "auto", marginLeft: "auto" }}
                                    id="buttonPerson"
                                    name="buttonPerson"
                                    type="button"
                                    onClick={() => {
                                        setMfaSetUp(5)
                                    }}
                                    className="btn btn-danger w-25 waves-effect waves-light"
                                >
                                    Deactivate MFA
                                </button>
                            </>
                        }
                    </div>
                </>}

            {
                mfaSetUp === 4 && <>
                    <div>
                        <img src={MfaAlreadySetup} alt="invoices not found" className="img-fluid mx-auto d-block image-not-found"></img>
                        <br />
                        {
                            <>
                                <p className="text-uppercase text-center" style={{ fontWeight: "lighter" }}>MFA activated</p>
                            </>
                        }
                    </div>
                </>
            }

            {
                mfaSetUp === 5 && <>
                    <DeleteMfa setMfaSetUp={setMfaSetUp} setMfaQr={setMfaQr} />
                </>
            }


        </>
    </>
    );
};

export default MfaQr;
