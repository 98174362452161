import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { RootStateAccount } from "storeAccount/accountStore";
import { Card, CardBody } from "reactstrap";
import { Customer } from "models/Customer";
import { Person } from "models/Person";
import Select from 'react-select'
import { CustomerState } from "storeAccount/reducers/Customers";
import { getInvoices } from "storeAccount/actions/Billing";
import { InvoiceState } from 'storeAccount/reducers/Invoices';
import Logout from "./Utils/Logout";


interface Props {
    customerState: CustomerState;
    person: Person
    customers: Customer[]
    currentCustomer: Customer | null
    invoiceState: InvoiceState;
}

interface Actions {
    setCustomer: (c: Customer) => void;
    getInvoices: () => void;
}

const AccountMenu: React.FC<Props & Actions> = ({ currentCustomer, customers, customerState, person, setCustomer, getInvoices, invoiceState }) => {

    const [activeItem, setActiveItem] = useState<string>("")
    const navigate = useNavigate();

    useEffect(() => {
        setActiveItem(window.location.pathname)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        document.addEventListener("visibilitychange", function () {
            if (document.visibilityState === 'visible') {
                getInvoices()
            }
        });
        getInvoices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (currentCustomer === null) {
        return (
            <Card>
                <CardBody>
                    <div>
                        <p className="text-muted text-uppercase fw-semibold">Please choose a company from the selection in the menu on the left</p>
                    </div>
                </CardBody>
            </Card>
        )
    }

    return (
        <>
            <Card>
                <CardBody>
                    <div>
                        <p className="text-muted text-uppercase fw-semibold">Hello, {person.name}</p>
                    </div>
                    {currentCustomer ?
                        <Select
                            options={customers.map((customer) => ({ value: customer.id, label: customer.name }))}
                            value={{ value: currentCustomer.id, label: currentCustomer.name }}
                            onChange={(object: any) => {
                                //@ts-ignore
                                setCustomer(customerState.getCustomerById(object.value))
                            }}
                        />
                        :
                        <Select
                            options={customers.map((customer) => ({ value: customer.id, label: customer.name }))}
                            onChange={(object: any) => {
                                //@ts-ignore
                                setCustomer(customerState.getCustomerById(object.value))
                            }}
                        />
                    }

                </CardBody>
                <hr style={{ width: '80%', marginLeft: '10%', marginRight: "10%" }} />
                <CardBody style={{ marginTop: "-5%" }}>

                    <div className={activeItem === "/invoices" ? "menu-account-item-active" : "menu-account-item"}
                        onClick={() => {
                            navigate("/invoices")
                            setActiveItem("/invoices")
                        }}
                    >
                        <div className={activeItem === "/invoices" ? "menu-account-active" : "menu-account"}>
                            <i className="fas fa-file-invoice-dollar menu-account-icon"></i>
                            &nbsp;
                            <span key="t-dashboard" style={{ marginRight: "auto" }}>Invoices</span>

                            {
                                invoiceState.getInvoicesByCustomerId(currentCustomer.id)
                                    .filter(invoice => invoice.quote === false)
                                    .filter(invoice => invoice.customer === currentCustomer.id)
                                    .filter(invoice => !invoice.IsPaid())
                                    .filter(invoice => !invoice.draft)
                                    .length > 0
                                    ?
                                    <div>
                                        <span className="badge bg-danger rounded-pill menu-account-bagde">
                                            {invoiceState.getInvoicesByCustomerId(currentCustomer.id)
                                                .filter(invoice => !invoice.IsPaid())
                                                .filter(invoice => !invoice.draft)
                                                .filter(invoice => invoice.quote === false)
                                                .filter(invoice => invoice.customer === currentCustomer.id).length}
                                        </span>
                                    </div >
                                    : <></>
                            }

                        </div>
                    </div>

                    <div className={activeItem === "/quotes" ? "menu-account-item-active" : "menu-account-item"}
                        onClick={() => {
                            navigate("/quotes")
                            setActiveItem("/quotes")
                        }}
                    >
                        <div className={activeItem === "/quotes" ? "menu-account-active" : "menu-account"}>
                            <i className="fas fa-file-invoice menu-account-icon"></i>
                            &nbsp;
                            <span key="t-dashboard" style={{ marginRight: "auto" }}>Quotes</span>
                        </div>
                    </div>


                    <div className={activeItem === "/company-details" ? "menu-account-item-active" : "menu-account-item"}
                        onClick={() => {
                            navigate("/company-details")
                            setActiveItem("/company-details")
                        }}>
                        <div className={activeItem === "/company-details" ? "menu-account-active" : "menu-account"}>
                            <i className="fas fa-store-alt menu-account-icon"></i>
                            &nbsp;
                            <span key="t-dashboard" style={{ marginRight: "auto" }}>Company Details</span>
                        </div>
                    </div>

                    <div className={activeItem === "/my-details" ? "menu-account-item-active" : "menu-account-item"} onClick={() => {
                        navigate("/my-details")
                        setActiveItem("/my-details")
                    }}>
                        <div className={activeItem === "/my-details" ? "menu-account-active" : "menu-account"}>
                            <i className="fas fa-address-card menu-account-icon"></i>
                            &nbsp;
                            <span key="t-dashboard" style={{ marginRight: "auto" }}>My Details</span>
                        </div>
                    </div>

                    <div className={activeItem === "/security" ? "menu-account-item-active" : "menu-account-item"} onClick={() => {
                        navigate("/security")
                        setActiveItem("/security")
                    }}>
                        <div className={activeItem === "/security" ? "menu-account-active" : "menu-account"}>
                            <i className="fas fa-lock menu-account-icon"></i>
                            &nbsp;
                            <span key="t-dashboard" style={{ marginRight: "auto" }}>Security</span>
                        </div>
                    </div>
                </CardBody>
                <hr style={{ width: '80%', marginLeft: '10%', marginRight: "10%" }} />
                <br />
                <CardBody style={{ marginTop: "-10%" }}>
                    <Logout />
                </CardBody>
            </Card >
            <Card>
                <CardBody>
                    <div className="d-flex mt-0">
                        <div className="flex-shrink-0 me-3">
                            <div className="avatar-xs">
                                <span className="avatar-title bg-primary bg-soft rounded-circle">
                                    <i className="mdi mdi-email-outline text-primary"></i>
                                </span>
                            </div>
                        </div>

                        <div className="flex-grow-1">
                            <h5 className="font-size-15 text-uppercase">Do you need Support?</h5>
                            <p className="text-muted mb-0">Please contact us <a href="mailto:support@galactic-group.nl" className="text-decoration-underline">support@galactic-group.nl</a></p>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}



const mapStateToProps = (state: RootStateAccount) => {
    return {
        customerState: state.Customers,
        invoiceState: state.Invoices
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getInvoices }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
