import TenantName from 'components/TenantName';
import WaitingDots from 'components/WaitingDots';
import { Customer } from 'models/Customer';
import { Invoice } from 'models/Invoice';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody } from 'reactstrap';
import { getInvoiceExport } from 'services/Api';
import { RootState } from 'store/store';

interface Props {
    invoice: Invoice;
    customers: Customer[]
}

const InvoiceInfoSummaryCard: React.FC<Props> = ( {
                                                      invoice,
                                                      customers
                                                  } ) => {

    if ( invoice === undefined ) {
        return <WaitingDots/>
    }

    return ( <Card>
            <CardBody>
                <div>
                    <h4 className="card-title mb-4">
                        <Link to={ `/customers/${ invoice.customer }` }>
                            { customers.find( customer => customer.id === invoice.customer )?.name }
                        </Link>
                        <br/>
                        <span className="badge bg-primary me-1 font-size-12 me-2 mb-3">Tenant: <TenantName
                            id={ invoice.tenant }/></span>
                    </h4>
                    <h4 className="mb-1 mt-1" style={ { textAlign: "right" } }>
                        &euro; { invoice.CalcTotalContents().toFixed( 2 ) }
                    </h4>
                </div>
                <hr className="my-4"></hr>
                <div>
                    <p className="text-muted text-uppercase fw-semibold" style={ { fontSize: "13px" } }>
                        Details
                    </p>
                </div>
                <table className="table table-borderless align-middle table-centered table mb-0">
                    <tbody>
                    <tr>
                        <td>Created</td>
                        <td>{ invoice.GetCreatedDate().toDateString() }</td>
                    </tr>
                    <>
                        { (!invoice.draft && !invoice.quote) && <>
                            <tr>
                                <td>Deadline</td>
                                <td>{ invoice.GetDeadlineDate().toDateString() }</td>
                            </tr>
                            <tr>
                                <td>Pay-link</td>
                                <td><a
                                    href={ `https://pay.galactic-group.nl/?${ btoa( `${ invoice.id }*${ invoice.token }` ) }` }>link</a>
                                </td>
                            </tr>
                        </> }
                    </>
                    <tr>
                        <td>Pdf download</td>
                        <td><Button onClick={ async () => {
                            try {
                                const pdfData = await getInvoiceExport( invoice.id );
                                const bytes = atob( pdfData );
                                const byteNumbers = new Array( bytes.length );
                                for ( let i = 0; i < bytes.length; i++ ) {
                                    byteNumbers[ i ] = bytes.charCodeAt( i );
                                }
                                const byteArray = new Uint8Array( byteNumbers );
                                const file = new Blob( [ byteArray ], { type: 'application/pdf;base64' } );
                                const fileURL = URL.createObjectURL( file );
                                window.location.assign( fileURL );
                            } catch ( e ) {
                                console.error( e )
                            }
                        } }>Download</Button></td>
                    </tr>
                    </tbody>
                </table>
            </CardBody>
        </Card>

    );

}

const mapStateToProps = ( state: RootState ) => {
    return {};
};

export default connect( mapStateToProps )( InvoiceInfoSummaryCard );