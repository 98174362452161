import React, { useEffect, useState } from "react";
import { Alert, Button } from "reactstrap";
import { Invoice } from "models/Invoice";

interface Props {
    filteredInvoices: Invoice[];
}


interface Actions {
    setOnlyUnpaid: (b: boolean) => void;
}

const InvoicesFilter: React.FC<Props & Actions> = ({ filteredInvoices, setOnlyUnpaid }) => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        setOnlyUnpaid(active)
    }, [active, setOnlyUnpaid])

    if (filteredInvoices
        .filter(invoice => !invoice.IsPaid())
        .filter(invoice => !invoice.draft)
        .length === 0) {
        return <></>;
    }

    return (
        <Alert color="warning" style={{ height: "60px" }}>

            <Button color="primary" style={{ float: "right" }} onClick={() => {
                setActive(!active)
            }}>

                <input className="form-check-input" key="filter" type="checkbox" name="billing" id="billing"
                    checked={active}
                    onChange={() => {
                        setActive(!active)
                    }}
                />
                <label className="form-check-label">&nbsp; Only show unpaid</label>
            </Button>

            <p style={{ paddingTop: "5px" }}>
                {filteredInvoices.length > 0
                    ?
                    filteredInvoices
                        .filter(invoice => !invoice.IsPaid())
                        .filter(invoice => !invoice.draft)
                        .length.toString()
                    :
                    0
                } unpaid invoices
            </p>

        </Alert>
    )
}

export default InvoicesFilter