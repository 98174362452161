import WaitingDots from 'components/WaitingDots';
import React, { useEffect } from 'react';
import { RootState } from "store/store";
import { getServices } from "store/actions/Services";
import { connect } from "react-redux";
import { ServicesState } from 'store/reducers/Services';
import { bindActionCreators } from 'redux';
import { Card, CardBody } from 'reactstrap';
import { useNavigate } from "react-router-dom";

interface Props {
    servicesState: ServicesState;
}

interface Actions {
    getServices: () => void;
}

const ServiceStatusBannerDashboard: React.FC<Props & Actions> = ({ servicesState, getServices }) => {

    const navigate = useNavigate();

    useEffect(() => {
        getServices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getServices])


    if (servicesState.getServices() === undefined) {
        return <WaitingDots />
    }

    let errors = 0;

    servicesState.getServices().forEach(service => service.checks.forEach(check => {
        if (check.last_result) {
            if (!check.last_result.ok) {
                errors = errors + 1;
            }
        }
    }))

    return (<>
        <Card
            onClick={() => {
                navigate("/services")
            }}
            style={{ height: "135px" }}
        >
            <CardBody>
                <div>
                    <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>Status page summary</p>
                </div>

                {
                    errors > 0
                        ?
                        <div className="alert alert-warning fade show" role="alert" style={{ display: "flex" }}>
                            <span className="bx bx-error-alt bx-sm" style={{ display: "flex" }} />
                            <div style={{ display: "flex", marginLeft: '10px', fontSize: "16px", fontWeight: "lighter" }}>
                                Minor service disruptions
                            </div>
                        </div>
                        :
                        <>
                            <div className="alert alert-success fade show" role="alert" style={{ display: "flex" }}>
                                <span className="bx bx-check-shield bx-sm" style={{ display: "flex" }} />
                                <div style={{ display: "flex", marginLeft: '10px', fontSize: "17px", fontWeight: "lighter" }}>
                                    All systems operational
                                </div>
                            </div>
                        </>
                }

            </CardBody>
        </Card>

    </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        servicesState: state.Services
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getServices }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(ServiceStatusBannerDashboard);