import {Check} from "./Check";
import {State} from "./State";

export class Service {

    constructor() {
        this.state_history = [];
        this.checks = [];
        this.last_result = new State();
        this.current_state = new State();
    }

    id!: string;
    name!: string;
    tenant!: string;
    region!: string;
    last_result: State;
    current_state!: State;
    state_history!: State[];
    checks: Check[];

}

export type ServiceHistory = { [checkId: string]: { [metricName: string]: number[] } };