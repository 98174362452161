export class CustomerBilling {

constructor() {
    this.ibans = [];
  }

    ibans!: [];
    vat_number!: string;
    default_deadline!: number;
    interval!: string;
}