import TenantName from 'components/TenantName';
import WaitingDots from 'components/WaitingDots';
import {Expense} from 'models/Expense';
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {RootState} from 'store/store';
import ExpenseEditButton from './ExpenseEditButton';

interface Props {
    expense: Expense;
}


const ExpenseRowIndex: React.FC<Props> = ({ expense }) => {

    if (expense === undefined) {
        return <WaitingDots />
    }

    return (
        <tr>
            <td>{expense.GetDate().toDateString()}</td>
            <td>
                <Link to={`/expenses/${expense.id}`}>
                    <span style={{ display: 'block', width: '350px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{expense.name}</span>
                </Link>
            </td>
            <td>
                <Link to={"/tenants/" + expense.tenant}>
                    <span className="badge bg-primary me-1 font-size-12 me-2 mb-3">Tenant: <TenantName fetch={false} id={expense.tenant} /></span>
                </Link>
            </td>
            <td>&euro; {expense.tax.toFixed(2)}</td>
            <td>{expense.supplier.name}</td>
            <td>&euro; {expense.total.toFixed(2)}</td>
            <td>
                {expense.attachments > 0 &&
                    <i className=" bx bx-file"></i>
                }
            </td>
            <td><ExpenseEditButton expense={expense} /></td>
        </tr >

    );
}

const mapStateToProps = (state: RootState) => {
    return {
    };
};


export default connect(mapStateToProps)(ExpenseRowIndex);