import MoneyCard from "components/MoneyCard";
import {Expense} from "models/Expense";
import {Invoice} from "models/Invoice";
import {AccountableContainer, FilterFinalInvoices} from "models/InvoiceContainer";
import React from "react";
import {connect} from "react-redux";
import {RootState} from "store/store";
import {InvoiceState} from 'store/reducers/Invoices';
import {ExpenseState} from 'store/reducers/Expenses';

interface Props {
    invoiceState: InvoiceState;
    tenantId: string;
    expenseState: ExpenseState;
}

const ProfitCard: React.FC<Props> = ({ invoiceState, expenseState, tenantId }) => {

    const qStart = () => {
        var today = new Date()
        var quarter = Math.floor((today.getMonth() / 3))
        return new Date(today.getFullYear(), quarter * 3, 1)
    }

    const qEnd = () => {
        return new Date(qStart().getFullYear(), qStart().getMonth() + 3, 0)
    }
    const percentageInQ = ((new Date()).getTime() - qStart().getTime()) / (qEnd().getTime() - qStart().getTime()) * 100;

    const amountThisQInvoices = (new AccountableContainer<Invoice>(invoiceState.getInvoices().filter(invoice => invoice.tenant === tenantId)))
        .RangeThisQuarter()
        .Filter(FilterFinalInvoices)
        .CalcTotal(invoice => invoice.CalcTotalContents())

    const amountPrevQInvoices = (new AccountableContainer<Invoice>(invoiceState.getInvoices().filter(invoice => invoice.tenant === tenantId)))
        .RangePreviousQuarter()
        .Filter(FilterFinalInvoices)
        .CalcTotal(invoice => invoice.CalcTotalContents())

    const amountThisQExpenses = (new AccountableContainer<Expense>(expenseState.getExpenses().filter(expense => expense.tenant === tenantId)))
        .RangeThisQuarter()
        .CalcTotal(expense => expense.total)

    const amountPrevQExpenses = (new AccountableContainer<Expense>(expenseState.getExpenses().filter(expense => expense.tenant === tenantId)))
        .RangePreviousQuarter()
        .CalcTotal(expense => expense.total)

    const percentage =
        (amountThisQInvoices - amountThisQExpenses)
        /
        ((amountPrevQInvoices - amountPrevQExpenses) / 100 * percentageInQ)
        *
        100 - 100

    return (
        <MoneyCard
            title={"Profit"}
            amount={(amountThisQInvoices - amountThisQExpenses)}
            timeSpan={"last quarter"}
            percentage={isFinite(percentage) ? percentage : 100}
            trendUp={percentage > 0}
            icon={`mdi mdi-cards-heart text-primary`}
        />
    );

}

const mapStateToProps = (state: RootState) => {
    return {
        invoiceState: state.Invoices,
        expenseState: state.Expenses
    };
};

export default connect(mapStateToProps)(ProfitCard);
