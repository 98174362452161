import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { Alert, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { getServices } from "../../store/actions/Services";
import { RootState } from "../../store/store";
import { editService } from "store/actions/Services";
import { Service } from "models/Service";

interface Props {
    serviceById: Service;
}

interface Actions {
    getServices: () => void;
    setEdit: (b: boolean) => void;
    editService: (service: Service) => void;
}

const ServiceEditForm: React.FC<Props & Actions> = ({ serviceById, getServices, editService, setEdit }) => {

    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        getServices()
    }, [getServices])


    const Save = () => {
        if (
            (serviceById.name !== "" && serviceById.name !== undefined)
            && (serviceById.region !== "" && serviceById.region !== undefined)
        ) {
            editService(serviceById)
                //@ts-ignore
                .then(() => {
                    setEdit(false)
                })
                .catch(() => {
                    setError("Saving service has failed")
                });

        } else {
            setError("Name & region are mandatory")
        }

    }



    return (
        <>
            <Card>
                <CardBody>
                    {
                        error &&
                        <Alert color="danger">{error}</Alert>
                    }

                    <div>
                        <p className="text-muted text-uppercase fw-semibold">Details</p>
                    </div>
                    <div className="dropdown-divider"></div>


                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" defaultValue={serviceById.name} placeholder="customer" onChange={e => {
                            serviceById.name = e.target.value
                        }} />
                        <label className="">Name</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" defaultValue={serviceById.region} onChange={e => {
                            serviceById.region = e.target.value
                        }} placeholder="Region" />
                        <label className="">Region</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" defaultValue={serviceById.tenant} onChange={e => {
                            serviceById.tenant = e.target.value
                        }} placeholder="Tenant" />
                        <label className="">Tenant</label>
                    </div>

                    <div className="mt-3">
                        <button
                            id="buttonCustomer"
                            name="buttonCustomer"
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="button"
                            onClick={() => {
                                Save()
                            }}
                        >
                            Save
                        </button>
                    </div>
                    <div className="mt-1">
                        <button
                            id="buttonCustomer"
                            name="buttonCustomer"
                            className="btn btn-danger w-100 waves-effect waves-light"
                            type="button"
                            onClick={() => {
                                setEdit(false)
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        servicesState: state.Services
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getServices, editService }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceEditForm);
