import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getTenants } from "store/actions/Tenants";
import { bindActionCreators } from "redux";
import { RootState } from "../../store/store";
import { Col, Row } from 'reactstrap';
import TenantCard from "./TenantCard";
import { getInvoices } from "store/actions/Billing";
import PageMeta from "components/PageMeta";
import { TenantState } from 'store/reducers/Tenants';

interface Props {
  tenantState: TenantState;
}


interface Actions {
  getTenants: () => void;
  getInvoices: () => void;
}

const Tenants: React.FC<Props & Actions> = ({ tenantState, getTenants, getInvoices }) => {

  const [nameKeyword, setNameKeyword] = useState("")

  //@ts-ignore
  var url = new URL(window.location);
  const searchTerm = url.searchParams.get('q')


  useEffect(() => {
    getTenants()
    getInvoices()
    // eslint-disable-next-line
    if (searchTerm != undefined) {
      //@ts-ignore
      setNameKeyword(searchTerm);
    }
    // eslint-disable-next-line
  }, [getTenants, getInvoices])

  const refreshSearch = () => {
    const params = new URLSearchParams(window.location.search)
    if (params.get('q') != null) {
      // @ts-ignore
      setNameKeyword(params.get('q'))
    }
  }

  const filteredTenant = tenantState.getTenants()
    .filter(tenant => tenant.name.toLowerCase().includes(nameKeyword.toLowerCase()))

  return (
    <>
      <PageMeta title={`Tenants`} />
      <div className="page-content">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <form className="app-search" style={{ marginLeft: "5px" }}>
            <div className="position-relative">
              <input type="text"
                name="search" id="search" key="search" className="form-control" value={nameKeyword}
                onChange={(e) => {
                  //@ts-ignore
                  var url = new URL(window.location);
                  url.searchParams.set('q', e.target.value);
                  //@ts-ignore
                  window.history.pushState({}, '', url);
                  refreshSearch()
                }}
                placeholder="Search..." />
              <span className="mdi mdi-magnify"></span>
            </div>
          </form>
          <Link to="/tenants/add" style={{ marginLeft: "10px", marginRight: "5px" }}>
            <button type="button" className="btn btn-success btn waves-effect waves-light">
              Add new tenant
            </button>
          </Link>
        </div>
        <Row>
          {filteredTenant
            .sort((b, a) => (a.name < b.name) ? 1 : (a.name > b.name) ? -1 : 0)
            .map((tenant, key) => (
              <Col md={6} xl={3} key={key}>
                <TenantCard tenant={tenant} />
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    tenantState: state.Tenants
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ getTenants, getInvoices }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Tenants);