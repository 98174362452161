import React, {useEffect} from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {connect} from "react-redux";
import {getUsers} from "store/actions/Users";
import {bindActionCreators} from "redux";
import {RootState} from "../../store/store";
import UserProfilesTable from "./UserProfilesTable";
import PageMeta from "components/PageMeta";
import {UsersState} from 'store/reducers/Users';

interface Props {
  usersState: UsersState;
}

interface Actions {
  getUsers: () => void;
}

const UserProfiles: React.FC<Actions & Props> = ({ usersState, getUsers }) => {
  useEffect(() => {
    getUsers()
  }, [getUsers])

  return (
    <>
      <div className="page-content">
        <PageMeta title={`Users`} />
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <UserProfilesTable users={usersState.getUsers()} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    usersState: state.Users
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ getUsers }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfiles);