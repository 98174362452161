import { countries, Country } from "components/Common/countries";
import Input from "components/Common/Input";
import TypeButton from "components/Common/TypeButton";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";


const CountrySelector = ( {
                              onSelect,
                              initial,
                              onKeyDown
                          }: { initial?: string, onKeyDown: (e: KeyboardEvent) => void, onSelect: ( country: string ) => void } ) => {
    const [ countrySuggest, setCountrySuggest ] = useState( "" );
    const [ focussed, setFocussed ] = useState( false );
    const [ country, setCountry ] = useState<Country | null>( countries.find( country => country.name === initial ) ?? null );

    useEffect( () => {
        if ( country ) {
            onSelect( country.name )
        } else {
            onSelect( "" )
        }
        setCountrySuggest( country?.name ?? "" )
    }, [ country ] );

    useEffect(() => {
        setCountry(countries.find( country => country.name === initial ) ?? null)
    }, [initial])

    return <div>
        <Row>
        <Col xs={ 12 }>
            <Input
                autocomplete={false}
                type={ "text" }
                name={ "country" }
                placeholder={ "Country" }
                value={ countrySuggest }
                onChange={ country => {
                    setCountrySuggest( country )
                    if ( country === "" ) {
                        setCountry( null )
                    }
                } }
                onFocus={() => setFocussed(true)}
                onKeyDown={ e => {
                    if ( e.key === "Tab" && countrySuggest !== "" ) {
                        e.preventDefault()
                        const first = countries.filter( country => country.name.toLowerCase().indexOf( countrySuggest.toLowerCase() ) > -1 )[ 0 ];
                        if ( first ) {
                            setCountry( first );
                        }
                    }
                    onKeyDown(e)
                } }
            />
            { ( country && country.name === countrySuggest ) && <div style={ {
                position : "absolute",
                marginTop: "-32px"
            } }>
                { country.icon }
            </div> }

        </Col>
    </Row>
        { ( (countrySuggest.length > 0 || focussed) && ( !country || countrySuggest !== country.name ) ) && <div style={ { marginTop: "10px" } }>
            { countries.filter( country => country.name.toLowerCase().indexOf( countrySuggest.toLowerCase() ) > -1 ).slice( 0, 6 ).map( country_entry => {
                return <TypeButton
                    showCheckbox={false}
                    key={ country_entry.name }
                    style={ {
                        display     : "inline-block",
                        marginBottom: "7px",
                        width       : "fit-content"
                    } }
                    text={ country_entry.icon + " " + country_entry.name }
                    selected={ ( country ? country.name : "" ) === country_entry.name }
                    onClick={ () => {
                        setCountry( country_entry );
                    } }
                />
            } ) }
        </div> }
    </div>
}

export default CountrySelector;