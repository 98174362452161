import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { addExpense, getExpenses } from "store/actions/Expenses";
import { RootState } from "../../store/store";
import PageMeta from "components/PageMeta";
import { Expense, Supplier } from "models/Expense";
import { GetRouterQuery } from "components/Utils/GetRouterQuery";
import { useNavigate } from "react-router";
//@ts-ignore
import Select from 'react-select'
import { ExpenseState } from 'store/reducers/Expenses';
// import DropzoneCustom from "components/DropzoneCustom";
// import { File } from "models/File";

interface Props {
    expenseState: ExpenseState;
}

interface Actions {
    getExpenses: () => void;
    addExpense: (e: Expense) => void;
}

const ExpenseAddForm: React.FC<Props & Actions> = ({ expenseState, getExpenses, addExpense }) => {
    // const [selectedFiles, setselectedFiles] = useState<File[]>([]);
    const [expense, setExpense] = useState(new Expense())
    const [supplier, setSupplier] = useState(new Supplier(""))
    const suppliers: Supplier[] = expenseState.getExpenses().map(a => a.supplier)
    const [error, setError] = useState<string | null>(null)
    const { id } = GetRouterQuery<{ id: string }>()

    const navigate = useNavigate();

    useEffect(() => {
        getExpenses()
    }, [getExpenses])


    const Save = () => {
        if (
            (expense.name !== undefined && expense.total !== undefined)
        ) {

            expense.tenant = id;
            expense.supplier = supplier;

            addExpense(expense)
                //@ts-ignore
                .then(() => {
                    navigate("/tenants/" + id + "/expenses");
                })
                .catch((e: any) => {
                    console.log(e)
                    setError("Expenses has failed, check your credentials")
                });

        } else {
            setError("Name & total are mandatory")
        }


    }

    const setSupplierFunction = (id: string) => {
        const ExpenseWithCorrectSupplier: Expense[] = expenseState.getExpensesBySupplierId(id);
        const supplier: Supplier = ExpenseWithCorrectSupplier[0].supplier;
        setSupplier(supplier)
    }

    return (
        <>
            <PageMeta title={`Add Expense`} />
            <div className="page-content">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h2 className="mb-0 font-size-120">Add new expense</h2>
                </div>
                {
                    error &&
                    <Alert color="danger">{error}</Alert>
                }
                <Container fluid>
                    <Row>
                        <Col >
                            <Card>
                                <CardBody>
                                    <p className="text-muted text-uppercase fw-semibold mb-0" style={{ fontSize: "20px" }}>Details</p>
                                    <div className="dropdown-divider"></div>

                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" defaultValue={supplier.name} onChange={e => {
                                            supplier.name = e.target.value
                                            setSupplier(supplier)
                                        }} placeholder="Supplier" />
                                        <label className="Supplier">Supplier</label>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Or choose existing supplier:</label>
                                        <Select
                                            options={suppliers
                                                .filter((value: Supplier, index: number, self: Supplier[]) => {
                                                    return self.map(sup => sup.id).indexOf(value.id) === index;
                                                })
                                                .map((supplier, key) => ({ value: supplier.id, label: supplier.name }))
                                            }
                                            onChange={(object: any) => {
                                                supplier.id = object.value
                                                setSupplierFunction(object.value)
                                            }}
                                        />
                                    </div>

                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" defaultValue={expense.name} onChange={e => {
                                            expense.name = e.target.value
                                            setExpense(expense)
                                        }} placeholder="Name" />
                                        <label className="Name">Name</label>
                                    </div>

                                    <div className="mb-3">
                                        <input className="form-control" type="date" id="example-date-input" onChange={e => {
                                            expense.date = new Date(e.target.value)
                                            setExpense(expense)
                                        }} />
                                    </div>


                                    <div className="form-floating mb-3">
                                        <input type="number" className="form-control" defaultValue={expense.tax} onChange={e => {
                                            expense.tax = parseFloat(e.target.value)
                                            setExpense(expense)
                                        }} placeholder="Tax" />
                                        <label className="Tax">Tax</label>
                                    </div>

                                    <div className="form-floating mb-3">
                                        <input type="number" className="form-control" defaultValue={expense.total} onChange={e => {
                                            expense.total = parseFloat(e.target.value)
                                            setExpense(expense)
                                        }} placeholder="Total" />
                                        <label className="Total">Total</label>
                                    </div>

                                    {/* <DropzoneCustom selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} /> */}


                                    <div className="mt-3">
                                        <button
                                            id="buttonCustomer"
                                            name="buttonCustomer"
                                            className="btn btn-primary w-100 waves-effect waves-light"
                                            type="button"
                                            onClick={() => {
                                                Save()
                                            }}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        expenseState: state.Expenses
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getExpenses, addExpense }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseAddForm);
