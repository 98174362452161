import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { getTenants } from "store/actions/Tenants";
import { RootState } from 'store/store';
//@ts-ignore
import Select from 'react-select'
import { TenantState } from 'store/reducers/Tenants';

interface Props {
    tenantState: TenantState;
}

interface Actions {
    getTenants: () => void;
}

const ExpenseAddButton: React.FC<Props & Actions> = ({ tenantState, getTenants }) => {

    const navigate = useNavigate();

    const [tenantId, setTenantId] = useState<String>("")
    const [addAction, setAddAction] = useState<boolean>(false)
    const [error, setError] = useState("")

    useEffect(() => {
        getTenants()
    }, [getTenants])

    return (<>
        {
            error &&
            <Alert color="danger">{error}</Alert>
        }
        {

            addAction ?

                <> <div className="form-floating mb-3" style={{ marginLeft: "5px", marginRight: "5px" }}>
                    <Select
                        options={tenantState.getTenants().map((tenant) => ({ value: tenant.id, label: tenant.name }))}
                        onChange={(object: any) => {
                            setTenantId(object.value);
                        }}
                    />

                    <button type="button" style={{ marginRight: "3px", width: "100px", marginTop: "3px" }} className="btn btn-success waves-effect waves-light"
                        onClick={() => {
                            if (tenantId) {
                                navigate("/tenants/" + tenantId + "/expenses/add")
                                setAddAction(false)
                            } else {
                                setError("Choose tenant");
                            }
                        }}>
                        Go to add
                    </button>
                    <button type="button" style={{ width: "100px", marginTop: "3px" }} className="btn btn-danger waves-effect waves-light"
                        onClick={() => {
                            setAddAction(false)
                        }}>
                        Cancel
                    </button>
                </div>
                </>
                :
                <>
                    <button type="button" className="btn btn-success waves-effect waves-light"
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                            setAddAction(true)
                        }}
                    >
                        Add expense
                    </button>
                </>
        }

    </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        tenantState: state.Tenants,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getTenants }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseAddButton);