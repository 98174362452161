import {User as UserModel} from "models/User";
import {LOGOUT_USER_ACCOUNT} from "store/actions/Auth";
import {ADD_USER_TENANTS, DELETE_USER_TENANTS} from "store/actions/Tenants";
import {GET_USERS} from "store/actions/Users";
import {createReducer} from "./Util";

export class UsersState {

  private users: { [index: string]: UserModel }

  constructor() {
    this.users = {}
  }

  public setUsers(users: UserModel[]) {
    this.users = {}
    users.forEach(user => {
      this.setUser(user)
    })
    return this
  }

  public setUser(user: UserModel) {
    this.users[user.id] = user
    return this
  }

  public getUserById(id: string): UserModel | null {
    return this.users[id] ?? null
  }

  public getUsers(): UserModel[] {
    return Object.values(this.users)
  }

}

//TODO: ASK IF SECOND AND THIRD ARE OK!!!
const User = createReducer<UsersState>(new UsersState())
  .handle<UserModel[]>([GET_USERS.SUCCESS], (_state, payload) => _state.setUsers(payload))
  .handle<UserModel>([DELETE_USER_TENANTS.SUCCESS], (state, payload) => state.setUser(payload))
  .handle<UserModel>([ADD_USER_TENANTS.SUCCESS], (state, payload) => state.setUser(payload))
  .handle([LOGOUT_USER_ACCOUNT.SUCCESS], () => new UsersState());

export default User.handler;
