import React, {useEffect} from "react";
import {Col, Row} from 'reactstrap';
import {GetRouterQuery} from 'components/Utils/GetRouterQuery';
import {getInvoice} from "store/actions/Billing";
import {getCustomers} from "store/actions/Customers";
import {RootState} from "store/store";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageMeta from "components/PageMeta";
import InvoiceStatusCard from "./InvoiceStatusCard";
import InvoiceInfoSummaryCard from "./InvoiceInfoSummaryCard";
import InvoiceContentCard from "./InvoiceContentCard";
import InvoiceContentAddCard from "./InvoiceContentAddCard";
import InvoiceEmails from "./InvoiceEmails";
import {InvoiceState} from 'store/reducers/Invoices';
import {CustomerState} from 'store/reducers/Customers';
import OrphanedPaymentsCard from "../Dashboard/OrphanedPaymentsCard";

interface Props {
    invoiceState: InvoiceState;
    customerState: CustomerState;
}

interface Actions {
    getInvoice: (id: string) => void;
    getCustomers: () => void;
}

const InvoiceDetail: React.FC<Props & Actions> = ({ getInvoice, invoiceState, customerState, getCustomers }) => {

    const { id } = GetRouterQuery<{ id: string }>()

    useEffect(() => {
        getCustomers()
        getInvoice(id)
    }, [getCustomers, getInvoice, id])

    const invoice = invoiceState.getInvoiceById(id)

    if (invoice === null) {
        return <></>
    }

    return (
        <div className="page-content">
            <PageMeta title={`Invoice ${invoice.name}`} />
            <Row>
                <Col md={6} xl={3}>
                    <InvoiceInfoSummaryCard invoice={invoice} customers={customerState.getCustomers()} />
                    <InvoiceStatusCard invoice={invoice} customers={customerState.getCustomers()} />
                    {
                        !invoice.IsPaid() &&
                        <OrphanedPaymentsCard invoice={invoice} />
                    }
                    <InvoiceEmails invoice={invoice} />

                </Col>
                <Col md={6} xl={9}>
                    <InvoiceContentCard invoice={invoice} />
                    <InvoiceContentAddCard invoice={invoice} />
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        invoiceState: state.Invoices,
        customerState: state.Customers,
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getInvoice, getCustomers }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail);