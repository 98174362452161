import { GetFaviconUrl } from "common/Favicon";
import PageMeta from "components/PageMeta";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Card, Col, Container, Row, Spinner, Table } from "reactstrap";
import { ReactComponent as LogoDark } from "../assets/images/logo-dark.svg";
import avatar4 from "../assets/images/logo-sm.png";

export type Session = { email: string, token: string };

const AuthEntry: React.FC = () => {

    const [ state, setState ] = useState( 0 );
    const [ payload, setPayload ] = useState<{ redirect_url: string } | null>( null )

    const [ sessions, setSessions ] = useState<Session[]>( [] );
    const params = useParams<{ payload: string }>();
    const navigate = useNavigate();

    const checkSessions = () => {
        try {
            const sessionsString = window.localStorage.getItem( "sessions" )
            if ( sessionsString === null ) {
                throw "no_store";
            }
            const sessions = JSON.parse( sessionsString );
            if ( sessions.length === 0 ) {
                throw "no_sessions";
            }
            setState( 2 );
            setSessions( sessions );
        } catch ( e ) {
            navigate( "/login" );
        }
    }

    useEffect( () => {
        if ( params.payload && params.payload !== "" ) {
            try {
                const payload = atob( params.payload );
                const parsed = JSON.parse( payload );
                window.localStorage.setItem( "entry", btoa( JSON.stringify( parsed ) ) )
                setPayload( parsed );
                checkSessions()
            } catch ( e ) {
                setState( 9 );
            }
        } else {
            const lsPayload = window.localStorage.getItem( "entry" )
            if ( lsPayload !== null ) {
                try {
                    const payload = atob( lsPayload );
                    const parsed = JSON.parse( payload );
                    setPayload( parsed );
                    checkSessions()
                } catch ( e ) {
                    setState( 9 );
                }
            } else {
                setState( 9 );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] )
    const removeSession = ( email: string ) => {
        const newSessions = sessions.filter( session => session.email !== email );
        window.localStorage.setItem( "sessions", JSON.stringify( newSessions ) )
        if ( newSessions.length === 0 ) {
            navigate( "/login" )
        }
    }

    return ( <>
        <PageMeta title={ `Auth | Galactic-Group` } hideTitle={ true }/>
        <div className="account-pages my-0 pt-sm-5 pt-sm-5 mt-lg-5">
            <Container>
                <Row className="justify-content-center mt-lg-5">
                    <Col md={ 10 } className="text-center mt-3">
                        <LogoDark/>

                        <div className="text-center mb-5 mt-5 mt-sm-5 mt-md-5" style={ { marginTop: '200px' } }>
                            <Row>
                                <Col lg={ 3 }/>
                                <Col lg={ 6 }>
                                    <Card className="overflow-hidden mt-lg-5">
                                        <div className={ "pt-1 text-center" }>
                                            { state === 0 && <div style={ { margin: '50px 0' } }>
                                                <Spinner/>
                                            </div> }
                                            { state === 4 && <div style={ {
                                                margin   : '50px 0',
                                                textAlign: "center"
                                            } }>
                                                <h5>Login successful, redirecting...</h5>
                                                <Spinner/>
                                            </div> }
                                            { state === 2 && <div style={ { margin: '50px 20px' } }>
                                                <h5>Continue as:</h5>

                                                <Table hover style={ { margin: '40px 0' } }>
                                                    <tbody>
                                                    { sessions.map( session => {
                                                        return <tr  key={session.email}
                                                            style={ { cursor: "pointer" } }
                                                                   onClick={ ( e ) => {
                                                                       //@ts-ignore
                                                                       if ( e.target.classList && e.target.classList[ 0 ] && e.target.classList[ 0 ] === "logout" ) {
                                                                           return;
                                                                       }
                                                                       setState( 4 )
                                                                       setTimeout( () => {
                                                                           window.location.assign(payload?.redirect_url + "/" + session.token)
                                                                       }, 1000 );
                                                                   } }>
                                                            <td>
                                                                <img
                                                                    className="rounded-circle avatar-xs"
                                                                    alt="200x200"
                                                                    src={ GetFaviconUrl(session.email.split("@")[1]) }
                                                                    data-holder-rendered="true"
                                                                />
                                                            </td>
                                                            <td style={ {
                                                                paddingTop: "9px",
                                                                textAlign : "left"
                                                            } }>
                                                                <b>{ session.email }</b><br/>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                                                                <a className={ 'logout' } style={ {
                                                                    color  : "#0576b9"
                                                                } }
                                                                   onClick={ ( e ) => {
                                                                       e.preventDefault()
                                                                       removeSession( session.email )
                                                                   } }>Logout this account</a>
                                                            </td>
                                                            <td style={ {
                                                                color     : "green",
                                                                paddingTop: "17px"
                                                            } }>Active
                                                            </td>
                                                        </tr>
                                                    } ) }
                                                    </tbody>
                                                </Table>
                                                <button
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    onClick={ () => navigate( "/login" ) }
                                                >
                                                    Use other account
                                                </button>
                                            </div> }
                                            { state === 9 && <div style={ { margin: '50px 0' } }>
                                                <h5>Something went wrong. Please go back and try again</h5>
                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                                                <a style={ {
                                                    display  : "block",
                                                    color    : "#0576b9",
                                                    marginTop: '50px'
                                                } } onClick={ () => {
                                                    navigate("/login")
                                                } }>Go back</a>
                                            </div> }
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </> );
};

export default connect( null, null )( AuthEntry );
