import WaitingDots from 'components/WaitingDots';
import { Customer } from 'models/Customer';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { RootState } from 'store/store';
import { getEmails } from "store/actions/Emails";
import { bindActionCreators } from 'redux';
import { EmailState } from 'store/reducers/Emails';
import EmailRowInbox from "../Email/EmailRowInbox";

interface Props {
    customer: Customer;
    emailState: EmailState;
}

interface Actions {
    getEmails: () => void;
}


const CustomerEmails: React.FC<Props & Actions> = ({ customer, emailState, getEmails }) => {

    useEffect(() => {
        getEmails()
    }, [getEmails])

    if (emailState.getEmails() === null) {
        return <WaitingDots />
    }

    return (
        <Card>
            <CardBody>
                <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>Conversations</p>
                <hr />

                <ul className="message-list">
                    {emailState.getEmails()
                        .filter(email => customer.id === email.customer)
                        .sort((a, b) => (a.date < b.date) ? 1 : (a.date > b.date) ? -1 : 0)
                        .slice(0, 8)
                        .map((email, key) => (
                            <EmailRowInbox email={email} hideTo={false} key={key} />
                        ))}
                </ul>
                {emailState.getEmails().length === 0 &&
                    <div style={{ textAlign: "center" }}>
                        <WaitingDots />
                    </div>
                }
            </CardBody>
        </Card>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        emailState: state.Emails,
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getEmails }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(CustomerEmails);