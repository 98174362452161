import {Expense} from "models/Expense";
import {Invoice} from "models/Invoice";
import {AccountableContainer, FilterFinalInvoices} from "models/InvoiceContainer";
import React from "react";
import {connect} from "react-redux";
import {RootState} from "store/store";
import {InvoiceState} from 'store/reducers/Invoices';
import {ExpenseState} from 'store/reducers/Expenses';
import {Card, CardBody} from "reactstrap";

interface Props {
    invoiceState: InvoiceState;
    tenantId: string;
    expenseState: ExpenseState;
}

const LastQCard: React.FC<Props> = ({invoiceState, expenseState, tenantId}) => {

    const amountPrevQInvoices = (new AccountableContainer<Invoice>(invoiceState.getInvoices().filter(invoice => invoice.tenant === tenantId)))
        .RangePreviousQuarter()
        .Filter(FilterFinalInvoices)
        .CalcTotal(invoice => invoice.CalcTotalContents())

    const amountPrevQExpenses = (new AccountableContainer<Expense>(expenseState.getExpenses().filter(expense => expense.tenant === tenantId)))
        .RangePreviousQuarter()
        .CalcTotal(expense => expense.tax)

    return (
        <>
            <Card>

                <CardBody>
                    <div>
                        <p className="text-muted text-uppercase fw-semibold" style={{fontSize: "13px"}}>Last quarter</p>
                        <h4 className="mb-1 mt-1">
                            &euro; {amountPrevQInvoices.toFixed(2)} ex.
                        </h4>
                    </div>
                    <p className="text-muted mt-3 mb-0">
                            <span className={`badge badge-soft-danger me-1`}>
                                 &euro; {amountPrevQExpenses.toFixed(2)}
                            </span> expense taxes
                    </p>

                </CardBody>
            </Card>
        </>
    );

}

const mapStateToProps = (state: RootState) => {
    return {
        invoiceState: state.Invoices,
        expenseState: state.Expenses
    };
};

export default connect(mapStateToProps)(LastQCard);
