import { generateAction, RequestTypes } from "./Util";
import * as AccountApi from "../../services/AccountApi";

export const GET_INVOICES = new RequestTypes("GET_INVOICES");

export const getInvoices = () => {
  return generateAction(GET_INVOICES, () =>
    AccountApi.getInvoices()
  );
};

