import React, { useState } from "react";
import { Check } from "models/Check";
import { Alert, Spinner } from "reactstrap";
import { Service } from "models/Service";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editService } from 'store/actions/Services';
import LongClickButton from "components/LongClickButton";
import { RootState } from "store/store";



interface Props {
    check: Check;
    service: Service;
    index: number;
    onCancel: () => void;
    save: () => void;
}

interface Actions {
    setChecks: (c: Check[]) => void;
    setService: (s: Service) => void;
}

const CheckServiceEditAdd: React.FC<Props & Actions> = ({ service, check, onCancel, save, index, setChecks, setService }) => {

    const [error, setError] = useState<string | null>(null)
    const [deleteProgress, setDeleteProgress] = useState<boolean>(false)

    const saveCheck = () => {

        if ((check.name === "" || check.name === undefined) || (check.target === "" || check.target === undefined) || (check.type === "" || check.type === undefined)) {
            setError("Check needs a name, target and type")
        } else {
            setChecks(service.checks)
            setService(service)
            save();
        }
    }

    const deleteCheck = () => {
        service.checks.splice(index, 1);
        save()
        setChecks(service.checks)
        setService(service)
        setDeleteProgress(false)
    }


    return (
        <>
            {
                error &&
                <Alert color="danger">{error}</Alert>
            }

            <br />
            {
                !deleteProgress ?

                    <>
                        <div className="form-floating mb-3">
                            <button type="button"
                                onClick={() => {
                                    onCancel();
                                }}
                                className="btn btn-danger waves-effect waves-light">
                                Cancel
                            </button>
                        </div>

                        <div className="form-floating mb-3">
                            <input type="text" name="nameperson" className="form-control" defaultValue={check.name} onInput={e => {
                                check.name = e.currentTarget.value
                            }} placeholder="customer" />
                            <label className="">Name</label>
                        </div>

                        <div className="form-floating mb-3">
                            <input type="text" name="roleperson" className="form-control" defaultValue={check.target} onInput={e => {
                                check.target = e.currentTarget.value
                            }} placeholder="target" />
                            <label className="">Target</label>
                        </div>

                        <div className="form-floating mb-0">
                            <h5 className="font-size-14 mb-2">Type</h5>
                            <select className="form-select" style={{ paddingTop: "10px" }} defaultValue={check.type} onInput={e => {
                                check.type = e.currentTarget.value
                            }} aria-label="Floating label select example">
                                <option value="">Choose one...</option>
                                <option value="http">HTTP</option>
                                <option value="ping">Ping</option>
                            </select>

                        </div>


                        <LongClickButton color="danger" style={{ marginTop: '5px', width: "100%" }}
                            onClick={() => {
                                deleteCheck()
                                setDeleteProgress(true)
                            }}>
                            Delete check</LongClickButton>


                        <div className="mt-3 mb-4">
                            <button
                                id="buttonPerson"
                                name="buttonPerson"
                                type="button"
                                onClick={() => {
                                    saveCheck()
                                }}
                                className="btn btn-primary w-100 waves-effect waves-light"
                            >
                                Save
                            </button>
                        </div>
                    </>

                    :
                    <div className="mt-3 mb-4">
                        <Spinner />
                    </div>
            }

        </>
    );
};


const mapStateToProps = (state: RootState) => {
    return {
        servicesState: state.Services
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ editService }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckServiceEditAdd);
