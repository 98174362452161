import { LOGOUT_ACCOUNT } from "storeAccount/actions/Auth";
import { createReducer } from "./Util";
import {
  GET_CUSTOMER_PERSONS,
  UPDATE_PERSON
} from "storeAccount/actions/Customers";
import { Person } from "../../models/Person";

export class PersonsState {

  private persons: { [index: string]: Person }

  constructor() {
    this.persons = {}
  }

  public setPersons(persons: Person[]) {
    persons.forEach(person => {
      this.setPerson(person)
    })
    return this
  }

  public setPerson(person: Person) {
    this.persons[person.id] = person
    return this
  }
  public deletePerson(id: number) {
    delete this.persons[id];
    return this
  }

  public getPersonById(id: string): Person | null {
    return this.persons[id] ?? null
  }

  public getPersonsByCustomerId(id: string): Person[] {
    return this.getPersons().filter(person => person.customers.indexOf(id) > -1)
  }

  public getPersons(): Person[] {
    return Object.values(this.persons)
  }

}

const Persons = createReducer<PersonsState>(new PersonsState())
  .handle<Person[]>([GET_CUSTOMER_PERSONS.SUCCESS], (state, payload) => state.setPersons(payload))
  .handle<Person>([UPDATE_PERSON.SUCCESS], (state, payload) => state.setPerson(payload))
  .handle([LOGOUT_ACCOUNT.SUCCESS], () => new PersonsState());

export default Persons.handler;
