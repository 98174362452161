import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import accountApi from "services/AccountApi";
import { loadState, saveState } from "./LocalStorage";
import { setAuth } from "./middleware/SetAuthToken";
import rootReducer from "./reducers/Index";

const persistedState = loadState();

const accountStore = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(
    thunkMiddleware,
    setAuth(accountApi)
  )
);

accountStore.subscribe(() => {
  saveState(accountStore.getState());
});

export type RootStateAccount = ReturnType<typeof rootReducer>;

export default accountStore;
