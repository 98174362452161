import WaitingDots from 'components/WaitingDots';
import {Expense} from 'models/Expense';
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {RootState} from 'store/store';

interface Props {
    expense: Expense;
    expenseId: string;
}

const ExpenseRowSupplier: React.FC<Props> = ({ expense, expenseId }) => {
    if (expense === undefined) {
        return <WaitingDots />
    }

    return (
        <tr key={expense.id}>
            <td>  {expense.id === expenseId && <span className='badge bg-primary'>Current expense</span>}<span style={{ display: 'block', width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{expense.supplier.name}</span></td>
            <td> <Link to={`/expenses/${expense.id}`}><span style={{ display: 'block', width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{expense.name !== "" ? expense.name : <i>No name</i>}</span></Link></td>
            <td>{expense.GetDate().toDateString()}</td>
            <td>&euro; {expense.total.toFixed(2)}</td>
        </tr>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        expenses: state.Expenses,
    };
};


export default connect(mapStateToProps)(ExpenseRowSupplier);