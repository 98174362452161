import {applyMiddleware, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import api from "services/Api";
import {loadState, saveState} from "./LocalStorage";
import {setAuth} from "./middleware/SetAuthToken";
import rootReducer from "./reducers/Index";

const persistedState = loadState();

const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(
      thunkMiddleware,
      setAuth(api)
  )
);

store.subscribe(() => {
  saveState(store.getState());
});

export type RootState = ReturnType<typeof rootReducer>;

export default store;
