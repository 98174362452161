import { UuidNil } from "common/uuid";
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import EmailSideBar from "./EmailSideBar"
import { getEmails } from "store/actions/Emails";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from "store/store"
import WaitingDots from "components/WaitingDots"
import EmailRowInbox from "./EmailRowInbox"
import { GetRouterQuery } from "components/Utils/GetRouterQuery";
import EmailCompose from "./EmailCompose";
import PageMeta from "components/PageMeta";
import { EmailState } from 'store/reducers/Emails';


interface Props {
  emailState: EmailState;
}

interface Actions {
  getEmails: () => void;
  getEmailsAfter: (date: number) => void;
}

const EmailInbox: React.FC<Props & Actions> = ({ emailState, getEmails }) => {

  const { box } = GetRouterQuery<{ box: string }>()
  const [nameKeyword, setNameKeyword] = useState("")
  const [searchActive, setSearchActive] = useState(false)

  const emailsUnread = emailState.getEmails().filter(email => (email.reader === null || email.reader.length === 0) && email.direction === "in").length;

  //@ts-ignore
  var url = new URL(window.location);
  const searchTerm = url.searchParams.get('q')

  useEffect(() => {
    // eslint-disable-next-line
    if (searchTerm != undefined && searchTerm != null && searchTerm != "") {
      //@ts-ignore
      setSearchActive(true)
      setNameKeyword(searchTerm);
    }

    if (emailState.getEmails().length === 0
      || emailState.getEmails().length === emailsUnread) {
      getEmails()
    }
    // eslint-disable-next-line
  }, [])


  if (emailState.getEmails() === undefined) {
    return <WaitingDots />
  }

  const filteredMails = emailState.getEmails().filter(
    email => email.subject.toLowerCase().includes(nameKeyword.toLowerCase())
      || email.recipient.toLowerCase().includes(nameKeyword.toLowerCase())
      || email.sender_name.toLowerCase().includes(nameKeyword.toLowerCase())
      || email.body.toLowerCase().includes(nameKeyword.toLowerCase())
  )

  const refreshSearch = () => {
    const params = new URLSearchParams(window.location.search)
    if (params.get('q') != null) {
      setSearchActive(true)
      // @ts-ignore
      setNameKeyword(params.get('q'))
    }

    if (params.get('q') === "" || params.get('q') === null) {
      console.log("test")
      setSearchActive(false)
    }

  }


  return (
    <>
      <div className="page-content">
        <PageMeta title={`Emails`} />
        <Container fluid>
          <Row>
            <Col xs="12">
              <EmailSideBar box={box} unread={emailState.getEmails()
                .filter(email => email.direction === "in" && !email.IsRead()).length} />
              <div className="email-rightbar mb-3">
                {
                  box !== "compose" ?
                    <>
                      <div className="app-search mb-3" style={{ marginLeft: "5px", marginRight: "5px" }}>
                        <div className="position-relative">
                          <input type="text"
                            name="search" autoComplete={'off'} id="search" key="search" className="form-control" value={nameKeyword}
                            onChange={(e) => {
                              //@ts-ignore
                              var url = new URL(window.location);
                              url.searchParams.set('q', e.target.value);
                              //@ts-ignore
                              window.history.pushState({}, '', url);
                              refreshSearch()
                            }}
                            placeholder="Search..." />
                          <span className="mdi mdi-magnify"></span>
                        </div>
                      </div>
                      <Card style={{ marginLeft: "5px", marginRight: "5px" }}>
                        <CardBody>
                          <div>
                            <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>{box}</p>
                          </div>

                          {(emailState.getEmails().length === 0) ?
                            <div style={{ textAlign: "center" }}>
                              <WaitingDots />
                            </div> :
                            <ul className="message-list">
                              {filteredMails
                                .filter(email => box !== "inbox" || email.direction === "in")
                                .filter(email => box !== "starred" || email.starred)
                                .filter(email => box !== "sent" || email.direction === "out" || email.direction === "")
                                .sort((a, b) => (a.date < b.date) ? 1 : (a.date > b.date) ? -1 : 0)
                                .map((email, key) => {
                                  const getThreadSizeUp = (id: string): number => {
                                    let size = 0;
                                    const threads = emailState.getThreads(id);
                                    for (let i = 0; i < threads.length; i++) {
                                      const newer = emailState.getEmailById(threads[i]);
                                      if (newer) {
                                        size += 1;
                                        size += getThreadSizeUp(threads[i])
                                      }
                                    }

                                    return size;
                                  }
                                  const getThreadSizeDown = (id: string): number => {
                                    let size = 0;
                                    const email = emailState.getEmailById(id);
                                    if (email) {
                                      size += 1;
                                      if (email.reply_to !== UuidNil) {
                                        size += getThreadSizeDown(email.reply_to)
                                      }
                                    }
                                    return size;
                                  }
                                  const hasNewerOfDirection = (dir: string, id: string): boolean => {
                                    const threads = emailState.getThreads(id);
                                    for (let i = 0; i < threads.length; i++) {
                                      const newer = emailState.getEmailById(threads[i]);
                                      if (!newer) {
                                        return false;
                                      }
                                      if (newer.direction === dir) {
                                        return true;
                                      }
                                      return hasNewerOfDirection(dir, threads[i])
                                    }
                                    return false;
                                  };

                                  if (searchActive && searchTerm?.toLowerCase() !== email.sender_name.toLowerCase()) {
                                    return <EmailRowInbox key={key} email={email} hideDirection={true} threadSize={1 + getThreadSizeDown(email.reply_to)} />;
                                  }

                                  if (hasNewerOfDirection(box === "inbox" ? "in" : "out", email.id)) {
                                    return <></>;
                                  }

                                  return <EmailRowInbox key={key} email={email} hideDirection={true} threadSize={1 + getThreadSizeUp(email.id) + getThreadSizeDown(email.reply_to)} />
                                })
                              }
                            </ul>
                          }
                        </CardBody>
                      </Card>
                    </>
                    :
                    <EmailCompose />
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    emailState: state.Emails
  };
};


const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ getEmails }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(EmailInbox);
