import WaitingDots from 'components/WaitingDots';
import { Customer } from 'models/Customer';
import { Person } from 'models/Person';
import React, { useState } from 'react';
import CustomerPersonChange from './CustomerPersonChange';

interface Props {
    person: Person;
    customer: Customer;
    setPerson: (p: Person) => void;
}

const CustomerPersonRow: React.FC<Props> = ({ person, customer, setPerson }) => {

    const [change, setChange] = useState<boolean>(false)

    if (person === undefined) {
        return <WaitingDots />
    }

    return (
        <>
            {
                change ?
                    <CustomerPersonChange customer={customer} person={person} setPerson={setPerson}
                        onCancel={() => {
                            setChange(false);
                        }}
                        save={() => {
                            setChange(false);
                        }} />
                    :
                    !change &&
                    <>
                        <div className="float-end ms-2 dropdown">
                            <button
                                type="button"
                                onClick={() => {
                                    setChange(true)
                                }}
                                className="btn btn-primary waves-effect waves-light"
                            >
                                <i className=" bx bx-edit-alt"></i>
                            </button>
                        </div>
                        <div className="p-3 px-4">
                            <div className="d-flex align-items-start">
                                <div className="align-self-center me-3">
                                    <div className="flex-1">
                                        <h5 className="font-size-15 mt-0 mb-1">{person.name}</h5>
                                        <p className="text-muted mb-0">{person.title}</p>
                                        <p className="text-muted mb-0">{person.email}</p>
                                        <p className="text-muted mb-0">{person.phone}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </>
            }
        </>

    );
}

export default CustomerPersonRow;