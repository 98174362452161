import {generateAction, RequestTypes} from "./Util";
import * as api from "../../services/Api";

export const ADD_TENANT = new RequestTypes("ADD_TENANT");
//export const GET_TENANT = new RequestTypes("GET_TENANT");

export const addTenant = (
  name: string
) => {
  return generateAction(ADD_TENANT, () =>
    api.addTenant(name)
  );
};

// export const getTenant = (id: string) => {
//   return generateAction(GET_TENANT, () =>
//     api.getTenant(id)
//   );
// };
