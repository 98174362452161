import React, {useEffect} from 'react';
import Socket from "./Socket";
import {useDispatch} from 'react-redux';

const WebsocketConnection: React.FC = () => {

    const dispatch = useDispatch();
    Socket.dispatch = (msg: any) => {
        if (msg !== undefined) {
            const action = msg()
            if (action !== undefined) {
                dispatch(action)
            }
        }
    };

    useEffect(() => {
        Socket.Connect()
    }, [])

    return (
        <span/>
    );

}

export default WebsocketConnection;