export class Recurring {

  id!: string;
  customer!: string;
  interval!: string; // cron
  type!: string;
  name!: string;
  description!: string;
  amount!: number;
  price!: number;
  uom!: string;

}