import WaitingDots from 'components/WaitingDots';
import { Service } from 'models/Service';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/store';
import ChangeHighlight from "react-change-highlight";
import { useNavigate } from 'react-router-dom';

interface Props {
    service: Service;
}

const ServiceRow: React.FC<Props> = ({ service }) => {
    const navigate = useNavigate();

    if (service === undefined) {
        return <WaitingDots />
    }

    let errors = 0;

    service.checks.forEach(check => {
        if (check.last_result) {
            if (!check.last_result.ok) {
                errors = errors + 1;
            }
        }
    })


    return (
        <tr key={service.id} onClick={() => {
            navigate(`/services/${service.id}`)
        }}>
            <td style={{ fontWeight: "bold", color: errors > 0 ? "orange" : "green" }}>

                {service.name}

            </td>
            <td>{service.region}</td>
            {service.checks.map(check => {
                if (check.last_result) {
                    const time = check.last_result.result.rtt ?? check.last_result.result.time
                    return <td>
                        <span className="mb-0" style={{
                            textAlign: "center",
                            color: check.last_result.ok ? "green" : "orange"
                        }}>{check.name}</span>
                        <ChangeHighlight>
                            <span
                                ref={React.createRef()}>{(time < 0 || time > 2999) ? "∞" : time ?? "∞"} ms</span>
                        </ChangeHighlight>
                    </td>
                }
                return null
            })}

        </tr>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        services: state.Services,
    };
};


export default connect(mapStateToProps)(ServiceRow);