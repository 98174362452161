import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "store/store";
import { Card, CardBody } from "reactstrap";
import { getUnreadEmails } from "store/actions/Emails";
import EmailRowInbox from "pages/Email/EmailRowInbox";
import { EmailState } from 'store/reducers/Emails';
import WaitingDots from "../../components/WaitingDots";

interface Props {
    emailState: EmailState;
}

interface Actions {
    getUnreadEmails: () => void;
}

const UnreadEmailCard: React.FC<Props & Actions> = ({ emailState, getUnreadEmails }) => {

    useEffect(() => {
        getUnreadEmails()
    }, [getUnreadEmails]);

    return (
        <Card>
            <CardBody>
                <div>
                    <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>Unread
                        conversations</p>
                </div>

                {emailState.isFetched() && emailState.getEmails()
                    .filter(email => (email.reader === null || email.reader.length === 0) && email.direction === "in").length === 0
                    ?
                    <div style={{ textAlign: "center" }}>No unread conversations</div>
                    :
                    <ul className="message-list">
                        {emailState.getEmails()
                            .filter(email => (email.reader === null || email.reader.length === 0) && email.direction === "in")
                            .sort((a, b) => (a.date < b.date) ? 1 : (a.date > b.date) ? -1 : 0)
                            .map(emailsUnread => {
                                return <EmailRowInbox email={emailsUnread} hideTo={false} />;
                            })}
                    </ul>
                }
                {!emailState.isFetched() &&
                    <div style={{ textAlign: "center" }}>
                        <WaitingDots />
                    </div>
                }
            </CardBody>
        </Card>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        emailState: state.Emails,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getUnreadEmails }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UnreadEmailCard);
