import { Customer } from 'models/Customer';
import { Person } from 'models/Person';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { editCustomer, getCustomerPersons } from 'store/actions/Customers';
import { RootState } from 'store/store';
import CustomerPersonAdd from './CustomerPersonAdd';
import CustomerPersonRow from './CustomerPersonRow';
import { PersonsState } from "../../store/reducers/Persons";


interface Props {
    customer: Customer;
    persons: PersonsState;
}

interface Actions {
    editCustomer: (c: Customer) => void;
    getCustomerPersons: typeof getCustomerPersons
}

const PersonManagementCard: React.FC<Props & Actions> = ({ persons, customer, editCustomer, getCustomerPersons }) => {
    const [customerState] = useState(customer)
    const [add, setAdd] = useState<boolean>(false)

    useEffect(() => {
        getCustomerPersons(customer.id);
    })

    return (
        <Card>
            <CardBody>
                <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>Persons</p>
                <hr />
                {
                    !add ?
                        persons.getPersonsByCustomerId(customer.id).map((person, i) => {
                            return <CustomerPersonRow customer={customerState} person={person} index={i} key={i} />
                        })
                        :
                        <CustomerPersonAdd customer={customerState} person={new Person()}
                            onCancel={() => {
                                setAdd(false);
                            }
                            }
                            onSave={() => {
                                setAdd(false);
                            }} />
                }


                {!add && <div className="mt-3">
                    <button
                        id="buttonPerson"
                        name="buttonPerson"
                        type="button"
                        onClick={() => {
                            setAdd(true);
                        }}
                        className="btn btn-primary w-100 waves-effect waves-light"
                    >
                        <i className="mdi mdi-account-plus"></i> Add another person
                    </button>
                </div>}

            </CardBody>
        </Card>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        persons: state.Persons

    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ editCustomer, getCustomerPersons }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonManagementCard);
