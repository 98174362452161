import { GetFaviconUrl } from "common/Favicon";
import WaitingDots from 'components/WaitingDots';
import { Customer } from 'models/Customer';
import { Person } from 'models/Person';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from "reactstrap";
import { callPerson } from "services/Api";
import CustomerPersonChange from './CustomerPersonChange';

interface Props {
    person: Person;
    customer: Customer;
    index: number;
}

const CustomerPersonRow: React.FC<Props> = ( {
                                                 person,
                                                 customer,
                                                 index
                                             } ) => {

    const [ editing, setEditing ] = useState( false );

    if ( person === undefined ) {
        return <WaitingDots/>
    }

    if ( editing ) {
        return <CustomerPersonChange
            index={ index }
            customer={ customer }
            person={ person }
            onCancel={ () => {
                setEditing( false );
            } }
            save={ () => {
                setEditing( false );
            } }/>
    }

    const domain = person.email.split( "@" )[ 1 ];
    return <>
        <img
            alt={ domain }
            src={ GetFaviconUrl( domain ) }
            style={ {
                position    : "absolute",
                marginTop   : "5px",
                height      : "40px",
                width       : "40px",
                borderRadius: "50%"
            } }
        />
        <Row style={ {
            marginBottom: "15px",
            paddingLeft : "55px"
        } }>

            <Col xs={ 10 }>
                <h6 style={ { marginTop: "4px" } }>
                    <span>{ person.name } { person.last_name }</span>
                    { ( person.auth !== null ) ? <>
                        { ( person.auth.mfa_active ) ? <span className="badge bg-success me-1 font-size-12 me-2" style={{marginLeft:"5px"}}>
                            MFA: Active
                        </span> : <span className="badge bg-warning me-1 font-size-12 me-2" style={{marginLeft:"5px"}}>
                            MFA: Inactive
                        </span> }
                    </>
                    :
                        <span className="badge bg-danger me-1 font-size-12 me-2" style={{marginLeft:"5px"}}>
                            No auth login
                        </span>
                    }
                </h6>


                { person.phone !== "" &&
                    <a style={{color:"rgb(5, 118, 185)"}} onClick={ () => callPerson( person, customer.tenant ) }>{ person.phone }</a>
                }
                { (person.phone !== ""  && person.email !== "") &&
                    <span> - </span>
                }
                { person.email !== "" &&
                    <Link
                        to={ `/conversations/compose?person=${ person.id }&customer=${ customer.id }` }>
                        { person.email }
                    </Link>
                }

            </Col>

            <Col xs={ 2 }>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={ () => {
                        setEditing( true );
                    } }>
                    <i className=" bx bx-edit-alt"></i>
                </button>
            </Col>
        </Row>
    </>;

    // return ( <>
    //         { <>
    //             <div className="float-end ms-2 dropdown">
    //                 <button
    //                     type="button"
    //                     onClick={ () => {
    //                         setEditing( true )
    //                     } }
    //                     className="btn btn-primary waves-effect waves-light"
    //                 >
    //                     <i className=" bx bx-edit-alt"></i>
    //                 </button>
    //             </div>
    //             <div className="p-3 px-4">
    //                 <div className="d-flex align-items-start">
    //                     <div className="align-self-center me-3">
    //                         <div className="flex-1">
    //                             <h5 className="font-size-15 mt-0 mb-1">{ person.name }
    //                                 { person.auth === null && <span className="badge bg-danger me-1 font-size-12 me-2"
    //                                                                 style={ { marginLeft: "10px" } }>
    //                                 Cannot login
    //                             </span> }
    //                             </h5>
    //                             <p className="text-muted mb-0">{ person.title }</p>
    //                             <p className="text-muted mb-0"><Link
    //                                 to={ `/conversations/compose?person=${ person.id }&customer=${ customer.id }` }>{ person.email }</Link>
    //                                 { ( person.auth !== null && person.email !== person.auth.email ) && <><br/><span
    //                                     className="badge bg-warning me-1 font-size-12 me-2">
    //                                 Username: { person.auth.email }
    //                             </span></> }
    //                             </p>
    //                             <p className="text-muted mb-0"><span
    //                                 onClick={ () => callPerson( person, customer.tenant ) }>{ person.phone }</span></p>
    //                             { person.roles?.includes( "billing" ) &&
    //                                 <span className="badge bg-dark me-1 font-size-12 me-2 mb-3">
    //                                             billing
    //                                         </span> }
    //                             { person.roles?.includes( "technical" ) &&
    //                                 <span className="badge bg-success me-1 font-size-12 me-2 mb-3">
    //                                             technical
    //                                         </span> }
    //                         </div>
    //
    //                     </div>
    //                 </div>
    //             </div>
    //         </> }
    //     </>
    //
    // );
}

export default CustomerPersonRow;