import WaitingDots from 'components/WaitingDots';
import { Customer } from 'models/Customer';
import { CustomerBilling } from 'models/CustomerBilling';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { editCustomer } from 'store/actions/Customers';
import parser from 'cron-parser';

interface Props {
    customer: Customer;
}

interface Actions {
    editCustomer: (c: Customer) => void;
}

const CustomerDatesCard: React.FC<Props & Actions> = ({ customer, editCustomer }) => {

    const [customerState] = useState<Customer>(customer)
    const [editDeadline, setEditDealine] = useState<boolean>(false)
    const [editInterval, setEditInterval] = useState<boolean>(false)
    const [billingState, setBillingState] = useState<CustomerBilling>(customer.billing)

    const editDates = () => {
        customerState.billing = billingState;
        editCustomer(customerState)
            //@ts-ignore
            .then(() => {
                setEditInterval(false)
                setEditDealine(false)
            });
    }


    if (customer === undefined) {
        return <WaitingDots />
    }

    const isCronValid = (freq: string) => {
        var cronregex = new RegExp(/^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/);
        return cronregex.test(freq);
    }

    return (
        <Card>
            <CardBody>
                <div>
                    <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>Billing</p>
                </div>
                <hr />
                <table className="table table-borderless align-middle table-centered mb-0">
                    <tbody>
                        <tr>
                            <td>Interval (cronjob)</td>
                            <td>
                                {editInterval &&
                                    <input type="text" defaultValue={customer.billing.interval} className="form-control" placeholder="Value"

                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                editDates()

                                            }
                                        }}

                                        onChange={e => {
                                            billingState.interval = e.target.value;
                                            setBillingState(billingState)

                                        }} />
                                }

                                {!editInterval &&
                                    customer.billing.interval
                                }

                            </td>

                            {!editInterval &&
                                <td>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setEditInterval(true)
                                        }}
                                        className="btn btn-primary waves-effect waves-light"
                                    >
                                        <i className=" bx bx-edit-alt"></i>
                                    </button>
                                </td>
                            }

                        </tr>
                        <tr>
                            <td>Default deadline (in days)</td>
                            <td>

                                {editDeadline &&
                                    <input type="number" defaultValue={customer.billing.default_deadline} className="form-control" placeholder="Amount of days"

                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                editDates()

                                            }
                                        }}

                                        onChange={e => {
                                            billingState.default_deadline = parseInt(e.target.value);
                                            setBillingState(billingState)
                                        }} />
                                }

                                {!editDeadline &&
                                    customer.billing.default_deadline
                                }



                            </td>

                            {!editDeadline &&
                                <td>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setEditDealine(true)
                                        }}
                                        className="btn btn-primary waves-effect waves-light"
                                    >
                                        <i className=" bx bx-edit-alt"></i>
                                    </button>
                                </td>
                            }

                        </tr>
                        {billingState.interval !== undefined && billingState.interval !== "" && isCronValid(billingState.interval) &&
                            <tr>
                                <td>Auto-Close</td>
                                <td>{parser.parseExpression(billingState.interval).next().toDate().toDateString()}</td>
                                <td>
                                    {parser.parseExpression(billingState.interval).next().toDate().toLocaleTimeString()}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </CardBody>
        </Card>
    );
}


const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ editCustomer }, dispatch);
};

export default connect(null, mapDispatchToProps)(CustomerDatesCard);
