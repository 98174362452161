import {generateAction, RequestTypes} from "./Util";
import * as api from "../../services/Api";

export const LOGIN_USER_ACCOUNT = new RequestTypes("LOGIN_USER_ACCOUNT");
export const LOGOUT_USER_ACCOUNT = new RequestTypes("LOGOUT_USER_ACCOUNT");

export const logoutUserAccount = () => LOGOUT_USER_ACCOUNT.success();

export const loginUserAccount = (
  email: string,
  password: string,
  mfa_token: string
) => {
  return generateAction(LOGIN_USER_ACCOUNT, () =>
    api.loginUserAccount(email, password, mfa_token)
  );
};
