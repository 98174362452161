import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { Alert, Card, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addTenant } from "../../store/actions/Tenant";
import { RootState } from "../../store/store";
import PageMeta from "components/PageMeta";

interface Actions {
    addTenant: (n: string) => void;
}

const TenantAddForm: React.FC<Actions> = ({
    addTenant
}) => {
    const [error, setError] = useState("")
    const [name, setName] = useState("")
    const navigate = useNavigate();

    const handleValidSubmit = () => {
        if (name === "") {
            setError("Name can't be empty")
            return
        }

        addTenant(name)
            //@ts-ignore
            .then(() => {
                navigate("/tenants");
            })
            .catch(() => {
                setError("Adding tenancy has failed, check data and try again")
            });

    };

    return (
        <>
            <PageMeta title={`Add Tenant`} />
            <div className="account-pages my-5 pt-sm-5">
                <Row className="justify-content-center">
                    <Col md={10}>
                        <Card className="overflow-hidden">
                            <Row className="g-0">
                                <div className="p-lg-5 p-4">
                                    <div>
                                        <h5>Add Tenant</h5>
                                    </div>
                                    <div className="mt-4 pt-3">
                                        {
                                            error &&
                                            <Alert color="danger">{error}</Alert>
                                        }
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" placeholder="name" onInput={e => {
                                                setName(e.currentTarget.value)
                                            }} />
                                            <label className="">Name</label>
                                        </div>

                                        <div className="mt-3">
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                onClick={() => {
                                                    handleValidSubmit()
                                                }}
                                            >
                                                Add
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </Row>
                        </Card>
                    </Col>
                </Row>

            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ addTenant }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantAddForm);
