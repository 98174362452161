import React from "react";
import {Tenant} from "models/Tenant";
import {Card, CardBody} from 'reactstrap';
import {User} from "models/User";
import {RootState} from "store/store";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {addUserToTenant, deleteUserFromTenant} from "store/actions/Tenants";

interface Props {
    tenant: Tenant;
    users: User[];
}


interface Actions {
    addUserToTenant: (userId: string, tenantId: string) => void;
    deleteUserFromTenant: (userId: string, tenantId: string) => void;

}

const TenantUsers: React.FC<Props & Actions> = ({ users, tenant, addUserToTenant, deleteUserFromTenant }) => {
    return (
        <>
            <Card>
                <CardBody>
                    <p className="text-muted text-uppercase fw-semibold">Users</p>
                    {
                        users
                            .sort((a, b) => (a.id < b.id) ? 1 : (a.id > b.id) ? -1 : 0)
                            .map((user, key) => (
                                <div key={key} className="form-check mb-2">
                                    <div className="form-check mb-2">
                                        <input onClick={() => {
                                            user.accounts.includes(tenant.id) ?
                                                deleteUserFromTenant(user.id, tenant.id)
                                                :
                                                addUserToTenant(user.id, tenant.id)
                                        }} className="form-check-input" type="checkbox" id={user.id} checked={user.accounts.includes(tenant.id)} />
                                        <label className="form-check-label">
                                            {user.name}
                                        </label>
                                    </div>
                                </div>
                            ))}
                </CardBody>
            </Card>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ addUserToTenant, deleteUserFromTenant }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantUsers);
