import { UuidNil } from "common/uuid";
import { LOGOUT_USER_ACCOUNT } from "store/actions/Auth";
import { createReducer } from "./Util";
import { Email, EmailReader } from "models/Email";
import {
  DELETE_EMAIL,
  GET_EMAILS,
  GET_EMAILS_AFTER,
  GET_EMAILS_UNREAD,
  READ_EMAIL,
  SEND_EMAIL,
  GET_EMAIL,
  SET_EMAIL_CUSTOMER,
  STAR_EMAIL
} from "store/actions/Emails";

export class EmailState {

  private emails: { [index: string]: Email } | null
  private threads: { [index: string]: string[] }

  constructor() {
    this.emails = null
    this.threads = { };
  }

  public setEmails(emails: Email[]) {
    this.emails = {}
    emails.forEach(email => {
      this.setEmail(email)
    })
    return this
  }

  public addEmails(emails: Email[]) {
    emails.forEach(email => {
      this.setEmail(email)
    })
    return this
  }

  public getThreads(id: string): string[] {
    return this.threads[id] ?? [];
  }
  public updateUnreadEmails(emailsUnread: Email[]) {
    if (this.emails === null) {
      this.emails = {}
    }

    var emailReader = new EmailReader()
    emailReader.ip_address = "0.0.0.0"
    emailReader.date = Date.now().toString()

    Object.values(this.emails).forEach((email) => {
      if (email.reader === null || email.reader.length === 0) {
        email.reader = [emailReader]
        this.setEmail(email)
      }

    })

    emailsUnread.forEach(email => {
      email.reader = [];
      this.setEmail(email)
    })

    return this
  }

  public setEmail(email: Email) {
    if (this.emails === null) {
      this.emails = {}
    }

    this.emails[email.id] = email
    if (email.reply_to !== UuidNil) {
      if (!this.threads[email.reply_to]) {
        this.threads[email.reply_to] = [];
      }
      this.threads[email.reply_to].push(email.id)
    }
    return this
  }

  public getEmailById(id: string): Email | null {
    if (this.emails === null) {
      this.emails = {}
    }

    return this.emails[id] ?? null
  }

  public deleteEmail(id: string) {
    if (this.emails === null) {
      this.emails = {}
    }

    delete this.emails[id]
    return this
  }

  public getEmails(): Email[] {
    if (this.emails === null) {
      this.emails = {}
    }

    return Object.values(this.emails)
  }

  public getNewestEmail(): Email | null {
    if (this.emails === null) {
      this.emails = {}
    }

    const newest = Object.values(this.emails).sort((a, b) => b.date - a.date)[0]
    if (newest === undefined) {
      return null
    }
    return newest
  }

  public isFetched(): boolean {
    return this.emails !== null
  }

}



const Emails = createReducer<EmailState>(new EmailState())
  .handle<Email[]>([GET_EMAILS.SUCCESS], (state, payload) => state.setEmails(payload))
  .handle<Email>([GET_EMAIL.SUCCESS], (state, payload) => state.setEmail(payload))
  .handle<Email[]>([GET_EMAILS_AFTER.SUCCESS], (state, payload) => state.addEmails(payload))
  .handle<Email[]>([GET_EMAILS_UNREAD.SUCCESS], (state, payload) => state.updateUnreadEmails(payload))
  .handle<Email>([SEND_EMAIL.SUCCESS], (state, payload) => state.setEmail(payload))
  .handle<string>([DELETE_EMAIL.SUCCESS], (state, payload) => state.deleteEmail(payload))
  .handle<Email>([STAR_EMAIL.SUCCESS], (state, payload) => state.setEmail(payload))
  .handle<Email>([READ_EMAIL.SUCCESS], (state, payload) => state.setEmail(payload))
  .handle<Email>([SET_EMAIL_CUSTOMER.SUCCESS], (state, payload) => state.setEmail(payload))
  .handle([LOGOUT_USER_ACCOUNT.SUCCESS], () => new EmailState());

export default Emails.handler;
