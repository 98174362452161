import { Customer } from 'models/Customer';
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import TenantName from "../../../components/TenantName";
import AccountCustomerVatCheckCard from './AccountCustomerVatCheckCard';


interface Props {
    customer: Customer | null
}

interface Actions {
    setChange: (b: boolean) => void;
    setCustomer: (c: Customer) => void;
}

const AccountDetailCard: React.FC<Props & Actions> = ({ customer, setChange, setCustomer }) => {

    if (customer === null) {
        return (
            <Card>
                <CardBody>
                    <div>
                        <p className="text-muted text-uppercase fw-semibold">Please choose a company from the selection in the menu on the left</p>
                    </div>
                </CardBody>
            </Card>
        )
    }

    return (
        <Card>
            <CardBody>
                <div>
                    <p className="text-muted text-uppercase fw-semibold">Company details</p>
                </div>
                <div className="float-end">
                    <div className="avatar-sm mx-auto mb-4">
                        <span className="avatar-title rounded-circle bg-light font-size-24">
                            <i className={`rounded-circle mdi mdi-alpha-${customer.name.charAt(0).toLowerCase()}-circle text-primary`} style={{ fontSize: "55px" }} />
                        </span>
                    </div>
                </div>
                <div>
                    <p className="text-muted text-uppercase fw-semibold">{customer.name}</p>
                </div>
                <p>
                    {customer.email}<br />
                </p>
                <p>
                    {customer.address.street} {customer.address.number}<br />
                    {customer.address.zip_code} {customer.address.city}<br />
                    {customer.address.country} <br />
                    {
                        customer.tenant ? <TenantName id={customer.tenant} /> : <></>
                    }

                </p>
                <AccountCustomerVatCheckCard customer={customer} />
            </CardBody>
        </Card>
    );
}

export default AccountDetailCard;