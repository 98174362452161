import { AxiosInstance } from "axios";
import { AnyAction, Dispatch, Middleware, Store } from "redux";
import { SET_TOKEN } from "../actions/Auth";

export const setAuth = (api: AxiosInstance): Middleware => {

    const setAuthHeader = (token: string) => {
        if (!token) return;
        api.defaults.headers.common['Authorization'] = token.replace(/["']/g, "");
    }

    // @ts-ignore
    return (store: Store) => {
        setAuthHeader(store.getState().Authentication);

        return (next: Dispatch<AnyAction>) =>
            (action: any) => {
                if (action.type === SET_TOKEN.SUCCESS) {
                    setAuthHeader(action.payload);
                }

                return next(action);
            };
    };
}
