import React, {useEffect} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {RootState} from "../store/store";
import {getTenants} from "store/actions/Tenants";
import WaitingDots from "./WaitingDots";
import {TenantState} from 'store/reducers/Tenants';

interface Props {
    tenantState: TenantState;
    fetch?: boolean;
    id: string;
}

interface Actions {
    getTenants: () => void;
}

const TenantName: React.FC<Props & Actions> = ({ id, tenantState, getTenants, fetch }) => {

    const tenant = tenantState.getTenantById(id)

    useEffect(() => {
        if (tenant === null && (fetch === undefined || fetch)) {
            getTenants()
        }
    }, [getTenants, tenant, id, fetch])

    if (tenant === null) {
        return <WaitingDots />
    }

    return <span>{tenant?.name}</span>
}

const mapStateToProps = (state: RootState) => {
    return {
        tenantState: state.Tenants,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getTenants }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantName);
