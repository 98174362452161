export type Country = {
    icon: string, code?: string, name: string
};

export const countries: Country[] = [
    {
        icon: "🇳🇱",
        name: "Netherlands",
        code: "NL"
    }, {
        icon: "🇧🇪",
        name: "Belgium",
        code: "BE"
    }, {
        icon: "🇱🇺",
        name: "Luxembourg"
    }, {
        icon: "🇫🇷",
        name: "France",
        code: "FR"
    }, {
        icon: "🇩🇪",
        name: "Germany",
        code: "DE"
    }, {
        icon: "🇬🇧",
        name: "United Kingdom"
    }, {
        icon: "🇦🇨",
        name: "Ascension Island"
    }, {
        icon: "🇦🇩",
        name: "Andorra"
    }, {
        icon: "🇦🇪",
        name: "United Arab Emirates"
    }, {
        icon: "🇦🇫",
        name: "Afghanistan"
    }, {
        icon: "🇦🇬",
        name: "Antigua & Barbuda"
    }, {
        icon: "🇦🇮",
        name: "Anguilla"
    }, {
        icon: "🇦🇱",
        name: "Albania"
    }, {
        icon: "🇦🇲",
        name: "Armenia"
    }, {
        icon: "🇦🇴",
        name: "Angola"
    }, {
        icon: "🇦🇶",
        name: "Antarctica"
    }, {
        icon: "🇦🇷",
        name: "Argentina"
    }, {
        icon: "🇦🇸",
        name: "American Samoa"
    }, {
        icon: "🇦🇹",
        name: "Austria"
    }, {
        icon: "🇦🇺",
        name: "Australia"
    }, {
        icon: "🇦🇼",
        name: "Aruba"
    }, {
        icon: "🇦🇽",
        name: "Åland Islands"
    }, {
        icon: "🇦🇿",
        name: "Azerbaijan"
    }, {
        icon: "🇧🇦",
        name: "Bosnia & Herzegovina"
    }, {
        icon: "🇧🇧",
        name: "Barbados"
    }, {
        icon: "🇧🇩",
        name: "Bangladesh"
    }, {
        icon: "🇧🇫",
        name: "Burkina Faso"
    }, {
        icon: "🇧🇬",
        name: "Bulgaria"
    }, {
        icon: "🇧🇭",
        name: "Bahrain"
    }, {
        icon: "🇧🇮",
        name: "Burundi"
    }, {
        icon: "🇧🇯",
        name: "Benin"
    }, {
        icon: "🇧🇱",
        name: "St. Barthélemy"
    }, {
        icon: "🇧🇲",
        name: "Bermuda"
    }, {
        icon: "🇧🇳",
        name: "Brunei"
    }, {
        icon: "🇧🇴",
        name: "Bolivia"
    }, {
        icon: "🇧🇶",
        name: "Caribbean Netherlands"
    }, {
        icon: "🇧🇷",
        name: "Brazil"
    }, {
        icon: "🇧🇸",
        name: "Bahamas"
    }, {
        icon: "🇧🇹",
        name: "Bhutan"
    }, {
        icon: "🇧🇻",
        name: "Bouvet Island"
    }, {
        icon: "🇧🇼",
        name: "Botswana"
    }, {
        icon: "🇧🇾",
        name: "Belarus"
    }, {
        icon: "🇧🇿",
        name: "Belize"
    }, {
        icon: "🇨🇦",
        name: "Canada"
    }, {
        icon: "🇨🇨",
        name: "Cocos (Keeling) Islands"
    }, {
        icon: "🇨🇩",
        name: "Congo - Kinshasa"
    }, {
        icon: "🇨🇫",
        name: "Central African Republic"
    }, {
        icon: "🇨🇬",
        name: "Congo - Brazzaville"
    }, {
        icon: "🇨🇭",
        name: "Switzerland"
    }, {
        icon: "🇨🇮",
        name: "Côte d’Ivoire"
    }, {
        icon: "🇨🇰",
        name: "Cook Islands"
    }, {
        icon: "🇨🇱",
        name: "Chile"
    }, {
        icon: "🇨🇲",
        name: "Cameroon"
    }, {
        icon: "🇨🇳",
        name: "China"
    }, {
        icon: "🇨🇴",
        name: "Colombia"
    }, {
        icon: "🇨🇵",
        name: "Clipperton Island"
    }, {
        icon: "🇨🇷",
        name: "Costa Rica"
    }, {
        icon: "🇨🇺",
        name: "Cuba"
    }, {
        icon: "🇨🇻",
        name: "Cape Verde"
    }, {
        icon: "🇨🇼",
        name: "Curaçao"
    }, {
        icon: "🇨🇽",
        name: "Christmas Island"
    }, {
        icon: "🇨🇾",
        name: "Cyprus"
    }, {
        icon: "🇨🇿",
        name: "Czechia"
    }, {
        icon: "🇩🇬",
        name: "Diego Garcia"
    }, {
        icon: "🇩🇯",
        name: "Djibouti"
    }, {
        icon: "🇩🇰",
        name: "Denmark"
    }, {
        icon: "🇩🇲",
        name: "Dominica"
    }, {
        icon: "🇩🇴",
        name: "Dominican Republic"
    }, {
        icon: "🇩🇿",
        name: "Algeria"
    }, {
        icon: "🇪🇦",
        name: "Ceuta & Melilla"
    }, {
        icon: "🇪🇨",
        name: "Ecuador"
    }, {
        icon: "🇪🇪",
        name: "Estonia"
    }, {
        icon: "🇪🇬",
        name: "Egypt"
    }, {
        icon: "🇪🇭",
        name: "Western Sahara"
    }, {
        icon: "🇪🇷",
        name: "Eritrea"
    }, {
        icon: "🇪🇸",
        name: "Spain"
    }, {
        icon: "🇪🇹",
        name: "Ethiopia"
    }, {
        icon: "🇪🇺",
        name: "European Union"
    }, {
        icon: "🇫🇮",
        name: "Finland"
    }, {
        icon: "🇫🇯",
        name: "Fiji"
    }, {
        icon: "🇫🇰",
        name: "Falkland Islands"
    }, {
        icon: "🇫🇲",
        name: "Micronesia"
    }, {
        icon: "🇫🇴",
        name: "Faroe Islands"
    }, {
        icon: "🇬🇦",
        name: "Gabon"
    }, {
        icon: "🇬🇩",
        name: "Grenada"
    }, {
        icon: "🇬🇪",
        name: "Georgia"
    }, {
        icon: "🇬🇫",
        name: "French Guiana"
    }, {
        icon: "🇬🇬",
        name: "Guernsey"
    }, {
        icon: "🇬🇭",
        name: "Ghana"
    }, {
        icon: "🇬🇮",
        name: "Gibraltar"
    }, {
        icon: "🇬🇱",
        name: "Greenland"
    }, {
        icon: "🇬🇲",
        name: "Gambia"
    }, {
        icon: "🇬🇳",
        name: "Guinea"
    }, {
        icon: "🇬🇵",
        name: "Guadeloupe"
    }, {
        icon: "🇬🇶",
        name: "Equatorial Guinea"
    }, {
        icon: "🇬🇷",
        name: "Greece"
    }, {
        icon: "🇬🇸",
        name: "South Georgia & South Sandwich Islands"
    }, {
        icon: "🇬🇹",
        name: "Guatemala"
    }, {
        icon: "🇬🇺",
        name: "Guam"
    }, {
        icon: "🇬🇼",
        name: "Guinea-Bissau"
    }, {
        icon: "🇬🇾",
        name: "Guyana"
    }, {
        icon: "🇭🇰",
        name: "Hong Kong SAR China"
    }, {
        icon: "🇭🇲",
        name: "Heard & McDonald Islands"
    }, {
        icon: "🇭🇳",
        name: "Honduras"
    }, {
        icon: "🇭🇷",
        name: "Croatia"
    }, {
        icon: "🇭🇹",
        name: "Haiti"
    }, {
        icon: "🇭🇺",
        name: "Hungary"
    }, {
        icon: "🇮🇨",
        name: "Canary Islands"
    }, {
        icon: "🇮🇩",
        name: "Indonesia"
    }, {
        icon: "🇮🇪",
        name: "Ireland"
    }, {
        icon: "🇮🇱",
        name: "Israel"
    }, {
        icon: "🇮🇲",
        name: "Isle of Man"
    }, {
        icon: "🇮🇳",
        name: "India"
    }, {
        icon: "🇮🇴",
        name: "British Indian Ocean Territory"
    }, {
        icon: "🇮🇶",
        name: "Iraq"
    }, {
        icon: "🇮🇷",
        name: "Iran"
    }, {
        icon: "🇮🇸",
        name: "Iceland"
    }, {
        icon: "🇮🇹",
        name: "Italy"
    }, {
        icon: "🇯🇪",
        name: "Jersey"
    }, {
        icon: "🇯🇲",
        name: "Jamaica"
    }, {
        icon: "🇯🇴",
        name: "Jordan"
    }, {
        icon: "🇯🇵",
        name: "Japan"
    }, {
        icon: "🇰🇪",
        name: "Kenya"
    }, {
        icon: "🇰🇬",
        name: "Kyrgyzstan"
    }, {
        icon: "🇰🇭",
        name: "Cambodia"
    }, {
        icon: "🇰🇮",
        name: "Kiribati"
    }, {
        icon: "🇰🇲",
        name: "Comoros"
    }, {
        icon: "🇰🇳",
        name: "St. Kitts & Nevis"
    }, {
        icon: "🇰🇵",
        name: "North Korea"
    }, {
        icon: "🇰🇷",
        name: "South Korea"
    }, {
        icon: "🇰🇼",
        name: "Kuwait"
    }, {
        icon: "🇰🇾",
        name: "Cayman Islands"
    }, {
        icon: "🇰🇿",
        name: "Kazakhstan"
    }, {
        icon: "🇱🇦",
        name: "Laos"
    }, {
        icon: "🇱🇧",
        name: "Lebanon"
    }, {
        icon: "🇱🇨",
        name: "St. Lucia"
    }, {
        icon: "🇱🇮",
        name: "Liechtenstein"
    }, {
        icon: "🇱🇰",
        name: "Sri Lanka"
    }, {
        icon: "🇱🇷",
        name: "Liberia"
    }, {
        icon: "🇱🇸",
        name: "Lesotho"
    }, {
        icon: "🇱🇹",
        name: "Lithuania"
    }, {
        icon: "🇱🇻",
        name: "Latvia"
    }, {
        icon: "🇱🇾",
        name: "Libya"
    }, {
        icon: "🇲🇦",
        name: "Morocco"
    }, {
        icon: "🇲🇨",
        name: "Monaco"
    }, {
        icon: "🇲🇩",
        name: "Moldova"
    }, {
        icon: "🇲🇪",
        name: "Montenegro"
    }, {
        icon: "🇲🇫",
        name: "St. Martin"
    }, {
        icon: "🇲🇬",
        name: "Madagascar"
    }, {
        icon: "🇲🇭",
        name: "Marshall Islands"
    }, {
        icon: "🇲🇰",
        name: "North Macedonia"
    }, {
        icon: "🇲🇱",
        name: "Mali"
    }, {
        icon: "🇲🇲",
        name: "Myanmar (Burma)"
    }, {
        icon: "🇲🇳",
        name: "Mongolia"
    }, {
        icon: "🇲🇴",
        name: "Macao Sar China"
    }, {
        icon: "🇲🇵",
        name: "Northern Mariana Islands"
    }, {
        icon: "🇲🇶",
        name: "Martinique"
    }, {
        icon: "🇲🇷",
        name: "Mauritania"
    }, {
        icon: "🇲🇸",
        name: "Montserrat"
    }, {
        icon: "🇲🇹",
        name: "Malta"
    }, {
        icon: "🇲🇺",
        name: "Mauritius"
    }, {
        icon: "🇲🇻",
        name: "Maldives"
    }, {
        icon: "🇲🇼",
        name: "Malawi"
    }, {
        icon: "🇲🇽",
        name: "Mexico"
    }, {
        icon: "🇲🇾",
        name: "Malaysia"
    }, {
        icon: "🇲🇿",
        name: "Mozambique"
    }, {
        icon: "🇳🇦",
        name: "Namibia"
    }, {
        icon: "🇳🇨",
        name: "New Caledonia"
    }, {
        icon: "🇳🇪",
        name: "Niger"
    }, {
        icon: "🇳🇫",
        name: "Norfolk Island"
    }, {
        icon: "🇳🇬",
        name: "Nigeria"
    }, {
        icon: "🇳🇮",
        name: "Nicaragua"
    }, {
        icon: "🇳🇴",
        name: "Norway"
    }, {
        icon: "🇳🇵",
        name: "Nepal"
    }, {
        icon: "🇳🇷",
        name: "Nauru"
    }, {
        icon: "🇳🇺",
        name: "Niue"
    }, {
        icon: "🇳🇿",
        name: "New Zealand"
    }, {
        icon: "🇴🇲",
        name: "Oman"
    }, {
        icon: "🇵🇦",
        name: "Panama"
    }, {
        icon: "🇵🇪",
        name: "Peru"
    }, {
        icon: "🇵🇫",
        name: "French Polynesia"
    }, {
        icon: "🇵🇬",
        name: "Papua New Guinea"
    }, {
        icon: "🇵🇭",
        name: "Philippines"
    }, {
        icon: "🇵🇰",
        name: "Pakistan"
    }, {
        icon: "🇵🇱",
        name: "Poland"
    }, {
        icon: "🇵🇲",
        name: "St. Pierre & Miquelon"
    }, {
        icon: "🇵🇳",
        name: "Pitcairn Islands"
    }, {
        icon: "🇵🇷",
        name: "Puerto Rico"
    }, {
        icon: "🇵🇸",
        name: "Palestinian Territories"
    }, {
        icon: "🇵🇹",
        name: "Portugal"
    }, {
        icon: "🇵🇼",
        name: "Palau"
    }, {
        icon: "🇵🇾",
        name: "Paraguay"
    }, {
        icon: "🇶🇦",
        name: "Qatar"
    }, {
        icon: "🇷🇪",
        name: "Réunion"
    }, {
        icon: "🇷🇴",
        name: "Romania"
    }, {
        icon: "🇷🇸",
        name: "Serbia"
    }, {
        icon: "🇷🇺",
        name: "Russia"
    }, {
        icon: "🇷🇼",
        name: "Rwanda"
    }, {
        icon: "🇸🇦",
        name: "Saudi Arabia"
    }, {
        icon: "🇸🇧",
        name: "Solomon Islands"
    }, {
        icon: "🇸🇨",
        name: "Seychelles"
    }, {
        icon: "🇸🇩",
        name: "Sudan"
    }, {
        icon: "🇸🇪",
        name: "Sweden"
    }, {
        icon: "🇸🇬",
        name: "Singapore"
    }, {
        icon: "🇸🇭",
        name: "St. Helena"
    }, {
        icon: "🇸🇮",
        name: "Slovenia"
    }, {
        icon: "🇸🇯",
        name: "Svalbard & Jan Mayen"
    }, {
        icon: "🇸🇰",
        name: "Slovakia"
    }, {
        icon: "🇸🇱",
        name: "Sierra Leone"
    }, {
        icon: "🇸🇲",
        name: "San Marino"
    }, {
        icon: "🇸🇳",
        name: "Senegal"
    }, {
        icon: "🇸🇴",
        name: "Somalia"
    }, {
        icon: "🇸🇷",
        name: "Suriname"
    }, {
        icon: "🇸🇸",
        name: "South Sudan"
    }, {
        icon: "🇸🇹",
        name: "São Tomé & Príncipe"
    }, {
        icon: "🇸🇻",
        name: "El Salvador"
    }, {
        icon: "🇸🇽",
        name: "Sint Maarten"
    }, {
        icon: "🇸🇾",
        name: "Syria"
    }, {
        icon: "🇸🇿",
        name: "Eswatini"
    }, {
        icon: "🇹🇦",
        name: "Tristan Da Cunha"
    }, {
        icon: "🇹🇨",
        name: "Turks & Caicos Islands"
    }, {
        icon: "🇹🇩",
        name: "Chad"
    }, {
        icon: "🇹🇫",
        name: "French Southern Territories"
    }, {
        icon: "🇹🇬",
        name: "Togo"
    }, {
        icon: "🇹🇭",
        name: "Thailand"
    }, {
        icon: "🇹🇯",
        name: "Tajikistan"
    }, {
        icon: "🇹🇰",
        name: "Tokelau"
    }, {
        icon: "🇹🇱",
        name: "Timor-Leste"
    }, {
        icon: "🇹🇲",
        name: "Turkmenistan"
    }, {
        icon: "🇹🇳",
        name: "Tunisia"
    }, {
        icon: "🇹🇴",
        name: "Tonga"
    }, {
        icon: "🇹🇷",
        name: "Turkey"
    }, {
        icon: "🇹🇹",
        name: "Trinidad & Tobago"
    }, {
        icon: "🇹🇻",
        name: "Tuvalu"
    }, {
        icon: "🇹🇼",
        name: "Taiwan"
    }, {
        icon: "🇹🇿",
        name: "Tanzania"
    }, {
        icon: "🇺🇦",
        name: "Ukraine"
    }, {
        icon: "🇺🇬",
        name: "Uganda"
    }, {
        icon: "🇺🇲",
        name: "U.S. Outlying Islands"
    }, {
        icon: "🇺🇸",
        name: "United States"
    }, {
        icon: "🇺🇾",
        name: "Uruguay"
    }, {
        icon: "🇺🇿",
        name: "Uzbekistan"
    }, {
        icon: "🇻🇦",
        name: "Vatican City"
    }, {
        icon: "🇻🇨",
        name: "St. Vincent & Grenadines"
    }, {
        icon: "🇻🇪",
        name: "Venezuela"
    }, {
        icon: "🇻🇬",
        name: "British Virgin Islands"
    }, {
        icon: "🇻🇮",
        name: "U.S. Virgin Islands"
    }, {
        icon: "🇻🇳",
        name: "Vietnam"
    }, {
        icon: "🇻🇺",
        name: "Vanuatu"
    }, {
        icon: "🇼🇫",
        name: "Wallis & Futuna"
    }, {
        icon: "🇼🇸",
        name: "Samoa"
    }, {
        icon: "🇽🇰",
        name: "Kosovo"
    }, {
        icon: "🇾🇪",
        name: "Yemen"
    }, {
        icon: "🇾🇹",
        name: "Mayotte"
    }, {
        icon: "🇿🇦",
        name: "South Africa"
    }, {
        icon: "🇿🇲",
        name: "Zambia"
    }, {
        icon: "🇿🇼",
        name: "Zimbabwe"
    }, {
        icon: "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
        name: "England"
    }, {
        icon: "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
        name: "Scotland"
    }, {
        icon: "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
        name: "Wales"
    },
];
