import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getInvoices } from "store/actions/Billing";
import { bindActionCreators } from "redux";
import { RootState } from "../../store/store";
import InvoiceRow from "./InvoiceRow";
import WaitingDots from "components/WaitingDots";
import { InvoiceState } from 'store/reducers/Invoices';

interface Props {
    tenantId: string | null;
    invoiceState: InvoiceState;
    customerId: string | null;
}


interface Actions {
    getInvoices: () => void;
}

const InvoiceList: React.FC<Props & Actions> = ({ tenantId, invoiceState, getInvoices, customerId }) => {

    useEffect(() => {
        getInvoices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const invoices = invoiceState.getInvoices();

    if (invoices === undefined || invoices === null) {
        return <WaitingDots />
    }

    if (invoices.length > 0) {
        return (
            <table className="table table table-borderless align-middle table-centered table mb-0">
                <tbody>

                    {invoices
                        .sort((a, b) => a.GetCreatedDate() < b.GetCreatedDate() ? 1 : -1)
                        .filter(invoice => !customerId || invoice.customer === customerId)
                        .filter(invoice => !tenantId || invoice.tenant === tenantId)
                        .map((invoice, key) => {
                            return <InvoiceRow key={key} invoice={invoice} hideCustomer={customerId !== null} />
                        })}
                </tbody>
            </table>
        );
    }

    return <></>


};

const mapStateToProps = (state: RootState) => {
    return {
        invoiceState: state.Invoices
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getInvoices }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);
