import React from "react";
import {Card, CardBody} from "reactstrap";

interface Props {
    title: string;
    amount: number;
    timeSpan: string;
    percentage: number;
    trendUp?: boolean;
    subtitle?: boolean;
    icon?: string;
}

interface Actions {
}

const MoneyCard: React.FC<Props & Actions> = ({ subtitle, trendUp, icon, title, amount, timeSpan, percentage }) => {
    return (
        <>
            <Card>

                <CardBody>
                    {icon !== undefined &&
                        <div className="float-end">
                            <div className="avatar-sm mx-auto mb-4">
                                <span className="avatar-title rounded-circle bg-light font-size-24">
                                    <i className={icon}></i>
                                </span>
                            </div>
                        </div>
                    }
                    <div>
                        <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>{title}</p>
                        <h4 className="mb-1 mt-1">
                            &euro; {amount.toFixed(2)}
                        </h4>
                    </div>
                    {(subtitle === undefined || subtitle) &&
                        <p className="text-muted mt-3 mb-0">
                            <span className={`badge badge-soft-${trendUp !== undefined ? (trendUp ? "success" : "danger") : "success"} me-1`}>
                                {trendUp !== undefined &&
                                    <>
                                        {trendUp ?
                                            <i className="mdi mdi-arrow-up-bold me-1"></i>
                                            :
                                            <i className="mdi mdi-arrow-down-bold me-1"></i>
                                        }
                                    </>
                                }
                                {percentage.toFixed(0)}%
                            </span> {timeSpan}
                        </p>
                    }
                </CardBody>
            </Card>
        </>
    );
}

export default MoneyCard;
