import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "store/store";
import { Card, CardBody } from "reactstrap";
import { getEmails } from "store/actions/Emails";
import { EmailState } from 'store/reducers/Emails';
import { Expense } from "models/Expense";
import { Email } from "models/Email";
import EmailRowExpense from "pages/Email/EmailRowExpense";
import WaitingDots from "../../components/WaitingDots";

interface Props {
    emailState: EmailState;
    expense: Expense;
    callback: () => void;
}

interface Actions {
    getEmails: () => void;
}

const ExpenseSuggestedEmails: React.FC<Props & Actions> = ({ callback, expense, emailState, getEmails }) => {

    const [nameKeyword, setNameKeyword] = useState("")


    useEffect(() => {
        getEmails()
        // eslint-disable-next-line
    }, [getEmails]);

    if (emailState.getEmails() === undefined) {
        return <WaitingDots />
    }

    var emails = emailState.getEmails();

    var filterDatePast = new Date(new Date(expense.GetDate()).setDate(new Date(expense.GetDate()).getDate() - 30));
    var filterDateFuture = new Date(new Date(expense.GetDate()).setDate(new Date(expense.GetDate()).getDate() + 14));

    const FilterEmails = (email: Email) => {
        var score = 0;

        //needs more regex vars to implement in
        let expenseParts: RegExp[] = [];
        let expenseSupplierParts: RegExp[] = [];
        expense.name.split(" ").forEach(part => {
            // Find numbers
            if (new RegExp(".*\\d\\d\\d.*").test(part)) {
                expenseParts.push(new RegExp("^.*" + part + ".*$", 'gmi'))
            }
        })
        expense.supplier.name.split(" ").forEach(part => {
            // Find numbers
            expenseSupplierParts.push(new RegExp("^.*" + part + ".*$", 'gmi'))
        })

        // var regExExpenseSupplier = new RegExp("^.*" + expense.supplier.name.split(" ")[0] + ".*$", 'gmi');

        //step 1: check of subject includes expense name
        email.subject.split(" ").forEach(part => {
            expenseParts.forEach(exPart => {
                if (exPart.test(part)) {
                    score = score + 0.5;
                }
            })
            expenseSupplierParts.forEach(exPart => {
                if (exPart.test(part)) {
                    score = score + 0.1;
                }
            })
        })
        email.sender_name.split(" ").forEach(part => {
            expenseParts.forEach(exPart => {
                if (exPart.test(part)) {
                    score = score + 0.4;
                }
            })
            expenseSupplierParts.forEach(exPart => {
                if (exPart.test(part)) {
                    score = score + 0.1;
                }
            })
        })
        email.body.split(" ").forEach(part => {
            expenseParts.forEach(exPart => {
                if (exPart.test(part)) {
                    score = score + 0.5;
                }
            })
            expenseSupplierParts.forEach(exPart => {
                if (exPart.test(part)) {
                    score = score + 0.2;
                }
            })
        })
        expenseParts.forEach(exPart => {
            if (exPart.test(email.body.toString())) {
                score = score + 0.3;
            }
        })

        //depending on r&d score can change in the future
        if (score >= 0.3) {
            return true;
        }

        return false;
    }


    const filteredMails = emailState.getEmails().filter(
        email => email.subject.toLowerCase().includes(nameKeyword.toLowerCase())
            || email.recipient.toLowerCase().includes(nameKeyword.toLowerCase())
            || email.sender_name.toLowerCase().includes(nameKeyword.toLowerCase())
            || email.body.toLowerCase().includes(nameKeyword.toLowerCase())
    )

    return (
        <Card>
            <CardBody>
                <div className="app-search mb-3" style={{ marginLeft: "5px", marginRight: "5px" }}>
                    <div className="position-relative">
                        <input type="text"
                            name="search" autoComplete={'off'} id="search" key="search" className="form-control" value={nameKeyword}
                            onInput={(e) => {
                                setNameKeyword(e.currentTarget.value)
                            }}
                            placeholder="Search..." />
                        <span className="mdi mdi-magnify"></span>
                    </div>
                </div>
                <div className="clearfix" />
                <p className="text-muted text-uppercase fw-semibold mb-0" style={{ fontSize: "13px" }}>Suggested conversations</p>
                <hr />
                {
                    nameKeyword === "" ?
                        emails
                            .filter(email => email.GetDate() > filterDatePast && email.GetDate() < filterDateFuture)
                            .filter(email => email.tenant === expense.tenant)
                            .filter(email => email.direction === "in")
                            .filter(FilterEmails)
                            .length === 0 ?
                            <div style={{ textAlign: "center" }}>No conversations found</div>
                            :
                            <ul className="message-list">
                                {emails
                                    .sort((a, b) => (a.date < b.date) ? 1 : (a.date > b.date) ? -1 : 0)
                                    .filter(email => email.GetDate() > filterDatePast && email.GetDate() < filterDateFuture)
                                    .filter(FilterEmails)
                                    .map(email => {
                                        return <EmailRowExpense callback={callback} key={email.id} expense={expense} email={email} search={false} />;
                                    })}
                            </ul>
                        :
                        filteredMails
                            .filter(email => email.direction === "in")
                            .length === 0 ?
                            <div style={{ textAlign: "center" }}>No conversations found</div>
                            :
                            <ul className="message-list">
                                <div className="text-muted" style={{ textAlign: "center" }}>Only conversations with attachments are shown</div>
                                <br />
                                {
                                    filteredMails
                                        .filter(email => email.direction === "in")
                                        .map(email => {
                                            return <EmailRowExpense callback={callback} key={email.id} expense={expense} email={email} search={true} />;
                                        })
                                }
                            </ul>
                }
                {
                    emails.length === 0 &&
                    <div style={{ textAlign: 'center' }}>
                        <WaitingDots delay={100} />
                    </div>
                }
            </CardBody>
        </Card>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        emailState: state.Emails
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getEmails }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseSuggestedEmails);
