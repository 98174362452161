import PropTypes from "prop-types";
import React, {Component} from "react";
import {connect} from "react-redux";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Rightbar from "../CommonForBoth/Rightbar";
import classNames from "classnames";
import EmailFetcher from "components/EmailFetcher";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentWillUnmount() {
    //make sure to remove body click event fot toggle rightbar
    document.body.removeEventListener("click", this.hideRightbar, true);
  }

  render() {
    const isUIpath = window.location.pathname === "/ui-components";
    return (
      <React.Fragment>
        <div id="layout-wrapper">
          <Header />
          <Sidebar theme="dark" type="normal" isMobile={this.state.isMobile} />
          <div
            className={classNames(
              { "main-content overflow-visible": isUIpath },
              { "main-content": !isUIpath }
            )}
          >
            {this.props.children}
          </div>

          <Footer />
          <EmailFetcher />
        </div>
        {this.props.showRightSidebar ? <Rightbar /> : null}
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {})(Layout);
