import React from "react";
import { Invoice } from "models/Invoice";

interface Props {
    invoices: Invoice[];
}

const InvoivesOutstanding: React.FC<Props> = ({ invoices }) => {

    return (
        <>
            {invoices
                .filter(invoice => !invoice.IsPaid())
                .filter(invoice => !invoice.draft)
                .length > 0 ?
                <div className={"alert alert-warning fade show"} role="alert">
                    <div style={{ fontSize: "17px", display: "flex", marginRight: "5px" }}>
                        {
                            invoices.filter(invoice => !invoice.IsPaid()).filter(invoice => !invoice.draft).length
                        }
                        <div style={{ fontSize: "17px", marginLeft: '10px', fontWeight: "lighter", display: "flex" }}>
                            {
                                invoices.filter(invoice => !invoice.IsPaid()).filter(invoice => !invoice.draft).length > 1 ? " unpaid invoices" : " unpaid invoice"
                            }
                        </div>
                    </div>
                </div>
                : <></>
            }

        </>
    )
}

export default InvoivesOutstanding