import WaitingDots from 'components/WaitingDots';
import { Service } from 'models/Service';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/store';
import ChangeHighlight from "react-change-highlight";
import { Card, CardBody } from 'reactstrap';

interface Props {
    service: Service;
}

const ChecksStatusCard: React.FC<Props> = ({ service }) => {

    if (service === undefined) {
        return <WaitingDots />
    }

    let errors = 0;

    service.checks.forEach(check => {
        if (check.last_result) {
            if (!check.last_result.ok) {
                errors = errors + 1;
            }
        }
    })


    return (

        <>
            <Card>
                <CardBody>
                    <div>
                        <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>Checks status</p>
                    </div>


                    <div style={{ display: "flex" }}>
                        {service.checks.map(check => {
                            if (check.last_result != null) {
                                const time = check.last_result.result.rtt ?? check.last_result.result.time
                                return <p className="text-muted mb-1" style={{ marginRight: "30px" }}>

                                    <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                            <span style={{ fontSize: "15px" }} className={`badge badge-soft-${check.last_result.ok !== undefined ? (check.last_result.ok ? "success" : "warning") : "success"} me-1`}>
                                                {check.name}
                                                <div style={{ lineHeight: "40%" }}>
                                                    <br />
                                                </div>
                                                {check.type}
                                                <div style={{ lineHeight: "20%" }}>
                                                    <br />
                                                </div>
                                            </span>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="mb-1">
                                                <ChangeHighlight>
                                                    <span
                                                        ref={React.createRef()}>{(time < 0 || time > 2999) ? "∞" : time ?? "∞"} ms</span>
                                                </ChangeHighlight>
                                            </h5>
                                            <p className="text-muted">{new Date(check.last_result.date).toUTCString()}</p>
                                        </div>

                                    </div>
                                </p>


                            }
                            return null
                        })}
                    </div>
                </CardBody>

            </Card>
        </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        services: state.Services,
    };
};


export default connect(mapStateToProps)(ChecksStatusCard);