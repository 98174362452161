import WaitingDots from 'components/WaitingDots';
import { Customer } from 'models/Customer';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import TenantName from "../../components/TenantName";
import { UuidNil } from "../../common/uuid";


interface Props {
    customer: Customer;
}

const CustomerDetailCard: React.FC<Props> = ({ customer }) => {

    const navigate = useNavigate();

    if (customer === undefined) {
        return <WaitingDots />
    }

    return (
        <Card>
            <CardBody>
                <div className="float-end">
                    <div className="avatar-sm mx-auto mb-4">
                        <span className="avatar-title rounded-circle bg-light font-size-24">
                            <i className={`rounded-circle mdi mdi-alpha-${customer.name.charAt(0).toLowerCase()}-circle text-primary`} style={{ fontSize: "55px" }} />
                        </span>
                    </div>
                </div>
                <div>
                    <p className="text-muted text-uppercase fw-semibold">{customer.name}</p>
                </div>
                <p>
                    {customer.address.street} {customer.address.number}<br />
                    {customer.address.zip_code} {customer.address.city}<br />
                    {customer.address.country} <br />
                    <Link to={`/conversations/compose?customer=${customer.id}`}>{customer.email}</Link>
                    <br />
                    {customer.is_tenant !== UuidNil &&
                        <code>Tenant: <TenantName id={customer.is_tenant} /> @ <TenantName id={customer.tenant} /></code>
                    }
                </p>
                <div style={{ float: "right" }}>
                    <button
                        type="button"
                        style={{ marginRight: "2px" }}
                        onClick={() => {
                            navigate("/customers/" + customer.id + "/edit")
                        }}
                        className="btn btn-primary waves-effect waves-light"
                    >
                        <i className=" bx bx-edit-alt"></i>
                    </button>
                </div>
            </CardBody>
        </Card>
    );
}

export default CustomerDetailCard;