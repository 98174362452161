import WaitingDots from 'components/WaitingDots';
import {Invoice} from 'models/Invoice';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Card, CardBody} from 'reactstrap';
import {RootState} from 'store/store';
import {deleteInvoiceContent, editBasicDataInvoice} from "store/actions/Billing";
import {bindActionCreators} from 'redux';
import {DebounceInput} from 'react-debounce-input';

interface Props {
    invoice: Invoice;
}

interface Actions {
    deleteInvoiceContent: (i: Invoice, contentId: string) => void;
    editBasicDataInvoice: (d: any) => void;
}



const InvoiceContentCard: React.FC<Props & Actions> = ({ invoice, deleteInvoiceContent, editBasicDataInvoice }) => {

    const [inEdit, setInEdit] = useState<boolean>(false)

    const deleteContent = (invoice: Invoice, id: string) => {
        deleteInvoiceContent(invoice, id)
    }

    const updateName = (invoice: Invoice, nameInvoice: string) => {

        const data = {
            id: invoice.id,
            name: nameInvoice,
            created: invoice.created,
            deadline: invoice.deadline
        }
        setInEdit(true);
        editBasicDataInvoice(data)
    }


    if (invoice === undefined) {
        return <WaitingDots />
    }

    const presentUom = (amount: number, uom: string): string => {
        if (uom === "min") {
            if (amount > 60) {
                amount = amount / 60;
                uom = "hrs"
            }
            if (amount > 24) {
                amount = amount / 24;
                uom = "days"
            }
            if (amount > 30) {
                amount = amount / 30;
                uom = "mos"
            }
        }
        if (uom === "GBm") {
            uom = "GB"
            amount = amount / (31 * 24 * 60)
        }
        return `${amount.toFixed(1)} ${uom}`;
    }

    if (invoice.draft || invoice.quote) {
        return <Card>
            <CardBody>
                <h4 className="card-title mb-4">
                    <div className="form-floating mb-3">
                        <DebounceInput
                            value={invoice.name}
                            minLength={1}
                            debounceTimeout={500}
                            onKeyDown={e => {
                                setInEdit(false);
                            }}
                            className={inEdit ? "form-control is-valid" : "form-control"}
                            onChange={e => {
                                invoice.name = e.target.value;
                                updateName(invoice, e.target.value)
                            }}
                            placeholder="invoicecontent"
                        />
                        <label className="">Name</label>
                    </div>
                </h4>
                <hr className="my-4"></hr>
                <div className="table-responsive">
                    <table className="table table table-borderless align-middle table-centered table mb-0">
                        <thead className="table-light">
                            <tr>
                                <th></th>
                                <th>Item</th>
                                <th>Description</th>
                                <th>Qty</th>
                                <th>Price</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoice.contents.map((content, i) => {
                                return <tr>
                                    <td> <button type="button"
                                        onClick={() => {
                                            deleteContent(invoice, content.id)
                                        }}
                                        className="btn btn-danger waves-effect waves-light">
                                        <i className="mdi mdi-delete"></i>
                                    </button></td>
                                    <td>{content.name}</td>
                                    <td>{content.description}</td>
                                    <td>{presentUom(content.amount, content.uom)}</td>
                                    <td>&euro; {content.price.toFixed(2)}</td>
                                    <td>&euro; {(content.amount * content.price).toFixed(2)}</td>
                                </tr>;
                            })}

                        </tbody>
                    </table>
                    <hr className="my-4"></hr>
                    <div className="float-end px-4">
                        <table className="table table-borderless">
                            <tbody>
                                <tr>
                                    <td className="left">
                                        <strong>Subtotal</strong>
                                    </td>
                                    <td className="right">€ {invoice.CalcTotalContents().toFixed(2)}</td>
                                </tr>

                                <tr>
                                    <td className="left">
                                        <strong>VAT (21%)</strong>
                                    </td>
                                    <td className="right">€ {invoice.CalcTaxes().toFixed(2)}</td>
                                </tr>

                                <tr>
                                    <td className="left">
                                        <strong>Total</strong>
                                    </td>
                                    <td className="right">
                                        <strong>€ {invoice.CalcGrandTotal().toFixed(2)}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </CardBody>
        </Card>
    }

    return (
        <Card>
            <CardBody>
                <h4 className="card-title mb-4">{invoice.quote ? "Quote" : "Invoice: " + invoice.name}</h4>
                <hr className="my-4"></hr>
                <div className="table-responsive">
                    <table className="table table-hover table-borderless align-middle table-centered table mb-0">
                        <thead className="table-light">
                            <tr>
                                <th>Item</th>
                                <th>Description</th>
                                <th>Qty</th>
                                <th>Price</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoice.contents.map(content => {
                                return <tr>
                                    <td>{content.name}</td>
                                    <td>{content.description}</td>
                                    <td>{content.amount} {content.uom}</td>
                                    <td>&euro; {content.price.toFixed(2)}</td>
                                    <td>&euro; {(content.amount * content.price).toFixed(2)}</td>
                                </tr>;
                            })}
                        </tbody>
                    </table>
                    <hr className="my-4"></hr>
                    <div className="float-end px-4">
                        <table className="table table-borderless">
                            <tbody>
                                <tr>
                                    <td className="left">
                                        <strong>Subtotal</strong>
                                    </td>
                                    <td className="right">€ {invoice.CalcTotalContents().toFixed(2)}</td>
                                </tr>

                                <tr>
                                    <td className="left">
                                        <strong>VAT (21%)</strong>
                                    </td>
                                    <td className="right">€ {invoice.CalcTaxes().toFixed(2)}</td>
                                </tr>

                                <tr>
                                    <td className="left">
                                        <strong>Total</strong>
                                    </td>
                                    <td className="right">
                                        <strong>€ {invoice.CalcGrandTotal().toFixed(2)}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        invoices: state.Invoices,
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ deleteInvoiceContent, editBasicDataInvoice }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(InvoiceContentCard);