import React, {useEffect} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {RootState} from "../../store/store";
import {getExpenses} from "store/actions/Expenses";
import WaitingDots from "components/WaitingDots";
import {ExpenseState} from 'store/reducers/Expenses';
import ExpenseRowSupplier from "./ExpenseRowSupplier";
import {GetRouterQuery} from "components/Utils/GetRouterQuery";

interface Props {
    expenseState: ExpenseState;
    supplierId: string | null;
}


interface Actions {
    getExpenses: () => void;
}

const ExpensesListSupplier: React.FC<Props & Actions> = ({ getExpenses, expenseState, supplierId }) => {

    const { id } = GetRouterQuery<{ id: string }>()

    useEffect(() => {
        getExpenses()
    }, [getExpenses])

    if (!supplierId) {
        return <WaitingDots />
    }

    return (
        <>
            <table className="table table table-borderless align-middle table-centered table mb-0">
                <tbody>
                    {
                        expenseState.getExpensesBySupplierId(supplierId).length > 0 &&
                        expenseState.getExpensesBySupplierId(supplierId)
                            .sort((a, b) => b.GetDate().getTime() - a.GetDate().getTime())
                            .map((expense, key) => {
                                return <ExpenseRowSupplier key={key} expense={expense} expenseId={id} />
                            })}
                </tbody>
            </table>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        expenseState: state.Expenses
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getExpenses }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpensesListSupplier);