type CallbackFn = (data: any) => any

const index = new Map<string, CallbackFn[]>()

export const RegisterWsHandler = (type: string, func: (data: any) => any, transform?: (data: any) => any)  => {
    let newFunc = func
    if (transform !== undefined) {
        newFunc = data => func(transform(data))
    }
    let arr = index.get(type)
    if (arr === undefined) {
        index.set(type, [newFunc])
        return
    }
    arr.push(newFunc)
    index.set(type, arr)
}

export const GetWsHandlers = (type: string): CallbackFn[] => {
    return index.get(type) ?? []
}