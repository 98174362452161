export class InvoiceContent {

    id!: string;
    type!: string;
    name!: string;
    description!: string;
    amount!: number;
    price!: number;
    uom!: string;


}

