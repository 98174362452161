import React from "react";

interface InputProps {
    name: string;
    type: string;
    value?: string;
    placeholder: string;
    onChange: ( x: string ) => void;
    onKeyDown?: ( e: KeyboardEvent ) => void;
    onFocus?: ( e: KeyboardEvent ) => void;
    onBlur?: ( e: KeyboardEvent ) => void;
    autocomplete?: boolean;
    autoFocus?: boolean;
    style?: object;

}

const Input = ( props: InputProps ) => {
    return <div className={ 'form-floating' }>
        <input
            autoFocus={props.autoFocus}
            // @ts-ignore
            autoComplete={props.autocomplete ? "true":"false"}
            name={ props.name }
            type={ props.type }
            placeholder={ props.placeholder }
            className="form-control"
            style={ {
                border      : "none",
                borderBottom: "1px solid black",
                borderRadius: "0px",
                marginLeft  : "10px",
                width       : "calc(100% - 20px)",
                ...props.style
            } }
            value={ props.value }
            onChange={ e => props.onChange( e.target.value ) }
            // @ts-ignore
            onKeyDown={ props.onKeyDown }
            // @ts-ignore
            onFocus={ props.onFocus }
            // @ts-ignore
            onBlur={ props.onBlur }
        />
        <label className="">{ props.placeholder }</label>
    </div>
}

export default Input;