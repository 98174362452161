import React, {useEffect, useState} from "react";

interface Props {
    delay?: number;
}

const WaitingDots: React.FC<Props> = ({delay}: Props) => {
    if (delay === undefined) {
        delay = 1000
    }
    const rollOver = 4;
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (amount + 1 === rollOver) {
                setAmount(0)
            } else {
                setAmount(amount + 1)
            }
        }, delay);
        return () => clearInterval(interval);
    });

    let response = "."
    for (let i = 0; i < amount; i++) {
        response += "."
    }

    return <span>{response}</span>
}

export default WaitingDots;