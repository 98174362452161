import React from "react";
import { Expense } from "models/Expense";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

interface Props {
    expense: Expense;
}

const ExpenseEditButton: React.FC<Props> = ({ expense }) => {

    const navigate = useNavigate();


    return <button
        type="button"
        onClick={() => {
            navigate('/expenses/' + expense.id + '/edit');
        }}
        className="btn btn-primary waves-effect waves-light button-width-mobile"
    >
        <i className=" bx bx-edit-alt"></i>
    </button>;
};

const mapStateToProps = (state: RootState) => {
    return {
        expenses: state.Expenses,
    };
};

export default connect(mapStateToProps)(ExpenseEditButton);