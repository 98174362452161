import { combineReducers } from 'redux';

import Authentication from './Authentication';
import User from './User';
import Users from './Users';
import Tenants from './Tenants';
import Customers from './Customers';
import Expenses from './Expenses';
import Invoices from './Invoices';
import Emails from './Emails';
import Calls from "./Calls";
import Persons from "./Persons";
import Recurring from './Recurring';
import Services from './Services';


export default combineReducers({
  Authentication,
  User,
  Calls,
  Expenses,
  Tenants,
  Users,
  Persons,
  Customers,
  Invoices,
  Emails,
  Recurring,
  Services
});
