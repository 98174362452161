import React, {useEffect} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {connect} from "react-redux";
import {addUserToTenant, deleteUserFromTenant, getTenant} from "store/actions/Tenants";
import {bindActionCreators} from "redux";
import {RootState} from "../../store/store";
import {GetRouterQuery} from '../../components/Utils/GetRouterQuery'
import {getUsers} from "store/actions/Users";
import {getCustomers} from "store/actions/Customers";
import TenantTab from "./TenantTab";
import TenantUsers from "./TenantUsers";
import RevenueCard from "components/RevenueCard";
import OutstandingCard from "components/OutstandingCard";
import ProfitCard from "components/ProfitCard";
import PageMeta from "components/PageMeta";
import {CustomerState} from 'store/reducers/Customers';
import {TenantState} from 'store/reducers/Tenants';
import {UsersState} from 'store/reducers/Users';
import LastQCard from "../../components/LastQCard";

interface Props {
    tenantState: TenantState;
    usersState: UsersState;
    customerState: CustomerState;
}

interface Actions {
    getTenant: (id: string) => void;
    getUsers: () => void;
    getCustomers: () => void;
}

const TenantDetail: React.FC<Props & Actions> = ({ usersState, getUsers, tenantState, getTenant, customerState, getCustomers }) => {

    const { id } = GetRouterQuery<{ id: string }>()

    useEffect(() => {
        getUsers();
        getTenant(id);
        getCustomers();
    }, [getUsers, getTenant, getCustomers, id])

    const tenant = tenantState.getTenantById(id);
    if (tenant === null) {
        return <></>
    }


    const tenantCustomers = customerState.getCustomersByTenantId(id);
    if (tenantCustomers === null) {
        return <></>
    }

    return (
        <>
            <PageMeta title={`${tenant.name} | Tenants`} />
            <div className="page-content">

                <Row>
                    <Col md={6} xl={3} xs={12} sm={12}>
                        <Row>
                            <Col md={12} xl={12} xs={6} sm={6}>
                                <Card>
                                    <CardBody>
                                        <div className="float-end">
                                            {tenant.layout.logo !== "" ?
                                                <div className="avatar-sm mx-auto mb-4" dangerouslySetInnerHTML={{ __html: tenant.layout.logo }}>
                                                </div>
                                                :
                                                <div className="avatar-sm mx-auto mb-4">
                                                    <span className="avatar-title rounded-circle bg-light font-size-24">
                                                        <i className={`rounded-circle mdi mdi-alpha-${tenant.name.charAt(0).toLowerCase()}-circle text-primary`} style={{ fontSize: "55px" }} />
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            <p className="text-muted text-uppercase fw-semibold">{tenant && tenant.name}</p>
                                        </div>
                                    </CardBody>
                                </Card>
                                <RevenueCard tenantId={id} />
                            </Col>
                            <Col md={12} xl={12} xs={6} sm={6}>
                                <ProfitCard tenantId={id} />
                                <OutstandingCard tenantId={id} />
                                <LastQCard tenantId={id} />
                                <TenantUsers users={usersState.getUsers()} tenant={tenant} />
                            </Col>
                        </Row>
                    </Col>

                    <Col md={9} xl={9}>
                        <TenantTab />
                    </Col>

                </Row>

            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        usersState: state.Users,
        tenantState: state.Tenants,
        customerState: state.Customers
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getTenant, getUsers, addUserToTenant, deleteUserFromTenant, getCustomers }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantDetail);