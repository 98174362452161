export class RequestTypes<T = {}> {

  constructor(base: string) {
    this.base = base;
    this.SUCCESS = `${base}_SUCCESS`;
    this.REQUEST = `${base}_REQUEST`;
    this.FAILURE = `${base}_FAILURE`;
  }

  request = () => new Action(this.REQUEST).toPlain()
  success = (data?: T) => new Action<T>(this.SUCCESS, data).toPlain()
  failure = (error: object) => new Action(this.FAILURE, error).toPlain()

  payload!: T;
  base: string;
  SUCCESS: string;
  REQUEST: string;
  FAILURE: string;
}

export class EventType<T = {}> {

  constructor(base: string) {
    this.EVENT = `${base}_EVENT`;
  }

  payload = (data: T) => new Action<T>(this.EVENT, data).toPlain()

  EVENT: string;
}

export class Action<T = {}> {

  constructor(type: string, payload?: T) {
    this.type = type;
    this.payload = payload;
  }

  toPlain(): object {
    return {
      type: this.type,
      payload: this.payload
    };
  }

  type: string;
  payload?: T;
}

class RequestCache {

  constructor() {
    this.caches = {};
  }

  caches: { [index: string]: Promise<any> | undefined };

  startRequest(id: string, promise: () => Promise<any>): Promise<any> {
    if (this.caches[id] !== undefined) {
      // @ts-ignore
      return this.caches[id]
    }

    const resetPromise = (resp: any) => {
      // setTimeout(() => {
      this.caches[id] = undefined
      // }, 1000)
      return resp
    }

    const prom = promise().then(resetPromise).catch(resetPromise)

    this.caches[id] = prom
    return prom
  }
}

const caches = new RequestCache();

export const generateAction = (types: RequestTypes, fn: () => Promise<any>): Promise<any> => {
  //@ts-ignore
  return (dispatch: (action: object) => any) => {
    dispatch(types.request());

    return caches.startRequest(types.base, fn)
      .then(resp => {
        dispatch(types.success(resp));
        return resp;
      })
      .catch(resp => {
        const msg = resp.message;
        dispatch(types.failure(msg));
        return Promise.reject(msg);
      });
  };
}