import React from "react";
import {Link} from "react-router-dom";
import {ReactComponent as LogoDark} from "../../assets/images/logo-dark.svg";

const AuthLogo: React.FC = () => {
    return (
        <>
            <div className="text-center mb-5">
                <Link to="/" className="auth-logo">
                    <LogoDark />
                </Link>
            </div>
        </>
    );
};

export default AuthLogo;
