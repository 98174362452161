import {LOGOUT_USER_ACCOUNT} from "store/actions/Auth";
import {createReducer} from "./Util";
import {Expense} from "models/Expense";
import {ADD_EXPENSE, EDIT_EXPENSE, GET_EXPENSES} from "store/actions/Expenses";

export class ExpenseState {

  private expenses: { [index: string]: Expense }

  constructor() {
    this.expenses = {}
  }

  public setExpenses(expenses: Expense[]) {
    this.expenses = {}
    expenses.forEach(expense => {
      this.setExpense(expense)
    })
    return this
  }

  public setExpense(expense: Expense) {
    this.expenses[expense.id] = expense
    return this
  }

  public getExpenseById(id: string): Expense | null {
    return this.expenses[id] ?? null
  }

  public getExpenses(): Expense[] {
    return Object.values(this.expenses)
  }

  public getExpensesBySupplierId(supplierId: string): Expense[] {
    return Object.values(this.expenses).filter(a => a.supplier.id === supplierId);
  }

}

const Expenses = createReducer<ExpenseState>(new ExpenseState())
  .handle<Expense[]>([GET_EXPENSES.SUCCESS], (state, payload) => state.setExpenses(payload))
  .handle<Expense>([ADD_EXPENSE.SUCCESS], (state, payload) => state.setExpense(payload))
  .handle<Expense>([EDIT_EXPENSE.SUCCESS], (state, payload) =>  state.setExpense(payload))
  .handle([LOGOUT_USER_ACCOUNT.SUCCESS], () => new ExpenseState());

export default Expenses.handler;

