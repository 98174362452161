import React, { useEffect } from "react";
import { logoutAccount } from "storeAccount/actions/Auth";
import { connect } from "react-redux";
import { RootStateAccount } from "storeAccount/accountStore";
import { bindActionCreators } from "redux";
import { AuthenticationState } from "storeAccount/reducers/Authentication";

interface Props {
  authentication: AuthenticationState;
}

interface Actions {
  logoutAccount: () => void;
}

const Logout: React.FC<Actions & Props> = ({ authentication, logoutAccount }) => {

  useEffect(() => {

    if (authentication === "") {
      window.location.assign(`https://auth.galactic-group.nl/entry/${typeof window !== "undefined" && window.btoa(JSON.stringify({ redirect_url: `${window.location.origin}/auth-return` }))}`)
    }

  }, [authentication])



  return (
    <>
      <div className={"menu-account-logout"} style={{
        display: "flex",
        justifyContent: "center"
      }} onClick={
        () => {
          logoutAccount()
        }
      }>
        <div className={"menu-account"}>
          <button type="button" style={{}} className="btn btn-outline-danger waves-effect waves-light w-lg">
            <i className="mdi mdi-logout"></i>
            Logout
          </button>
        </div>
      </div>
    </>
  );
};


const mapStateToProps = (state: RootStateAccount) => {
  return {
    authentication: state.Authentication
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ logoutAccount }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);