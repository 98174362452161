
export class Call {

    id!: string;

    from!: string;

    person!: string;

    tenant!: string;

    status!: string;

    date!: number;

    public GetDate(): Date {
        return new Date(this.date * 1000);
    }
}