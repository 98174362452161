import {generateAction, RequestTypes} from "./Util";
import * as api from "../../services/Api";
import {Invoice} from "models/Invoice";
import {InvoiceContent} from "models/InvoiceContent";

export const GET_INVOICES = new RequestTypes("GET_INVOICES");
export const GET_INVOICE = new RequestTypes("GET_INVOICE");
export const ADD_INVOICE = new RequestTypes("ADD_INVOICE");
export const EDIT_INVOICE = new RequestTypes("EDIT_INVOICE");
export const DELETE_INVOICE_CONTENT = new RequestTypes("DELETE_INVOICE_CONTENT");
export const ADD_INVOICE_CONTENT = new RequestTypes("DELETE_INVOICE_CONTENT");
export const DELETE_INVOICE = new RequestTypes("DELETE_INVOICE");
export const CONVERT_INVOICE = new RequestTypes("CONVERT_INVOICE");
export const FINALIZE_INVOICE = new RequestTypes("FINALIZE_INVOICE");
export const EDIT_BASIC_DATA_INVOICE = new RequestTypes("EDIT_BASIC_DATA_INVOICE");
export const GET_ORPHANED_PAYMENTS = new RequestTypes("GET_ORPHANED_PAYMENTS");
export const LINK_ORPHANED_PAYMENT = new RequestTypes("LINK_ORPHANED_PAYMENT");


export const getOrphanedPayments = () => {
  return generateAction(GET_ORPHANED_PAYMENTS, () =>
    api.getOrphanedPayments()
  );
};

export const linkOrphanedPayment = (payment: string, invoice: string) => {
  return generateAction(LINK_ORPHANED_PAYMENT, () =>
    api.linkOrphanedPayment(payment, invoice)
  );
};

export const getInvoices = () => {
  return generateAction(GET_INVOICES, () =>
    api.getInvoices()
  );
};

export const getInvoice = (id: string) => {
  return generateAction(GET_INVOICE, () =>
    api.getInvoice(id)
  );
};

export const addInvoice = (invoice: Invoice) => {
  return generateAction(ADD_INVOICE, () =>
    api.addInvoice(invoice)
  );
};

export const addInvoiceContent = (ic: InvoiceContent, invoiceId: string) => {
  return generateAction(ADD_INVOICE_CONTENT, () =>
    api.addInvoiceContent(ic, invoiceId)
  );
};

export const deleteInvoiceContent = (invoice: Invoice, contentId: string) => {
  return generateAction(DELETE_INVOICE_CONTENT, () =>
    api.deleteInvoiceContent(invoice, contentId)
  );
};

export const deleteInvoice = (invoice: Invoice) => {
  return generateAction(DELETE_INVOICE, () =>
    api.deleteInvoice(invoice)
  );
};


export const convertInvoice = (invoice: Invoice) => {
  return generateAction(CONVERT_INVOICE, () =>
    api.convertInvoice(invoice)
  );
};


export const finalizeInvoice = (invoice: Invoice) => {
  return generateAction(FINALIZE_INVOICE, () =>
    api.finalizeInvoice(invoice)
  );
};


export const editBasicDataInvoice = (data: any) => {
  return generateAction(EDIT_BASIC_DATA_INVOICE, () =>
    api.editBasicDataInvoice(data)
  );
};
