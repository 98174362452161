import WaitingDots from 'components/WaitingDots';
import { Customer } from 'models/Customer';
import { Recurring } from 'models/Recurring';
import React from 'react';
import parser from 'cron-parser';
import LongClickButton from 'components/LongClickButton';
import { deleteRecurring } from 'store/actions/Customers';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from 'store/store';
import cronstrue from 'cronstrue';



interface Props {
    recurring: Recurring;
    customer: Customer;
    index: number;
}

interface Actions {
    deleteRecurring: typeof deleteRecurring;
}

const CustomerRecurringRow: React.FC<Props & Actions> = ({ recurring, customer, index, deleteRecurring }) => {

    const isCronValid = (freq: string) => {
        var cronregex = new RegExp(/^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/);
        return cronregex.test(freq);
    }

    if (recurring === undefined) {
        return <WaitingDots />
    }

    return (
        <>
            {
                <>

                    <div className="float-end ms-2 dropdown">
                        <LongClickButton color="danger" style={{ marginTop: '16px', width: "100%" }}
                            onClick={() => {
                                deleteRecurring(recurring)
                            }}>
                            <i className="mdi mdi-delete"></i></LongClickButton>
                    </div>
                    <div className="p-3 px-4">
                        <div className="d-flex align-items-start">
                            <div className="align-self-center">
                                <div className="flex-1">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <h4 className="mb-0"> {recurring.amount}x {recurring.name}</h4>
                                            <p className="text-muted">{recurring.description}</p>
                                        </div>
                                    </div>
                                    <div className="mb-0">
                                        <h5>&euro; {(recurring.amount * recurring.price).toFixed(2)}/ {recurring.interval !== undefined && recurring.interval !== "" && isCronValid(recurring.interval) && <span className="font-size-13 text-muted mb-0">{cronstrue.toString(recurring.interval, { use24HourTimeFormat: true, verbose: true })}</span>}</h5>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="float-end dropdown" style={{ marginTop: "-20px" }}>
                        {recurring.interval !== undefined && recurring.interval !== "" && isCronValid(recurring.interval) && <><span className="badge badge-soft-primary float-end">Next run: {parser.parseExpression(recurring.interval).next().toDate().toDateString()} {parser.parseExpression(recurring.interval).next().toDate().toLocaleTimeString()}</span></>}
                    </div>
                    <hr style={{ marginTop: "5px" }} />
                </>
            }
        </>

    );
}

const mapStateToProps = (state: RootState) => {
    return {
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ deleteRecurring }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRecurringRow)