import MoneyCard from "components/MoneyCard";
import {Invoice} from "models/Invoice";
import {AccountableContainer, FilterExternalInvoices, FilterFinalInvoices} from "models/InvoiceContainer";
import React from "react";
import {connect} from "react-redux";
import {RootState} from "store/store";
import {InvoiceState} from 'store/reducers/Invoices';


interface Props {
    invoiceState: InvoiceState;
    tenantId?: string;
    customerId?: string;
}

const RevenueCard: React.FC<Props> = ({ invoiceState, tenantId, customerId }) => {

    let filter = (arg: Invoice) => true
    if (tenantId !== undefined) {
        filter = (invoice: Invoice) => invoice.tenant === tenantId
    }
    if (customerId !== undefined) {
        filter = (invoice: Invoice) => invoice.customer === customerId
    }

    const qStart = () => {
        var today = new Date()
        var quarter = Math.floor((today.getMonth() / 3))
        return new Date(today.getFullYear(), quarter * 3, 1)
    }

    const qEnd = () => {
        return new Date(qStart().getFullYear(), qStart().getMonth() + 3, 0)
    }
    const percentageInQ = ((new Date()).getTime() - qStart().getTime()) / (qEnd().getTime() - qStart().getTime()) * 100;

    const amountThisQ = (new AccountableContainer<Invoice>(invoiceState.getInvoices().filter(filter)))
        .RangeThisQuarter()
        .Filter(FilterExternalInvoices)
        .Filter(FilterFinalInvoices)
        .CalcTotal(invoice => invoice.CalcTotalContents())

    const amountPrevQ = (new AccountableContainer<Invoice>(invoiceState.getInvoices().filter(filter)))
        .RangePreviousQuarter()
        .Filter(FilterExternalInvoices)
        .Filter(FilterFinalInvoices)
        .CalcTotal(invoice => invoice.CalcTotalContents()) / 100 * percentageInQ

    const percentage = ((amountThisQ - amountPrevQ) / (amountPrevQ)) * 100;


    return (
        <MoneyCard
            title={"Revenue"}
            amount={amountThisQ}
            timeSpan={"last quarter"}
            percentage={isFinite(percentage) ? percentage : 100}
            trendUp={percentage > 0}
            icon={`mdi mdi-cash-multiple text-primary`}
        />
    );

}

const mapStateToProps = (state: RootState) => {
    return {
        invoiceState: state.Invoices
    };
};

export default connect(mapStateToProps)(RevenueCard);
