import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { Button, Card } from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

interface Props {
  box: string;
  unread: number;
}

const EmailSideBar: React.FC<Props> = ({ box, unread }) => {

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Card className="email-leftbar">
        <Button
          type="button"
          color="danger"
          className="btn btn-danger btn-block waves-effect waves-light fw-semibold"
          onClick={() => {
            //setmodal(!modal)
            navigate("/conversations/compose")
          }}
          block

        >
          Compose
        </Button>
        <div className="mail-list mt-4">
          <Link to={"/conversations/inbox"} className={box === "inbox" ? "active" : ""}>
            <i className="bx bxs-inbox font-size-16 align-middle me-2"></i> Inbox{" "}
            <span className="ml-1 float-end">{unread > 0 && (unread)}</span>
          </Link>
          <Link to={"/conversations/starred"} className={box === "starred" ? "active" : ""}>
            <i className="bx bx-star font-size-16 align-middle me-2"></i>Starred
          </Link>
          <Link to={"/conversations/sent"} className={box === "sent" ? "active" : ""}>
            <i className="bx bx-mail-send font-size-16 align-middle me-2"></i>Sent
          </Link>
        </div>

        <h6 className="mt-4">Labels</h6>

        <div className="mail-list mt-1">
          <span className="mdi mdi-circle text-success me-2"></span>
          Invoices
          <br />
          <span className="mdi mdi-circle text-danger me-2"></span>
          Tickets
        </div>
      </Card>
    </React.Fragment>
  )
}

export default EmailSideBar