import { LOGOUT_USER_ACCOUNT } from "store/actions/Auth";
import { createReducer } from "./Util";
import {
  GET_RECURRINGS,
  ADD_RECURRING,
  DELETE_RECURRING
} from "store/actions/Customers";
import { Recurring } from "../../models/Recurring";

export class RecurringsState {

  private recurrings: { [index: string]: Recurring }

  constructor() {
    this.recurrings = {}
  }

  public setRecurrings(recurrings: Recurring[]) {
    recurrings.forEach(recurring => {
      this.setRecurring(recurring)
    })
    return this
  }

  public setRecurring(recurring: Recurring) {
    this.recurrings[recurring.id] = recurring
    return this
  }

  public deleteRecurring(id: string) {
    delete this.recurrings[id];
    return this
  }

  public getRecurringById(id: string): Recurring | null {
    return this.recurrings[id] ?? null
  }

  public getRecurringsByCustomerId(id: string): Recurring[] {
    return this.getRecurrings().filter(recurring => recurring.customer === id)
  }

  public getRecurrings(): Recurring[] {
    return Object.values(this.recurrings)
  }

}

const Persons = createReducer<RecurringsState>(new RecurringsState())
  .handle<Recurring[]>([GET_RECURRINGS.SUCCESS], (state, payload) => state.setRecurrings(payload))
  .handle<Recurring>([ADD_RECURRING.SUCCESS], (state, payload) => state.setRecurring(payload))
  .handle<string>([DELETE_RECURRING.SUCCESS], (state, payload) => state.deleteRecurring(payload))
  .handle([LOGOUT_USER_ACCOUNT.SUCCESS], () => new RecurringsState());

export default Persons.handler;
