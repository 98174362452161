import { IsValidEmail } from "common/EmailUtils";
import Input from "components/Common/Input";
import Copyright from "components/Copyright";
import PageMeta from "components/PageMeta";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Card, Col, Container, Row } from "reactstrap";
import { bindActionCreators } from "redux";
import { loginUserAccount } from "../../store/actions/Auth";
import { AuthenticationState } from "../../store/reducers/Authentication";
import { RootState } from "../../store/store";
import AuthLogo from "./AuthLogo";

interface Props {
    authentication: AuthenticationState;
}

interface Actions {
    loginUserAccount: typeof loginUserAccount;
}

const Login: React.FC<Props & Actions> = ( {
                                               authentication,
                                               loginUserAccount,
                                           } ) => {
    const [ error, setError ] = useState<string | null>( null )

    const [ email, setEmail ] = useState( "" );
    const [ password, setPassword ] = useState( "" );
    const [ totp, setTotp ] = useState( "" );

    const navigate = useNavigate();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect( () => {
        if ( authentication ) {
            navigate( "/" );
        }
    }, [ authentication ] );

    const handleValidSubmit = () => {
            loginUserAccount( email, password, totp )
                .then( () => {
                    navigate( "/" );
                } )
                .catch( () => {
                    setError( "Login has failed, check your credentials" )
                } );

    };

    const invalidForm = email === "" || !IsValidEmail(email) || password.length < 3 || totp.length !== 6;

    return ( <>
        <PageMeta title={ `Login` }/>
        <div className="account-pages my-0 pt-sm-5">
            <Container>
                <Row className="justify-content-center text-center">
                    <Col md={ 10 }>
                        <AuthLogo/>
                        <Card className="overflow-hidden">
                            <Row className="g-0">
                                <Col lg={ 6 }>
                                    <div className="p-lg-5 p-4">
                                        <div>
                                            <h5>Sign in</h5>
                                            <p className="text-muted">
                                                Don't try to hack this, you will be eaten by our interns.
                                            </p>
                                        </div>
                                        <div className="mt-4 pt-3">
                                            { error && <Alert color="danger">{ error }</Alert> }
                                            <Input
                                                name={ "email" }
                                                type={ "email" }
                                                placeholder={ "Email-address" }
                                                onChange={ setEmail }
                                            />
                                            <br />
                                            <Input
                                                name={ "password" }
                                                type={ "password" }
                                                placeholder={ "Password" }
                                                onChange={ setPassword }
                                                onKeyDown={ e => {
                                                    if ( e.key === "Enter" && !invalidForm ) {
                                                        handleValidSubmit()
                                                    }
                                                } }
                                            />
                                            <br />
                                            <Input
                                                name={ "totp" }
                                                type={ "text" }
                                                placeholder={ "TOTP" }
                                                onChange={ setTotp }
                                                onKeyDown={ e => {
                                                    if ( e.key === "Enter" && !invalidForm ) {
                                                        handleValidSubmit()
                                                    }
                                                } }
                                            />
                                            <br />

                                            <div className="mt-3">
                                                <button
                                                    disabled={invalidForm}
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    onClick={ () => {
                                                        handleValidSubmit()
                                                    } }
                                                >
                                                    Log In
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                                <Col lg={ 6 }>
                                    <div className="p-lg-5 p-4 bg-auth h-100 d-none d-lg-block">
                                        <div className="bg-overlay"></div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                        <Copyright/>
                    </Col>
                </Row>
            </Container>
        </div>
    </> );
};

const mapStateToProps = ( state: RootState ) => {
    return {
        authentication: state.Authentication,
    };
};

const mapDispatchToProps = ( dispatch: any ) => {
    return bindActionCreators( { loginUserAccount }, dispatch );
};

export default connect( mapStateToProps, mapDispatchToProps )( Login );
