import WaitingDots from 'components/WaitingDots';
import { Invoice } from 'models/Invoice';
import { InvoiceContent } from 'models/InvoiceContent';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Card, CardBody } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { RootState } from 'store/store';
import { addInvoiceContent } from "store/actions/Billing";

interface Props {
    invoice: Invoice;
}

interface Actions {
    addInvoiceContent: (ic: InvoiceContent, invoiceId: string) => void;
}

const InvoiceContentAddCard: React.FC<Props & Actions> = ({ addInvoiceContent, invoice }) => {

    const [content, setContent] = useState<InvoiceContent>(new InvoiceContent())
    const [error, setError] = useState<string>("")
    const [invoiceState] = useState<Invoice>(invoice)

    const addContent = () => {

        if (
            invoiceState
            && (content.name !== "" && content.name !== undefined)
            && (content.amount !== undefined)
            && (content.price !== undefined)
        ) {
            setError("")
            addInvoiceContent(content, invoiceState.id)
                //@ts-ignore
                .then(() => {
                    //@ts-ignore
                    document.getElementById('form').reset();
                    setContent(new InvoiceContent())
                })
        } else {
            setError("Name & amount & price are mandatory")
        }

    }


    if (invoice === undefined) {
        return <WaitingDots />
    }

    if (invoice.draft || invoice.quote) {
        return <Card>
            <CardBody>
                {
                    error !== "" &&
                    <Alert color="danger">{error}</Alert>
                }
                <div>
                    <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>
                        Add content
                    </p>
                </div>
                <div className="table-responsive">
                    <form id="form" name='form'>
                        <table className="table table table-borderless align-middle table-centered table mb-0">
                            <tbody>
                                <tr className="td-tr-mobile">
                                    <td className="td-tr-mobile">
                                        <div className="form-floating mb-3">
                                            <input type="text" name="name" className="form-control" defaultValue={content.name} onInput={e => {
                                                content.name = e.currentTarget.value
                                                setContent(content)
                                            }} placeholder="name" />
                                            <label className="">Name</label>
                                        </div>
                                    </td>
                                    <td className="td-tr-mobile"> <div className="form-floating mb-3">
                                        <input type="text" name="description" className="form-control" defaultValue={content.description} onInput={e => {
                                            content.description = e.currentTarget.value
                                            setContent(content)
                                        }} placeholder="description" />
                                        <label className="">Description</label>
                                    </div></td>
                                    <td className="td-tr-mobile">  <div className="form-floating mb-3">
                                        <input type="number" name="qty" defaultValue={content.amount} className="form-control" onInput={e => {
                                            content.amount = parseFloat(e.currentTarget.value)
                                            setContent(content)
                                        }} placeholder="qty" />
                                        <label className="">Qty</label>
                                    </div></td>
                                    <td className="td-tr-mobile">  <div className="form-floating mb-3">
                                        <input type="text" name="uom" defaultValue={content.uom} className="form-control" onInput={e => {
                                            content.uom = e.currentTarget.value
                                            setContent(content)
                                        }} placeholder="uom" />
                                        <label className="">Uom</label>
                                    </div></td>
                                    <td className="td-tr-mobile">  <div className="form-floating mb-3">
                                        <input type="number" name="price" defaultValue={content.price} className="form-control" onInput={e => {
                                            content.price = parseFloat(e.currentTarget.value)
                                            setContent(content)
                                        }} placeholder="price" />
                                        <label className="">Price</label>
                                    </div></td>

                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
                <div className="mt-3">
                    <button
                        id="buttonCustomer"
                        name="buttonCustomer"
                        className="btn btn-primary w-100 waves-effect waves-light"
                        onClick={() => {
                            addContent()
                        }}
                    >
                        Add
                    </button>
                </div>
            </CardBody>
        </Card>
    }

    return <></>
}

const mapStateToProps = (state: RootState) => {
    return {
        invoices: state.Invoices,
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ addInvoiceContent }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceContentAddCard);