import { generateAction, RequestTypes } from "./Util";
import * as api from "../../services/Api";
import { Email } from "models/Email";

export const GET_EMAILS = new RequestTypes("GET_EMAILS");
export const GET_EMAIL = new RequestTypes("GET_EMAIL");
export const GET_EMAILS_UNREAD = new RequestTypes("GET_EMAILS_UNREAD");
export const GET_EMAILS_AFTER = new RequestTypes("GET_EMAILS_AFTER");
export const SEND_EMAIL = new RequestTypes("SEND_EMAIL");
export const STAR_EMAIL = new RequestTypes("STAR_EMAIL");
export const READ_EMAIL = new RequestTypes("READ_EMAIL");
export const DELETE_EMAIL = new RequestTypes("DELETE_EMAIL");
export const SET_EMAIL_CUSTOMER = new RequestTypes("SET_EMAIL_CUSTOMER");

export const getEmails = () => {
  return generateAction(GET_EMAILS, () =>
    api.getEmails()
  );
};

export const getEmailsAfter = (date: number) => {
  return generateAction(GET_EMAILS_AFTER, () =>
    api.getEmailsAfter(date)
  );
};

export const getUnreadEmails = () => {
  return generateAction(GET_EMAILS_UNREAD, () =>
    api.getUnreadEmails()
  );
};

export const sendEmail = (email: Email) => {
  return generateAction(SEND_EMAIL, () =>
    api.sendEmail(email)
  );
};


export const readEmail = (email: Email) => {
  return generateAction(READ_EMAIL, () =>
    api.readEmail(email)
  );
};

export const getEmail = (id: string) => {
  return generateAction(GET_EMAIL, () =>
    api.getEmail(id)
  );
};


export const deleteEmail = (email: Email) => {
  return generateAction(DELETE_EMAIL, () =>
    api.deleteEmail(email)
  );
};


export const starEmail = (email: Email, body: any) => {
  return generateAction(STAR_EMAIL, () =>
    api.starEmail(email, body)
  );
};

export const setEmailCustomer = (email: Email, customer: string) => {
  return generateAction(SET_EMAIL_CUSTOMER, () =>
    api.setEmailCustomer(email, customer)
  );
};
