import MoneyCard from "components/MoneyCard";
import {Invoice} from "models/Invoice";
import {AccountableContainer, FilterFinalInvoices} from "models/InvoiceContainer";
import React from "react";
import {connect} from "react-redux";
import {RootState} from "store/store";
import {InvoiceState} from 'store/reducers/Invoices';

interface Props {
    invoiceState: InvoiceState;
    tenantId: string;
}

const OutstandingCard: React.FC<Props> = ({ invoiceState, tenantId }) => {

    const unpaid = (new AccountableContainer<Invoice>(invoiceState.getInvoices().filter(invoice => invoice.tenant === tenantId)))
        .Filter(FilterFinalInvoices)
        .Filter(invoice => !invoice.IsPaid())
        .CalcTotal(invoice => invoice.CalcPendingTotal())

    return (
        <MoneyCard
            title={"Outstanding"}
            amount={unpaid}
            subtitle={false}
            timeSpan={"last week"}
            percentage={0}
            icon={`mdi mdi-clock text-primary`}
        />
    );

}

const mapStateToProps = (state: RootState) => {
    return {
        invoiceState: state.Invoices,
    };
};

export default connect(mapStateToProps)(OutstandingCard);
