import React, {useEffect} from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {RootState} from "../../store/store";
import {getTenants} from "store/actions/Tenants";
import WaitingDots from "components/WaitingDots";
import PageMeta from "components/PageMeta";
import {TenantState} from 'store/reducers/Tenants';
import {UserState} from 'store/reducers/User';

interface Props {
  tenantState: TenantState;
  userState: UserState;
}

interface Actions {
  logoutUserAccount: () => void;
  getTenants: () => void;
}

const UserProfile: React.FC<Actions & Props> = ({ tenantState, userState, getTenants }) => {

  const user = userState.getUser();

  useEffect(() => {
    getTenants()
  }, [getTenants])

  if (user === null) {
    return <WaitingDots />
  }

  return (
    <>
      <div className="page-content">
        <PageMeta title={`Profile`} />
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 align-self-center">
                      <div className="text">
                        <h2>{user.name}</h2>
                        <h6>{"Email: " + user.email}</h6>
                        <h6>{"Phone: " + user.phone}</h6>
                        <h6>{"Region: " + user.region}</h6>
                        <h6>{"Activated: " + user.activated}</h6>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 align-self-center">
                      <div className="text">
                        <h5>Accounts</h5>

                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    userState: state.User,
    tenantState: state.Tenants
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ getTenants }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);