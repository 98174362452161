import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../store/store";
import { getExpenses } from "store/actions/Expenses";
import WaitingDots from "components/WaitingDots";
import PageMeta from "components/PageMeta";
import { Card, CardBody, Col, Row } from "reactstrap";
import ExpenseAddButton from "./ExpenseAddButton";
import ExpenseRowIndex from "./ExpenseRowIndex";
import { ExpenseState } from 'store/reducers/Expenses';

interface Props {
    expenseState: ExpenseState;
}


interface Actions {
    getExpenses: () => void;
}

const Expenses: React.FC<Props & Actions> = ({ expenseState, getExpenses }) => {

    const [nameKeyword, setNameKeyword] = useState("")

    //@ts-ignore
    var url = new URL(window.location);
    const searchTerm = url.searchParams.get('q')

    useEffect(() => {
        getExpenses()
        // eslint-disable-next-line
        if (searchTerm != undefined) {
            //@ts-ignore
            setNameKeyword(searchTerm);
        }
        // eslint-disable-next-line
    }, [getExpenses])

    if (expenseState.getExpenses() === undefined) {
        return <WaitingDots />
    }

    const refreshSearch = () => {
        const params = new URLSearchParams(window.location.search)
        if (params.get('q') != null) {
            // @ts-ignore
            setNameKeyword(params.get('q'))
        }
    }

    const filteredExpenses = expenseState.getExpenses().filter(expense => expense.name.toLowerCase().includes(nameKeyword.toLowerCase()) || expense.supplier.name.toLowerCase().includes(nameKeyword.toLowerCase()))

    return (
        <div className="page-content">
            <PageMeta title={`Expenses`} />
            <Row>
                <Col md={12} xl={12}>
                    <Row>
                        <Col md={12}>
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <form className="app-search" style={{ marginLeft: "5px" }}>
                                    <div className="position-relative">
                                        <input type="text"
                                            name="search" id="search" key="search" className="form-control" value={nameKeyword}
                                            onChange={(e) => {
                                                //@ts-ignore
                                                var url = new URL(window.location);
                                                url.searchParams.set('q', e.target.value);
                                                //@ts-ignore
                                                window.history.pushState({}, '', url);
                                                refreshSearch()
                                            }}
                                            placeholder="Search..." />
                                        <span className="mdi mdi-magnify"></span>
                                    </div>
                                </form>
                                <ExpenseAddButton />
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table table-hover table-borderless align-middle table-centered table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>Created</th>
                                                    <th>Name</th>
                                                    <th>Tenant</th>
                                                    <th>Tax</th>
                                                    <th>Supplier</th>
                                                    <th>Total</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredExpenses.length > 0 && filteredExpenses
                                                    .sort((a, b) => (a.GetDate() < b.GetDate()) ? 1 : (a.GetDate() > b.GetDate()) ? -1 : 0)
                                                    .map(expense => <ExpenseRowIndex key={expense.id} expense={expense} />)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}




const mapStateToProps = (state: RootState) => {
    return {
        expenseState: state.Expenses,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getExpenses }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Expenses)