import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../store/store";
import { getServices } from "store/actions/Services";
import WaitingDots from "components/WaitingDots";
import PageMeta from "components/PageMeta";
import { Card, CardBody, Col, Row } from "reactstrap";
import { ServicesState } from 'store/reducers/Services';
import ServiceRow from "./ServiceRow";
import ServiceStatusBanner from "./ServiceStatusBanner";
import { Link } from "react-router-dom";

interface Props {
    servicesState: ServicesState;
}


interface Actions {
    getServices: () => void;
}

const Services: React.FC<Props & Actions> = ({ servicesState, getServices }) => {

    const [nameKeyword, setNameKeyword] = useState("")

    //@ts-ignore
    var url = new URL(window.location);
    const searchTerm = url.searchParams.get('q')

    useEffect(() => {
        getServices()
        // eslint-disable-next-line
        if (searchTerm != undefined) {
            //@ts-ignore
            setNameKeyword(searchTerm);
        }
        // eslint-disable-next-line
    }, [getServices])

    if (servicesState.getServices() === undefined) {
        return <WaitingDots />
    }

    const refreshSearch = () => {
        const params = new URLSearchParams(window.location.search)
        if (params.get('q') != null) {
            // @ts-ignore
            setNameKeyword(params.get('q'))
        }
    }

    console.log(servicesState.getServices())

    const filteredServices = servicesState.getServices().filter(service => service.name.toLowerCase().includes(nameKeyword.toLowerCase()))

    return (
        <div className="page-content">
            <PageMeta title={`Expenses`} />
            <Row>
                <Col md={12} xl={12}>
                    <Row>
                        <Col md={12}>
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <form className="app-search" style={{ marginLeft: "5px" }}>
                                    <div className="position-relative">
                                        <input type="text"
                                            name="search" id="search" key="search" className="form-control" value={nameKeyword}
                                            onChange={(e) => {
                                                //@ts-ignore
                                                var url = new URL(window.location);
                                                url.searchParams.set('q', e.target.value);
                                                //@ts-ignore
                                                window.history.pushState({}, '', url);
                                                refreshSearch()
                                            }}
                                            placeholder="Search..." />
                                        <span className="mdi mdi-magnify"></span>
                                    </div>
                                </form>
                                <Link to="/services/add" style={{ marginLeft: "10px", marginRight: "5px" }}>
                                    <button type="button" className="btn btn-success waves-effect waves-light">
                                        Add new service
                                    </button>
                                </Link>
                            </div>

                        </Col>
                    </Row>
                    <ServiceStatusBanner />
                    <Row >
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table table-borderless align-middle table-centered table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th style={{ width: "20%" }}>Name</th>
                                                    <th style={{ width: "20%" }}>Region</th>
                                                    <th>Checks</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredServices.length > 0 && filteredServices
                                                    .sort((a, b) => (a.region < b.region) ? 1 : ((b.region <= a.region) ? -1 : 0))
                                                    .map(service => <ServiceRow key={service.id} service={service} />)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}




const mapStateToProps = (state: RootState) => {
    return {
        servicesState: state.Services,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getServices }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Services)