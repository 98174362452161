import {generateAction, RequestTypes} from "./Util";
import * as api from "../../services/Api";

export const GET_USERS = new RequestTypes("USERS");

export const getUsers= () => {
  return generateAction(GET_USERS, () =>
    api.getUsers()
  );
};

