import React, { useState } from "react";
import { Recurring } from "models/Recurring";
import { Customer } from "models/Customer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addRecurring } from 'store/actions/Customers';
import { Alert } from "reactstrap";
import { RecurringsState } from "../../store/reducers/Recurring";
import { RootState } from "../../store/store";
import cronstrue from 'cronstrue';


interface Props {
    recurring: Recurring;
    customer: Customer;
    recurringState: RecurringsState;
    onCancel: () => void;
    onSave: () => void;
}

interface Actions {
    addRecurring: typeof addRecurring;
}

const CustomerRecurringAdd: React.FC<Props & Actions> = ({ recurring, customer, recurringState, onCancel, onSave, addRecurring }) => {

    const [error, setError] = useState<string | null>(null)
    const [interval, setInterval] = useState<string>("")

    const isCronValid = (freq: string) => {
        var cronregex = new RegExp(/^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/);
        return cronregex.test(freq);
    }

    const saveRecurring = () => {

        if (recurring.name === ""
            || recurring.name === undefined
            || recurring.price === undefined
            || recurring.amount === undefined
            || recurring.interval === ""
        ) {
            setError("Recurring needs a name, price, interval and amount")
        } else {
            recurring.customer = customer.id;
            recurring.interval = interval;
            addRecurring(recurring).then(() => onSave())
        }
    }

    return (
        <>
            {
                error &&
                <Alert color="danger">{error}</Alert>
            }

            <div className="form-floating mb-3">
                <button type="button"
                    onClick={() => {
                        onCancel();
                    }}
                    className="btn btn-danger waves-effect waves-light">
                    Cancel
                </button>
            </div>

            <div className="form-floating mb-3">
                <input type="text" name="namerecurring" className="form-control" defaultValue={recurring.name} onInput={e => {
                    recurring.name = e.currentTarget.value

                }} placeholder="name" />
                <label className="">Name</label>
            </div>

            <div className="form-floating mb-3">
                <input type="text" name="typerecurring" className="form-control" defaultValue={recurring.type} onInput={e => {
                    recurring.type = e.currentTarget.value

                }} placeholder="type" />
                <label className="">Type</label>
            </div>

            <div className="form-floating mb-3">
                <input type="text" name="descriptionrecurring" className="form-control" defaultValue={recurring.description} onInput={e => {
                    recurring.description = e.currentTarget.value

                }} placeholder="description" />
                <label className="">Description</label>
            </div>

            <div className="form-floating mb-3">
                <input type="number" name="qty" defaultValue={recurring.amount} className="form-control" onInput={e => {
                    recurring.amount = parseFloat(e.currentTarget.value)
                }} placeholder="qty" />
                <label className="">Qty</label>
            </div>

            <div className="form-floating mb-3">
                <input type="text" name="uom" defaultValue={recurring.uom} className="form-control" onInput={e => {
                    recurring.uom = e.currentTarget.value
                }} placeholder="uom" />
                <label className="">Uom</label>
            </div>

            <div className="form-floating mb-3">
                <input type="number" name="price" defaultValue={recurring.price} className="form-control" onInput={e => {
                    recurring.price = parseFloat(e.currentTarget.value)
                }} placeholder="price" />
                <label className="">Price</label>
            </div>

            <div className="form-floating mb-3">
                <input type="text" name="intervalrecurring" className="form-control" value={interval} onInput={e => {
                    setInterval(e.currentTarget.value)
                }} placeholder="interval" />
                <label className="">Interval   {interval !== undefined && interval !== "" && isCronValid(interval) && " (" + cronstrue.toString(interval, { use24HourTimeFormat: true, verbose: true }) + ")"}</label>
                <span className="badge badge-soft-primary" style={{ marginRight: "5px" }} onClick={() => {
                    // eslint-disable-next-line
                    setInterval("0 0 1 * *")
                }}>Every month</span>
                <span className="badge badge-soft-primary" style={{ marginRight: "5px" }}
                    onClick={() => {
                        // eslint-disable-next-line
                        setInterval("0 0 1 1 *")
                    }}>Every year</span>
                <span className="badge badge-soft-primary" style={{ marginRight: "5px" }} onClick={() => {
                    // eslint-disable-next-line
                    setInterval("0 0 1 */3 *")
                }}>Every quarter</span>
            </div>


            <div className="form-floating mb-3">

                <div className="mt-3">
                    <button
                        id="buttonPerson"
                        name="buttonPerson"
                        type="button"
                        onClick={() => {
                            saveRecurring()
                        }}
                        className="btn btn-primary w-100 waves-effect waves-light"
                    >
                        <i className="mdi mdi-account-plus"></i> Save
                    </button>
                </div>

            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        personsState: state.Persons,
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ addRecurring }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRecurringAdd);
