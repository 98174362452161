import {LOGOUT_USER_ACCOUNT} from "store/actions/Auth";
import {createReducer} from "./Util";
import {Call} from "../../models/Call";
import {GET_CALLS} from "../actions/Calls";

export class CallsState {

  private calls: Call[] = [];

  public setCalls(calls: Call[]): CallsState {
    this.calls = calls;
    return this;
  }

  public getCalls(): Call[] {
    return this.calls
  }

}

const Calls = createReducer<CallsState>(new CallsState())
  .handle<Call[]>([GET_CALLS.SUCCESS], (state, payload) => state.setCalls(payload))
  .handle([LOGOUT_USER_ACCOUNT.SUCCESS], () => new CallsState());

export default Calls.handler;
