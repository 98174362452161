import {LOGIN_USER_ACCOUNT, LOGOUT_USER_ACCOUNT} from "../actions/Auth";
import {createReducer} from "./Util";

export type AuthenticationState = string;

const Authentication = createReducer<AuthenticationState>("")
  .handle<string>([LOGIN_USER_ACCOUNT.SUCCESS], (_state, payload) => payload)
  .handle([LOGOUT_USER_ACCOUNT.SUCCESS], () => "");

export default Authentication.handler;
