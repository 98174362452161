import Date from 'components/Date';
import { Email } from 'models/Email';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { starEmail } from "store/actions/Emails";
import { RootState } from 'store/store';
import { fetchEmailAttachments } from "services/Api";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { addExpenseAttachment } from "../../store/actions/Expenses";
import { DroppedFile } from "../../components/DropzoneCustom";
import { Expense, ExpenseAttachment } from "../../models/Expense";

interface Props {
    expense: Expense;
    search: boolean;
    email: Email;
    addExpenseAttachment: (id: string, file: DroppedFile) => Promise<ExpenseAttachment[]>
    callback: () => void;
}

const EmailRowExpense: React.FC<Props> = ({ callback, email, addExpenseAttachment, expense, search }) => {
    const [attachments, setAttachments] = useState<string[]>([]);
    const [dropOpen, setDropOpen] = useState(false);

    useEffect(() => {
        fetchEmailAttachments(email.id).then((newAttachments) => {
            if (newAttachments != null) {
                setAttachments(newAttachments);
            }
        })
    }, [email.id])

    return (
        <>
            {
                (search && attachments.length <= 0) ? <></> :
                    <li key={email.id} id="email-expense" className="red-hover">
                        <div className="col-mail col-mail-1">
                            {attachments.length > 0 &&
                                <ButtonDropdown isOpen={dropOpen} toggle={() => setDropOpen(!dropOpen)}>
                                    <DropdownToggle caret color="primary">
                                        Attach
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {
                                            attachments.map(attachment => {
                                                return <DropdownItem key={attachment.split("*")[0]} onClick={() => {
                                                    const file = new DroppedFile();
                                                    file.name = attachment.split("*")[0];
                                                    file.content = attachment.split("*")[1];
                                                    addExpenseAttachment(expense.id, file).then(() => {
                                                        callback()
                                                        setDropOpen(false)
                                                    })
                                                }}>{attachment.split("*")[0]}</DropdownItem>
                                            })
                                        }
                                    </DropdownMenu>
                                </ButtonDropdown>
                            }

                            <>
                                {email.direction === "in" ?
                                    <i className="title" style={{ marginLeft: '50px' }} title={email.sender}>{email.sender_name}</i>
                                    :
                                    <i className="title" style={{ marginLeft: '50px' }}
                                        title={email.recipient}>{email.recipient.split(" ")[0]}</i>
                                }
                            </>
                        </div>
                        <div className="col-mail col-mail-2">
                            <Link to={"/conversation/" + email.id}>
                                <div className="subject" style={{ marginLeft: '50px' }}>
                                    {email.subject}{" "}
                                    {email.reference.includes("invoice") &&
                                        <Link to={"/invoice/" + email.reference.replace('invoice_', '')}>
                                            <span className="badge bg-success me-1 font-size-12 me-2 mb-3">
                                                Invoice
                                            </span>
                                        </Link>
                                    }
                                    {email.reference.includes("ticket") &&
                                        <span className="badge bg-danger me-1 font-size-12 me-2 mb-3">
                                            Ticket
                                        </span>
                                    }
                                    <span className="teaser" style={{ color: "grey" }}>
                                        - {email.body.replaceAll("<br />", " ").replace(/(\r\n|\n|\r)/gm, "").replaceAll(/<blockquote (.*?)>(.*?)<\/blockquote>/img, "").replaceAll(/<style(.*?)>(.*?)<\/style>/img, "").replaceAll(/<(.*?)>/img, " ").replaceAll(/\s{2,}/img, " ")}
                                    </span>
                                </div>
                                <div className="date">
                                    <Date date={email.GetDate()} />
                                </div>
                            </Link>
                        </div>

                    </li>
            }
        </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        customers: state.Customers,
        emails: state.Emails
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ starEmail, addExpenseAttachment }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailRowExpense);