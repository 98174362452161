import AccountMainPage from "pages/Account/AccountMainPage";
import Customers from "pages/Customer";
import CustomerAddForm from "pages/Customer/CustomerAddForm";
import CustomerDetail from "pages/Customer/CustomerDetail";
import CustomerEditForm from "pages/Customer/CustomerEditForm";
import EmailInbox from "pages/Email/EmailInbox";
import EmailView from "pages/Email/EmailView";
import ExpenseAddForm from "pages/Expenses/ExpenseAddForm";
import ExpenseDetail from "pages/Expenses/ExpenseDetail";
import ExpensesEdit from "pages/Expenses/ExpenseEditForm";
import Expenses from "pages/Expenses/Expenses";
import InvoiceDetail from "pages/Invoice/InvoiceDetail";
import Invoices from "pages/Invoice/Invoices";
import LoginScreenCustomer from "pages/LoginScreenCustomer";
import PaymentScreen from "pages/PaymentScreen";
import Persons from "pages/Persons/Persons";
import Services from "pages/Services";
import AddServiceForm from "pages/Services/AddServiceForm";
import ServiceDetail from "pages/Services/ServiceDetail";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import Websocket from "websocket/Websocket";
import "./assets/scss/theme.scss";
import AuthProtected from "./components/AuthProtected";
import NonAuthLayout from "./components/NonAuthLayout";
import VerticalLayout from "./components/VerticalLayout";
import Login from "./pages/Authentication/Login";
import AuthEntry from "./pages/AuthEntry";
import AuthReturn from "./pages/AuthReturn";
import Dashboard from "./pages/Dashboard";
import RegisterScreenCustomer from "./pages/RegisterScreenCustomer";
import Tenants from "./pages/Tenants";
import TenantAddForm from "./pages/Tenants/TenantAddForm";
import TenantDetail from "./pages/Tenants/TenantDetail";
import UserProfile from "./pages/User/UserProfile";
import UserProfiles from "./pages/User/UserProfiles";
import store from "./store/store";
import accountStore from "./storeAccount/accountStore";

const routes = [
    {
        path: "/login",
        auth: false,
        component: Login,
        layout: NonAuthLayout
    },
    {
        path: "/",
        auth: true,
        component: Dashboard,
        layout: VerticalLayout
    },
    {
        path: "/tenants",
        auth: true,
        component: Tenants,
        layout: VerticalLayout
    },
    {
        path: "/tenants/add",
        auth: true,
        component: TenantAddForm,
        layout: VerticalLayout
    },
    {
        path: "/tenants/:id",
        auth: true,
        component: TenantDetail,
        layout: VerticalLayout
    },
    {
        path: "/tenants/:id/expenses",
        auth: true,
        component: TenantDetail,
        layout: VerticalLayout
    },
    {
        path: "/tenants/:id/tickets",
        auth: true,
        component: TenantDetail,
        layout: VerticalLayout
    },
    {
        path: "/tenants/:id/customers",
        auth: true,
        component: TenantDetail,
        layout: VerticalLayout
    },
    {
        path: "/profile",
        auth: true,
        component: UserProfile,
        layout: VerticalLayout
    },
    {
        path: "/users",
        auth: true,
        component: UserProfiles,
        layout: VerticalLayout
    },
    {
        path: "/customers",
        auth: true,
        component: Customers,
        layout: VerticalLayout
    },
    {
        path: "/invoices",
        auth: true,
        component: Invoices,
        layout: VerticalLayout
    },
    {
        path: "/invoice/:id",
        auth: true,
        component: InvoiceDetail,
        layout: VerticalLayout
    },
    {
        path: "/customers/add",
        auth: true,
        component: CustomerAddForm,
        layout: VerticalLayout
    },
    {
        path: "/customers/:id",
        auth: true,
        component: CustomerDetail,
        layout: VerticalLayout
    },
    {
        path: "/customers/:id/edit",
        auth: true,
        component: CustomerEditForm,
        layout: VerticalLayout
    },
    {
        path: "/conversations/:box",
        auth: true,
        component: EmailInbox,
        layout: VerticalLayout
    },
    {
        path: "/conversation/:id",
        auth: true,
        component: EmailView,
        layout: VerticalLayout
    },
    {
        path: "/expenses",
        auth: true,
        component: Expenses,
        layout: VerticalLayout
    },
    {
        path: "/expenses/:id",
        auth: true,
        component: ExpenseDetail,
        layout: VerticalLayout
    },
    {
        path: "/expenses/:id/edit",
        auth: true,
        component: ExpensesEdit,
        layout: VerticalLayout
    },
    {
        path: "/tenants/:id/expenses/add",
        auth: true,
        component: ExpenseAddForm,
        layout: VerticalLayout
    },
    {
        path: "/persons",
        auth: true,
        component: Persons,
        layout: VerticalLayout
    },
    {
        path: "/services",
        auth: true,
        component: Services,
        layout: VerticalLayout
    },
    {
        path: "/services/add",
        auth: true,
        component: AddServiceForm,
        layout: VerticalLayout
    },
    {
        path: "/services/:id",
        auth: false,
        component: ServiceDetail,
        layout: VerticalLayout
    },
]

const App: React.FC = () => {

    var parts = window.location.hostname.split('.');
    var subdomain = parts.shift();
    // subdomain= "auth";

    return (<Router>

        {

            (subdomain === "pay" && <Provider store={store}>
                <NonAuthLayout>
                    <PaymentScreen />
                </NonAuthLayout>
            </Provider>)

            ||

            (subdomain === "auth" && <Provider store={store}>
                <NonAuthLayout>
                    <Routes>
                        <Route key={"entry"} path={"/entry"} element={<AuthEntry />} />
                        <Route key={"entrypayload"} path={"/entry/:payload"} element={<AuthEntry />} />
                        <Route key={"login"} path={"/login"} element={<LoginScreenCustomer />} />
                        <Route key={"register"} path={"/register/*"} element={<RegisterScreenCustomer />} />
                        <Route path="*" element={<Navigate replace to={`/entry`} />} />
                    </Routes>
                </NonAuthLayout>
            </Provider>)

            ||

            (subdomain === "account" && <Provider store={accountStore}>
                <Websocket />
                <NonAuthLayout>
                    <Routes>
                        <Route key={"auth-return"} path={"/auth-return/:token"}
                            element={<AuthReturn />} />
                        <Route key={"*"} path="*" element={<AccountMainPage />} />
                    </Routes>
                </NonAuthLayout>
            </Provider>)

            ||

            <Provider store={store}>
                <Websocket />
                <Routes>
                    {routes.map((route: any, index: number) => {
                        return <Route
                            key={index}
                            path={route.path}
                            element={route.auth ?

                                <AuthProtected>
                                    <>
                                        <route.layout>
                                            <route.component />
                                        </route.layout>
                                    </>
                                </AuthProtected>

                                : <>
                                    <route.layout>
                                        <route.component />
                                    </route.layout>
                                </>
                            } />
                    })}
                    <Route path="*" element={<Navigate replace to={`/login`} />} />
                </Routes>
            </Provider>}
    </Router>);
};

export default App;
