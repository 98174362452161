import React, { useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { logoutUserAccount } from "store/actions/Auth";
import { getUser } from "store/actions/User";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../store/store";
import { bindActionCreators } from "redux";
import { UserState } from 'store/reducers/User';

interface Props {
  userState: UserState;
}

interface Actions {
  logoutUserAccount: () => void;
  getUser: () => void;
}

const ProfileMenu: React.FC<Actions & Props> = ({ userState, logoutUserAccount, getUser }) => {

  const user = userState.getUser();

  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (user === null || user.id === undefined) {
      getUser()
    }
  }, [getUser, user])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="d-none d-xl-inline-block ms-2 me-1">{user && user.name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile">
            <span className="dropdown-item pointer">
              <i className="bx bx-user font-size-16 align-middle me-1" />
              <span>Profile</span>
            </span>
          </Link>
          <div className="dropdown-divider" />
          <span onClick={logoutUserAccount} className="dropdown-item pointer">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </span>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};


const mapStateToProps = (state: RootState) => {
  return {
    userState: state.User
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ logoutUserAccount, getUser }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);