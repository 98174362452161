import WaitingDots from "components/WaitingDots";
import { Customer } from "models/Customer";
import { Person } from "models/Person";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { bindActionCreators } from "redux";
import { getPersonLoggedIn } from 'services/AccountApi';
import { RootStateAccount } from "storeAccount/accountStore";
import { getAuthCustomers } from "storeAccount/actions/Customers";
import { ReactComponent as LogoDark } from "../../assets/images/logo-dark.svg";
import { AuthenticationState } from "../../storeAccount/reducers/Authentication";
import { CustomerState } from "../../storeAccount/reducers/Customers";
import AccountMenu from "./AccountMenu";
import AccountDetailCard from "./CustomerDetails/AccountDetailCard";
import AccountInvoiceList from "./InvoiceQuoteViews/AccountInvoiceList";
import AccountPersonManagementCard from "./PersonManagement/AccountPersonManagementCard";
import SecurityPage from "./Security/SecurityPage";
import ServiceStatusBanner from "./ServiceStatus/ServiceStatusBanner";

interface Props {
    customerState: CustomerState;
    authentication: AuthenticationState;
}

interface Actions {
    getAuthCustomers: typeof getAuthCustomers;
}

const AccountMainPage: React.FC<Props & Actions> = ({
    authentication,
    customerState,
    getAuthCustomers
}) => {

    const [, setChange] = useState(false)
    const [customer, setCustomer] = useState<Customer | null>(null)
    const [customers, setCustomers] = useState<Customer[]>([])
    const [person, setPerson] = useState<Person | null>(null)

    useEffect(() => {

        if (authentication === "") {
            window.location.assign(`https://auth.galactic-group.nl/entry/${typeof window !== "undefined" && window.btoa(JSON.stringify({ redirect_url: `${window.location.origin}/auth-return` }))}`)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (authentication !== "") {

            getAuthCustomers().then(customers => {
                if (customers.length > 0) {
                    setCustomers(customers)
                    setCustomer(customers[0])
                }
            })

            getPersonLoggedIn().then((person) => {
                setPerson(person)
            })
        }

    }, [authentication, getAuthCustomers])

    if (person === null) {
        return (<WaitingDots />)
    }

    return (<>
        <Row>
            <Col lg={2} md={1} sm={0} />
            <Col lg={8} md={10} sm={12}>
                    <Container className={"text-center mt-3"}>
                        <LogoDark />
                    </Container>

                <div className="page-content">
                    {(customer?.vat === undefined || customer?.vat === "") &&
                        <Alert color="danger">Important: VAT-number is missing, please add your VAT-number <Link
                            to="/company-details">here</Link></Alert>}
                    <ServiceStatusBanner />
                    <Row>
                        <Col md={3} xs={12}>
                            <AccountMenu currentCustomer={customer} customers={customers} person={person}
                                setCustomer={setCustomer} />
                        </Col>
                        <Col md={9} xs={12}>
                            <Routes>
                                <Route key={"invoices"} path={"/invoices"}
                                    element={<AccountInvoiceList customer={customer} quotes={false}
                                        person={person} />} />
                                <Route key={"quotes"} path={"/quotes"}
                                    element={<AccountInvoiceList customer={customer} quotes={true}
                                        person={person} />} />
                                <Route key={"company-details"} path={"/company-details"}
                                    element={<AccountDetailCard customer={customer} setChange={setChange}
                                        setCustomer={setCustomer} />} />
                                <Route key={"my-details"} path={"/my-details"} element={customer ?
                                    <AccountPersonManagementCard setPerson={setPerson} person={person}
                                        customer={customer} /> : <><Card><CardBody>
                                            <div><p className="text-muted text-uppercase fw-semibold">Choose a
                                                company</p></div>
                                        </CardBody></Card></>} />
                                <Route key={"security"} path={"/security"} element={<SecurityPage />} />
                                <Route path="*" element={<Navigate replace to={`/invoices`} />} />

                            </Routes>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col md={0} xs={1} />
        </Row>
    </>);
}

const mapStateToProps = (state: RootStateAccount) => {
    return {
        customerState: state.Customers,
        authentication: state.Authentication,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getAuthCustomers }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountMainPage);