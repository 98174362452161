export class EmailReader {

  ip_address!: string;
  date!: string;

}

export class Email {

  constructor() {
    this.body = "";
  }

  tenant!: string;
  customer!: string;
  id!: string;
  reply_to!: string;
  subject!: string;
  body!: string;
  date!: number;
  reference!: string;
  carbon_copy!: string;
  attachments!: string[];
  attachment_count!: number;
  type!: string;
  direction!: string;
  starred!: boolean;
  recipient!: string;
  sender!: string;
  sender_name!: string;
  status!: string;
  reader!: EmailReader[];

  public IsRead(): boolean {
    return this.reader !== null && this.reader.length > 0;
  }

  public GetDate(): Date {
    return new Date(this.date * 1000);
  }
}

