import { Challenge } from "common/Challenge";
import Copyright from "components/Copyright";
import PageMeta from "components/PageMeta";
import RegistrationForm from "components/RegistrationForm";
// @ts-ignore
import md5 from "js-md5";
import { LoginModel } from "models/LoginModel";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Alert, Card, Col, Container, Row } from "reactstrap";
import { ReactComponent as LogoDark } from "../assets/images/logo-dark.svg";
import { getChallenge } from "../services/AccountApi";

const RegisterScreenCustomer: React.FC = () => {
    const [ solution, setSolution ] = useState<Challenge>( {
        solution : "",
        signature: ""
    } )
    const [ step, setStep ] = useState( 0 );

    const doChallenge = () => {
        setSolution( {
            solution : "",
            signature: ""
        } );

        getChallenge().then( challenge => {
            // @ts-ignore
            var x = parseInt( challenge.challenge, 10 )
            let doWork = function () {
                var count = 100, y, h;
                while ( --count > 0 ) {
                    x += 1;
                    y = x.toString();
                    h = md5( y );
                    if ( h.match( /^0000/ ) ) {
                        setSolution( {
                            solution : `${ x }`,
                            signature: challenge.signature
                        } );
                        return;
                    }
                }
                setTimeout( doWork, 5 )
            };
            doWork();
        } )
    }

    useEffect( () => {
        document.body.setAttribute( "data-layout-mode", "light" );
        document.body.setAttribute( "data-topbar", "light" );

        const interval = setInterval( () => {
            doChallenge()
        }, 60 * 1000 );
        doChallenge();
        return () => clearInterval( interval )
    }, [] )

    const [ error, setError ] = useState<string | null>( null );
    const [ done, setDone ] = useState( false );
    const [ login, setLogin ] = useState( new LoginModel() )

    return ( <>
        <PageMeta title={ `Register | Galactic-Group` } hideTitle={ true }/>
        <div className="account-pages my-0 pt-sm-5">
            <Container>
                <Row className="justify-content-center">

                    <Col md={ 10 } className="text-center">
                        <LogoDark/>
                        <div style={ { height: "55px" } }/>

                        <Row>
                            <Col lg={ 2 }/>
                            <Col lg={ 8 }>
                                <Card className="overflow-hidden" style={{textAlign:"left"}}>
                                    <div style={ {
                                        height      : "8px",
                                        width       : "100%",
                                        borderRadius: "3px",
                                        padding     : 0,
                                        marginBottom: "20px",
                                    } }>
                                        <div style={ {
                                            height    : "6px",
                                            padding   : 0,
                                            width     : ( ( ( step - 1 ) * 32 ) + 1  + (solution.solution !== "" ? 3 : 0)) + "%",
                                            background: "lightblue",
                                            transition: "width 0.5s"
                                        } }></div>
                                    </div>
                                    {step === 4 ?
                                        <div className={ "pt-1 text-center" }>
                                            <h3>Awesome!</h3>
                                        </div>
                                        :
                                        <div className={ "pt-1 pb-5 text-center" }>
                                            <h5>Create a new account</h5>
                                            <Link to={ `/` }>Or go to login</Link>
                                        </div>
                                    }
                                    { done ?

                                        <div className="g-0">
                                            <div className="p-lg-5 p-4">
                                                <div className="mt-4 pt-0 text-center">
                                                    <h5>Great</h5>
                                                    We have sent you an email to confirm it is really you. Please
                                                    click on the link in the email to continue.
                                                </div>
                                            </div>
                                        </div> :

                                        <div className="g-0">
                                            <div className="p-lg-5 p-4">
                                                <div className="mt-2 pt-0">


                                                    { error && <Alert color="danger">{ error }</Alert> }
                                                    <RegistrationForm
                                                        onStepChange={ step => setStep( step ) }
                                                        challenge={solution}
                                                        />

                                                </div>
                                            </div>
                                        </div> }
                                </Card>
                                <div className={ "text-center" }>
                                    <Copyright/>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    </> );
};

export default connect( null, null )( RegisterScreenCustomer );
