import PageMeta from "components/PageMeta";
import RevenueCard from "components/RevenueCard";
import UnreadEmailCard from "pages/Email/UnreadEmailCard";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import SalesAnalytics from "./SalesAnalytics";
import RecentCallsCard from "./RecentCallsCard";
import OrphanedPaymentsCard from "./OrphanedPaymentsCard";
import ServiceStatusBannerDashboard from "./ServiceStatusBannerDashboard";

const Dashboard: React.FC = () => {
    return (
        <div className="page-content">
            <PageMeta title={`Dashboard`} />
            <Container fluid>
                <Row>
                    <Col xl={8}>

                        <SalesAnalytics />
                    </Col>
                    <Col xl={4}>
                        <RevenueCard />
                        <ServiceStatusBannerDashboard />
                        <UnreadEmailCard />
                        <OrphanedPaymentsCard />
                        <RecentCallsCard />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Dashboard;
