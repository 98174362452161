import React from "react";
import { Card, CardBody } from "reactstrap";
import InvoiceListOnlyFinals from "./InvoiceListOnlyFinals";
import { Person } from "models/Person";
import { Customer } from "models/Customer";

interface Props {
    person: Person;
    quotes: boolean;
    customer: Customer | null;
}

const AccountInvoiceList: React.FC<Props> = ({ customer, quotes, person }) => {

    if (customer === null) {
        return (
            <Card>
                <CardBody>
                    <div>
                        <p className="text-muted text-uppercase fw-semibold">Please choose a company from the selection in the menu on the left</p>
                    </div>
                </CardBody>
            </Card>
        )
    }

    return (
        <Card>
            <CardBody>
                {quotes ? <div>
                    <p className="text-muted text-uppercase fw-semibold">Quotes</p>
                </div> :
                    <div>
                        <p className="text-muted text-uppercase fw-semibold">Invoices</p>
                    </div>
                }
                <InvoiceListOnlyFinals customer={customer} quotes={quotes} person={person} />
            </CardBody>
        </Card>
    );
};

export default AccountInvoiceList;
