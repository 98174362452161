import {generateAction, RequestTypes} from "./Util";
import * as api from "../../services/Api";

export const GET_TENANTS = new RequestTypes("TENANTS");
export const ADD_USER_TENANTS = new RequestTypes("ADD_USER_TENANTS");
export const ADD_TENANT = new RequestTypes("ADD_TENANT");
export const GET_TENANT = new RequestTypes("GET_TENANT");
export const DELETE_USER_TENANTS = new RequestTypes("DELETE_USER_TENANTS");

export const addTenant = (name: string) => {
  return generateAction(ADD_TENANT, () =>
    api.addTenant(name)
  );
};

export const getTenant = (id: string) => {
  return generateAction(GET_TENANT, () =>
    api.getTenant(id)
  );
};

export const getTenants = () => {
  return generateAction(GET_TENANTS, () =>
    api.getTenants()
  );
};

export const addUserToTenant = (userId: string, tenantId: string) => {
  return generateAction(ADD_USER_TENANTS, () =>
    api.addUserToTenant(userId, tenantId)
  );
};

export const deleteUserFromTenant = (userId: string, tenantId: string) => {
  return generateAction(DELETE_USER_TENANTS, () =>
    api.deleteUserFromTenant(userId, tenantId)
  );
};