import { Check } from 'models/Check';
import { Service } from 'models/Service';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { RootState } from 'store/store';
import CheckServiceAdd from './CheckServiceAdd';
import ServiceCheckRow from './ServiceCheckRow';


interface Props {
    service: Service;
}


const CheckManagmentCard: React.FC<Props> = ({ service }) => {
    const [add, setAdd] = useState<boolean>(false)
    const [checks, setChecks] = useState<Check[]>([])

    useEffect(() => {
        setChecks(service.checks)
    }, [service.checks])


    return (
        <Card>
            <CardBody>
                <div>
                    <p className="text-muted text-uppercase fw-semibold md-0" style={{ fontSize: "13px" }}>Checks</p>
                </div>
                <hr />
                {
                    !add ?
                        checks.map((check, i) => {
                            return <ServiceCheckRow service={service} check={check} index={i} setChecks={setChecks} />
                        })
                        : <CheckServiceAdd service={service} check={new Check()}
                            onCancel={() => {
                                setAdd(false);
                            }
                            }
                            save={() => {
                                setAdd(false);
                            }}
                            setChecks={setChecks}
                        />
                }


                {!add && <div className="mt-3">
                    <button
                        id="buttonPerson"
                        name="buttonPerson"
                        type="button"
                        onClick={() => {
                            setAdd(true);
                        }}
                        className="btn btn-primary w-100 waves-effect waves-light"
                    >
                        Add check
                    </button>
                </div>}

            </CardBody>
        </Card >
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        servicesState: state.Services
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckManagmentCard);
