import {Tenant} from "./Tenant";

export class User {
    id!: string;
    name!: string;
    email!: string;
    activated!: boolean;
    region!: string;
    phone!: string;
    accounts!: string[];


    public AddTenantToUser(tenant: Tenant) {
        this.accounts = [...this.accounts.filter(account => account !== tenant.id), tenant.id]
    }
    
    public RemoveTenantFromUser(tenant: Tenant) {
        this.accounts = this.accounts.filter(account => account !== tenant.id)
    }

}