import { Service, ServiceHistory } from 'models/Service';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { getServiceHistory } from "../../services/Api";
import DashedLine from 'components/DashedLine';
import { RootState } from 'store/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RemoveServiceButton from './RemoveServiceButton';
import ChecksStatusCard from './ChecksStatusCard';
import ServiceEditForm from './ServiceEditForm';
import CheckManagementCard from './CheckManagementCard';

interface Props {
    service: Service;
}

interface Actions {

}

const ServiceDetailPage: React.FC<Props & Actions> = ({ service }) => {

    const [history, setHistory] = useState<ServiceHistory | null>(null)
    const [data, setData] = useState<{ [check: string]: { name: string, data: number[] } }>({})
    const [lastChecks, setLastChecks] = useState<{ [id: string]: string }>({})

    const [edit, setEdit] = useState<boolean>(false)

    useEffect(() => {
        setLastChecks({})
        getServiceHistory(service.id).then(newHistory => setHistory(newHistory))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const checks = service.checks;
        checks.forEach(check => {
            if (check.last_result) {
                if (check.last_result.result.hasOwnProperty("error") || lastChecks[check.id] === check.last_result.id || check.last_result.service_id !== service.id || history === null) return;
                Object.keys(check.last_result.result).forEach(metric => {
                    if (history[check.id][metric] !== undefined) {

                        if (check.last_result.ok) {
                            history[check.id][metric].push(check.last_result.result[metric])
                        } else {
                            history[check.id][metric].push(check.last_result.result[metric] * -1)
                        }
                    }
                })

                lastChecks[check.id] = check.last_result.id;
            }
        })
        setHistory(history)
        setLastChecks(lastChecks);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service.checks]);

    useEffect(() => {
        if (history === null) return;

        const result: any = [];
        Object.keys(history).forEach((checkId, i) => {
            const check = history[checkId];
            return Object.keys(check).filter(metricName => check[metricName].length > 0).map((metricName) => result.push({
                name: service.checks.find(checkS => checkS.id === checkId)?.name + " " + metricName,
                data: check[metricName]
            }));
        });
        setData(result);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);


    let errors = 0;

    service.checks.forEach(check => {
        if (check.last_result) {
            if (!check.last_result.ok) {
                errors = errors + 1;
            }
        }
    })

    return (
        <>

            <Row>
                <Col md={6} xl={4}>
                    {
                        !edit ?
                            <Card>
                                <CardBody>
                                    <div className="float-end">
                                        <div className="avatar-sm mx-auto mb-4">
                                            <span className="avatar-title rounded-circle bg-soft font-size-24">
                                                {
                                                    errors > 0 ? <i className={`bx bx-error-circle bx-md`} style={{ fontSize: "55px", color: "orange" }} /> : <i className={`bx bx-check-circle bx-md`} style={{ fontSize: "55px", color: "rgb(0 228 150)" }} />
                                                }

                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="text-muted text-uppercase fw-semibold">{service.name}</p>
                                    </div>
                                    <p>
                                        <span className="badge rounded-pill badge-soft-primary" style={{ fontSize: "12px", fontWeight: "bold" }}> Region: {service.region ? service.region : "-"}</span> <br />
                                        <span className="badge rounded-pill badge-soft-dark" style={{ fontSize: "12px", fontWeight: "bold" }}>  Tenant: {service.tenant ? service.tenant : "-"}</span>
                                    </p>

                                    <div>
                                        <button
                                            type="button"
                                            style={{ marginTop: '5px', width: "100%" }}
                                            onClick={() => {
                                                setEdit(true)
                                            }}
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Edit service
                                        </button>
                                    </div>
                                    <RemoveServiceButton service={service} />
                                </CardBody>
                            </Card>
                            :
                            <ServiceEditForm serviceById={service} setEdit={setEdit} />
                    }

                    <CheckManagementCard service={service} />


                </Col>

                <Col md={9} xl={8}>
                    <Card>
                        <CardBody>
                            <div>
                                <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>Live graphic</p>
                            </div>

                            {data &&
                                <DashedLine data={Object.values(data)} />
                            }
                        </CardBody>
                    </Card>
                    <ChecksStatusCard service={service} />
                </Col>
            </Row>


        </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        servicesState: state.Services
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetailPage);