import { RequestTypes, generateAction, EventType } from "../../storeAccount/actions/Util";
import * as Api from "../../services/Api";
import { RegisterWsHandler } from "./Index";
import { Service } from "models/Service";

export const GET_SERVICES = new RequestTypes("GET_SERVICES");
export const ADD_SERVICE = new RequestTypes("ADD_SERVICE");
export const GET_SERVICE = new RequestTypes("GET_SERVICE");
export const EDIT_SERVICE = new RequestTypes("EDIT_SERVICE");
export const REMOVE_SERVICE = new RequestTypes("REMOVE_SERVICE");
export const SERVICE_LAST_CHECK_UPDATED = new EventType("SERVICE_LAST_CHECK_UPDATED");

export const getServices = () =>
    generateAction(GET_SERVICES, Api.getServices);


export const addService = (service: Service) =>
    generateAction(ADD_SERVICE, () =>
        Api.addService(service)
    );

export const getService = (id: string) => {
    return generateAction(GET_SERVICE, () =>
        Api.getService(id)
    );
};


export const editService = (service: Service) => {
    return generateAction(EDIT_SERVICE, () =>
        Api.editService(service)
    );
};

export const removeService = (service: Service) => {
    return generateAction(REMOVE_SERVICE, () =>
        Api.removeService(service)
    );
};

export const serviceLastCheckUpdated = (payload: any) =>
    (dispatch: any) =>
        dispatch(SERVICE_LAST_CHECK_UPDATED.payload(payload))
RegisterWsHandler("last_check_update", serviceLastCheckUpdated)
