import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCustomer } from "store/actions/Customers";
import { addInvoice } from "store/actions/Billing";
import WaitingDots from "components/WaitingDots";
import { RootState } from "store/store";
import { Col, Row } from "reactstrap";
import { GetRouterQuery } from "components/Utils/GetRouterQuery";
import { Invoice } from "models/Invoice";
import RevenueCard from "components/RevenueCard";
import CustomerInvoiceList from "./CustomerInvoiceList";
import CustomerDetailCard from "./CustomerDetailCard";
import CustomerDatesCard from "./CustomerDatesCard";
import CustomerVatCheckCard from "./CustomerVatCheckCard";
import PersonManagementCard from "./PersonManagementCard";
import { CustomerState } from "store/reducers/Customers";
import CustomerEmails from "./CustomerEmails";
import RecurringManagementCard from "./RecurringManagementCard";
import ComputersCard from "./ComputersCard";

interface Props {
    customerState: CustomerState;
}

interface Actions {
    getCustomer: (id: string) => void;
    addInvoice: (i: Invoice) => void;
}

const CustomerDetail: React.FC<Props & Actions> = ({ customerState, getCustomer, addInvoice }) => {

    const { id } = GetRouterQuery<{ id: string }>()
    const customer = customerState.getCustomerById(id);

    useEffect(() => {
        if (customer === null) {
            getCustomer(id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (customer === null) {
        return <WaitingDots />
    }

    return (
        <div className="page-content">
            <Row>
                <Col md={6} xl={3}>
                    <CustomerDetailCard customer={customer} />
                    <CustomerDatesCard customer={customer} />
                    <CustomerVatCheckCard customer={customer} />
                    <ComputersCard customer={customer} />
                </Col>

                <Col md={6} xl={5}>
                    <CustomerInvoiceList customer={customer} />
                </Col>

                <Col md={6} xl={4}>
                    <RevenueCard customerId={customer.id} />
                    <CustomerEmails customer={customer} />
                    <RecurringManagementCard customer={customer} />
                    <PersonManagementCard customer={customer} />
                </Col>
            </Row>
        </div>
    );
}



const mapStateToProps = (state: RootState) => {
    return {
        customerState: state.Customers
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getCustomer, addInvoice }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);
