import React, { useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootStateAccount } from "../storeAccount/accountStore";
import { bindActionCreators } from "redux";
import { setToken } from "../storeAccount/actions/Auth"


interface Props {
}

interface Actions {
    setToken: (s: string) => void;
}

const AuthReturn: React.FC<Props & Actions> = ({ setToken }) => {

    const navigate = useNavigate();

    const args = useParams<{ token: string }>();

    useEffect(() => {
        setToken(JSON.stringify(args.token))
        setTimeout(() => {
            navigate("/")
        }, 100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [args]);

    return <></>;
}

const mapStateToProps = (state: RootStateAccount) => {
    return {
        authenticateState: state.Authentication
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ setToken }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthReturn)