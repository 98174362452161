import React, {useState} from "react";
import {Button, ButtonProps} from "reactstrap";

const LongClickButton: React.FC<ButtonProps> = (props) => {
    const [timeout, sTimeout] = useState<NodeJS.Timeout | undefined>()

    const { children, onClick } = props;

    const startCount = (event: any) => {
        const ntimeout = setTimeout(() => {
            if (onClick !== undefined) {
                onClick(event)
            }
        }, 2000)
        sTimeout(ntimeout)
    }

    const stopCount = () => {
        if (timeout !== undefined) {
            clearTimeout(timeout)
            sTimeout(undefined)
        }
    }

    return <Button
        {...props} 
        className={`${props.className} lcb lcb-${props.color} ${timeout !== undefined ? "lcb-active" : ""}`}
        onMouseDown={startCount}
        onMouseUp={stopCount}
        onMouseLeave={stopCount}
        onClick={() => { }}
    >
        {children}
    </Button>
}

export default LongClickButton;
