import WaitingDots from 'components/WaitingDots';
import { Invoice } from 'models/Invoice';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { RootState } from 'store/store';
import { getEmails } from "store/actions/Emails";
import { bindActionCreators } from 'redux';
import { EmailState } from 'store/reducers/Emails';
import EmailRowInbox from "../Email/EmailRowInbox";

interface Props {
    invoice: Invoice;
    emailState: EmailState;
}

interface Actions {
    getEmails: () => void;
}


const InvoiceEmails: React.FC<Props & Actions> = ({ invoice, emailState, getEmails }) => {


    useEffect(() => {
        getEmails()
    }, [getEmails])

    if (emailState.getEmails() === null) {
        return <WaitingDots />
    }

    return (
        <Card>
            <CardBody>
                <div>
                    <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>
                        Sent mails
                    </p>
                </div>
                <hr />

                <ul className="message-list">
                    {emailState.getEmails()
                        .filter(email => "invoice_" + invoice.id === email.reference)
                        .sort((a, b) => (a.date < b.date) ? 1 : (a.date > b.date) ? -1 : 0)
                        .map((email, key) => (
                            <EmailRowInbox email={email} hideLabels={true} />
                        ))}
                    {emailState.getEmails().length === 0 &&
                        <div style={{ textAlign: "center" }}>
                            <WaitingDots />
                        </div>
                    }
                </ul>
            </CardBody>
        </Card>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        invoices: state.Invoices,
        emailState: state.Emails,
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getEmails }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(InvoiceEmails);