import { Customer } from "models/Customer";
import { LOGOUT_ACCOUNT } from "storeAccount/actions/Auth";
import { createReducer } from "./Util";
import { EDIT_CUSTOMER, GET_AUTH_CUSTOMER } from "storeAccount/actions/Customers";

export class CustomerState {

  private customers: { [index: string]: Customer }

  constructor() {
    this.customers = {}
  }

  public setCustomers(customers: Customer[]) {
    this.customers = {}
    customers.forEach(customer => {
      this.setCustomer(customer)
    })
    return this
  }

  public isFetched(): boolean {
    return Object.values(this.customers).length > 0;
  }

  public setCustomer(customer: Customer) {
    this.customers[customer.id] = customer
    return this
  }

  public getCustomerById(id: string): Customer | null {
    return this.customers[id] ?? null
  }

  public findByEmail(email: string): Customer[] {
    let found: { [index: string]: Customer } = {}
    Object.values(this.customers).forEach(customer => {
      if (customer.email === email) {
        found[customer.id] = customer;

      }
    })
    return Object.values(found);
  }

  public getCustomers(): Customer[] {
    return Object.values(this.customers)
  }

  public getCustomersByTenantId(tenantId: string): Customer[] {
    return Object.values(this.customers).filter(x => x.tenant === tenantId)
  }

  public getCustomersByIds(ids: string[]): Customer[] {
    return Object.values(this.customers).filter(function (el) {
      return ids.indexOf(el.id) >= 0;
    });
  }

}

const Customers = createReducer<CustomerState>(new CustomerState())
  .handle<Customer[]>([GET_AUTH_CUSTOMER.SUCCESS], (state, payload) => state.setCustomers(payload))
  .handle<Customer>([EDIT_CUSTOMER.SUCCESS], (state, payload) => state.setCustomer(payload))
  .handle([LOGOUT_ACCOUNT.SUCCESS], () => new CustomerState());

export default Customers.handler;
