import Date from 'components/Date';
import WaitingDots from 'components/WaitingDots';
import { Email } from 'models/Email';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { starEmail } from "store/actions/Emails";
import { RootState } from 'store/store';

interface Props {
    email: Email;
    threadSize?: number;
    hideTo?: boolean;
    hideLabels?: boolean;
    hideDirection?: boolean;
}


interface Actions {
    starEmail: (e: Email, b: any) => void;
}


const EmailRowInbox: React.FC<Props & Actions> = ({ email, hideDirection, hideLabels, hideTo, threadSize, starEmail }) => {

    if (hideLabels === undefined) {
        hideLabels = false
    }
    if (hideDirection === undefined) {
        hideDirection = false
    }
    if (hideTo === undefined) {
        hideTo = false
    }
    if (email === undefined) {
        return <WaitingDots />
    }

    const star = () => {
        if (email.starred) {
            starEmail(email, { "starred": false })
        } else {
            starEmail(email, { "starred": true })
        }
    }

    return (

        <Link to={"/conversation/" + email.id} style={{ height: "600%" }}>
            <li key={email.id} className={`red-hover mail-row ${!email.IsRead() && "text-bold"}`}>
                <div className="col-mail col-mail-1">
                    {!hideTo &&
                        <>
                            {email.direction === "in" ?
                                <>
                                    <i className="title" title={email.sender}>{!hideDirection && "From: "}
                                        {email.sender_name === "" ? email.sender : email.sender_name}
                                        {threadSize && threadSize > 1 && <span className="badge bg-primary rounded-pill" style={{ marginLeft: "5px", fontStyle: "normal" }}>{threadSize}</span>}
                                    </i>
                                </>
                                :
                                <>
                                    <i className="title" title={email.recipient}>{!hideDirection && "To: "}{email.recipient.split(" ")[0]}</i>
                                </>
                            }
                        </>
                    }
                    {
                        email.starred ?
                            <span onClick={(e) => {
                                e.preventDefault()
                                star()
                            }} className={"star-toggle text-warning bx bxs-star"} />
                            :
                            <span onClick={(e) => {
                                e.preventDefault()
                                star()
                            }} className={"star-toggle bx bx-star"} />
                    }
                    <br />
                </div>
                <div className="col-mail col-mail-2">
                    <div className="subject" style={{ marginTop: "5px" }}>
                        {email.subject}{" "}
                        {(!hideLabels && email.reference.includes("invoice")) &&
                            <Link to={"/invoice/" + email.reference.replace('invoice_', '')}>
                                <span className="badge bg-success me-1 font-size-12 me-2 mb-3">
                                    Invoice
                                </span>
                            </Link>
                        }
                        {(!hideLabels && email.reference.includes("ticket")) &&
                            <span className="badge bg-danger me-1 font-size-12 me-2 mb-3">
                                Ticket
                            </span>
                        }
                        <span className="teaser" style={{ color: "grey" }}>
                            - {email.body.replaceAll("<br />", " ").replace(/(\r\n|\n|\r)/gm, "").replaceAll(/<blockquote (.*?)>(.*?)<\/blockquote>/img, "").replaceAll(/<style(.*?)>(.*?)<\/style>/img, "").replaceAll(/<(.*?)>/img, " ").replaceAll(/\s{2,}/img, " ")}
                        </span>
                    </div>
                    <div className="date">
                        <Date date={email.GetDate()} />
                    </div>
                </div>
            </li>
        </Link>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        customers: state.Customers,
        emails: state.Emails
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ starEmail }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailRowInbox);