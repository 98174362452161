


export const GetPasswordStrength = (password: string): number => {

    let passwordStrength = 0;
    passwordStrength += ( password.length * ( password.length > 5 ? 4 : 1 ) > 70 ) ? 70 : password.length * 5;
    if ( /\d/g.test( password ) ) {
        passwordStrength += 10;
    } else {
        passwordStrength -= 20;
    }
    if ( /[A-Z]/g.test( password ) ) {
        passwordStrength += 10;
    } else {
        passwordStrength -= 5;
    }
    if ( /[a-z]/g.test( password ) ) {
        passwordStrength += 10;
    } else {
        passwordStrength -= 5;
    }
    if ( /[.!?*^$@_-]/g.test( password ) ) {
        passwordStrength += 10;
    } else {
        passwordStrength -= 20;
    }

    return passwordStrength < password.length ? password.length : passwordStrength
}
