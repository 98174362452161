import {UuidNil} from "../common/uuid";

export class Supplier {

  constructor(name: string) {
    this.id = UuidNil;
    this.name = name;
  }

  id: string;
  name: string;
}
export class ExpenseAttachment {

  id!: string;
  name!: string;
  content!: string;
}

export class Expense {

  constructor() {
    this.date = new Date()
  }

  id!: string;
  name!: string;
  tenant!: string;
  date!: Date;
  supplier!: Supplier;

  tax!: number;
  total!: number;
  attachments!: number;

  metadata!: object;

  public GetCreatedDate(): Date {
    return new Date(this.date)
  }

  public GetDate(): Date {
    return new Date(this.date)
  }

  public CalcTotalContents(): number {
    return this.total;
  }

  public CalcTaxes(amount?: number): number {
    if (amount === undefined) {
      amount = 21
    }
    return this.CalcTotalContents() / 100 * amount
  }

  public CalcGrandTotal(tax_amount?: number): number {
    return this.CalcTotalContents() + this.CalcTaxes(tax_amount)
  }
}
