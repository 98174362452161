import { generateAction, RequestTypes } from "./Util";
import * as AccountApi from "../../services/AccountApi";
import { Person } from "models/Person";

export const GET_AUTH_CUSTOMER = new RequestTypes("GET_AUTH_CUSTOMER");
export const EDIT_CUSTOMER = new RequestTypes("EDIT_CUSTOMER");
export const GET_CUSTOMER_PERSONS = new RequestTypes("GET_CUSTOMER_PERSONS");
export const UPDATE_PERSON = new RequestTypes("UPDATE_PERSON");

export const getAuthCustomers = () => {
  return generateAction(GET_AUTH_CUSTOMER, () =>
    AccountApi.getAuthCustomers());
};

export const editCustomerVat = (id: string, vat: string) => {
  return generateAction(EDIT_CUSTOMER, () =>
    AccountApi.editCustomerVat(id, vat)
  );
};

export const getCustomerPersons = (id: string) => {
  return generateAction(GET_CUSTOMER_PERSONS, () =>
    AccountApi.getCustomerPersons(id)
  );
};

export const updatePerson = (person: Person) => {
  return generateAction(UPDATE_PERSON, () =>
    AccountApi.updatePerson(person)
  );
};
