import {LOGOUT_USER_ACCOUNT} from "store/actions/Auth";
import {createReducer} from "./Util";
import {Invoice} from "models/Invoice";
import {
    ADD_INVOICE,
    ADD_INVOICE_CONTENT,
    CONVERT_INVOICE,
    DELETE_INVOICE,
    DELETE_INVOICE_CONTENT,
    EDIT_BASIC_DATA_INVOICE,
    FINALIZE_INVOICE,
    GET_INVOICE,
    GET_INVOICES, GET_ORPHANED_PAYMENTS, LINK_ORPHANED_PAYMENT
} from "store/actions/Billing";
import {InvoicePayment} from "../../models/InvoicePayment";

export class InvoiceState {

    private invoices: { [index: string]: Invoice }
    private orphanedPayments: { [index: string]: InvoicePayment }

    constructor() {
        this.invoices = {}
        this.orphanedPayments = {}
    }

    public setInvoices(invoices: Invoice[]) {
        invoices.forEach(invoice => {
            this.setInvoice(invoice)
        })
        return this
    }

    public setInvoice(invoice: Invoice) {
        this.invoices[invoice.id] = invoice
        return this
    }

    public setPayment(payment: InvoicePayment) {
        this.orphanedPayments[payment.id] = payment
        return this
    }

    public deleteInvoice(id: string) {
        delete this.invoices[id]
        return this
    }

    public deletePayment(id: string) {
        delete this.orphanedPayments[id]
        return this
    }

    public getInvoiceById(id: string): Invoice | null {
        return this.invoices[id] ?? null
    }

    public getInvoices(): Invoice[] {
        return Object.values(this.invoices)
    }

    public getOrphanedPayments(): InvoicePayment[] {
        return Object.values(this.orphanedPayments)
    }

    public getInvoicesFromTenant(tenantId: string): Invoice[] {
        return Object.values(this.invoices).filter(invoice => invoice.tenant === tenantId)
    }

    public getInvoicesByCustomerId(customerId: string): Invoice[] {
        return Object.values(this.invoices).filter((x) => x.customer === customerId)
    }

}


const Invoices = createReducer<InvoiceState>(new InvoiceState())
    .handle<Invoice[]>([GET_INVOICES.SUCCESS], (state, payload) => state.setInvoices(payload))
    .handle<InvoicePayment[]>([GET_ORPHANED_PAYMENTS.SUCCESS], (state, payload) => {
        payload.forEach(payment => {
            state.setPayment(payment)
        })
        return state
    })
    .handle<Invoice>([LINK_ORPHANED_PAYMENT.SUCCESS], (state, payload) => {
        const inv = state.getInvoiceById(payload.id)
        if (inv) {
            inv.payments = payload.payments;
            state.setInvoice(inv);
        }
        payload.payments.forEach(payment => {
            state.deletePayment(payment.id)
        })
        return state
    })
    .handle<Invoice>([ADD_INVOICE.SUCCESS], (state, payload) => state.setInvoice(payload))
    .handle<Invoice>([ADD_INVOICE_CONTENT.SUCCESS], (state, payload) => state.setInvoice(payload))
    .handle<Invoice>([DELETE_INVOICE_CONTENT.SUCCESS], (state, payload) => state.setInvoice(payload))
    .handle<Invoice>([CONVERT_INVOICE.SUCCESS], (state, payload) => state.setInvoice(payload))
    .handle<Invoice>([FINALIZE_INVOICE.SUCCESS], (state, payload) => state.setInvoice(payload))
    .handle<Invoice>([EDIT_BASIC_DATA_INVOICE.SUCCESS], (state, payload) => state.setInvoice(payload))
    .handle<string>([DELETE_INVOICE.SUCCESS], (state, payload) => state.deleteInvoice(payload))
    .handle<Invoice>([GET_INVOICE.SUCCESS], (state, payload) => state.setInvoice(payload))
    .handle([LOGOUT_USER_ACCOUNT.SUCCESS], () => new InvoiceState());

export default Invoices.handler;